export enum DefaultCommandTypes {
    Save = 1,
    Copy = 2,
    New = 3,
    Add = 4,
    Edit = 5,
    Delete = 6,
    Remove = 7,
    ToggleActive = 8,
    Share = 10,
    Print = 11,
    Export = 12,
    Email = 13,
}