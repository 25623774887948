import React, { useState } from 'react';

import {
    DetailsListLayoutMode, SelectionMode, IColumn,
} from 'office-ui-fabric-react/lib';

import {
    BaseListView,
    DetailsList,
    ListViewCommandBarTypes
} from 'components/common';

import {
    MenuAPI,
} from 'api';

import { Loader } from 'components/common';
import { IDefaultCommand } from 'interfaces';
import { DefaultCommandTypes } from 'enums';
import { IListViewProps } from './interfaces';
import TileList from '../TileList';

export const ListView = (props: IListViewProps): JSX.Element => {
    const {
        menu,
        loading,
        dataLoading,
        title,
        newContextMenuItems,
        printContextMenuItems,
        exportContextMenuItems,
        commandBarItems,
        overflowCommandBarItems,
        isInsertCommandBarItemsItemsBeforeDefaultCommands,
        availableListViewCommandBarTypes,
        checkable,
        tileListCellProps,
        detailsListColumns,
        orderByRules,
        disableServerSideSort,
        disabledServerSideSortFields,
        items,
        selection,
        pagingProps,
        readOnly,
        compact,
        informationPanelContentVisibleByDefault,
        isSub,
        onRenderContentBeforeCommandBar,
        onDefaultCommandClick,
        onRenderFilterBar,
        onOrderByChange,
        onRefreshButtonClick,
        onRenderDetailsListItemColumn,
        onRenderContent,
        onRenderTileListCell,
        onRenderGroupListComponent,
        onRenderInformationPanelContent,
        onItemClick,
        getInitialFocusedIndex
    } = props;

    const [listViewCommandBarType, setListViewCommandBarType] = useState<ListViewCommandBarTypes>(availableListViewCommandBarTypes?.length > 0 ? availableListViewCommandBarTypes[0] : ListViewCommandBarTypes.List);
    const [informationPanelContentVisible, setInformationPanelContentVisible] = useState(informationPanelContentVisibleByDefault);

    const onColumnClick = (column: IColumn) => {
        if (onOrderByChange) {
            onOrderByChange(column.fieldName || "", (!column.isSortedDescending && column.isSorted));
        }
    }

    const onDetailsListRowDidMount = (item?: any, index?: number) => {
        if (pagingProps && index < (items?.length - 1)) {

            console.log("onDetailsListRowDidMount", pagingProps.page + 1)
            pagingProps.onPageChanged(pagingProps.page + 1);
        }
    }

    const getDefaultCommands = (): IDefaultCommand[] => {

        const commands: IDefaultCommand[] = [];

        if (!readOnly) {
            commands.push({
                type: isSub ? DefaultCommandTypes.Add : DefaultCommandTypes.New,
                subMenuItems: newContextMenuItems?.length > 0 && newContextMenuItems
            });

            if (printContextMenuItems?.length > 0) {
                commands.push({
                    type: DefaultCommandTypes.Print,
                    subMenuItems: printContextMenuItems
                });
            }

            if (exportContextMenuItems?.length > 0) {
                commands.push({
                    type: DefaultCommandTypes.Export,
                    subMenuItems: exportContextMenuItems
                });
            }

            commands.push({ type: DefaultCommandTypes.Remove, isDisabled: selection?.getSelectedCount() === 0 });
        }

        return commands;
    }

    const _onRenderContent = (): React.ReactNode => {

        if (onRenderContent) {

            onRenderContent(listViewCommandBarType)

        } else if (items) {
            switch (listViewCommandBarType) {
                case ListViewCommandBarTypes.GroupList:
                    if (onRenderGroupListComponent) {
                        return onRenderGroupListComponent();
                    }
                case ListViewCommandBarTypes.Tiles:
                    return dataLoading ? <Loader /> : (
                        <TileList
                            items={items}
                            cellProps={tileListCellProps}
                            readOnly={readOnly}
                            selection={selection}
                            onRenderCell={onRenderTileListCell}
                            onItemClick={onItemClick} />
                    );
                default:
                    return (
                        <DetailsList
                            items={items}
                            selection={selection}
                            orderByRule={orderByRules?.length > 0 ? orderByRules[0] : undefined}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionMode={checkable ? SelectionMode.multiple : SelectionMode.single}
                            noBorder={true}
                            compact={compact}
                            readOnly={readOnly}
                            dataLoading={dataLoading}
                            detailsListColumns={detailsListColumns}
                            disableServerSideSort={disableServerSideSort}
                            onColumnClick={onColumnClick}
                            onRenderItemColumn={onRenderDetailsListItemColumn}
                            onItemLinkClick={onItemClick}
                            getInitialFocusedIndex={getInitialFocusedIndex}
                        />
                    );
            }
        }
    }

    const onToggleInformationButtonClick = () => {
        setInformationPanelContentVisible((v) => !v);
    }


    return (
        <BaseListView
            isLoading={loading}
            titleBarProps={title && {
                title: title || menu.Name,
                iconName: MenuAPI.getIconById(menu.Id)
            }}
            onRenderContentBeforeCommandBar={onRenderContentBeforeCommandBar}
            listviewCommandBarProps={{
                menuPrivilege: menu.Privilege,
                defaultCommands: getDefaultCommands && getDefaultCommands(),
                items: commandBarItems,
                overflowItems: overflowCommandBarItems,
                isInsertItemsBeforeDefaultCommands: isInsertCommandBarItemsItemsBeforeDefaultCommands,
                listViewCommandBarTypes: availableListViewCommandBarTypes,
                activeListViewCommandBarType: listViewCommandBarType,
                informationButtonChecked: informationPanelContentVisible,
                onDefaultCommandClick: onDefaultCommandClick,
                onListViewTypeButtonClick: (type) => setListViewCommandBarType(type),
                onRefreshButtonClick: onRefreshButtonClick,
                onInformationButtonClick: onRenderInformationPanelContent ? onToggleInformationButtonClick : undefined
            }}
            onRenderFilterBar={onRenderFilterBar}
            onRenderContent={_onRenderContent}
            onRenderRightPanelContent={informationPanelContentVisible && onRenderInformationPanelContent}
            pagingProps={pagingProps}
        />
    )
}