export enum ProductDocumentDetailViewModelFields {
    AVAILABLE_QUANTITY = "AvailableQuantity",
    DOCUMENT_ID = "DocumentId",
    ID = "Id",
    PRODUCT_ID = "ProductId",
    QUANTITY = "Quantity",
    SIZE_CODE = "SizeCode",
    SIZE_ID = "SizeId",
    SIZE_NAME = "SizeName",
    UNIT_PRICE = "UnitPrice"
}