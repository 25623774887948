import { BaseAPI } from 'api';
import { IFilterResultInfo } from 'interfaces/models';

export class ProductStockAPI {

    public static getProductStockItemFilterContext = (onSuccess: (data: any) => void) => {
        
        BaseAPI.get(`Api/Lgs/ProductStock/GetFilterContext`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [
                        { FieldName: "Name", IsDescending: false },
                    ],
                    PageSize: 50
                }
            }));
    }

    public static filterProductStockItems = (filterContext: any, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Lgs/ProductStock/Filter", filterContext, onSuccess);
    }

}