import React, { useEffect } from 'react';
import { Stack, StackItem, TextField, PrimaryButton, DefaultButton, Icon, Checkbox } from 'office-ui-fabric-react';
import { Loader, RelationSelector } from 'components/common'
import { DictionaryAPI } from 'api';
import { DocumentAPI } from 'api/lgs/DocumentAPI';
import { IPendingOrderInformationViewModel } from 'interfaces/viewmodels';
import { useForm } from 'hooks';
import { IPendingOrderStepBaseProps } from './interfaces';
import { RelationTypes } from 'enums';
import { PendingOrderInformationViewModelFields } from 'enums/fields';

export const PendingOrderInformation = (props: IPendingOrderStepBaseProps) => {

    const { documentRowGuid, onNextStepRequest, onPreviousStepRequest } = props;
    const form = useForm<IPendingOrderInformationViewModel>(undefined, undefined, DocumentAPI.updatePendingOrderInformation);
    const { item, updateItem, submit, getErrorMessage, isSubmitting } = form;
    const { CONTACT_RELATION_ROW_GUID, DELIVERY_ADDRESS_RELATION_ROW_GUID } = PendingOrderInformationViewModelFields;

    const confirmOnNextClick = !item?.OnlinePaymentRequired;

    const getPendingOrderInformation = () => {
        DocumentAPI.getPendingOrderInformation(documentRowGuid,
            (data) => {
                form.initialize(true, data);
            });
    }

    const onNextClick = () => {
        submit(() => {
            onNextStepRequest && onNextStepRequest(confirmOnNextClick);
        }, undefined, true)
    }

    useEffect(() => {
        getPendingOrderInformation();
    }, [documentRowGuid])

    return (
        !item ? <Loader /> : (
            <>
                <Stack tokens={{ childrenGap: 10, padding: 10 }} style={{ maxWidth: 800 }} verticalFill>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <StackItem grow>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <StackItem>
                                    <RelationSelector
                                        labelText={DictionaryAPI.getTranslation("InvoiceAddress")}
                                        relationTypeIds={[RelationTypes.Customer]}
                                        rowGuid={item.CustomerRelationRowGuid}
                                        defaultRelation={item.CustomerRelation}
                                        newNotAllowed={true} />
                                </StackItem>
                                <StackItem>
                                    <RelationSelector
                                        labelText={DictionaryAPI.getTranslation("Contact")}
                                        rowGuidFieldName={CONTACT_RELATION_ROW_GUID}
                                        companyRelationId={item.CompanyRelationId}
                                        parentContactId={item.CustomerContactId}
                                        parentRelationId={item.CustomerRelation?.Id}
                                        relationTypeIds={[RelationTypes.Contact]}
                                        rowGuid={item.ContactRelationRowGuid}
                                        defaultRelation={item.ContactRelation}
                                        onChange={updateItem} />
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem grow>
                            <RelationSelector
                                labelText={DictionaryAPI.getTranslation("DeliveryAddress")}
                                rowGuidFieldName={DELIVERY_ADDRESS_RELATION_ROW_GUID}
                                companyRelationId={item.CompanyRelationId}
                                parentContactId={item.CustomerContactId}
                                parentRelationId={item.CustomerRelation?.Id}
                                relationTypeIds={[RelationTypes.DeliveryAddress]}
                                defaultRelation={item.DeliveryAddressRelation}
                                rowGuid={item.DeliveryAddressRelationRowGuid}
                                errorMessage={getErrorMessage(DELIVERY_ADDRESS_RELATION_ROW_GUID)}
                                onChange={updateItem} />
                        </StackItem>
                    </Stack>
                    {/* <StackItem>
                        <TextField
                            multiline
                            defaultValue={item.Remarks}
                            name={REMARKS}
                            label={DictionaryAPI.getTranslation(REMARKS)}
                            style={{ minHeight: 150 }}
                            onChange={onUnControlledTextFieldChange} />
                    </StackItem> */}
                    {/* <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="stretch">
                        <StackItem grow>
                            <DrawableCanvas
                                ref={customerAutographRef}
                                labeltext="Customer autograph"
                                containerStyle={{ width: "100%", height: 150 }} />
                        </StackItem>
                        <StackItem grow>
                            <DrawableCanvas
                                ref={customerAutographRef}
                                labeltext="Customer autograph"
                                containerStyle={{ width: "100%", height: 150 }} />
                        </StackItem>
                    </Stack> */}
                    <StackItem align="end">
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <StackItem>
                                <DefaultButton
                                    iconProps={{
                                        iconName: "Back",
                                    }}
                                    disabled={isSubmitting}
                                    text={DictionaryAPI.getTranslation("BackToDetails")}
                                    onClick={() => onPreviousStepRequest && onPreviousStepRequest()}
                                />
                            </StackItem>
                            <StackItem>
                                {
                                    confirmOnNextClick ? (
                                        <PrimaryButton
                                            text={DictionaryAPI.getTranslation("ConfirmOrder")}
                                            disabled={isSubmitting}
                                            onClick={() => onNextClick()}
                                        />
                                    ) : (
                                            <PrimaryButton
                                                text={DictionaryAPI.getTranslation("Next")}
                                                disabled={isSubmitting}
                                                styles={{ root: { width: "auto" } }}
                                                onClick={() => onNextClick()}
                                            >

                                                <Icon
                                                    iconName="Forward"
                                                    style={{
                                                        margin: "0 4px",
                                                        fontSize: 16
                                                    }}
                                                />
                                            </PrimaryButton>
                                        )
                                }

                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </>
        )
    );
};
