import { UserSettingsTypes } from './types';
import { IUserSetting } from 'interfaces/models';
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_USER_SETTING = "SET_USER_SETTING";

export const setUserSettings = (userSettings: IUserSetting[]): UserSettingsTypes => {
    return {
        type: SET_USER_SETTINGS,
        userSettings: userSettings
    }
}

export const setUserSetting = (userSetting: IUserSetting): UserSettingsTypes => {
    return {
        type: SET_USER_SETTING,
        userSetting: userSetting
    }
}