import { Modal, Stack } from 'office-ui-fabric-react'
import React from 'react'
import { Loader } from '../Loader/Loader'

export const LoaderModal = () => {
    return (
        <Modal
            isOpen={true}
            isBlocking
            isDarkOverlay={false}>
            <div style={{ height: 200 }} >
                <Loader />
            </div>

        </Modal>
    )
}
