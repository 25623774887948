import React from 'react';
import { getTheme, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { ChoiceGroup as FabricChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import { IChoiceGroupProps } from '.';

const theme: ITheme = getTheme();

export const ChoiceGroup = (props: IChoiceGroupProps): JSX.Element => {

    const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        props.onChange(ev, option, props.name);
    }

    return (
        <FabricChoiceGroup
            {...props}
            onChange={onChange}
        />);
}