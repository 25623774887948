import React from 'react';
import {
    Stack, StackItem,
    TextField,
    Checkbox,
    ScrollablePane
} from "office-ui-fabric-react";
import { BankAccounts, ColumnsStack, DatePicker, Loader } from 'components/common';
import { RelationViewModelFields, CustomerViewModelFields } from 'enums/fields';
import { DictionaryAPI } from 'api';
import { IAdministrationTabProps } from './interfaces';

export const AdministrationTab = (props: IAdministrationTabProps) => {

    const { form, cultureId, } = props;
    const { item: relation, onTextFieldChange, onCheckboxChange, onDatePickerSelectDate } = form;

    const {
        CODE,
    } = RelationViewModelFields

    const {
        CUSTOMERCARD_RECEIVED,
        CUSTOMERCARD_RECEIVED_ON,
        GROUPED_INVOICE_DETAIL,
        HIDE_AMOUNT_ON_PACKINGLIST,
    } = CustomerViewModelFields;


    if (!relation)
        return <Loader />

    return (
        <>
            <ScrollablePane>
                <Stack tokens={{ childrenGap: 12, padding: 12, maxWidth: 800 }}>
                    <StackItem>
                        <div style={{ height: 200 }}>
                            <BankAccounts
                                contactId={relation.ContactId}
                            />
                        </div>
                    </StackItem>
                    <ColumnsStack gap={12}>
                        <Stack >
                            <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign="center">
                                <StackItem>
                                    <Checkbox
                                        checked={relation.CustomerCardReceived}
                                        name={CUSTOMERCARD_RECEIVED}
                                        label={DictionaryAPI.getTranslation(CUSTOMERCARD_RECEIVED)}
                                        onChange={onCheckboxChange} />

                                </StackItem>
                                <StackItem grow>
                                    <DatePicker
                                        cultureId={cultureId}
                                        date={relation.CustomerCardReceivedOn}
                                        name={CUSTOMERCARD_RECEIVED_ON}
                                        disabled={!relation.CustomerCardReceived}
                                        onSelectDate={onDatePickerSelectDate} />
                                </StackItem>
                            </Stack>
                            <Stack horizontal style={{ marginTop: 10 }}>
                                <StackItem grow>
                                    <Checkbox
                                        checked={relation.GroupedInvoiceDetail}
                                        name={GROUPED_INVOICE_DETAIL}
                                        label={DictionaryAPI.getTranslation(GROUPED_INVOICE_DETAIL)}
                                        onChange={onCheckboxChange} />
                                </StackItem>
                            </Stack>
                            <StackItem styles={{ root: { paddingTop: 10 } }}>
                                <Checkbox
                                    checked={relation.HideAmountOnPackinglist}
                                    name={HIDE_AMOUNT_ON_PACKINGLIST}
                                    label={DictionaryAPI.getTranslation(HIDE_AMOUNT_ON_PACKINGLIST)}
                                    onChange={onCheckboxChange} />
                            </StackItem>
                        </Stack>


                        <></>
                    </ColumnsStack>

                </Stack>

                {/* <div className="ms-Grid" dir="ltr" style={{ margin: 10, maxWidth: 800 }}>
                    <div className="ms-Grid-row" style={{ height: 65 }}>
                        <div className="ms-Grid-col ms-lg6">
                            <Stack horizontalAlign="stretch" verticalFill>
                                <StackItem>
                                    <TextField
                                        readOnly
                                        autoComplete="off"
                                        value={relation.Code}
                                        name={CODE}
                                        label={DictionaryAPI.getTranslation(CODE)} />
                                </StackItem>
                            </Stack>
                        </div>
                        <div className="ms-Grid-col ms-lg6" style={{ height: "inherit" }}>
                            <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" verticalFill>
                                <StackItem grow>
                                    <Checkbox
                                        checked={relation.CustomerCardReceived}
                                        name={CUSTOMERCARD_RECEIVED}
                                        label={DictionaryAPI.getTranslation(CUSTOMERCARD_RECEIVED)}
                                        onChange={onCheckboxChange} />
                                </StackItem>
                                <StackItem grow>
                                    <DatePicker
                                        cultureId={cultureId}
                                        date={relation.CustomerCardReceivedOn}
                                        name={CUSTOMERCARD_RECEIVED_ON}
                                        disabled={!relation.CustomerCardReceived}
                                        onSelectDate={onDatePickerSelectDate} />
                                </StackItem>
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg12" style={{ height: 200 }}>
                            <BankAccounts
                                contactId={relation.ContactId}
                            />
                        </div>
                    </div>
                </div> */}
            </ScrollablePane>

        </>
    );
};