import { useState, useEffect } from 'react';
import { IFilterContextHandlerProps, IFilterContextHandlerState } from './interfaces';
import { IFilterValue, IFilter, IFilterResultInfo, IFilterInfo, IFilterContext, IRangeFilter, IRangeFilterValue, IBaseFilter } from 'interfaces/models';
import { IUseUserSettingsProps } from 'hooks/useUserSetting';
import { FilterFields } from 'enums/fields/core/FilterFields';
import { FilterValueFields } from 'enums/fields/core/FilterValueFields';
import { FilterContextFields } from 'enums/fields/core/FilterContextFields';
import { OrderByRuleFields, RangeFilterValueFields } from 'enums/fields';
import { FilterInfoFields } from 'enums/fields/core/FilterInfoFields';
import { IBaseFilterValue } from 'interfaces/models/core/IBaseFilterValue';

export const useFilterContextHandler = <T extends IFilterContext>(
    getFilterContext: (onSuccess: (data: T) => void, isActive?: boolean) => void,
    getFilterResultInfo: (filterContext: T, onSuccess: (data: IFilterResultInfo) => void, onError: (xhr: XMLHttpRequest) => void) => void,
    useUserSetting?: IUseUserSettingsProps,
    isManualInitialisation?: boolean): IFilterContextHandlerProps<T> => {

    const [state, setState] = useState<IFilterContextHandlerState<T>>({
        isFiltering: false
    });

    //#region USER SETTINGSS

    const FILTER_INFO_USERSETTING_NAME_PREFIX = "filterinfo_";
    const FILTER_USERSETTING_NAME_PREFIX = "filter_";
    const { FILTER_INFO } = FilterContextFields;
    const { SEARCH, IS_ACTIVE, ORDER_BY_RULES } = FilterInfoFields;
    const { VALUES } = FilterFields;
    const { IS_CHECKED, NAME, VALUE } = FilterValueFields;
    const { MAXIMUM_VALUE, MINIMUM_VALUE } = RangeFilterValueFields;
    const { FIELD_NAME, IS_DESCENDING } = OrderByRuleFields;

    const getFilterInfoUserSettingName = (propertyName: string): string => {
        return FILTER_INFO_USERSETTING_NAME_PREFIX + propertyName;
    }

    const setFilterInfoUserSetting = (propertyName: string, value: string) => {
        useUserSetting && useUserSetting.setUserSetting(getFilterInfoUserSettingName(propertyName), value);
    }

    const getFilterUserSettingName = (filterName: string, filterValueName: string): string => {
        return FILTER_USERSETTING_NAME_PREFIX + filterName + "_" + filterValueName;
    }

    const setFilterUserSetting = (filterName: string, filterValueName: string, value: string) => {
        useUserSetting && useUserSetting.setUserSetting(getFilterUserSettingName(filterName, filterValueName), value);
    }

    const applyUserSettingsOnFilterContext = (filterContext: IFilterContext) => {
        if (useUserSetting) {

            const { userSettingsMap } = useUserSetting;
            const { FilterInfo } = filterContext;
            const searchUserSetting = userSettingsMap[getFilterInfoUserSettingName(SEARCH)];
            const isActiveUserSetting = userSettingsMap[getFilterInfoUserSettingName(IS_ACTIVE)];
            const orderByRuleFieldNameUserSetting = userSettingsMap[getFilterInfoUserSettingName(ORDER_BY_RULES + FIELD_NAME)];

            if (searchUserSetting && searchUserSetting.Value != "") {
                FilterInfo.Search = userSettingsMap[getFilterInfoUserSettingName(SEARCH)].Value;
            }

            if (isActiveUserSetting && isActiveUserSetting.Value != "") {
                FilterInfo.IsActive = isActiveUserSetting.Value === "true";
            }

            if (orderByRuleFieldNameUserSetting && orderByRuleFieldNameUserSetting.Value !== "") {
                FilterInfo.OrderByRules = [
                    {
                        FieldName: orderByRuleFieldNameUserSetting.Value,
                        IsDescending: userSettingsMap[getFilterInfoUserSettingName(ORDER_BY_RULES + IS_DESCENDING)]?.Value === "true",
                    }
                ]
            }

            for (var filterProp in filterContext) {
                if (Object.prototype.hasOwnProperty.call(filterContext, filterProp)) {

                    if (filterProp !== FILTER_INFO) {
                        const filter = filterContext[filterProp];

                        if (filter && filter[VALUES] !== undefined) {

                            for (const v of filter[VALUES]) {

                                const filterUserSettingName = getFilterUserSettingName(filterProp, String(v[NAME]));
                                const filterUserSetting = userSettingsMap[filterUserSettingName];

                                if (filterUserSetting && filterUserSetting.Value != "") {
                                    v[IS_CHECKED] = filterUserSetting.Value === "true";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const clearUserSettings = () => {
        if (useUserSetting) {
            for (var prop in useUserSetting.userSettingsMap) {
                if (Object.prototype.hasOwnProperty.call(useUserSetting.userSettingsMap, prop)) {
                    if (prop.substring(0, FILTER_USERSETTING_NAME_PREFIX.length) === FILTER_USERSETTING_NAME_PREFIX ||
                        prop.substring(0, FILTER_INFO_USERSETTING_NAME_PREFIX.length) === FILTER_INFO_USERSETTING_NAME_PREFIX) {
                        useUserSetting.setUserSetting(prop, "");
                    }
                }
            }
        }
    }

    //#endregion

    const _getFilterContext = (isClearUserSettings?: boolean) => {
        getFilterContext(
            (filterContext) => {
                if (isClearUserSettings === true) {
                    clearUserSettings();
                } else {
                    applyUserSettingsOnFilterContext(filterContext);
                }

                setState({ filterContext: filterContext, isFiltering: false })
            },
            getFilterInfo() ? getFilterInfo().IsActive : true);
    }

    const setFilterContext = (isPageChange?: boolean) => {

        if (!isPageChange) {
            const filterInfo = getFilterInfo();
            filterInfo.Page = 1;
        }

        if (state.filterContext && !state.isFiltering) {
            setState({
                ...state,
                filterContext: Object.assign({}, state.filterContext),
            });
        }
    }

    const _getFilterResultInfo = (clearFilters?: boolean) => {
        if (clearFilters !== true && state.filterContext) {

            setState({
                ...state,
                isFiltering: true
            });

            getFilterResultInfo(state.filterContext,
                (data) => setState({
                    ...state,
                    filterResultInfo: data,
                    isFiltering: false
                }),
                (xhr) => {
                    console.log(xhr.statusText)
                    setState({
                        ...state,
                        isFiltering: false
                    })
                });
        } else {
            _getFilterContext();
        }
    }

    const getFilterResultItemIndex = (findIndexByFieldName: string, findIndexByFieldValue: any): number => {
        if (state.filterResultInfo) {
            return state.filterResultInfo.Items.findIndex(i => i[findIndexByFieldName] === findIndexByFieldValue);
        }

        return -1;
    }

    const getFilterInfo = (): IFilterInfo | undefined => {
        if (state.filterContext) {
            const filterContext: IFilterContext = state.filterContext as IFilterContext;
            return filterContext.FilterInfo;
        }
    }

    const getFilter = (name: string): IBaseFilter | undefined => {
        if (state.filterContext) {
            return state.filterContext[name];
        }
    }

    const onFilterChange = (name: string, value: IFilterValue, isChecked?: boolean, isMultiSelection?: boolean) => {
        const filter = getFilter(name);

        if (filter) {

            if (!isMultiSelection) {
                filter.Values = filter.Values.map((v, i) => {
                    return { ...v, IsChecked: false }
                });
            }

            const index = filter.Values.findIndex(v => v.Name === value.Name);
            if (index > -1) {
                filter.Values[index] = { ...value, IsChecked: isChecked === true }


                console.log("onFilterChange", name, value.Name)
                setFilterUserSetting(name, value.Name, String(isChecked === true));
            }

            setFilterContext();
        }
    }

    const onRangeFilterChange = (name: string, value: IRangeFilterValue, isChecked?: boolean, isMultiSelection?: boolean) => {
        const filter = getFilter(name);

        if (filter) {

            if (!isMultiSelection) {
                for (const v of filter.Values) {
                    v.IsChecked = false;
                    console.log("onRangeFilterChange", name, v.Name)
                    setFilterUserSetting(name, v.Name, String(false));
                }
            }

            const index = filter.Values.findIndex(v => v.Name === value.Name);
            if (index > -1) {
                filter.Values[index] = { ...value, IsChecked: isChecked === true };

                setFilterUserSetting(name, value.Name, String(isChecked === true));
                setFilterUserSetting(name, value.Name + MINIMUM_VALUE, String(value.MinimumValue));
                setFilterUserSetting(name, value.Name + MAXIMUM_VALUE, String(value.MaximumValue));
            }

            setFilterContext();
        }
    }

    const onUnCheckFilterValue = (filterName: string, value: IBaseFilterValue) => {

        const filter = getFilter(filterName);

        if (filter) {

            const index = filter.Values.findIndex(v => v.Name === value.Name);
            if (index > -1) {
                filter.Values[index] = { ...value, IsChecked: false }

                console.log("onUnCheckFilterValue", filterName, value.Name)
                setFilterUserSetting(filterName, value.Name, String(false));
            }

            setFilterContext();
        }
    }

    const onClearFilter = (name: string) => {
        const filter = getFilter(name);
        if (filter) {
            for (const v of filter.Values) {

                if (v.IsChecked) {
                    v.IsChecked = false;

                    console.log("onClearFilter", name, v.Name)
                    setFilterUserSetting(name, v.Name, String(v.IsChecked));

                    if (v[MINIMUM_VALUE]) {
                        setFilterUserSetting(name, v.Name + MINIMUM_VALUE, "");
                    }

                    if (v[MAXIMUM_VALUE]) {
                        setFilterUserSetting(name, v.Name + MAXIMUM_VALUE, "");
                    }
                }
            }

            setFilterContext();
        }
    }

    const onSearchChange = (value: string) => {
        const filterInfo = getFilterInfo();
        if (filterInfo) {
            filterInfo.Search = value;
            setFilterInfoUserSetting(SEARCH, value);
        }

        setFilterContext();
    }

    const onActiveToggle = (isActive: boolean) => {
        const filterInfo = getFilterInfo();
        if (filterInfo) {
            filterInfo.IsActive = isActive;
            setFilterInfoUserSetting(IS_ACTIVE, String(isActive));
        }

        _getFilterContext();
    }

    const onPageChange = (page: number) => {
        const filterInfo = getFilterInfo();
        if (filterInfo) {
            filterInfo.Page = page;
        }

        setFilterContext(true);
    }

    const onOrderByChange = (fieldName: string, isDescending: boolean) => {
        const filterInfo = getFilterInfo();
        if (filterInfo) {

            filterInfo.OrderByRules = [
                {
                    FieldName: fieldName,
                    IsDescending: isDescending
                }
            ];

            setFilterInfoUserSetting(ORDER_BY_RULES + FIELD_NAME, fieldName);
            setFilterInfoUserSetting(ORDER_BY_RULES + IS_DESCENDING, String(isDescending));
        }

        setFilterContext();
    }

    const clearFilterContext = () => {
        setState({
            ...state,
            filterContext: undefined,
            filterResultInfo: undefined
        })
    }

    useEffect(() => {
        if (!isManualInitialisation && (useUserSetting === undefined || useUserSetting.userSettingsLoaded))
            _getFilterContext();
    }, [isManualInitialisation, useUserSetting?.userSettingsLoaded]);

    useEffect(() => {
        if (state.filterContext) {
            _getFilterResultInfo();
        }
    }, [state.filterContext]);

    return {
        filterContext: state.filterContext,
        filterResultInfo: state.filterResultInfo,
        isFiltering: state.isFiltering,
        onSearchChange,
        onActiveToggle,
        onPageChange,
        onOrderByChange,
        onFilterChange,
        onClearFilter,
        onUnCheckFilterValue,
        onClearAllFilters: () => _getFilterContext(true),
        onRangeFilterChange,
        getFilterContext: _getFilterContext,
        getFilterResultInfo: _getFilterResultInfo,
        getFilterResultItemIndex,
        clearFilterContext,
    }
};