export enum ProductDocumentDetailsGroupFields {
    CODE = "Code",
    CREATED_ON = "CreatedOn",
    DETAILS = "Details",
    DISPLAY_NAME = "DisplayName",
    DOCUMENTDETAIL_STATUS_ID = "DocumentDetailStatusId",
    REMARKS = "Remarks",
    DOCUMENT_ID = "DocumentId",
    FIRST_SIZE_NAME = "FirstSizeName",
    HAS_PRODUCTION_ORDER = "HasProductionOrder",
    IMAGE = "Image",
    LAST_SIZE_NAME = "LastSizeName",
    NAME = "Name",
    PRODUCT_CATEGORY_NAME = "ProductCategoryName",
    PRODUCT_ID = "ProductId",
    SIZE_RANGE_ID = "SizeRangeId",
    SUB_PRODUCT_CATEGORY_NAME = "SubProductCategoryName",
    TOTAL_PRICE = "TotalPrice",
    TOTAL_QUANTITY = "TotalQuantity",
    UOM_CODE = "UomCode",
    VARIANT_DESCRIPTION = "VariantDescription",
    COLLI_ID = "ColliId",
    COLLI_PACKAGE_NUMBER = "ColliPackageNumber",
    GROUP_KEY = "GroupKey",
}