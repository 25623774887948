import React, { useRef, useState, useEffect } from 'react';
import { ICreateCustomerViewModel } from 'interfaces/viewmodels';
import { RelationAPI, DictionaryAPI } from 'api';
import { CreateCustomerViewModelFields, ContactBaseViewModelFields } from 'enums/fields';
import { IFormProps } from 'hooks';
import { StackItem, IComboBoxOption } from 'office-ui-fabric-react';
import { ITerritory, IRelationListItem } from 'interfaces/models';
import { ICreateCustomerProps } from '.';
import { ComboBox } from 'components/common';
import { CreateRelationLayout } from 'components/layouts';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const CreateCustomer = (props: ICreateCustomerProps): JSX.Element => {

    const { companyRelationId, parentContactId, isProspect, onSuccess, onCancel } = props;

    const [territories, setTerritories] = useState<ITerritory[]>();
    const [agents, setAgents] = useState<IRelationListItem[]>([]);

    const hideAgentRelationIdRef = useRef<boolean>(false);
    const account = useSelector((state: RootState) => state.authenticationState.account);

    const {
        AGENT_RELATION_ID,
        PRODUCT_CATEGORY_IDS,
        TERRITORY_ID,
    } = CreateCustomerViewModelFields

    const { COUNTRY_ID, CONTACT_ID } = ContactBaseViewModelFields;

    const getCreateCustomer = (onSuccess: (data: ICreateCustomerViewModel) => void) => {
        RelationAPI.getCreateCustomer(companyRelationId, parentContactId || account.Privilege.CompanyContactId, isProspect === true,
            (data) => {
                setTerritories([...data.Territories.filter(t => t.CountryId === data.CountryId)]);
                hideAgentRelationIdRef.current = data.AgentRelationId !== null;
                onSuccess(data);
            })
    }

    const getContent = (form: IFormProps<ICreateCustomerViewModel>): JSX.Element => {

        const { item, getErrorMessage, onComboBoxSimpleChange, onMultiSelectComboBoxSimpleChange } = form;

        return (
            <>
                <StackItem>
                    <ComboBox
                        autoComplete="on"
                        placeholder={DictionaryAPI.getTranslation("Territory")}
                        errorMessage={getErrorMessage(TERRITORY_ID)}
                        selectedKey={item.TerritoryId}
                        name={TERRITORY_ID}
                        options={territories.map((v: ITerritory, i): IComboBoxOption => {
                            return {
                                key: v.Id,
                                text: v.Name,
                            }
                        })}
                        disabled={!(territories?.length > 0)}
                        onSimpleChange={onComboBoxSimpleChange} />
                </StackItem>
                {
                    !hideAgentRelationIdRef.current && (
                        <StackItem>
                            <ComboBox
                                autoComplete="on"
                                placeholder={DictionaryAPI.getTranslation("Agent")}
                                errorMessage={getErrorMessage(AGENT_RELATION_ID)}
                                selectedKey={item.AgentRelationId}
                                name={AGENT_RELATION_ID}
                                options={agents.map((v: IRelationListItem, i): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                disabled={!(agents?.length > 0)}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                    )
                }
                {
                    item.ProductCategories?.length > 0 && (
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            placeholder={DictionaryAPI.getTranslation("ProductCategory")}
                            errorMessage={getErrorMessage(PRODUCT_CATEGORY_IDS)}
                            multiSelect={true}
                            selectedKey={item.ProductCategoryIds}
                            name={PRODUCT_CATEGORY_IDS}
                            options={item.ProductCategories.map((v, i): IComboBoxOption => {
                                return {
                                    key: v.Id,
                                    text: DictionaryAPI.getTranslation(v.Name),
                                }
                            })}
                            onSimpleChange={onMultiSelectComboBoxSimpleChange} />
                    )
                }
            </>
        )
    }

    const onFormChange = (form: IFormProps<ICreateCustomerViewModel>, name: string, value: any) => {

        if ((name === COUNTRY_ID && form.item.CountryId) || (name === CONTACT_ID && form.item.ContactId)) {
            form.updateItem(TERRITORY_ID, undefined, true);
            setTerritories(form.item.Territories.filter(t => t.CountryId === form.item.CountryId || t.CountryId === null));
        } else if (name === TERRITORY_ID && !hideAgentRelationIdRef.current) {
            form.updateItem(AGENT_RELATION_ID, undefined, true);
            setAgents(form.item.Territories.find(t => t.Id === value)?.Agents || []);
        }

    }

    useEffect(() => {
        setAgents([]);
    }, [territories])

    return (
        <CreateRelationLayout<ICreateCustomerViewModel>
            getCreateRelation={getCreateCustomer}
            createRelation={RelationAPI.createCustomer}
            getContent={getContent}
            onFormChangeCallback={onFormChange}
            onSuccess={onSuccess}
            onCancel={onCancel}
        />
    );
}