import React from 'react';
import { Stack, StackItem, CommandBarButton, Icon } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IFilterClearButtonProps } from './interfaces';

export const FilterClearButton = (props: IFilterClearButtonProps) => {
    return (
        <CommandBarButton
            style={{ padding: 10, width: "100%" }}
            onClick={props.onClick}>
            <Icon iconName="Cancel" style={{ marginRight: 5 }} /> <span>{DictionaryAPI.getTranslation("Clear")}</span>
        </CommandBarButton>
    );
};