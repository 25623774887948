import React from 'react';
import { DatePicker as FabricDatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react';
import { IDatePickerProps } from '.';
import { DictionaryAPI } from 'api';
import { useDates } from 'hooks';

export const DatePicker = (props: IDatePickerProps): JSX.Element => {

    const { placeholder, cultureId } = props;
    
    const { dateToLocaleDateString } = useDates(cultureId);
    const label = DictionaryAPI.getTranslation(placeholder ? placeholder : "Select");

    const DayPickerStrings: IDatePickerStrings = {
        months: [
            DictionaryAPI.getTranslation('January'),
            DictionaryAPI.getTranslation('February'),
            DictionaryAPI.getTranslation('March'),
            DictionaryAPI.getTranslation('April'),
            DictionaryAPI.getTranslation('May'),
            DictionaryAPI.getTranslation('June'),
            DictionaryAPI.getTranslation('July'),
            DictionaryAPI.getTranslation('August'),
            DictionaryAPI.getTranslation('September'),
            DictionaryAPI.getTranslation('October'),
            DictionaryAPI.getTranslation('November'),
            DictionaryAPI.getTranslation('December')],

        shortMonths: [
            DictionaryAPI.getTranslation('shortMonth_Jan'),
            DictionaryAPI.getTranslation('shortMonth_Feb'),
            DictionaryAPI.getTranslation('shortMonth_Mar'),
            DictionaryAPI.getTranslation('shortMonth_Apr'),
            DictionaryAPI.getTranslation('shortMonth_May'),
            DictionaryAPI.getTranslation('shortMonth_Jun'),
            DictionaryAPI.getTranslation('shortMonth_Jul'),
            DictionaryAPI.getTranslation('shortMonth_Aug'),
            DictionaryAPI.getTranslation('shortMonth_Sep'),
            DictionaryAPI.getTranslation('shortMonth_Oct'),
            DictionaryAPI.getTranslation('shortMonth_Nov'),
            DictionaryAPI.getTranslation('shortMonth_Dec')],

        days: [
            DictionaryAPI.getTranslation('Sunday'),
            DictionaryAPI.getTranslation('Monday'),
            DictionaryAPI.getTranslation('Tuesday'),
            DictionaryAPI.getTranslation('Wednesday'),
            DictionaryAPI.getTranslation('Thursday'),
            DictionaryAPI.getTranslation('Friday'),
            DictionaryAPI.getTranslation('Saturday')],

        shortDays: [
            DictionaryAPI.getTranslation('shortDay_Sunday'),
            DictionaryAPI.getTranslation('shortDay_Monday'),
            DictionaryAPI.getTranslation('shortDay_Tuesday'),
            DictionaryAPI.getTranslation('shortDay_Wednesday'),
            DictionaryAPI.getTranslation('shortDay_Thursday'),
            DictionaryAPI.getTranslation('shortDay_Friday'),
            DictionaryAPI.getTranslation('shortDay_Saturday')],

        goToToday: DictionaryAPI.getTranslation('GoToToday'),
        prevMonthAriaLabel: DictionaryAPI.getTranslation('GoToPreviousMonth'),
        nextMonthAriaLabel: DictionaryAPI.getTranslation('GoToNextMonth'),
        prevYearAriaLabel: DictionaryAPI.getTranslation('GoToPreviousYear'),
        nextYearAriaLabel: DictionaryAPI.getTranslation('GoToNextYear'),
        closeButtonAriaLabel: DictionaryAPI.getTranslation('Close')
    };

    const onFormatDate = (date?: Date): string => {
        return dateToLocaleDateString(date);
    }

    const onSelectDate = (date: Date | null | undefined) => {
        if (props.onSelectDate) {
            props.onSelectDate(date, props.name);
        }
    }

    return (
        <FabricDatePicker
            value={props.date}
            disabled={props.disabled}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            minDate={props.minDate}
            maxDate={props.maxDate}
            placeholder={label}
            ariaLabel={label}
            allowTextInput={true}
            formatDate={onFormatDate}
            onSelectDate={onSelectDate}
        />);
}