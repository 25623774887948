import React, { useEffect, useState } from 'react'
import { DocumentTypes, OrdersInteractionMenus, OrderSubDocumentTypes } from 'enums';
import { CreateOrder, Order } from 'components/pages';
import { DocumentsListViewLayout } from 'components/layouts';
import { storeInstance } from 'store';
import { setPendingOrdersVisible } from 'store/pendingOrders/actions';
import { IDocumentsListViewLayoutBaseProps } from 'components/layouts/DocumentsListViewLayout/interfaces';

export const Orders = (props: IDocumentsListViewLayoutBaseProps) => {

    useEffect(() => {
        storeInstance.dispatch(setPendingOrdersVisible(false, true));
        return () => {
            storeInstance.dispatch(setPendingOrdersVisible(false, false));
        }
    }, [props.menu])

    console.log("orders menu", props.menu);

    return (
        <DocumentsListViewLayout {...props}
            documentTypeId={DocumentTypes.Order}
            fromRelationNameHeader="Warehouse"
            relationNameHeader="Customer"
            toRelationNameHeader="Delivery"
            newInteractionMenuId={OrdersInteractionMenus.New}
            exportInteractionMenuId={OrdersInteractionMenus.Export}
            recalculateDocumentsInteractionMenuId={OrdersInteractionMenus.RecalculateOrders}
            getCreateDocumentComponent={(onAccept, onCancel, menuId) => {

                let subDocumentTypeId = 0;

                switch (menuId) {
                    case OrdersInteractionMenus.New_Sales:
                        subDocumentTypeId = OrderSubDocumentTypes.Sales
                        break;
                    case OrdersInteractionMenus.New_Presales:
                        subDocumentTypeId = OrderSubDocumentTypes.Presales
                        break;
                    case OrdersInteractionMenus.New_Company:
                        subDocumentTypeId = OrderSubDocumentTypes.Company
                        break;
                    case OrdersInteractionMenus.New_Sample:
                        subDocumentTypeId = OrderSubDocumentTypes.Sample
                        break;
                    default:
                        break;
                }

                return (
                    <CreateOrder
                        subDocumentTypeId={subDocumentTypeId}
                        onAccept={onAccept}
                        onCancel={onCancel}
                    />
                )
            }}
            getDocumentComponent={(rowGuid, onDismiss) => {
                return (
                    <Order
                        documentRowGuid={rowGuid}
                        onDismiss={onDismiss}
                    />
                )
            }}
        />


    )
}
