import React from 'react';
import { StackItem, CommandBarButton, IContextualMenuListProps, IRenderFunction, Stack } from 'office-ui-fabric-react';
import { filterButtonStyles } from './styles';
import { IFilterBarItemDropDownProps } from './interfaces';
import { DictionaryAPI, FilterAPI } from 'api';
import { FilterClearButton } from './FilterClearButton';

export const FilterBarItemDropDown: React.FunctionComponent<IFilterBarItemDropDownProps> = props => {

    const { onClearClick } = props;

    return (
        <Stack>
            <StackItem styles={{ root: { padding: '14px', borderBottom: '1px solid #ccc' } }}>
                {props.children}
            </StackItem>
            <StackItem align="stretch">
                <FilterClearButton onClick={() => onClearClick()} />
            </StackItem>
        </Stack>

    );
};
