import React, { useState } from 'react';
import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { UsernameTextField, PasswordTextField } from 'components/common';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { DictionaryAPI, AccountAPI } from 'api';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { ICreatePasswordProps } from '.';
import { useForm } from 'hooks';
import { ICreatePasswordViewModel } from 'interfaces/viewmodels';
import { CreatePasswordViewModelFields } from 'enums/fields';
import { AuthenticationLayout } from 'components/layouts';

export const CreatePassword = (props: ICreatePasswordProps) => {

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const { item, isSubmitting, submit, onTextFieldChange, getErrorMessage } = useForm<ICreatePasswordViewModel>(
        {
            UserName: "",
            Password: "",
            RepeatPassword: "",
            VerifyUrl: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/#/account-verification/{0}"
        },
        undefined, AccountAPI.createPassword);

    const { PASSWORD, REPEAT_PASSWORD, USER_NAME } = CreatePasswordViewModelFields;

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submit(() => {
            setIsConfirmed(true);
        },
            (xhr) => {
                console.log(xhr)
            });
    }

    if (!item)
        return null;

    return (
        <AuthenticationLayout title={DictionaryAPI.getTranslation(isConfirmed ? "PasswordCreated" : "CreateYourPassword")}>
            <form onSubmit={onSubmit}>
                <Stack tokens={{ childrenGap: 24 }}>
                    {
                        isConfirmed ?
                            (<StackItem>
                                <MessageBar>
                                    {DictionaryAPI.getTranslation("txtOnCreatePasswordSuccess")}
                                </MessageBar>
                            </StackItem>) :
                            (<>
                                <StackItem>
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        {DictionaryAPI.getTranslation("PasswordMustMeetFollowingRequirements")}
                                        <ul>
                                            <li>
                                                {DictionaryAPI.getTranslation("AtLeast1LowercaseLetter")}
                                            </li>
                                            <li>
                                                {DictionaryAPI.getTranslation("AtLeast1UppercaseLetter")}
                                            </li>
                                            <li>
                                                {DictionaryAPI.getTranslation("AtLeast1Digit")}
                                            </li>
                                            <li>
                                                {DictionaryAPI.getTranslation("MinimumNumberOfCharacters", ["8"])}
                                            </li>
                                        </ul>
                                    </MessageBar>
                                </StackItem>
                                <StackItem>
                                    <UsernameTextField
                                        autoFocus
                                        value={item.UserName}
                                        errorMessage={getErrorMessage(USER_NAME)}
                                        onChange={onTextFieldChange} />
                                </StackItem>
                                <StackItem>
                                    <PasswordTextField
                                        value={item.Password}
                                        errorMessage={getErrorMessage(PASSWORD)}
                                        onChange={onTextFieldChange}
                                    />

                                </StackItem>
                                <StackItem>
                                    <PasswordTextField
                                        isRepeat
                                        value={item.RepeatPassword}
                                        errorMessage={getErrorMessage(REPEAT_PASSWORD)}
                                        onChange={onTextFieldChange} />
                                </StackItem>

                                <StackItem styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                                    <span>{DictionaryAPI.getTranslation("YourAccountWillBeVerified")} </span>
                                </StackItem>
                                <StackItem align="end" styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                                    <PrimaryButton
                                        type="submit"
                                        text={DictionaryAPI.getTranslation("Confirm")}
                                        disabled={isSubmitting || item.UserName === "" || item.Password === "" || item.RepeatPassword === ""} />
                                </StackItem>
                            </>)
                    }
                </Stack>
            </form>
        </AuthenticationLayout>);
}