import { IGlobals } from "./interfaces";

export const useGlobals = (): IGlobals => {
    return {
        companyContactId: Number.parseInt(process.env.REACT_APP_COMPANY_CONTACT_ID || "0"),
        companyRelationId: Number.parseInt(process.env.REACT_APP_COMPANY_RELATION_ID || "0"),
        warehouseContactIds: [37755, 997990, 994619],
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        rootUrl: process.env.REACT_APP_API_ROOT_URL as string,
        filterInfoPageSize: 50
    }
}