export enum DocumentBaseViewModelFields {
     AGENT_NAME = "AgentName",
     AGENT_RELATION = "AgentRelation",
     AGENT_RELATION_ROW_GUID = "AgentRelationRowGuid",
     AGENTS = "Agents",
     COLLECTIONCODE = "CollectionCode",
     COLLECTION_ID = "CollectionId",
     COMPANYCONTACT_ID = "CompanyContactId",
     COMPANY_NAME = "CompanyName",
     COMPANY_RELATION_ID = "CompanyRelationId",
     CONFIRMED_ON = "ConfirmedOn",
     CONTACT_RELATION = "ContactRelation",
     CONTACT_RELATION_ROW_GUID = "ContactRelationRowGuid",
     CREATED_ON = "CreatedOn",
     CURRENCY_ID = "CurrencyId",
     CURRENCY_RATE = "CurrencyRate",
     DOCUMENTDETAIL_STATUSES = "DocumentDetailStatuses",
     DOCUMENT_ID = "DocumentId",
     DOCUMENT_ROW_GUID = "DocumentRowGuid",
     DOCUMENT_STATUS_ID = "DocumentStatusId",
     DOCUMENT_STATUS_INTERACTION_MENU_IDS = "DocumentStatusInteractionMenuIds",
     DOCUMENT_SUMMARY = "DocumentSummary",
     DOCUMENT_TYPE_ID = "DocumentTypeId",
     EXECUTED_ON = "ExecutedOn",
     EXTERNAL_REFERENCE = "ExternalReference",
     FROM_CONTACT_ID = "FromContactId",
     FROM_RELATION = "FromRelation",
     FROM_RELATION_ROW_GUID = "FromRelationRowGuid",
     INTERNAL_REMARKS = "InternalRemarks",
     IS_READ_ONLY = "IsReadOnly",
     IS_RESTRICTED_TO_STOCK = "IsRestrictedToStock",
     IS_VALIDATION_ONLY = "IsValidationOnly",
     IS_WAREHOUSE_READ_ONLY = "IsWarehouseReadOnly",
     NUMBER = "Number",
     PRINT_INTERACTION_MENU_IDS = "PrintInteractionMenuIds",
     PRODUCT_CATEGORIES = "ProductCategories",
     REFERENCE = "Reference",
     RELATION = "Relation",
     RELATIONROW_GUID = "RelationRowGuid",
     REMARKS = "Remarks",
     SUB_DOCUMENT_TYPE_ID = "SubDocumentTypeId",
     TO_RELATION = "ToRelation",
     TO_RELATION_ROW_GUID = "ToRelationRowGuid",
     WAREHOUSES = "Warehouses"
}