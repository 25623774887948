import {
    ISetDetailLayoutCommandBarAction,
    ISetDetailLayoutTitleBarAction,
    IDetailLayoutCommandBarState,
    IDetailLayoutTitleBarState
} from './types'
import { 
    SET_DETAILS_LAYOUT_COMMAND_BAR_STATE,
    SET_DETAILS_LAYOUT_TITLE_BAR_STATE
} from './actions';

const initialCommandBarState: IDetailLayoutCommandBarState = {
    map: {},
}

const initialTitleBarState: IDetailLayoutTitleBarState = {
    map: {},
}

export const detailLayoutCommandBarReducer = (state = initialCommandBarState, action: ISetDetailLayoutCommandBarAction): IDetailLayoutCommandBarState => {
    if (action.type === SET_DETAILS_LAYOUT_COMMAND_BAR_STATE) {

        const index: string = `${action.id}_${action.menuId}_${action.defaultCommandType}`;
        if (state.map[index] !== action.enabled) {
            state.map[index] = action.enabled;
            return {...state, 
                map: state.map
            };
        }
        
    }

    return state;
}

export const detailLayoutTitleBarReducer = (state = initialTitleBarState, action: ISetDetailLayoutTitleBarAction): IDetailLayoutTitleBarState => {
    if (action.type === SET_DETAILS_LAYOUT_TITLE_BAR_STATE) {

        const index: string = `${action.id}_${action.menuId}`;
        if (state.map[index] !== action.title) {
            state.map[index] = action.title;
            return {...state, 
                map: state.map
            };
        }
        
    }

    return state;
}