import React from 'react';
import { Stack, StackItem, Spinner, SpinnerSize, Label } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';

export const Loader = () => {
    return (
            <Stack verticalAlign="center" verticalFill>
                <StackItem align="center">
                    <Label>{DictionaryAPI.getTranslation("PleaseWait") + "..."}</Label>
                    <Spinner size={SpinnerSize.large} />
                </StackItem>
            </Stack>
    );
}