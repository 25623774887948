import { BaseAPI, FilterAPI } from 'api';
import { IFilterResultInfo, ICatalogFilterContext, ICatalogPageDetailFilterContext, IFilter, ICatalogRelationFilterContext, IEventLogFilterContext, ICatalogPage, ICatalogPageDetailListItem, ICatalogListItem } from 'interfaces/models';
import { ICreateCatalogViewModel, ICatalogViewModel, IAddProductsToCatalogViewModel, ISelectCatalogRelationViewModel, IDeleteCatalogPageDetailViewModel, IAddCatalogPageViewModel, ICatalogPageViewModel } from 'interfaces/viewmodels';
import { IErrorMessagesMap } from 'interfaces';
import { useDates } from 'hooks';

const { getDateByUtc } = useDates();

export class CatalogAPI {

    public static getFilterContext = (isActive: boolean, onSuccess: (data: ICatalogFilterContext) => void) => {
        FilterAPI.getFilterContext("Sls/Catalog",
            [
                String(isActive),
            ],
            (data) => onSuccess({
                ...data,
                FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [{ FieldName: "Id", IsDescending: true }],
                    PageSize: 50
                }
            }));
    }

    public static filter = (filterContext: ICatalogFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Sls/Catalog/Filter", {
            ...filterContext,
            CatalogGroupId: { ...filterContext.CatalogGroupId, Values: filterContext.CatalogGroupId.Values.filter(v => v.IsChecked) },
            CreatedOn: { ...filterContext.CreatedOn, Values: filterContext.CreatedOn.Values.filter(v => v.IsChecked) }
        }, onSuccess);
    }

    public static getPageDetailFilterContext = (catalogPageId: number, onSuccess: (data: ICatalogPageDetailFilterContext) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetPageDetailFilterContext/${catalogPageId}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [{ FieldName: "DisplayOrder", IsDescending: false }, { FieldName: "Id", IsDescending: true }],
                    PageSize: 50
                }
            }));
    }

    public static filterPageDetails = (filterContext: ICatalogPageDetailFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Sls/Catalog/FilterPageDetails", {
            ...filterContext,
            SubProductCategoryId: { ...filterContext.SubProductCategoryId, Values: filterContext.SubProductCategoryId.Values.filter(v => v.IsChecked) },
            ProductCollectionId: { ...filterContext.ProductCollectionId, Values: filterContext.ProductCollectionId.Values.filter(v => v.IsChecked) },
            ProductGenderId: { ...filterContext.ProductGenderId, Values: filterContext.ProductGenderId.Values.filter(v => v.IsChecked) }
        }, onSuccess);
    }

    public static getRelationFilterContext = (catalogRowGuid: string, relationTypeId: number, onSuccess: (data: ICatalogRelationFilterContext) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetRelationFilterContext/${catalogRowGuid}/${relationTypeId}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [{ FieldName: "Name", IsDescending: false }],
                    PageSize: 50
                }
            }));
    }

    public static filterRelations = (filterContext: ICatalogRelationFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Sls/Catalog/FilterRelations", {
            ...filterContext,
            CountryId: { ...filterContext.CountryId, Values: filterContext.CountryId.Values.filter(v => v.IsChecked) },
            CatalogActiveFrom: { ...filterContext.CatalogActiveFrom, Values: filterContext.CatalogActiveFrom.Values.filter(v => v.IsChecked) },
            CatalogActiveTo: { ...filterContext.CatalogActiveTo, Values: filterContext.CatalogActiveTo.Values.filter(v => v.IsChecked) }
        }, onSuccess);
    }

    public static getEventLogFilterContext = (catalogRowGuid: string | undefined, onSuccess: (data: IEventLogFilterContext) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetEventLogFilterContext/${catalogRowGuid}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    PageSize: 50
                }
            }));
    }

    public static readActives = (productCategoryId: number, onSuccess: (data: ICatalogListItem[]) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/ReadActives/${productCategoryId}`, null, onSuccess);
    }

    public static getCreate = (onSuccess: (data: ICreateCatalogViewModel) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetCreate`, null, onSuccess);
    }

    public static create = (Catalog: ICreateCatalogViewModel, onSuccess: (rowGuid: string) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const data: ICreateCatalogViewModel = {
            ...Catalog,
            CatalogGroups: []
        };
        BaseAPI.post("Api/Sls/Catalog/create", data, onSuccess, onError);
    }

    public static get = (rowGuid: string, onSuccess: (data: ICatalogViewModel) => void, onError?: (xhr: XMLHttpRequest) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/get/${rowGuid}`, null,
            (catalog: ICatalogViewModel) => {
                onSuccess({
                    ...catalog,
                    ActiveFrom: getDateByUtc(catalog.ActiveFrom),
                    ActiveTo: getDateByUtc(catalog.ActiveTo)
                });
            }, onError);
    }

    public static update = (catalog: ICatalogViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        const data: ICatalogViewModel = {
            ...catalog,
            Countries: [],
        };
        BaseAPI.post("Api/Sls/Catalog/Update", data, onSuccess, onError);
    }

    public static toggleIsActive = (rowGuid: string, onSuccess: (response: any) => void) => {
        BaseAPI.post("Api/Sls/Catalog/ToggleIsActive", rowGuid, onSuccess);
    }

    public static delete = (rowGuid: string, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post(`Api/Sls/Catalog/delete/${rowGuid}`, null, onSuccess, onError);
    }

    public static readPages = (catalogRowGuid: string, onSuccess: (data: ICatalogPage[]) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/ReadPages/${catalogRowGuid}`, null, onSuccess);
    }

    public static addPage = (viewModel: IAddCatalogPageViewModel, imageFile: File | undefined, onSuccess: (Id: number) => void) => {
        BaseAPI.post(`Api/Sls/Catalog/AddPage`, viewModel,
            (Id) => CatalogAPI.updateCatalogPageImage(Id, imageFile, onSuccess));
    }

    public static getPage = (catalogPageId: number, onSuccess: (data: ICatalogPageViewModel) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetPage/${catalogPageId}`, null, onSuccess);
    }

    public static updatePage = (viewModel: ICatalogPageViewModel, imageFile: File | undefined, onSuccess: () => void) => {
        BaseAPI.post(`Api/Sls/Catalog/UpdatePage`, viewModel, 
            () => CatalogAPI.updateCatalogPageImage(viewModel.Id, imageFile, onSuccess));
    }

    public static removePage = (catalogRowGuid: string, catalogPageId: number, onSuccess: () => void) => {
        BaseAPI.post(`Api/Sls/Catalog/RemovePage/${catalogRowGuid}/${catalogPageId}`, null, onSuccess);
    }

    public static readPageDetails = (catalogPageId: number, onSuccess: (data: ICatalogPageDetailListItem[]) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/ReadPageDetails/${catalogPageId}`, null, onSuccess);
    }

    public static getAddProductsToCatalog = (catalogRowGuid: string, catalogPageId: number, onSuccess: (data: IAddProductsToCatalogViewModel) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetAddProductsToCatalog/${catalogRowGuid}/${catalogPageId}`, null, onSuccess);
    }

    public static addProductsToCatalog = (selectProducts: IAddProductsToCatalogViewModel, onSuccess: () => void) => {
        BaseAPI.post("Api/Sls/Catalog/AddProductsToCatalog", selectProducts, onSuccess);
    }

    public static removePageDetails = (viewModel: IDeleteCatalogPageDetailViewModel, onSuccess: () => void) => {

        BaseAPI.post(`Api/Sls/Catalog/RemovePageDetails`, viewModel, onSuccess);
    }

    public static getSelectRelation = (relationRowGuid: string, catalogRowGuid: string, onSuccess: (data: ISelectCatalogRelationViewModel) => void) => {
        BaseAPI.get(`Api/Sls/Catalog/GetSelectRelation/${relationRowGuid}/${catalogRowGuid}`, null,
            (viewModel: ISelectCatalogRelationViewModel) => {
                onSuccess({
                    ...viewModel,
                    CatalogActiveFrom: getDateByUtc(viewModel.CatalogActiveFrom),
                    CatalogActiveTo: getDateByUtc(viewModel.CatalogActiveTo),
                });
            });
    }

    public static saveRelationSelection = (viewModel: ISelectCatalogRelationViewModel, onSuccess: () => void) => {
        BaseAPI.post("Api/Sls/Catalog/SaveRelationSelection", viewModel, onSuccess);
    }

    private static updateCatalogPageImage = (catalogPageId: number, imageFile: File | undefined, onSuccess: (catalogPageId: number) => void) => {
        if (imageFile) {
            const formData = new FormData();
            formData.append("id", String(catalogPageId));
            formData.append("imageFile", imageFile);

            BaseAPI.post("Api/Sls/Catalog/UploadCatalogPageImage", formData, () => onSuccess(catalogPageId), undefined, true);

        } else {
            onSuccess(catalogPageId);
        }
    }
}