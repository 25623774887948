import React, { useEffect } from 'react';
import {
    Stack, StackItem,
    TextField,
    IComboBoxOption,
    ScrollablePane,
    Label,
    SelectableOptionMenuItemType,
    DefaultButton,
} from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { ICountry } from 'interfaces/models';
import { ICatalogBaseTabProps } from './interfaces';
import { CatalogViewModelFields, BaseCatalogViewModelFields } from 'enums/fields';
import { DatePicker, ComboBox } from 'components/common';

export const InformationTab = (props: ICatalogBaseTabProps) => {

    const { form, cultureId, } = props;
    const { item: catalog, updateItem, onTextFieldChange, onUnControlledTextFieldChange, onMultiSelectComboBoxSimpleChange, onComboBoxSimpleChange, onDatePickerSelectDate, getErrorMessage } = form;

    const { CODE, COLLECTION_ID, CATALOG_GROUP_ID, NAME, } = BaseCatalogViewModelFields;
    const { ACTIVE_FROM, ACTIVE_TO, SELECTED_COUNTY_IDS, DESCRIPTION, } = CatalogViewModelFields;

    const getCountryComboBoxOptions = (): IComboBoxOption[] => {
        const options: IComboBoxOption[] = [
            { key: 'selectAll', text: DictionaryAPI.getTranslation('SelectAll'), itemType: SelectableOptionMenuItemType.Header },
            { key: 'clearSelection', text: DictionaryAPI.getTranslation('ClearSelection'), itemType: SelectableOptionMenuItemType.Header },
        ]

        return [...options, ...catalog.Countries.map((v: ICountry, i): IComboBoxOption => {
            return {
                key: v.Id,
                text: DictionaryAPI.getTranslation("Country_" + v.Id),
            }
        })];
    }

    const onRenderOption = (item: IComboBoxOption) => {

        switch (item.itemType) {
            case SelectableOptionMenuItemType.Header:
                if (item.key === "selectAll") {
                    return <DefaultButton
                        style={{ width: "100%" }}
                        text={item.text}
                        iconProps={{
                            iconName: "Accept"
                        }}
                        onClick={() => updateItem(SELECTED_COUNTY_IDS, catalog.Countries.map((v: ICountry, i) => v.Id))} />;
                } else if (item.key === "clearSelection") {
                    return <DefaultButton
                        style={{ width: "100%" }}
                        text={item.text}
                        iconProps={{
                            iconName: "Cancel"
                        }}
                        onClick={() => updateItem(SELECTED_COUNTY_IDS, [])} />;
                }
        }

        return <span>{item.text}</span>;
    };

    /* const onCatalogGroupSelectorChange = (Id?: number, catalogGroupsChanged?: boolean) => {
        if (catalogGroupsChanged) {
            CatalogGroupAPI.read(true,
                (data) => {
                    catalog.CatalogGroupId = Id;
                    catalog.CatalogGroups = [...data];
                })
        } else {
            updateItem(CatalogViewModelFields.CATALOG_GROUP_ID, Id);
        }
    } */

    return catalog ? (
        <ScrollablePane>
            <div className="ms-Grid" dir="ltr" style={{ margin: 10, maxWidth: 800 }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg6">
                        <Stack>
                            <StackItem grow>
                                <TextField
                                    required
                                    autoFocus
                                    value={catalog.Name}
                                    name={NAME}
                                    label={DictionaryAPI.getTranslation(NAME)}
                                    errorMessage={getErrorMessage(NAME)}
                                    onChange={onTextFieldChange}
                                />
                            </StackItem>
                            <StackItem grow>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Collection")}
                                    name={COLLECTION_ID}
                                    errorMessage={getErrorMessage(COLLECTION_ID)}
                                    selectedKey={catalog.CollectionId}
                                    options={catalog.Collections.map((v): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem grow>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Group")}
                                    errorMessage={getErrorMessage(CATALOG_GROUP_ID)}
                                    selectedKey={catalog.CatalogGroupId}
                                    name={CATALOG_GROUP_ID}
                                    options={catalog.CatalogGroups.map((v): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem grow>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Country")}
                                    errorMessage={getErrorMessage(SELECTED_COUNTY_IDS)}
                                    multiSelect={true}
                                    selectedKey={catalog.SelectedCountyIds}
                                    name={SELECTED_COUNTY_IDS}
                                    options={getCountryComboBoxOptions()}
                                    onRenderOption={onRenderOption}
                                    onSimpleChange={onMultiSelectComboBoxSimpleChange} />
                            </StackItem>
                            <Stack>
                                <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                    <StackItem grow>
                                        <Label>{DictionaryAPI.getTranslation(ACTIVE_FROM)}</Label>
                                        <DatePicker
                                            cultureId={cultureId}
                                            date={catalog.ActiveFrom}
                                            name={ACTIVE_FROM}
                                            onSelectDate={onDatePickerSelectDate} />
                                    </StackItem>
                                    <StackItem grow>
                                        <Label>{DictionaryAPI.getTranslation(ACTIVE_TO)}</Label>
                                        <DatePicker
                                            cultureId={cultureId}
                                            date={catalog.ActiveTo}
                                            name={ACTIVE_TO}
                                            onSelectDate={onDatePickerSelectDate} />
                                    </StackItem>
                                </Stack>
                            </Stack>
                        </Stack>

                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg6">

                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <TextField
                            multiline
                            defaultValue={catalog.Description}
                            name={DESCRIPTION}
                            label={DictionaryAPI.getTranslation(DESCRIPTION)}
                            style={{ minHeight: 100 }}
                            onChange={onUnControlledTextFieldChange} />
                    </div>
                </div>
            </div>
        </ScrollablePane>
    ) : null;
}