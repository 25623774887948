import React, { useState, useEffect } from 'react';
import { Stack, StackItem, TextField, ComboBox, IComboBoxOption, Label, IComboBox, Icon, IStyle, mergeStyleSets, getFocusStyle, getThemedContext, getTheme } from 'office-ui-fabric-react';
import { IRelationSelectorProps } from './interfaces';
import { IRelationListItem } from 'interfaces/models';
import { useString } from 'hooks';
import { RelationAPI, DictionaryAPI, MenuAPI } from 'api';
import { IRelationViewModel } from 'interfaces/viewmodels';
import { RelationTypes, FormMenus } from 'enums';
import { AutoCompleteComboBox, MoreOptionsButton } from 'components/common';
import { CreateCustomer, Customer, CreateRelation, Relation, CreateContactPerson } from 'components/pages';
import { AddressRelationListItem } from '../AddressRelationListItem';

const optionTextWrapper: IStyle = {
    paddingBottom: 8
}

export const RelationSelector = (props: IRelationSelectorProps) => {

    const {
        labelText,
        defaultRelation,
        rowGuid,
        rowGuidFieldName,
        companyRelationId,
        parentContactId,
        parentRelationId,
        relationTypeIds,
        newNotAllowed,
        editNotAllowed,
        searchEnabled,
        readOnly,
        disabled,
        required,
        errorMessage,
        onChange,
        getRelationCallback,
        getRelationListItemsCallback } = props;

    const [relation, setRelation] = useState<IRelationViewModel | undefined>(defaultRelation);
    const [isEditRelationVisible, setIsEditRelationVisible] = useState<boolean>();
    const [comboBoxOptions, setComboboxOptions] = useState<IComboBoxOption[]>();
    const [isCreateRelationVisible, setIsCreateRelationVisible] = useState<boolean>();
    const { isEmpty } = useString();



    const setComboboxOptionsFromRelationListItems = (relationListItems: IRelationListItem[]) => {
        setComboboxOptions(relationListItems.map((v: IRelationListItem, i): IComboBoxOption => {
            return {
                key: v.RowGuid,
                text: v.Name,
                data: v,
                styles: { optionTextWrapper: optionTextWrapper }
            }
        }));
    }

    const getRelation = () => {
        if (rowGuid) {
            RelationAPI.get(rowGuid,
                (data) => {
                    setRelation(data);
                    getRelationCallback && getRelationCallback(data);
                });
            getRelationListItems();
            setIsEditRelationVisible(false);
        } else {
            setRelation(undefined);
            getRelationCallback && getRelationCallback(undefined);
        }


    }

    const getRelationListItems = () => {
        if (parentRelationId && !searchEnabled) {

            RelationAPI.read(parentRelationId, relationTypeIds[0], relationTypeIds[0] === RelationTypes.DeliveryAddress,
                (data) => {
                    setComboboxOptionsFromRelationListItems(data);
                    getRelationListItemsCallback && getRelationListItemsCallback(data);
                });
        }
    }

    const onAutoCompleteComboBoxInputValueChange = (newValue?: string, composing?: boolean) => {
        RelationAPI.search({
            SearchText: newValue,
            ParentContactId: parentContactId,
            RelationTypeIds: relationTypeIds,
            Results: 30
        },
            (data) => setComboboxOptionsFromRelationListItems(data));
    }

    const onComboboxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => {
        if (onChange && rowGuidFieldName && option) {
            const _rowGuid = String(option.key);
            onChange(rowGuidFieldName, _rowGuid);
        } else {
            onChange(rowGuidFieldName);
        }
    }

    const onRenderOption = (item: IComboBoxOption) => {

        const listItem = item.data as IRelationListItem;

        return (
            <AddressRelationListItem relationListItem={listItem} />
        );
    };

    const onMoreOptionsButtonMenuItemClick = (menuKey: string) => {
        switch (menuKey) {
            case "Add":
                setIsCreateRelationVisible(true);
                break;
            case "Edit":
                setIsEditRelationVisible(true);
                break;
            default:
                break;
        }
    }

    const getCreateRelationComponent = (): JSX.Element => {
        switch (relationTypeIds[0]) {
            case RelationTypes.Customer:
                return (
                    <CreateCustomer
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        onSuccess={onCreateRelationSuccess}
                        onCancel={onCancelCreateRelation}
                    />
                )
            case RelationTypes.Contact:
                return (
                    <CreateContactPerson
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        onSuccess={onCreateRelationSuccess}
                        onCancel={onCancelCreateRelation}
                    />
                )
            case RelationTypes.DeliveryAddress:
                return (
                    <CreateRelation
                        isPerson={false}
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        relationTypeId={relationTypeIds[0]}
                        onSuccess={onCreateRelationSuccess}
                        onCancel={onCancelCreateRelation}
                    />
                )

            default:
                break;
        }
    }

    const onCreateRelationSuccess = (rowGuid?: string) => {
        if (onChange && rowGuidFieldName && rowGuid) {
            setIsCreateRelationVisible(undefined);
            onChange(rowGuidFieldName, rowGuid);
        }
    }

    const onCancelCreateRelation = () => {
        setIsCreateRelationVisible(false)
    }

    const getEditRelationComponent = (): JSX.Element => {
        switch (relationTypeIds[0]) {
            case RelationTypes.Customer:
                return (
                    <Customer
                        rowGuid={rowGuid}
                        onDismiss={getRelation}
                    />
                )
            case RelationTypes.DeliveryAddress:
                return (
                    <Relation
                        formMenu={MenuAPI.find(FormMenus.Address)}
                        rowGuid={rowGuid}
                        onDismiss={getRelation}
                    />
                )
            case RelationTypes.Contact:
                return (
                    <Relation
                        formMenu={MenuAPI.find(FormMenus.Contact)}
                        rowGuid={rowGuid}
                        onDismiss={getRelation}
                    />
                )
            default:
                break;
        }
    }

    useEffect(() => {
        getRelation();
    }, [rowGuid])

    useEffect(() => {
        if (parentRelationId) {
            getRelationListItems();
        }
    }, [parentRelationId])

    return (
        <>
            <Label required={required}>{labelText}</Label>
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack verticalAlign="stretch" horizontal verticalFill>
                    <StackItem grow>
                        {
                            !readOnly ?
                                searchEnabled ?
                                    <AutoCompleteComboBox
                                        required={required}
                                        defaultText={relation && relation.Name}
                                        placeholder={DictionaryAPI.getTranslation("SearchByNameOrPlace")}
                                        options={comboBoxOptions}
                                        disabled={disabled}
                                        errorMessage={errorMessage}
                                        onInputValueChange={onAutoCompleteComboBoxInputValueChange}
                                        onRenderOption={onRenderOption}
                                        onChange={onComboboxChange} /> :
                                    <ComboBox
                                        required={required}
                                        allowFreeform
                                        autoComplete="on"
                                        text={relation && relation.Name}
                                        selectedKey={rowGuid}
                                        options={comboBoxOptions}
                                        disabled={disabled}
                                        onRenderOption={onRenderOption}
                                        errorMessage={errorMessage}
                                        onChange={onComboboxChange} /> :
                                <TextField
                                    readOnly
                                    disabled={disabled}
                                    value={relation?.Name || ""} />
                        }
                    </StackItem>
                    {
                        ((!newNotAllowed && !readOnly && !disabled) || (!editNotAllowed && (rowGuid && rowGuid !== ""))) && (
                            <StackItem>
                                <MoreOptionsButton
                                    addAllowed={!newNotAllowed}
                                    editAllowed={!editNotAllowed && (rowGuid && rowGuid !== "")}
                                    onMenuItemClick={onMoreOptionsButtonMenuItemClick}
                                />
                            </StackItem>
                        )
                    }
                </Stack>
                <StackItem>
                    {
                        rowGuid && (
                            <div style={{ height: 40 }}>
                                {
                                    relation && (
                                        <div className="ms-motion-fadeIn">
                                            {
                                                relationTypeIds[0] === RelationTypes.Contact ? (
                                                    <>
                                                        {!isEmpty(relation.Email) && <><Icon iconName="Mail" /> {relation.Email}<br /></>}
                                                        {!isEmpty(relation.Phone) && <><Icon iconName="Phone" /> {relation.Phone}<br /></>}
                                                        {!isEmpty(relation.Mobile) && <><Icon iconName="CellPhone" /> {relation.Mobile}<br /></>}
                                                    </>
                                                ) : (
                                                        <>
                                                            {!isEmpty(relation.Address1) && <>{relation.Address1}<br /></>}
                                                            {relation.CountryId}
                                                            {!isEmpty(relation.PostalCode) && `-${relation.PostalCode} `} <>{relation.City}<br /></>
                                                        </>
                                                    )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </StackItem>

            </Stack>
            {
                isEditRelationVisible && rowGuid && (
                    getEditRelationComponent()
                )
            }
            {
                isCreateRelationVisible && (
                    getCreateRelationComponent()
                )
            }
        </>
    );
};