import { defaultContentType, defaultDataType, execute } from '../request'
import { storeInstance } from 'store'
import { setAccount } from 'store/authentication/actions'
import { IAuthentication } from 'interfaces'
import { IAccount } from 'interfaces/models'
import { useCookies, useGlobals } from 'hooks'
import { DocumentAPI } from 'api'
import { AccountPrivilegeFields } from 'enums/fields'
import { Profiles } from 'enums'

const { getCookie, setCookie, setCookieBySeconds } = useCookies();
const { rootUrl, clientId } = useGlobals();

export const getAccessToken = () => getCookie("access_token");
export const getRefreshToken = () => getCookie("refresh_token");

const authenticate = (authentication: IAuthentication, onSuccess: (account: IAccount) => void, onError: (xhr: XMLHttpRequest) => void) => {
    const accessTokenExpireDate = new Date();
    const refreshTokenExpireDate = new Date();

    accessTokenExpireDate.setHours(accessTokenExpireDate.getHours() + 4);
    refreshTokenExpireDate.setHours(refreshTokenExpireDate.getHours() + 12);

    setCookie("access_token", authentication.token_type + " " + authentication.access_token, accessTokenExpireDate);
    setCookie("refresh_token", authentication.refresh_token, refreshTokenExpireDate);

    getAccount((account) => {
        storeInstance.dispatch(setAccount(account));

        if (account.Privilege.Relation &&
            [Profiles.DealerAccessories, Profiles.DealerFashion, Profiles.DealerShoes].includes(account.Privilege.ProfileId)) {
            DocumentAPI.setOrderCustomerRelationRowGuidToLocalStorage(account.Privilege.Relation.RowGuid);
            DocumentAPI.loadActivePendingOrderCustomerState(() => onSuccess(account));
        } else {
            onSuccess(account);
        }
    }, onError)
}

export const getAccount = (onSuccess: (account: IAccount) => void, onError: (xhr: XMLHttpRequest) => void) => {
    execute(`${rootUrl}/api/app/account/get`,
        "GET",
        defaultContentType,
        defaultDataType,
        getAccessToken(),
        null,
        "",
        onSuccess,
        onError);
}

export const clearAuthentication = () => {
    // remove access token cookie
    setCookieBySeconds("access_token", "", -1);
    // remove refresh token cookie
    setCookieBySeconds("refresh_token", "", -1);

    // remove account from store
    storeInstance.dispatch(setAccount());
}

export const authenticateByRefreshToken = (onSuccess: (account: IAccount) => void, onError: (xmlHttpRequest: XMLHttpRequest) => void): void => {
    const refreshToken = getRefreshToken();
    const data = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}`;
    execute(`${rootUrl}/token`, "POST", "application/x-www-form-urlencoded", "json", "", data, "", (response) => authenticate(response, onSuccess, onError), onError);
}

export const authenticateByUsernameAndPassword = (username: string, password: string, impersonationUserName: string, onSuccess: (account: IAccount) => void, onError: (xmlHttpRequest: XMLHttpRequest) => void): void => {
    const data = `grant_type=password&username=${username}&impersonationUserName=${impersonationUserName}&password=${encodeURIComponent(password)}&client_id=${clientId}`;
    execute(`${rootUrl}/token`, "POST", "application/x-www-form-urlencoded", "json", "", data, "",
        (response) => authenticate(response, onSuccess, onError), onError);
}
