export enum ContactSearchListItemFields {
    ADDRESS = "Address",
    CITY = "City",
    COUNTRY_ID = "CountryId",
    EMAIL = "Email",
    FIRST_NAME = "FirstName",
    ID = "Id",
    LAST_NAME = "LastName",
    NAME = "Name",
    PHONE = "Phone",
    POSTAL_CODE = "PostalCode"
}