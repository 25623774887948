export enum AddProductToOrderViewModelFields {
     DOCUMENT_ROW_GUID = "DocumentRowGuid",
     EXISTS_ON_DOCUMENT = "ExistsOnDocument",
     FIRST_DOCUMENT_DETAIL_REMARKS = "FirstDocumentDetailRemarks",
     FIRST_DOCUMENT_DETAIL_STATUS_ID = "FirstDocumentDetailStatusId",
     FROM_RELATION_ID = "FromRelationId",
     HAS_SIZES = "HasSizes",
     IS_ORDER_INPUT_POLICY_VALID = "IsOrderInputPolicyValid",
     IS_PENDING_ORDER = "IsPendingOrder",
     LAST_ORDERED_PRODUCT_DOCUMENT_DETAILS = "LastOrderedProductDocumentDetails",
     ORDER_INPUT_POLICY_ID = "OrderInputPolicyId",
     ORDER_INPUT_POLICY_ROW_GUID = "OrderInputPolicyRowGuid",
     ORDER_SUB_DOCUMENT_TYPE_ID = "OrderSubDocumentTypeId",
     PRODUCT_CATEGORY_ID = "ProductCategoryId",
     PRODUCT_DOCUMENT_DETAILS = "ProductDocumentDetails",
     PRODUCT_ID = "ProductId",
     TOTAL_PRICE = "TotalPrice",
     TOTAL_PRICE_INCL_VAT = "TotalPriceInclVat",
     TOTAL_QUANTITY = "TotalQuantity",
     ZERO_TOTAL_QUANTITY_ALLOWED = "ZeroTotalQuantityAllowed"
}