import React, { useEffect } from 'react';
import { ICreateCatalogGroupProps } from './interfaces';
import { CreateCatalogGroupViewModelFields } from 'enums/fields';
import { ICreateCatalogGroupViewModel } from 'interfaces/viewmodels';
import { DictionaryAPI, CatalogGroupAPI } from 'api';
import { useForm } from 'hooks';
import { Dialog } from 'components/common';
import { Stack, StackItem, TextField } from 'office-ui-fabric-react';

export const CreateCatalogGroup = (props: ICreateCatalogGroupProps) => {
    const { onSuccess, onCancel } = props;

    const { NAME } = CreateCatalogGroupViewModelFields;

    const {
        item,
        submit,
        onTextFieldChange,
        getErrorMessage,
        hasChanges
    } = useForm<ICreateCatalogGroupViewModel>({ Name: "" }, undefined, CatalogGroupAPI.create);

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("NewCatalogGroup")}
            maxWidth={425}
            isLoading={!item}
            acceptButtonDisabled={!hasChanges}
            onAccept={() => submit((Id) => onSuccess(Id))}
            onCancel={onCancel}
        >
            {
                item && (
                    <Stack tokens={{ childrenGap: 5 }} style={{ width: 325 }}>
                        <StackItem>
                            <TextField
                                autoFocus
                                required
                                value={item.Name}
                                name={NAME}
                                label={DictionaryAPI.getTranslation(NAME)}
                                errorMessage={getErrorMessage(NAME)}
                                onChange={onTextFieldChange} />
                        </StackItem>
                    </Stack>
                )
            }
        </Dialog>
    );
}