import React, { useState, useEffect } from 'react';
import { IProductsProps } from './interfaces';
import { ProductAPI } from 'api';
import { IProductFilterContext } from 'interfaces/models';
import { useFilterContextHandler, useUserSetting } from 'hooks';
import { ProductDetail } from 'components/pages';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Profiles } from 'enums';
import { ProductsListViewLayout } from 'components/layouts';

export const Products = (props: IProductsProps) => {

    const {
        menu,
        parentCategoryId,
    } = props;

    const [productId, setProductId] = useState<number>();
    const account = useSelector((state: RootState) => state.authenticationState.account);
    const pendingOrdersState = useSelector((state: RootState) => state.pendingOrdersState);
    const useUserSettingHook = useUserSetting(menu.Id);

    const filterContextHandler = useFilterContextHandler<IProductFilterContext>(
        (onSuccess, isActive) => {
            ProductAPI.getFilterContext(
                isActive, 
                parentCategoryId, 
                ![Profiles.Administrator, Profiles.Design].includes(account.Privilege.ProfileId), 
                pendingOrdersState.catalogId || 0, 0, [], onSuccess);
        },
        (filterContext, onSuccess) => {
            ProductAPI.filter({ ...filterContext, AssociatedProductsGrouped: false }, onSuccess);
        }, useUserSettingHook, true);

    useEffect(() => {
        if (useUserSettingHook.userSettingsLoaded) {
            filterContextHandler.getFilterContext();
        }
    }, [parentCategoryId, pendingOrdersState.catalogId, useUserSettingHook.userSettingsLoaded])
    

    return (
        <>
            <ProductsListViewLayout
                menu={menu}
                associatedProductsGrouped={false}
                printInteractionMenuId={86}
                filterContextHandler={filterContextHandler}
                onItemClick={(product) => setProductId(product.Id)}
            />
            {
                productId !== undefined ?
                    <ProductDetail
                        productId={productId}
                        warehouseContactIds={filterContextHandler.filterContext.WarehouseContactId && filterContextHandler.filterContext.WarehouseContactId.Values.map((v) => v.Value)}
                        catalogId={pendingOrdersState.catalogId}
                        onAssociatedProductClick={(productId) => setProductId(productId)}
                        onDismiss={() => setProductId(undefined)} />
                    : null
            }
        </>
    );
};