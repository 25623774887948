import React, { useState, useEffect } from 'react';
import { IBankAccount } from 'interfaces/models';
import { BankAccountAPI, DictionaryAPI } from 'api';
import { IBankAccountsProps } from '.';
import { Stack, StackItem, Label, DetailsListLayoutMode, SelectionMode, Link } from 'office-ui-fabric-react';
import { DefaultCommandTypes } from 'enums';
import { CommandBar, DetailsList, Dialog, Loader } from 'components/common';
import { BankAccountFields } from 'enums/fields';
import { useSelection } from 'hooks';
import { BankAccount } from './BankAccount';

export const BankAccounts = (props: IBankAccountsProps) => {

    const { contactId } = props;

    const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>();
    const [bankAccountId, setBankAccountId] = useState<number>();
    const [bankAccountIdToDelete, setBankAccountIdToDelete] = useState<number>();
    const [isSelectionBlocked, setIsSelectionBlocked] = useState<boolean>();

    const { selection, selectedKeys, getFirstSelectedItemIndex } = useSelection<number>(
        BankAccountFields.ID,
        bankAccounts,
        [],
        isSelectionBlocked
    );

    const readBankAccounts = (blockSelection?: boolean) => {
        setIsSelectionBlocked(blockSelection);
        BankAccountAPI.read(contactId || 0,
            (data) => {
                setBankAccounts(data);
                setIsSelectionBlocked(false);
            });
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.New:
                setBankAccountId(0);
                break;
            case DefaultCommandTypes.Delete:
                deleteBankAccount(false);
                break;
            default:
                break;
        }
    }

    const onBankAccountUpdateSuccess = () => {
        setBankAccountId(undefined);
        readBankAccounts(true);
    }

    const onIbanLinkClick = (bankAccount: IBankAccount) => {

        setBankAccountId(bankAccount.Id);
    }

    const deleteBankAccount = (confirmed?: boolean) => {
        if (confirmed && bankAccountIdToDelete) {
            BankAccountAPI.delete(bankAccountIdToDelete, () => { readBankAccounts() });
        } else {
            if (selectedKeys.length > 0) {
                setBankAccountIdToDelete(Number(selectedKeys[0]))
            }
        }
    }

    useEffect(() => {
        readBankAccounts(true);
    }, [contactId])

    useEffect(() => {
        setBankAccountId(undefined);
        setBankAccountIdToDelete(undefined)
    }, [bankAccounts])

    return (
        <>
            {
                !bankAccounts ? <Loader /> : (
                    <Stack verticalFill>
                        <StackItem>
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem><Label>{DictionaryAPI.getTranslation("BankAccounts")}</Label></StackItem>
                                <StackItem>
                                    <CommandBar
                                        defaultCommands={[
                                            { type: DefaultCommandTypes.New },
                                            { type: DefaultCommandTypes.Delete, isDisabled: selectedKeys.length === 0 }
                                        ]}
                                        onDefaultCommandClick={onDefaultCommandClick}
                                    />
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem grow>
                            <DetailsList
                                compact
                                detailsListColumns={[
                                    { fieldName: BankAccountFields.IBAN, width: 150, isRowHeader: true },
                                    { fieldName: BankAccountFields.BIC, width: 100 },
                                    { fieldName: BankAccountFields.BANK_NAME, width: 200, name: "Name" }
                                ]}
                                //onItemLinkClick={onIbanLinkClick}
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.single}
                                selection={selection}
                                items={bankAccounts}
                                getInitialFocusedIndex={getFirstSelectedItemIndex}
                            />
                        </StackItem>
                    </Stack>
                )
            }

            {
                bankAccountId !== undefined &&
                <BankAccount
                    bankAccountId={bankAccountId}
                    contactId={contactId}
                    onSuccess={onBankAccountUpdateSuccess}
                    OnCancel={() => setBankAccountId(undefined)}
                />
            }
            {
                bankAccountIdToDelete !== undefined &&
                <Dialog
                    title={DictionaryAPI.getTranslation("Delete")}
                    subText={DictionaryAPI.getTranslation("DeleteBankAccount") + "?"}
                    maxWidth={250}
                    onAccept={deleteBankAccount}
                    onCancel={() => setBankAccountIdToDelete(undefined)}
                />
            }
        </>

    );
};