import React, { useState, useEffect } from 'react';
import { WindowModal, StepPivot, IStepPivotItem, DocumentTotalsTable } from 'components/common';
import { DictionaryAPI } from 'api';
import { IPendingOrderProps } from './interfaces';
import { Stack, StackItem, mergeStyleSets, getTheme, ScrollablePane, MessageBar, MessageBarType, Link, ILinkStyles } from 'office-ui-fabric-react';
import { IPendingOrderViewModel } from 'interfaces/viewmodels';
import { DocumentAPI } from 'api/lgs/DocumentAPI';
import { ShoppingCartCheckoutStep } from './enums';
import { PendingOrderInformation } from './PendingOrderInformation';
import { PendingOrderDetails } from './PendingOrderDetails';
import { PendingOrderConfirmed } from './PendingOrderConfirmed';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const PendingOrder = (props: IPendingOrderProps) => {

    const { documentRowGuid, customerRelationRowGuid, onDismiss } = props;
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [pendingOrder, setPendingOrder] = useState<IPendingOrderViewModel>();

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const theme = getTheme();
    const classNames = mergeStyleSets({
        container: {
            color: theme.semanticColors.bodySubtext,
        },
        stepIconStyle: {
            color: theme.semanticColors.bodySubtext
        }
    });

    const linkStyles: ILinkStyles = {
        root: {
            color: theme.semanticColors.bodySubtext,
            fontSize: theme.fonts.smallPlus.fontSize
        }
    }

    const getPendingOrder = () => {
        DocumentAPI.getPendingOrder(documentRowGuid, (data) => setPendingOrder(data));
    }

    const onPreviousStepRequest = () => {
        setActiveStepIndex(activeStepIndex - 1);
    }

    const onNextStepRequest = (confirmed?: boolean) => {
        if (confirmed) {
            setActiveStepIndex(ShoppingCartCheckoutStep.Confirmed);
        } else {
            setActiveStepIndex(activeStepIndex + 1);
        }
    }

    const getContent = (): JSX.Element => {
        switch (activeStepIndex) {
            case ShoppingCartCheckoutStep.Details:
                return <PendingOrderDetails
                    documentRowGuid={documentRowGuid}
                    customerRelationRowGuid={customerRelationRowGuid}
                    documentSummary={pendingOrder.DocumentSummary}
                    cultureId={cultureId}
                    onNextStepRequest={onNextStepRequest}
                    onOrderUpdate={getPendingOrder} />
            case ShoppingCartCheckoutStep.Information:
                return <PendingOrderInformation
                    documentRowGuid={documentRowGuid}
                    customerRelationRowGuid={customerRelationRowGuid}
                    cultureId={cultureId}
                    onNextStepRequest={onNextStepRequest}
                    onPreviousStepRequest={onPreviousStepRequest} />
            case ShoppingCartCheckoutStep.Confirmed:
                return <PendingOrderConfirmed
                    documentRowGuid={documentRowGuid} />
            default:
                return <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                >
                    Not implemented.
            </MessageBar>
        }
    }

    const stepPivotItems: IStepPivotItem[] = [
        { 
            text: DictionaryAPI.getTranslation("Details"), 
            key: 'details', 
            iconProps: { iconName: "BulletedList" }, 
            disabled: activeStepIndex == ShoppingCartCheckoutStep.Confirmed 
        },
        { 
            text: DictionaryAPI.getTranslation("Information"), 
            key: 'information', 
            iconProps: { iconName: "PageHeaderEdit" }, 
            disabled: activeStepIndex < ShoppingCartCheckoutStep.Information || activeStepIndex == ShoppingCartCheckoutStep.Confirmed 
        },
        { 
            text: DictionaryAPI.getTranslation("Confirmed"), 
            key: 'confirmed', 
            iconProps: { iconName: "Completed" }, 
            disabled: activeStepIndex != ShoppingCartCheckoutStep.Confirmed }
    ];

    useEffect(() => {
        getPendingOrder();
    }, [documentRowGuid])

    return (
        <WindowModal
            titleBarProps={{
                title: pendingOrder &&
                    `${DictionaryAPI.getTranslation("Order")} ${pendingOrder.CatalogCode ? " - " + pendingOrder.CatalogCode : ""} \u2022 ${pendingOrder.CustomerName}`
            }}
            isOpen={true}
            width={950}
            height={725}
            isLoading={!pendingOrder}
            onDismiss={onDismiss}
        >
            {
                pendingOrder && (
                    <Stack verticalFill>
                        <Stack className={classNames.container} horizontal verticalFill>
                            <StackItem grow>
                                <Stack verticalFill tokens={{ childrenGap: 7 }}>
                                    <StackItem>
                                        <StepPivot
                                            items={stepPivotItems}
                                            activeStepIndex={activeStepIndex}
                                            onClick={(index) => setActiveStepIndex(index)}
                                        />
                                    </StackItem>
                                    <StackItem grow>
                                        <div style={{ position: "relative", height: "100%" }}>
                                            <ScrollablePane>
                                                {getContent()}
                                            </ScrollablePane>
                                        </div>
                                    </StackItem>

                                </Stack>
                            </StackItem>
                            {
                                /* pendingOrder.DocumentTotalsViewModel.Quantity > 0 ? (
                                    <StackItem grow>
                                        <Stack verticalFill tokens={{ childrenGap: 7 }}>
                                            <StackItem>
                                                <StepPivot
                                                    items={stepPivotItems}
                                                    activeStepIndex={activeStepIndex}
                                                    onClick={(index) => setActiveStepIndex(index)}
                                                />
                                            </StackItem>
                                            <StackItem grow>
                                                <div style={{ position: "relative", height: "100%" }}>
                                                    <ScrollablePane>
                                                        {getContent()}
                                                    </ScrollablePane>
                                                </div>
                                            </StackItem>

                                        </Stack>
                                    </StackItem>
                                ) : (
                                        <StackItem grow align="center" styles={{ root: { textAlign: "center" } }}>
                                            <h3>{DictionaryAPI.getTranslation("OrderIsEmpty")}</h3>
                                            <DefaultButton
                                                text={DictionaryAPI.getTranslation("Close")}
                                                iconProps={{ iconName: "Cancel" }}
                                                onClick={() => onDismiss()} />
                                        </StackItem>
                                    ) */
                            }
                            {
                                (activeStepIndex > ShoppingCartCheckoutStep.Details && activeStepIndex < ShoppingCartCheckoutStep.Confirmed) && (
                                    <StackItem styles={{
                                        root: {
                                            padding: 10,
                                            borderLeft: "1px solid " + theme.semanticColors.bodyFrameDivider,
                                            backgroundColor: theme.palette.neutralLighterAlt
                                        }
                                    }}>
                                        <div className="ms-depth-8" style={{ padding: 10, backgroundColor: theme.semanticColors.bodyBackground }}>
                                            <DocumentTotalsTable
                                                cultureId={cultureId}
                                                documentSummary={pendingOrder.DocumentSummary} />
                                        </div>
                                    </StackItem>
                                )
                            }
                        </Stack>
                        <Stack
                            horizontal
                            horizontalAlign="center"
                            tokens={{ childrenGap: 30, padding: 5 }}
                            style={{
                                fontSize: 8,
                                borderTop: "1px solid " + theme.semanticColors.bodyFrameDivider,
                            }}>

                            <StackItem>
                                <Link href="https://www.diffumo.com/legal/termsandconditions" target="_blank" styles={linkStyles}>Privacy Policy</Link>
                            </StackItem>
                            <StackItem>
                                <Link href="https://www.diffumo.com/legal/termsandconditions" target="_blank" styles={linkStyles}>Sales Conditions</Link>
                            </StackItem>
                        </Stack>
                    </Stack>

                )}
        </WindowModal>
    )
};