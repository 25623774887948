import React from 'react';
import { AddressFormTabMenus, ContactFormTabMenus, CustomerFormTabMenus, RelationTypes } from 'enums';
import { RelationAPI, DictionaryAPI } from 'api';
import { IRelationViewModel } from 'interfaces/viewmodels';
import { IRelationFormLayoutProps } from './interfaces';
import { FormLayout } from 'components/layouts';
import { IFormProps } from 'hooks';
import { EventLogList } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const RelationFormLayout = <T extends IRelationViewModel>(props: IRelationFormLayoutProps<T>) => {

    const { rowGuid, formMenu, getItem, updateItem, getTabMenuContent, onDismiss } = props;

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);
    
    const _getTabMenuContent = (tabMenuId: number, form?: IFormProps<T>): JSX.Element | undefined => {
        const relation = form.item;

        switch (tabMenuId) {

            case CustomerFormTabMenus.Log:
            case AddressFormTabMenus.Log:
            case ContactFormTabMenus.Log:
                return (
                    <EventLogList
                        cultureId={cultureId}
                        getFilterContext={(onSuccess) => RelationAPI.getEventLogFilterContext(relation.RowGuid, onSuccess)} />
                )
            default:
                if (getTabMenuContent) {
                    return getTabMenuContent(tabMenuId, form);
                }
                break;
        }
    }

    return (
        <FormLayout<IRelationViewModel>
            formMenu={formMenu}
            itemDependencies={[rowGuid]}
            getItem={
                (onSuccess) =>
                    getItem((data, code) =>
                        onSuccess(
                            data,
                            code ? (code + " \u2022 ") + data?.Name : data?.Name,
                            DictionaryAPI.getTranslation(RelationTypes[data?.RelationTypeId as number]),
                            data?.IsActive))
            }
            updateItem={updateItem}
            deleteItem={(item, onSuccess, onError) => RelationAPI.delete(item.RowGuid, onSuccess, onError)}
            toggleIsActive={(onSuccess) => RelationAPI.toggleIsActive(rowGuid, onSuccess)}
            getTabMenuContent={_getTabMenuContent}
            onDismiss={onDismiss}
        />
    );
}