import React from 'react';
import { TextField, ITextFieldStyleProps, ITextFieldStyles, ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';

const getStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
    const { theme, focused } = props;
    return {
        field: { padding: 0 },
        wrapper: [
            {
                width: "100%",
            },
            focused && {
                borderColor: theme.palette.themePrimary
            }
        ],
    };
}

export const UnderlinedTextField = (props: ITextFieldProps) => {
    return (
        <TextField {...props} underlined styles={getStyles} />
    );
}