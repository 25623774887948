import React, { useEffect } from 'react';
import { Dialog } from 'components/common'
import { DictionaryAPI, BankAccountAPI } from 'api';
import { Stack, StackItem, TextField } from 'office-ui-fabric-react';
import { useForm } from 'hooks';
import { IBankAccountViewModel } from 'interfaces/viewmodels';
import { BankAccountViewModelFields } from 'enums/fields';
import { IBankAccountProps } from '.';

export const BankAccount = (props: IBankAccountProps) => {

    const { bankAccountId, contactId, onSuccess, OnCancel } = props;

    const { item, initialize, onTextFieldChange, getErrorMessage, submit, hasChanges } = useForm<IBankAccountViewModel>(
        undefined,
        undefined,
        bankAccountId == 0 ? BankAccountAPI.create : BankAccountAPI.update);

    const onAccept = () => {
        submit(() => { onSuccess(); }, (xhr) => console.log(xhr), true);
    }

    const { BANK_NAME, BIC, IBAN } = BankAccountViewModelFields

    const getBankAccount = () => {
        BankAccountAPI.get(bankAccountId, (data) => initialize(true, data));
    }

    useEffect(() => {
        if (bankAccountId > 0) {
            getBankAccount();
        } else {
            initialize(true, {
                BankName: "", Bic: "", ContactId: contactId, Iban: "", Id: 0
            });
        }
    }, [bankAccountId])

    return (
        <Dialog
            title={DictionaryAPI.getTranslation(item?.Id === 0 ? "CreateBankAccount" : "BankAccount")}
            maxWidth={300}
            onAccept={onAccept}
            acceptButtonDisabled={!hasChanges}
            onCancel={OnCancel}
            isLoading={item === undefined}>
            {
                item && (
                    <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem grow>
                            <TextField
                                defaultValue={item.Iban}
                                name={IBAN}
                                placeholder={DictionaryAPI.getTranslation(IBAN)}
                                errorMessage={getErrorMessage(IBAN)}
                                onChange={onTextFieldChange} autoFocus />
                        </StackItem>
                        <StackItem styles={{ root: { width: 125 } }}>
                            <TextField
                                defaultValue={item.Bic}
                                name={BIC}
                                placeholder={DictionaryAPI.getTranslation(BIC)}
                                onChange={onTextFieldChange} />
                        </StackItem>
                        <StackItem grow>
                            <TextField
                                defaultValue={item.BankName}
                                name={BANK_NAME}
                                placeholder={DictionaryAPI.getTranslation("Name")}
                                onChange={onTextFieldChange} />
                        </StackItem>
                    </Stack>
                )
            }

        </Dialog>
    );
};