import { IApplication, IApplicationParameters } from "interfaces/models";
import { BaseAPI } from "../BaseAPI";
import { storeInstance } from 'store'
import { setApplication } from 'store/application/actions'

export class ApplicationAPI {
    public static load(id: number, onSuccess: (application: IApplication) => void) {
        BaseAPI.get(`api/app/application/get/${id}`, null,
        (data) => {
            storeInstance.dispatch(setApplication(data));
            onSuccess(data);
        });
    }

    public static getApplication(): IApplication | undefined {
        return storeInstance.getState().applicationState.application;
    }

    public static getApplicationParameters(): IApplicationParameters | undefined {
        return this.getApplication()?.Parameters;
    }
}