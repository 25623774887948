import { RelationTypes } from 'enums';
import { useString } from 'hooks';
import { getFocusStyle, getTheme, Icon, mergeStyleSets } from 'office-ui-fabric-react';
import React from 'react'
import { IAddressProps } from './interfaces'

export const AddressRelationListItem = (props: IAddressProps) => {

    const { relationListItem: item, containerClassName, isFocusable } = props;

    const { isEmpty } = useString();
    const theme = getTheme();
    const { palette } = theme;

    return (
        <div className={containerClassName} data-is-focusable={isFocusable}>
            <span className="ms-fontWeight-bold">
                {item.Name}
                {item.RelationTypeId === RelationTypes.Prospect && (
                    <span style={{ color: palette.themePrimary, marginLeft: 8 }}>
                        <Icon iconName="D365TalentInsight" />
                    </span>
                )}
            </span><br />
            <span>{!isEmpty(item.Address1) && item.Address1}</span><br />
            <span>{item.CountryId} {!isEmpty(item.PostalCode) && `-${item.PostalCode} `} {item.City}</span>
        </div>
    )
}
