import { useDates } from 'hooks';
import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { ICatalogPage } from 'interfaces/models';
import { getTheme, Stack, Selection, SelectionMode, SelectionZone, Icon, StackItem } from 'office-ui-fabric-react';
import React, { useEffect, useRef, useState } from 'react'
import { IPageListProps } from './interfaces'

export const PageList = (props: IPageListProps) => {

    const { pages, selectedCatalogPageId, onAddClick, onPageSelectionChange } = props;

    const [pageElementHeight, setPageElementHeight] = useState<number>();

    const { getImageUrl } = useFileStoreUrl();
    const { dateByUtcToLocaleString } = useDates();

    const selectionRef = useRef<Selection>(new Selection({
        canSelectItem: (item) => item.key !== 0,
        onSelectionChanged: () => handleSelectionChanged(),
        selectionMode: SelectionMode.single,
    }));

    const pageContainerRef = useRef<HTMLDivElement>();
    const visiblePageElementRef = useRef<HTMLDivElement>();

    const handleSelectionChanged = () => {
        const selection = selectionRef.current.getSelection();

        if (selection.length > 0 && onPageSelectionChange) {
            onPageSelectionChange(Number(selection[0].key));
        }
    }

    const theme = getTheme();

    const pageStyle: React.CSSProperties = {
        width: "100%",
        height: pageElementHeight,
        marginBottom: 10,
        padding: 5,
        boxSizing: "border-box",
        textAlign: "center",
        backgroundColor: `${theme.semanticColors.bodyBackground}`,
        cursor: "pointer"
    }

    const renderPage = (page: ICatalogPage, index?: number, isSelected?: boolean) => {
        const style: React.CSSProperties = page.Image ? {
            ...pageStyle,
            background: `${theme.semanticColors.bodyBackground} url('${getImageUrl(page?.Image, dateByUtcToLocaleString(page.UpdatedOn))}') no-repeat center`,
            backgroundSize: "contain"
        } : pageStyle;

        return (
            <Stack
                verticalAlign="center"
                key={page.Id}
                data-is-focusables
                data-selection-toggle
                data-selection-index={index}
                className="ms-depth-8"
                style={
                    isSelected ?
                        {
                            ...style,
                            border: "1px solid " + theme.palette.themePrimary,
                        } : style
                }
            >
                <div ref={isSelected ? visiblePageElementRef : undefined}>
                    <StackItem>
                        {(page.Image || "") === "" && page.Title}
                    </StackItem>
                </div>
            </Stack>
        )
    }

    useEffect(() => {
        if (pages) {
            selectionRef.current.setItems(pages.map((v) => {
                return {
                    ...v,
                    key: v.Id
                }
            }));

            if (visiblePageElementRef.current) {
                window.scrollTo(0, visiblePageElementRef.current.offsetTop)
            }
        }
    }, [pages, selectionRef])

    useEffect(() => {

        if (pageContainerRef.current) {
            const pageContainerWidth = pageContainerRef.current.offsetWidth;
            setPageElementHeight(pageContainerWidth / 1.5);
        }

    }, [pageContainerRef.current?.offsetWidth])

    return (
        <Stack
            verticalFill
            style={{
                borderTop: "1px solid " + theme.semanticColors.bodyFrameDivider,
                borderRight: "1px solid " + theme.semanticColors.bodyFrameDivider,
                backgroundColor: theme.palette.neutralLighterAlt,
            }}>
            <div style={{ position: "relative", height: "100%", width: 150 }}>
                <div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, padding: 8, overflowY: "scroll" }}>
                    <div ref={pageContainerRef}>
                        <SelectionZone selection={selectionRef.current}>
                            {
                                pages.map((v, i) => {
                                    return renderPage(v, i, selectedCatalogPageId === v.Id)
                                })
                            }
                            <Stack
                                verticalAlign="center"
                                className="ms-depth-8"
                                style={pageStyle}
                                onClick={onAddClick}
                            >
                                <StackItem>
                                    <Icon iconName="Add" />
                                </StackItem>
                            </Stack>
                        </SelectionZone>
                    </div>
                </div>
            </div>
        </Stack>
    )
}
