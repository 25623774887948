export enum DocumentDetailStatuses {
    Processing = 10,
    Production = 20,
    Depot = 30,
    Delivered = 40,
    Cancelled = 50,
    Cancelled_Company = 51,
    Cancelled_Production = 52,
    Cancelled_Customer = 53,
    Suspended = 60,
}