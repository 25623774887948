export enum CustomerViewModelFields {
    AGENT_RELATION_ID = "AgentRelationId",
    AGENTS = "Agents",
    CATALOG_ID = "CatalogId",
    CATALOGS = "Catalogs",
    CATALOG_ACTIVE_FROM = "CatalogActiveFrom",
    CATALOG_ACTIVE_TO = "CatalogActiveTo",
    CONTACT_PRODUCT_CATEGORIES = "ContactProductCategories",
    CUSTOMERCARD_RECEIVED = "CustomerCardReceived",
    CUSTOMERCARD_RECEIVED_ON = "CustomerCardReceivedOn",
    DISCOUNT1 = "Discount1",
    DISCOUNT2 = "Discount2",
    GROUPED_INVOICE_DETAIL = "GroupedInvoiceDetail",
    HIDE_AMOUNT_ON_PACKINGLIST = "HideAmountOnPackinglist",
    HIDE_SRP_ON_ITEM_LABEL = "HideSrpOnItemLabel",
    MAX_CATALOG_ACTIVE_TO = "MaxCatalogActiveTo",
    MIN_CATALOG_ACTIVE_FROM = "MinCatalogActiveFrom",
    NO_INVOICE = "NoInvoice",
    NO_SHIPPING_COSTS = "NoShippingCosts",
    ORDER_INPUT_POLICIES = "OrderInputPolicies",
    ORDER_INPUT_POLICY_ID = "OrderInputPolicyId",
    PAYMENT_CONDITION_ID = "PaymentConditionId",
    PAYMENT_CONDITION_NAME = "PaymentConditionName",
    PAYMENT_CONDITIONS = "PaymentConditions",
    PAYMENT_INSTALLMENTS = "PaymentInstallments",
    PAYMENT_TERM_IN_DAYS = "PaymentTermInDays",
    PRODUCT_CATEGORIES = "ProductCategories",
    PURCHASE_ASSOCIATION_CONTACT_ID = "PurchaseAssociationContactId",
    PURCHASE_ASSOCIATION_DESCRIPTION = "PurchaseAssociationDescription",
    PURCHASE_ASSOCIATIONS = "PurchaseAssociations",
    SHIPPING_COST_POLICY_ID = "ShippingCostPolicyId",
    SHIPPING_REMARKS = "ShippingRemarks",
    TERRITORIES = "Territories",
    TERRITORY_ID = "TerritoryId"
}