import { IUseSelectionProps } from './interfaces';
import { IObjectWithKey, Selection, SelectionMode } from 'office-ui-fabric-react';
import { useState, useRef, useEffect } from 'react';

export const useSelection = <T extends unknown>(
    keyFieldName: string,
    items?: any[],
    initialSelectedKeys?: T[],
    isSelectionLocked?: boolean,
    selectionMode?: SelectionMode): IUseSelectionProps<T> => {

    const [selectedKeys, setSelectedKeys] = useState<T[]>(initialSelectedKeys && [...initialSelectedKeys]);

    const selectionRef = useRef<Selection>(new Selection({
        getKey: (item) => { return keyFieldName ? item[keyFieldName] : String(item) },
        onSelectionChanged: () => handleSelectionChanged(),
        selectionMode: selectionMode,
    }));

    const previousSelectionRef = useRef<IObjectWithKey[]>([]);
    const isSelectionLockedRef = useRef<boolean>(false);

    const handleSelectionChanged = () => {

        if (!isSelectionLockedRef.current) {
            if (selectionMode === SelectionMode.multiple) {
                const keysToRemove: T[] = previousSelectionRef.current.filter(v => !selectionRef.current.getSelection().includes(v)).map((v) => v[keyFieldName]);

                setSelectedKeys((prev) => [...prev.filter(k => !keysToRemove.includes(k)),
                ...selectionRef.current.getSelection().map((v) => v[keyFieldName]).filter(value => !prev.includes(value))]);

                previousSelectionRef.current = selectionRef.current.getSelection();
            } else {
                setSelectedKeys([...selectionRef.current.getSelection().map((v) => v[keyFieldName])]);
            }
        }
    }

    const _setSelectedKeys = (keys: T[]) => {
        setSelectedKeys(keys);
    }

    const getFirstSelectedItemIndex = (): number => {

        if (items && selectedKeys && selectedKeys.length > 0) {
            return items.findIndex(i => i[keyFieldName] === selectedKeys[0]);
        }
        
        return -1;
    }

    useEffect(() => {
        if (items) {
            isSelectionLockedRef.current = true;
            selectionRef.current.setItems(items, true)
            for (const i of selectedKeys) {
                selectionRef.current.setKeySelected(String(i), true, false);
            }
            
            isSelectionLockedRef.current = false;
            previousSelectionRef.current = selectionRef.current.getSelection();
        }

    }, [items]);

    useEffect(() => {

        

        if (isSelectionLocked) {
            isSelectionLockedRef.current = true;
        }
        
    }, [isSelectionLocked]);

    return { 
        selectedKeys, 
        setSelectedKeys: _setSelectedKeys,
        selection: selectionRef.current,
        getFirstSelectedItemIndex,
        isSelectionLocked
    };
};
