import { BaseAPI } from '../BaseAPI';
import { IUserSetting } from 'interfaces/models';
import { IUserSettingMap } from 'store/userSettings/types';

export class UserSettingAPI {

    /*     public static read = (menuId: number, onSuccess: (userSettingsMap: IUserSettingMap) => void) => {
            BaseAPI.get(`Api/App/Usersetting/read/${BaseAPI.getClientId()}/${menuId}`, null,
                (userSettings: IUserSetting[]) => {
                    const map: IUserSettingMap = {};
    
                    for (const s of userSettings) {
                        map[s.Name] = s;
                    }
    
                    onSuccess(map);
                });
        }
    
        public static createOrUpdateByList = (userSettingsMap: IUserSettingMap, onSuccess?: () => void) => {
    
            const userSettings: IUserSetting[] = [];
    
            for (var prop in userSettingsMap) {
                if (Object.prototype.hasOwnProperty.call(userSettingsMap, prop)) {
                    userSettings.push(userSettingsMap[prop]);
                }
            }
    
            BaseAPI.post(`Api/App/Usersetting/CreateOrUpdateByList`, userSettings, 
            () => onSuccess && onSuccess());
        } */

    public static read = (menuId: number, onSuccess: (userSettingsMap: IUserSettingMap) => void) => {

        const map: IUserSettingMap = {}
        const data = localStorage.getItem(`user_setting_${menuId}`);

        if (!data) {
            onSuccess(map);
            return;
        }

        const userSettings: IUserSetting[] = JSON.parse(data);

        for (const s of userSettings) {
            map[s.Name] = s;
        }

        onSuccess(map);
    }

    public static createOrUpdateByList = (menuId: number, userSettingsMap: IUserSettingMap, onSuccess?: () => void) => {

        const userSettings: IUserSetting[] = [];

        for (var prop in userSettingsMap) {
            if (Object.prototype.hasOwnProperty.call(userSettingsMap, prop)) {
                userSettings.push(userSettingsMap[prop]);
            }
        }

        const data = JSON.stringify(userSettings);
        localStorage.setItem(`user_setting_${menuId}`, data);
    }

    public static saveCurrentUrlToLocalStorage = () => {
        localStorage.setItem("currentUrl", window.location.href);
    }

    public static getLastSavedCurrentUrlFromLocalStorage = (): string => {
        return localStorage.get("currentUrl") || "/";
    }

}