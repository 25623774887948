import React, { useEffect, useState } from 'react';
import { getTheme, mergeStyleSets, FontSizes, FontWeights, Stack, StackItem, Label, IconButton, IButtonProps, CommandBarButton, CommandButton, IButtonStyles } from 'office-ui-fabric-react';
import { useNumberFormat } from 'hooks';
import { DictionaryAPI, DocumentAPI } from 'api';
import { OrderSubDocumentTypes } from 'enums';
import { IOpenProductDetailState, IPendingOrdersProps } from './interfaces';
import { Accordion, AccordionItem, DocumentSummaryAccordion, Loader, ScrollableContainer, TitleBar } from 'components/common';
import { ProductDetail } from '../ProductDetail';
import { PendingOrder } from '../PendingOrder/PendingOrder';

const theme = getTheme();

export const PendingOrders = (props: IPendingOrdersProps) => {

    const { orders, cultureId, customerRelationRowGuid, onOpenOrderButtonClick } = props;

    //const [pendingOrderIndex, setPendingOrderIndex] = useState<number>();
    const [openProductDetail, setOpenProductDetail] = useState<IOpenProductDetailState>();

    /* const onAccordionItemClick = (expanded?: boolean, index?: number) => {
        if (expanded && index !== undefined) {
            setPendingOrderIndex(index);
        } else {
            setPendingOrderIndex(undefined);
            setDocumentTotalsViewModel(undefined);
        }
    }

    const getDocumentTotals = () => {
        DocumentAPI.getDocumentTotals(orders[pendingOrderIndex].RowGuid, 10,
            (data) => {
                setDocumentTotalsViewModel(data);
            });
    }

    const onProductDetailDismiss = (updated?: boolean) => {
        setProductId(undefined);

        if (updated) {
            getDocumentTotals();
        }
    }

    useEffect(() => {
        if (pendingOrderIndex !== undefined) {
            getDocumentTotals();
        }
    }, [pendingOrderIndex, orders]) */

    return (
        <>
            <ScrollableContainer width={290} padding={10}>
                <Accordion>
                    {orders.map((v, i) => {
                        return (
                            <AccordionItem
                                useNeutralBackground
                                isExpandedByDefault={i === 0}
                                key={v.RowGuid}
                                index={i}
                                header={DictionaryAPI.getTranslation(OrderSubDocumentTypes[v.SubDocumentTypeId as number]) + (v.CollectionId ? ` (${v.CollectionCode})` : "")}
                                labelStyle={{ fontSize: theme.fonts.mediumPlus.fontSize, fontWeight: 600 }}
                                actionButtonProps={{
                                    iconProps: { iconName: "Forward" },
                                    title: DictionaryAPI.getTranslation("OpenOrder"),
                                    onClick: () => onOpenOrderButtonClick(orders[i].RowGuid)
                                }}
                            //onClick={onAccordionItemClick}
                            >
                                <div style={{ padding: 10 }}>

                                    <DocumentSummaryAccordion
                                        cultureId={cultureId}
                                        documentSummary={v.DocumentSummary}
                                        onRecentlyAddedProductClick={
                                            (productId) => setOpenProductDetail({
                                                productId: productId,
                                                documentRowGuid: v.RowGuid
                                            })}
                                    />

                                </div>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </ScrollableContainer>
            {
                openProductDetail && (
                    <ProductDetail
                        productId={openProductDetail.productId}
                        documentRowGuid={openProductDetail.documentRowGuid}
                        onDismiss={() => setOpenProductDetail(undefined)}
                    />
                )
            }

        </>

    );

    {/* <Stack
                            key={v.Id}
                            tokens={{ padding: 15, childrenGap: 10 }}
                            className={`${classNames.orderContainer} ms-depth-16`}
                            onClick={() => onOrderClick(v.RowGuid)}>
                            <Stack horizontal>
                                <StackItem grow className={classNames.orderTypeName}>
                                    {DictionaryAPI.getTranslation(OrderSubDocumentTypes[v.SubDocumentTypeId as number])}
                                </StackItem>
                                <StackItem>
                                    {v.CollectionCode}
                                </StackItem>
                            </Stack>
                            <Stack className={classNames.orderCustomerName}>
                                {v.RelationName}
                            </Stack>
                            <Stack horizontal>
                                <StackItem grow>
                                    {DictionaryAPI.getTranslation("Quantity")}
                                </StackItem>
                                <StackItem>
                                    {v.Quantity}
                                </StackItem>
                            </Stack>
                            <Stack horizontal>
                                <StackItem grow>
                                    {DictionaryAPI.getTranslation("Total")}
                                </StackItem>
                                <StackItem className={classNames.orderTotal}>
                                    {getPrice(v.Total, v.CurrencyId)}
                                </StackItem>
                            </Stack>
                        </Stack> */}
};