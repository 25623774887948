
export enum SizeRangeFields {
    AGE_GROUP_ID = "AgeGroupId",
    CODE = "Code",
    CREATED_ON = "CreatedOn",
    GENDER_ID = "GenderId",
    ID = "Id",
    IS_ACTIVE = "IsActive",
    MAXIMUM_SIZE_CODE = "MaximumSizeCode",
    MINIMUM_SIZE_CODE = "MinimumSizeCode",
    NAME = "Name",
    SIZE_TYPE_ID = "SizeTypeId",
    SIZES = "Sizes"
}