import { DictionaryAPI } from 'api';
import { ProgressIndicator, Stack, StackItem } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { Dialog, FileUpload } from '../..'
import { IAddDialogProps } from './interfaces';

export const AddDialog = (props: IAddDialogProps) => {

    const { entityKey, onAccept, onCancel, uploadCallback } = props;
    const [fileList, setFileList] = useState<FileList>();
    const [submitting, setSubmitting] = useState(false);


    const submit = () => {
        setSubmitting(true);
        uploadCallback(entityKey, "", fileList[0], () => onAccept());
    }

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("Add")}
            maxWidth={450}
            acceptButtonDisabled={!fileList || submitting}
            cancelButtonDisabled={submitting}    
            onAccept={submit}
            onCancel={onCancel}
        >
            <Stack>
                <StackItem>
                    <FileUpload
                        label={DictionaryAPI.getTranslation("Attachment")}
                        onChange={(list) => setFileList(list)}
                    />
                </StackItem>
                {
                    submitting && (
                        <StackItem>
                            <ProgressIndicator />
                        </StackItem>
                    )
                }
            </Stack>

        </Dialog>
    )
}
