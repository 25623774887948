import React, { useState } from 'react';
import {
    getTheme,
    Stack, StackItem,
    ICommandBarItemProps
} from 'office-ui-fabric-react/lib';

import { CommandBar } from 'components/common'
import { IListViewCommandBarProps } from '.';
import { DictionaryAPI } from 'api';
import { ListViewCommandBarTypes } from './enums';

export const ListViewCommandBar = (props: IListViewCommandBarProps): JSX.Element => {

    const {
        defaultCommands,
        onDefaultCommandClick,
        menuPrivilege,
        items,
        isInsertItemsBeforeDefaultCommands,
        overflowItems,
        activeListViewCommandBarType,
        listViewCommandBarTypes,
        disabled,
        informationButtonChecked,
        onListViewTypeButtonClick,
        onRefreshButtonClick,
        onInformationButtonClick } = props;


    const theme = getTheme();

    const getFarItems = (): ICommandBarItemProps[] => {
        const items: ICommandBarItemProps[] = [];

        if (listViewCommandBarTypes?.length === 0 || listViewCommandBarTypes?.includes(ListViewCommandBarTypes.List)) {
            items.push({
                iconOnly: true,
                key: "listButton",
                name: DictionaryAPI.getTranslation("List"),
                iconProps: { iconName: "GroupedList" },
                checked: activeListViewCommandBarType === ListViewCommandBarTypes.List,
                onClick: () => onListViewTypeButtonClick(ListViewCommandBarTypes.List)
            });
        }

        if (listViewCommandBarTypes?.includes(ListViewCommandBarTypes.GroupList)) {
            items.push({
                iconOnly: true,
                key: "groupListButton",
                name: DictionaryAPI.getTranslation("GroupList"),
                iconProps: { iconName: "GroupList" },
                checked: activeListViewCommandBarType === ListViewCommandBarTypes.GroupList,
                onClick: () => onListViewTypeButtonClick(ListViewCommandBarTypes.GroupList)
            });
        }

        if (listViewCommandBarTypes?.includes(ListViewCommandBarTypes.Tiles)) {
            items.push({
                iconOnly: true,
                key: "tilesButton",
                name: DictionaryAPI.getTranslation("Tiles"),
                iconProps: { iconName: "GridViewSmall" },
                checked: activeListViewCommandBarType === ListViewCommandBarTypes.Tiles,
                onClick: () => onListViewTypeButtonClick(ListViewCommandBarTypes.Tiles)
            });
        }

        if (onRefreshButtonClick) {
            items.push({
                iconOnly: true,
                key: "refreshButton",
                name: DictionaryAPI.getTranslation("Refresh"),
                iconProps: { iconName: "Refresh" },
                onClick: onRefreshButtonClick
            });
        }

        if (onInformationButtonClick) {
            items.push({
                iconOnly: true,
                key: "informationButton",
                name: DictionaryAPI.getTranslation("Information"),
                iconProps: { iconName: "Info" },
                checked: informationButtonChecked,
                onClick: onInformationButtonClick
            });
        }

        return items;
    };

/*     useEffect(() => {
        if (informationButtonChecked !== undefined) {
            onToggleInformationButtonClick(informationButtonChecked);
        }
    }, [informationButtonChecked]) */

    return (
        <Stack horizontalAlign="stretch" tokens={{ childrenGap: 8 }}>
            <StackItem grow>
                <CommandBar
                    menuPrivilege={menuPrivilege}
                    defaultCommands={defaultCommands}
                    onDefaultCommandClick={onDefaultCommandClick}
                    isInsertItemsBeforeDefaultCommands={isInsertItemsBeforeDefaultCommands}
                    items={items}
                    overflowItems={overflowItems}
                    farItems={getFarItems()}
                    disabled={disabled}
                />
            </StackItem>
        </Stack>);
}