import React from 'react';
import { IAccordionProps } from '.';

export const Accordion: React.FunctionComponent<IAccordionProps> = props => {

    const { children } = props;
    return (
        <div style={{ position: "relative", width: "100%" }}>
            {children}
        </div>
    );
};