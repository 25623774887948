export enum ContactFields {
    ADDRESS1 = "Address1",
    ADDRESS2 = "Address2",
    AGENT_RELATION_ID = "AgentRelationId",
    CATALOG_ACTIVE_FROM = "CatalogActiveFrom",
    CATALOG_ACTIVE_TO = "CatalogActiveTo",
    CATALOG_ID = "CatalogId",
    CITY = "City",
    COMPANY = "Company",
    COUNTRY_ID = "CountryId",
    CREATED_ON = "CreatedOn",
    CURRENCY_ID = "CurrencyId",
    CUSTOMERCARD_RECEIVED = "CustomerCardReceived",
    CUSTOMERCARD_RECEIVED_ON = "CustomerCardReceivedOn",
    DATE_OF_BIRTH = "DateOfBirth",
    DISCOUNT1 = "Discount1",
    DISCOUNT2 = "Discount2",
    EMAIL = "Email",
    FIRST_NAME = "FirstName",
    FISCAL_CODE = "FiscalCode",
    GENDER_ID = "GenderId",
    GROUPED_INVOICE_DETAIL = "GroupedInvoiceDetail",
    HIDE_AMOUNT_ON_PACKINGLIST = "HideAmountOnPackinglist",
    HIDE_SRP_ON_ITEM_LABEL = "HideSrpOnItemLabel",
    ID = "Id",
    IS_PERSON = "IsPerson",
    IS_VAT_EXEMPT = "IsVatExempt",
    LANGUAGE_ID = "LanguageId",
    LAST_NAME = "LastName",
    LATITUDE = "Latitude",
    LONGITUDE = "Longitude",
    MOBILE = "Mobile",
    NAME = "Name",
    NO_INVOICE = "NoInvoice",
    NO_SHIPPING_COSTS = "NoShippingCosts",
    ORDER_INPUT_POLICY_ID = "OrderInputPolicyId",
    PAYMENT_CONDITION_ID = "PaymentConditionId",
    PAYMENT_INSTALLMENTS = "PaymentInstallments",
    PAYMENT_TERM_IN_DAYS = "PaymentTermInDays",
    PHONE = "Phone",
    POSTAL_CODE = "PostalCode",
    PURCHASE_ASSOCIATION_CONTACT_ID = "PurchaseAssociationContactId",
    PURCHASE_ASSOCIATION_DESCRIPTION = "PurchaseAssociationDescription",
    REMARKS = "Remarks",
    ROW_GUID = "RowGuid",
    SALUTATION = "Salutation",
    SHIPPING_COST_POLICY_ID = "ShippingCostPolicyId",
    SHIPPING_REMARKS = "ShippingRemarks",
    SUBDIVISION = "Subdivision",
    TERRITORY_ID = "TerritoryId",
    VAT = "Vat",
    VAT_NUMBER = "VatNumber",
    WEBSITE = "Website"
}