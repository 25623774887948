import { BaseAPI } from '../BaseAPI'
import { authenticateByRefreshToken, authenticateByUsernameAndPassword, getAccount, clearAuthentication } from './authentication'
import { IAccount } from 'interfaces/models'
import { IErrorMessagesMap } from 'interfaces';
import { ISignInViewModel, ICreatePasswordViewModel } from 'interfaces/viewmodels';
import { useGlobals } from 'hooks';

export class AccountAPI {
    public static authenticateByRefreshToken = (onSuccess: (account: IAccount) => void, onError: (xmlHttpRequest: XMLHttpRequest) => void) => {
        authenticateByRefreshToken(onSuccess, onError);
    }

    public static get(onSuccess: (account: IAccount) => void, onError: (xhr: XMLHttpRequest) => void) {
        getAccount(onSuccess, onError);
    }

    public static getByRowGuid(rowGuid: string, onSuccess: (account: IAccount) => void, onError: (xhr: XMLHttpRequest) => void) {
        BaseAPI.get(`api/app/account/GetByRowGuid/${rowGuid}/${useGlobals().clientId}`, onSuccess, onError);
    }

    public static signIn(signIn: ISignInViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest) => void) {
        BaseAPI.post("api/app/account/signin", signIn,
            () => authenticateByUsernameAndPassword(signIn.UserName, signIn.Password, "", onSuccess, onError),
            onError);
    }

    public static signInByImpersonation(username: string, password: string, impersonationUserName: string, onSuccess: () => void, onError: (xhr: XMLHttpRequest) => void) {
        authenticateByUsernameAndPassword(username, password, impersonationUserName, onSuccess, onError);
    }
    
    public static verify(rowGuid: string, onSuccess: (account: IAccount) => void, onError: (xhr: XMLHttpRequest) => void) {
        BaseAPI.get(`api/app/account/verify/${rowGuid}`, null, onSuccess, onError);
    }

    public static createPassword(createPassword: ICreatePasswordViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) {
        BaseAPI.post("api/app/account/createPassword", createPassword,
            onSuccess,
            onError);
    }

    public static signOut() {
        clearAuthentication();
    }
}

