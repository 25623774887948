import { useState, useEffect, useRef } from 'react';
import { IFormProps, IFormState } from '.';
import { IErrorMessagesMap } from 'interfaces';
import { IComboBox, IComboBoxOption, IChoiceGroupOption } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { useChangeTracker } from 'hooks/useChangeTracker';
import { get, set, cloneDeep } from 'lodash';

export const useForm = <T extends unknown>(
    initialItem?: T,
    onChangeCallback?: (name: string, value: any, initialValue?: any) => void,
    onSubmitCallback?: (data: any, onSuccess: (response?: any) => void, onError: (xmlHttpRequest: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void, isValidationOnly?: boolean) => void): IFormProps<T> => {

    const [state, setState] = useState<IFormState<T>>({
        item: initialItem,
        isSubmitting: false,
        errorMessages: undefined,
        //errorMessages: { [""]: [] }
    });

    const sourceItemRef = useRef<T>(initialItem ? Object.assign({}, initialItem) : undefined);
    const { pushChange, pushArrayChange, clearChanges, hasChanges } = useChangeTracker();

    const submit = (onSuccess?: (response?: any) => void, onError?: (xhr: XMLHttpRequest) => void, isDismissing?: boolean, isValidationOnly?: boolean) => {
        if (onSubmitCallback) {

            setState({ ...state, isSubmitting: true, isSubmittingSuccess: false });

            onSubmitCallback(state.item,
                (response) => {
                    onSuccess && onSuccess(response);

                    if (!isDismissing) {
                        setState({
                            ...state,
                            isSubmitting: false,
                            isSubmittingSuccess: true
                        });
                    }
                },
                (xhr, errorMessages) => {
                    if (xhr.status === 400 && errorMessages) {
                        setState({
                            ...state,
                            errorMessages: errorMessages,
                            isSubmitting: false,
                        });
                    } else {
                        setState({ ...state, isSubmitting: false });
                    }

                    onError && onError(xhr);
                },
                isValidationOnly);
        }
    };

    const onUnControlledTextFieldChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event) {
            event.persist();
            const target = event.target as HTMLFormElement;
            updateItem(target.name, target.value, true);
        }
    }

    const onTextFieldChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event) {
            event.persist();
            const target = event.target as HTMLFormElement;
            updateItem(target.name, target.value);
        }
    }

    const onSearchBoxChange = (value?: string, name?: string) => {
        if (name) {
            updateItem(name, value)
        }
    }

    const onComboBoxSimpleChange = (name?: string, option?: IComboBoxOption, index?: number, value?: string) => {
        updateItem(name, option?.key)
    }

    const onMultiSelectComboBoxSimpleChange = (name?: string, option?: IComboBoxOption, index?: number, value?: string) => {
        if (name) {
            if (option) {
                const array: any[] = state.item[name] || [];

                updateItem(name, option.selected ? [...array, option.key] : array.filter(key => key !== option.key));
            } else {
                updateItem(name, []);
            }
        }
    }


    const onCheckboxChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (event) {
            event.persist();
            const target = event.target as HTMLFormElement;
            updateItem(target.name, checked);
        }
    }

    const onChoiceGroupChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption, name?: string) => {
        if (option && name) {
            updateItem(name, option.key);
        }
    }

    const onSpinButtonChange = (value?: number, name?: string) => {
        if (name) {

            console.log("onSpinButtonChange", value);

            updateItem(name, value)
        }
    }

    const onDatePickerSelectDate = (date: Date | null | undefined, name?: string) => {
        if (name) {
            updateItem(name, date)
        }
    }

    const updateItem = (name: string, value: any, unControlled?: boolean) => {

        if (state.item) {

            const fieldValue = get(state.item, name);
            const sourceFieldValue = sourceItemRef.current && get(sourceItemRef.current, name);
            const isArray = Array.isArray(fieldValue);

            if (typeof fieldValue === "number") {
                set<any>(state.item, name, Number(value));
            } else if (typeof fieldValue === "boolean") {
                set<any>(state.item, name, String(value) === "true");
            } else if (isArray) {
                set<any>(state.item, name, [...value]);
            } else {
                set<any>(state.item, name, value);
            }

            if (state.errorMessages) {
                if (state.errorMessages[name] !== undefined && state.errorMessages[name].length > 0) {
                    state.errorMessages[name] = [];
                    setState({ ...state });
                }
            }

            if (!unControlled) {
                setState({ ...state });
            }

            if (isArray) {
                pushArrayChange(name, value, sourceFieldValue);
            } else {
                pushChange(name, value, sourceFieldValue);
            }

            if (onChangeCallback) {
                onChangeCallback(name, value, sourceFieldValue);
            }

        }
    }

    const getErrorMessage = (name: string, index?: number, disableTranslation?: boolean): string => {
        if (state.errorMessages) {
            if (state.errorMessages[name] && state.errorMessages[name].length > 0) {
                const message = state.errorMessages[name][index || 0];
                return disableTranslation ? message : DictionaryAPI.getTranslation(message);
            }
        }

        return "";
    }

    const initialize = (reset?: boolean, item?: T): [T?, T?] => {
        if (!state.item || reset) {
            setState({
                item: item,
                errorMessages: undefined,
                isSubmitting: false,
                isReset: true,
            });

            sourceItemRef.current = cloneDeep(item);
            return [initialItem, sourceItemRef.current];
        }

        return [state.item, sourceItemRef.current];
    }

    useEffect(() => {
        if (state.isReset || state.isSubmittingSuccess) {
            clearChanges();
            setState({
                ...state,
                isReset: false
            });
        }
    }, [state.isReset, state.isSubmittingSuccess])

    return {
        initialize,
        sourceItem: sourceItemRef.current,
        item: state.item,
        onTextFieldChange,
        onUnControlledTextFieldChange,
        onSearchBoxChange,
        onComboBoxSimpleChange,
        onMultiSelectComboBoxSimpleChange,
        onCheckboxChange,
        onChoiceGroupChange,
        onSpinButtonChange,
        onDatePickerSelectDate,
        updateItem,
        hasChanges,
        submit,
        isSubmitting: state.isSubmitting,
        errorMessages: state.errorMessages,
        getErrorMessage,
        onChangeCallback
    }
};