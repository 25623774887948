export const defaultContentType: string = "application/json; charset=utf-8";
export const defaultDataType: string = "json";

export const objectToParams = (obj: any) => {
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    if (str.length > 0) {
        return "?" + str.join("&");
    }

    return "";
}

export const execute = (url: string, type: string, contentType: string, dataType: string, authorization: string, data: any, culture: string | undefined, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest) => void, onAuthenticationFailed?: (xhr: XMLHttpRequest) => void) => {

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === 4 && (xmlhttp.status === 200 || xmlhttp.status === 204)) {

            if (xmlhttp.status === 200 && xmlhttp.response) {
                if (dataType === defaultDataType) {
                    onSuccess(JSON.parse(xmlhttp.response));
                } else {
                    onSuccess(xmlhttp.response);
                }
            } else {
                onSuccess(null);
            }

        } else if (xmlhttp.readyState === 4) {
            if (xmlhttp.status === 401) {
                if (onAuthenticationFailed) {
                    onAuthenticationFailed(xmlhttp);
                } else {
                    onError(xmlhttp);
                }
            } else {
                onError(xmlhttp);
            }
        }
    }
    xmlhttp.open(type, url, true);
    if (contentType != "") {
        xmlhttp.setRequestHeader("Content-type", contentType);
    }
    xmlhttp.setRequestHeader("Data-type", dataType);
    if (authorization !== "") {
        xmlhttp.setRequestHeader("Authorization", authorization);
    }
    xmlhttp.setRequestHeader("Culture", culture || "");
    xmlhttp.withCredentials = true;
    xmlhttp.send(data);

}

