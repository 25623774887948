import { BaseAPI } from '../BaseAPI';

export class FilterAPI {

    public static getFilterContext = (contextName: string, params: string[], onSuccess: (response: any) => void) => {
        let url = `api/${contextName}/getfiltercontext/`;

        if (params) {
            url = url + params.join("/");
        }

        BaseAPI.get(url, null, onSuccess);
    }
}