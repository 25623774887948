import React from 'react'
import { IDocumentSummaryAccordionProps } from './interfaces';
import { useFileStoreUrl, useNumberFormat } from 'hooks';
import { getTheme, ImageFit, Link, Stack, StackItem, Image, LinkBase } from 'office-ui-fabric-react';
import { Accordion, AccordionItem, DocumentTotalsTable, OrderTrendStatistics } from '..';
import { DictionaryAPI } from 'api';

const theme = getTheme();

export const DocumentSummaryAccordion = (props: IDocumentSummaryAccordionProps) => {

    const { documentSummary, cultureId } = props;

    const { getImageUrl } = useFileStoreUrl();
    const { getNumber } = useNumberFormat(cultureId);

    const onRecentlyAddedProductClick = (event: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement | LinkBase, MouseEvent>) => {

        const currentEvent = event as React.MouseEvent<HTMLAnchorElement>;

        if (props.onRecentlyAddedProductClick) {
            props.onRecentlyAddedProductClick(Number(currentEvent.currentTarget.dataset.productId));
        }
    }

    return (
        <Accordion>
            {
                documentSummary.ProductDocumentDetailsGroups?.length > 0 && (
                    <AccordionItem
                        isExpandedByDefault
                        header={DictionaryAPI.getTranslation("RecentlyAdded")}
                    >
                        {documentSummary.ProductDocumentDetailsGroups?.map((v) => {
                            return (
                                <Stack key={v.GroupKey} tokens={{ childrenGap: 6 }} horizontal verticalAlign="center">
                                    <StackItem>
                                        <Image
                                            src={getImageUrl(v.Image)}
                                            height={32}
                                            width={32}
                                            imageFit={ImageFit.contain}
                                        />
                                    </StackItem>
                                    <StackItem grow>
                                        <Link data-product-id={v.ProductId} onClick={onRecentlyAddedProductClick}>
                                            {v.DisplayName}
                                        </Link>
                                    </StackItem>
                                    <StackItem 
                                        styles={{ 
                                            root: { textAlign: "right", fontSize: theme.fonts.small.fontSize, whiteSpace: "nowrap" } 
                                        }}
                                    >
                                        <span style={{ color: theme.semanticColors.disabledBodyText }}>
                                            {(v.SizeRangeId && v.TotalQuantity > 0) && (
                                                <>
                                                    {v.FirstSizeName + (v.FirstSizeName !== v.LastSizeName ? "-" + v.LastSizeName : "")}
                                                    <br />
                                                </>
                                            )}
                                        </span>
                                        <span style={{ color: theme.semanticColors.disabledBodyText }}>
                                            {v.TotalQuantity}
                                        </span>
                                    </StackItem>
                                </Stack>
                            )
                        })}
                        {
                            documentSummary.Quantity > documentSummary.ProductDocumentDetailsGroupsQuantity && (
                                <div
                                    style={{
                                        color: theme.semanticColors.disabledBodyText, 
                                        width: "100%", 
                                        textAlign: "right",
                                        marginTop: 6
                                    }}
                                >
                                    (+ {getNumber(documentSummary.Quantity - documentSummary.ProductDocumentDetailsGroupsQuantity)})
                                </div>
                            )
                        }
                    </AccordionItem>
                )
            }
            <AccordionItem
                header={DictionaryAPI.getTranslation("Totals")}
                isExpandedByDefault={!documentSummary.ProductDocumentDetailsGroups}
            >
                <DocumentTotalsTable
                    cultureId={cultureId}
                    documentSummary={documentSummary} />
            </AccordionItem>

            {
                documentSummary.OrderTrendStatistics && (
                    <AccordionItem header={DictionaryAPI.getTranslation("Statistics")}>
                        <OrderTrendStatistics
                            cultureId={cultureId}
                            orderTrendStatistics={documentSummary.OrderTrendStatistics}
                        />
                    </AccordionItem>
                )
            }
        </Accordion>
    )
}
