export enum RelationListItemFields {
    ADDRESS1 = "Address1",
    CITY = "City",
    CODE = "Code",
    COMPANY_RELATION_ID = "CompanyRelationId",
    CONTACT_ID = "ContactId",
    COUNTRY_ID = "CountryId",
    COMPANY = "Company",
    LANGUAGE_ID = "LanguageId",
    EMAIL = "Email",
    FIRST_NAME = "FirstName",
    HORIZONTAL_ADDRESS_FORMAT = "HorizontalAddressFormat",
    HORIZONTAL_NAME_AND_ADDRESS_FORMAT = "HorizontalNameAndAddressFormat",
    ID = "Id",
    IS_PERSON = "IsPerson",
    IS_PRIMARY = "IsPrimary",
    LAST_NAME = "LastName",
    MOBILE = "Mobile",
    NAME = "Name",
    PARENT_CONTACT_ID = "ParentContactId",
    PHONE = "Phone",
    POSTAL_CODE = "PostalCode",
    RELATION_TYPE_ID = "RelationTypeId",
    RELATION_TYPE_NAME = "RelationTypeName",
    ROW_GUID = "RowGuid",
    PRESENTATION_IMAGE = "PresentationImage",
    CREATED_ON = "CreatedOn",
}