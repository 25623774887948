export enum ContactBaseViewModelFields {
     ADDRESS1 = "Address1",
     ADDRESS2 = "Address2",
     ADDRESS_PROPERTIES_REQUIRED = "AddressPropertiesRequired",
     CITY = "City",
     CONTACT_ID = "ContactId",
     COUNTRIES = "Countries",
     COUNTRY_ID = "CountryId",
     FIRST_NAME = "FirstName",
     IS_PERSON = "IsPerson",
     LAST_NAME = "LastName",
     NAME = "Name",
     POSTAL_CODE = "PostalCode"
    }