import { AuthenticationTypes, IAuthenticationState } from './types'
import { SET_ACCOUNT } from './actions'

const initialState: IAuthenticationState = {
    account: undefined
}

export function authenticationReducer(state = initialState, action: AuthenticationTypes): IAuthenticationState {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                account: action.account
            }
        default:
            return state
    }
}