import React, { useState, useEffect } from 'react';
import {
    getTheme,
    Stack, StackItem,
    CommandBar as FabricCommandBar, ICommandBarItemProps,
    IContextualMenuItem,
    IButtonStyles
} from 'office-ui-fabric-react/lib/index';

import { ICommandBarProps } from '.';
import { MenuTypes, DefaultCommandTypes } from 'enums'
import { DictionaryAPI } from 'api';

export const CommandBar = (props: ICommandBarProps): JSX.Element => {

    const {
        defaultCommands,
        onDefaultCommandClick,
        menuPrivilege,
        items,
        isInsertItemsBeforeDefaultCommands,
        overflowItems,
        farItems,
        disabled,
        isActive,
        useNeutralBackground } = props;
    const theme = getTheme();

    const getButtonStyles = (): IButtonStyles | undefined => {

        let buttonStyles: IButtonStyles | undefined;

        if (useNeutralBackground) {
            buttonStyles = {
                ...buttonStyles,
                root: { backgroundColor: theme.palette.neutralLighter },
                rootPressed: { backgroundColor: theme.palette.neutralQuaternaryAlt },
                rootDisabled: { backgroundColor: theme.palette.neutralLighter },
                rootCheckedDisabled: { backgroundColor: theme.palette.neutralQuaternaryAlt },
                rootHovered: { backgroundColor: theme.palette.neutralLight },
                rootChecked: { backgroundColor: theme.palette.neutralQuaternaryAlt },
                rootCheckedHovered: { backgroundColor: theme.palette.neutralQuaternary },
                rootCheckedPressed: { backgroundColor: theme.palette.neutralTertiaryAlt },
            }
        }

        return buttonStyles;
    }

    const getItems = (): ICommandBarItemProps[] => {
        let _items: ICommandBarItemProps[] = [];

        if (defaultCommands) {
            for (const n of defaultCommands) {

                let name: string = DictionaryAPI.getTranslation(DefaultCommandTypes[n.type]);
                let isAllowed: boolean = false;
                let iconName: string = "";

                switch (n.type) {
                    case DefaultCommandTypes.Save:
                        isAllowed = menuPrivilege?.EditAllowed;
                        iconName = "Save";
                        break;
                    case DefaultCommandTypes.Copy:
                        isAllowed = menuPrivilege?.CreateAllowed;
                        iconName = "Copy";
                        break;
                    case DefaultCommandTypes.Share:
                        isAllowed = true;
                        iconName = "Share";
                        break;
                    case DefaultCommandTypes.Print:
                        isAllowed = true;
                        iconName = "Print";
                        break;
                    case DefaultCommandTypes.Export:
                        isAllowed = true;
                        iconName = "Share";
                        break;
                    case DefaultCommandTypes.Email:
                        isAllowed = true;
                        iconName = "Mail";
                        break;
                    case DefaultCommandTypes.New:
                        isAllowed = menuPrivilege?.CreateAllowed;
                        iconName = "CircleAddition";
                        break;
                    case DefaultCommandTypes.Add:
                        isAllowed = menuPrivilege?.CreateAllowed;
                        iconName = "Add";
                        break;
                    case DefaultCommandTypes.Edit:
                        isAllowed = menuPrivilege?.EditAllowed;
                        iconName = "Edit";
                        break;
                    case DefaultCommandTypes.Delete:
                        isAllowed = menuPrivilege?.DeleteAllowed;
                        iconName = "Delete";
                        break;
                    case DefaultCommandTypes.Remove:
                        isAllowed = menuPrivilege?.DeleteAllowed;
                        iconName = "Remove";
                        break;
                    case DefaultCommandTypes.ToggleActive:
                        isAllowed = menuPrivilege?.ToggleActiveAllowed;
                        name = isActive ?
                            DictionaryAPI.getTranslation("Deactivate") : DictionaryAPI.getTranslation("Activate");
                        iconName =
                            isActive ? "Cancel" : "CheckMark";
                        break;

                    default:
                }

                if (isAllowed) {
                    _items.push({
                        key: name,
                        name: name,
                        title: name,
                        ariaLabel: name,
                        iconProps: { iconName: iconName },
                        disabled: n.isDisabled || disabled,
                        subMenuProps: n.subMenuItems ? { items: n.subMenuItems } : undefined,
                        buttonStyles: getButtonStyles(),
                        onClick: () => onDefaultCommandClick && onDefaultCommandClick(n.type)
                    })
                }

            }
        }

        if (items) {
            for (let index = 0; index < items.length; index++) {
                const _item = items[index];
                const item = {
                    ..._item,
                    disabled: _item.disabled || disabled,
                    buttonStyles: getButtonStyles()
                };

                if (isInsertItemsBeforeDefaultCommands && _items.length > index) {
                    _items.splice(index, 0, item);
                } else {
                    _items.push(item);
                }
            }
        }

        return _items;
    }

    const getfarItems = (): ICommandBarItemProps[] => {
        for (const item of farItems) {
            item.buttonStyles = getButtonStyles();
        }

        return farItems;
    }

    return (
        <Stack horizontalAlign="stretch" horizontal>


            <StackItem grow>
                <FabricCommandBar
                    items={getItems()}
                    overflowItems={overflowItems}
                    farItems={farItems && getfarItems()}
                    ariaLabel={'Use left and right arrow keys to navigate between commands'}
                    styles={{
                        root: { padding: 0, height: 32, backgroundColor: useNeutralBackground ? theme.palette.neutralLighter : theme.palette.white },
                    }}
                />
            </StackItem>
        </Stack>);
}