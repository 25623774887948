import React, { useState } from 'react';
import { Dialog, RelationSelector } from 'components/common';
import { DictionaryAPI } from 'api';
import { RelationTypes } from 'enums';
import { useGlobals } from 'hooks';
import { Stack } from 'office-ui-fabric-react';
import { ISelectPendingOrderCustomer } from '.';

export const SelectPendingOrderCustomer = (props: ISelectPendingOrderCustomer) => {

    const { onAccept, onCancel } = props;
    const [customerRelationRowGuid, setCustomerRelationRowGuid] = useState<string>();
    const { companyRelationId } = useGlobals();

    return (
        <>
            <Dialog
                title={DictionaryAPI.getTranslation("SelectCustomer")}
                maxWidth={450}
                onAccept={() => onAccept(customerRelationRowGuid)}
                onCancel={onCancel}
            >
                <Stack style={{ width: 300 }}>
                    <RelationSelector
                        rowGuidFieldName={"customerRelationRowGuid"}
                        rowGuid={customerRelationRowGuid}
                        companyRelationId={companyRelationId}
                        relationTypeIds={[RelationTypes.Customer, RelationTypes.Prospect]}
                        searchEnabled={true}
                        labelText={DictionaryAPI.getTranslation("CustomerOrProspect")}
                        onChange={(rowGuidFieldName, rowGuid) => setCustomerRelationRowGuid(rowGuid)}
                    />
                </Stack>
            </Dialog>
        </>

    );
};