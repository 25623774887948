import { BaseAPI, FilterAPI } from 'api';
import { IErrorMessagesMap } from 'interfaces';
import { IDocumentListItem, IFilterResultInfo, IDocumentFilterContext, IProductDocumentDetailFilterContext, IEventLogFilterContext, IDocumentProductSizeRange, IDocumentDetailFilterContext, IDocumentDetailListItem, IDocumentSummary, IFileDetail } from 'interfaces/models';
import { storeInstance } from 'store'
import { setPendingOrders, setActivePendingOrderCustomer } from 'store/pendingOrders/actions'
import { RelationAPI } from 'api/crm/RelationAPI';
import { OrderReports, DocumentStatuses, DocumentTypes, FileTypes, Profiles } from 'enums';
import { useDates, useGlobals } from 'hooks';
import {
    ICreateOrderViewModel,
    IAddProductToOrderViewModel,
    IPendingOrderDetailsViewModel,
    IPendingOrderInformationViewModel,
    IPendingOrderViewModel,
    IOrderViewModel,
    ICancelDocumentViewModel,
    IPendingOrderConfirmedViewModel,
    IUpdateDocumentDetailsViewModel,
    IProductDocumentDetailViewModel,
    IDocumentFilterResultViewModel,
    ICopyDocumentViewModel,
    ICreateEmailNotificationViewModel,
    IPendingOrderEmailNotificationViewModel,
    ICreateOrderReportEmailNotificationViewModel,
    IRecalculateDocumentsViewModel,
    IDocumentBaseViewModel
} from 'interfaces/viewmodels';

import {
    DocumentDetailListItemFields,
    DocumentListItemFields,
    ProductDocumentDetailListItemFields
} from 'enums/fields';
import { getAccessToken } from 'api/app/authentication';
import { IOrderProductViewModel } from 'interfaces/viewmodels/sls/IOrderProductViewModel';
import { ICreateStockAssortmentViewModel } from 'interfaces/viewmodels/sls/ICreateStockAssortmentViewModel';
import { IStockAssortmentViewModel } from 'interfaces/viewmodels/sls/IStockAssortmentViewModel';
import { IAddMultipleProductsToDocumentViewModel } from 'interfaces/viewmodels/lgs/IAddMultipleProductsToDocumentViewModel';
import { StockAssortmentReports } from 'enums/lgs/StockAssortmentReports';
import { ICreateStockAssortmentReportEmailNotificationViewModel } from 'interfaces/viewmodels/lgs/ICreateStockAssortmentReportEmailNotificationViewModel';

const { getDateByUtc } = useDates();
const { rootUrl } = useGlobals();

export class DocumentAPI {

    private static PENDING_ORDER_CUSTOMER_RELATION = "PENDING_ORDER_CUSTOMER_RELATION";

    public static getFilterContext = (documentTypeId: number, customerRelationRowGuid: string | undefined, onSuccess: (data: IDocumentFilterContext) => void) => {
        const account = storeInstance.getState().authenticationState.account;
        FilterAPI.getFilterContext("lgs/document",
            [
                String(documentTypeId),
                customerRelationRowGuid || "",
            ],
            (data: IDocumentFilterContext) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [{ FieldName: DocumentListItemFields.CREATED_ON, IsDescending: true }],
                    PageSize: 200
                }
            }));
    }

    public static filter = (filterContext: IDocumentFilterContext, onSuccess: (data: IDocumentFilterResultViewModel) => void) => {
        BaseAPI.post("api/lgs/document/Filter", DocumentAPI.prepareDocumentFilterContextForPosting(filterContext), onSuccess);
    }

    public static getDetailFilterContext = (documentRowGuid: string, excludeEmptyQuantities: boolean, onSuccess: (data: IDocumentDetailFilterContext) => void) => {
        BaseAPI.get(`Api/Lgs/Document/GetDetailFilterContext/${documentRowGuid}/${excludeEmptyQuantities}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [
                        { FieldName: DocumentDetailListItemFields.CREATED_ON, IsDescending: false },
                    ],
                    PageSize: 200
                }
            }));
    }

    public static filterDetails = (filterContext: IDocumentDetailFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Lgs/Document/FilterDetails", {
            ...filterContext,
            DocumentDetailTypeId: { ...filterContext.DocumentDetailTypeId, Values: filterContext.DocumentDetailTypeId.Values.filter(v => v.IsChecked) },
        }, onSuccess);
    }

    public static getProductDetailFilterContext = (documentRowGuid: string, sizeRangeId: number, isGrouped: boolean, onSuccess: (data: IProductDocumentDetailFilterContext) => void) => {
        BaseAPI.get(`Api/Lgs/Document/GetProductDetailFilterContext/${documentRowGuid}/${sizeRangeId}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [
                        { FieldName: ProductDocumentDetailListItemFields.CREATED_ON, IsDescending: false },
                    ],
                    PageSize: isGrouped ? 100 : 200
                }
            }));
    }

    public static filterProductDetailsGroups = (filterContext: IProductDocumentDetailFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Lgs/Document/FilterProductDetailsGroups", {
            ...filterContext,
            ProductCategoryId: { ...filterContext.ProductCategoryId, Values: filterContext.ProductCategoryId.Values.filter(v => v.IsChecked) },
        }, onSuccess);
    }

    public static getEventLogFilterContext = (documentRowGuid: string | undefined, onSuccess: (data: IEventLogFilterContext) => void) => {
        BaseAPI.get(`Api/Lgs/Document/GetEventLogFilterContext/${documentRowGuid}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    PageSize: 50
                }
            }));
    }

    public static getOrder = (documentRowGuid: string, onSuccess: (data: IOrderViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetOrder/${documentRowGuid}`, null,
            (order: IOrderViewModel) => {
                onSuccess({
                    ...order,
                    CreatedOn: getDateByUtc(order.CreatedOn),
                    ConfirmedOn: getDateByUtc(order.ConfirmedOn),
                    ConfirmationSentOn: getDateByUtc(order.ConfirmationSentOn),
                    ExecutedOn: getDateByUtc(order.ExecutedOn),
                    SignedOn: getDateByUtc(order.SignedOn),
                });
            }
        );
    }

    public static updateOrder = (viewModel: IOrderViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void, isValidationOnly?: boolean) => {
        BaseAPI.post("api/lgs/document/UpdateOrder", DocumentAPI.prepareDocumentBaseViewModelForPosting(viewModel, isValidationOnly), onSuccess, onError);
    }

    public static createOrder = (createOrder: ICreateOrderViewModel, onSuccess: (rowGuid: string) => void, onError?: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/lgs/document/CreateOrder", createOrder, onSuccess, onError);
    }

    public static getCreateOrder = (subDocumentTypeId: number, companyRelationId: number, onSuccess: (data: ICreateOrderViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetCreateOrder/${subDocumentTypeId}/${companyRelationId}`, null, onSuccess);
    }

    public static getStockAssortment = (documentRowGuid: string, onSuccess: (data: IStockAssortmentViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetStockAssortment/${documentRowGuid}`, null,
            (order: IOrderViewModel) => {
                onSuccess({
                    ...order,
                    CreatedOn: getDateByUtc(order.CreatedOn),
                    ConfirmedOn: getDateByUtc(order.ConfirmedOn),
                    ExecutedOn: getDateByUtc(order.ExecutedOn),
                });
            }
        );
    }

    public static updateStockAssortment = (viewModel: IStockAssortmentViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void, isValidationOnly?: boolean) => {
        BaseAPI.post("api/lgs/document/UpdateStockAssortment", DocumentAPI.prepareDocumentBaseViewModelForPosting(viewModel, isValidationOnly), onSuccess, onError);
    }

    public static createStockAssortment = (createOrder: ICreateStockAssortmentViewModel, onSuccess: (rowGuid: string) => void, onError?: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/lgs/document/CreateStockAssortment", createOrder, onSuccess, onError);
    }

    public static getCreateStockAssortment = (companyRelationId: number, onSuccess: (data: ICreateStockAssortmentViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetCreateStockAssortment/${companyRelationId}`, null, onSuccess);
    }

    public static readDocuments = (relationRowGuid: string, documentTypeId: number, documentStatusId: number, collectionId: number, includeDocumentSummary: boolean, recentlyAddedProductDetailsGroupsToInclude: number, onSuccess: (data: IDocumentListItem[]) => void) => {
        BaseAPI.get(`api/lgs/document/readDocuments/${relationRowGuid}/${documentTypeId}/${documentStatusId}/${collectionId}/${includeDocumentSummary}/${recentlyAddedProductDetailsGroupsToInclude}`, null, onSuccess);
    }

    public static readPendingOrders = (relationRowGuid: string, onSuccess: (data: IDocumentListItem[]) => void) => {
        if (relationRowGuid) {
            DocumentAPI.readDocuments(relationRowGuid, DocumentTypes.Order, DocumentStatuses.Pending, 0, true, 10, onSuccess);
        }
    }

    public static getAddProductToOrder = (productId: number, companyRelationId: number, customerRelationRowGuid: string, catalogId: number, onSuccess: (data: IAddProductToOrderViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetAddProductToOrder/${productId}/${companyRelationId}/${customerRelationRowGuid}/${catalogId}`, null, onSuccess);
    }

    public static getAddProductToOrderByDocument = (productId: number, documentRowGuid: string, onSuccess: (data: IAddProductToOrderViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetAddProductToOrderByDocument/${productId}/${documentRowGuid}`, null, onSuccess);
    }

    public static getPendingOrder = (documentRowGuid: string, onSuccess: (data: IPendingOrderViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetPendingOrder/${documentRowGuid}`, null, onSuccess);
    }

    public static getPendingOrderDetails = (documentRowGuid: string, onSuccess: (data: IPendingOrderDetailsViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetPendingOrderDetails/${documentRowGuid}`, null, onSuccess);
    }

    public static getPendingOrderInformation = (documentRowGuid: string, onSuccess: (data: IPendingOrderInformationViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetPendingOrderInformation/${documentRowGuid}`, null, onSuccess);
    }

    public static getPendingOrderConfirmed = (documentRowGuid: string, onSuccess: (data: IPendingOrderConfirmedViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetPendingOrderConfirmed/${documentRowGuid}`, null, onSuccess);
    }

    public static readDocumentDetails = (documentRowGuid: string, onSuccess: (data: IDocumentDetailListItem[]) => void) => {
        BaseAPI.get(`api/lgs/document/ReadDocumentDetails/${documentRowGuid}`, null, onSuccess);
    }

    public static getDocumentSummary = (documentRowGuid: string, lastAddedProductDetailsGroupsToInclude: number, onSuccess: (data: IDocumentSummary) => void) => {
        BaseAPI.get(`api/lgs/document/GetDocumentSummary/${documentRowGuid}/${lastAddedProductDetailsGroupsToInclude}`, null, onSuccess);
    }

    public static updatePendingOrderInformation = (viewModel: IPendingOrderInformationViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/lgs/document/UpdatePendingOrderInformation", {
            ...viewModel,
            CustomerRelation: null,
            Contactrelation: null,
            DeliveryAddressRelation: null,
        }, onSuccess, onError);
    }

    public static sendAndConfirmPendingOrderInformation = (viewModel: IPendingOrderEmailNotificationViewModel, onSuccess: () => void) => {
        BaseAPI.post("api/lgs/document/SendAndConfirmPendingOrderInformation", viewModel, onSuccess);
    }

    public static confirmDocument = (documentRowGuid: string, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/ConfirmDocument/${documentRowGuid}`, null,
            () => {
                DocumentAPI.loadPendingOrdersState(onSuccess);
            });
    }

    public static executeDocument = (documentRowGuid: string, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/ExecuteDocument/${documentRowGuid}`, null,
            () => {
                DocumentAPI.loadPendingOrdersState(onSuccess);
            });
    }

    public static editDocument = (documentRowGuid: string, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/EditDocument/${documentRowGuid}`, null,
            () => {
                DocumentAPI.loadPendingOrdersState(onSuccess);
            });
    }

    public static cancelDocument = (viewModel: ICancelDocumentViewModel, onSuccess: () => void) => {
        BaseAPI.post('api/lgs/document/CancelDocument', viewModel,
            () => {
                DocumentAPI.loadPendingOrdersState(onSuccess);
            });
    }

    public static createFilteredDocumentListCsv = (filterContext: IDocumentFilterContext, onSuccess: (url: string) => void) => {
        BaseAPI.post(`api/lgs/document/CreateFilteredDocumentListCsv`, DocumentAPI.prepareDocumentFilterContextForPosting(filterContext), onSuccess);
    }

    public static createOrderReportAndGetUrl = (documentRowGuid: string, orderReport: OrderReports, onSuccess: (url: string) => void) => {
        BaseAPI.post(`api/lgs/document/CreateOrderReportAndGetUrl/${documentRowGuid}/${OrderReports[orderReport]}`, null, 
            (data) => {
               onSuccess(data + `?t=${Date.now()}`);
            });
    }

    public static sendProductDetailsEdmByFtp = (documentRowGuid: string, includeImages: boolean, onSuccess: (errorMessage: string) => void) => {
        BaseAPI.post(`api/lgs/document/SendProductDetailsEdmByFtp/${documentRowGuid}/${includeImages}`, null, onSuccess);
    }

    public static getCreateOrderReportEmailNotification = (documentRowGuid: string, orderReport: OrderReports, isCreate: boolean, onSuccess: (viewModel: ICreateOrderReportEmailNotificationViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetCreateOrderReportEmailNotification/${documentRowGuid}/${OrderReports[orderReport]}/${isCreate}`, null, onSuccess);
    }

    public static createOrderReportEmailNotification = (viewModel: ICreateOrderReportEmailNotificationViewModel, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/CreateOrderReportEmailNotification`, viewModel, onSuccess);
    }

    public static createStockAssortmentReportAndGetUrl = (documentRowGuid: string, report: StockAssortmentReports, onSuccess: (url: string) => void) => {
        BaseAPI.post(`api/lgs/document/CreateStockAssortmentReportAndGetUrl/${documentRowGuid}/${StockAssortmentReports[report]}`, null, 
            (data) => {
               onSuccess(data + `?t=${Date.now()}`);
            });
    }

    public static getCreateStockAssortmentReportEmailNotification = (documentRowGuid: string, report: StockAssortmentReports, isCreate: boolean, onSuccess: (viewModel: ICreateStockAssortmentReportEmailNotificationViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetCreateStockAssortmentReportEmailNotification/${documentRowGuid}/${StockAssortmentReports[report]}/${isCreate}`, null, onSuccess);
    }

    public static createStockAssortmentReportEmailNotification = (viewModel: ICreateStockAssortmentReportEmailNotificationViewModel, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/CreateStockAssortmentReportEmailNotification`, viewModel, onSuccess);
    }

    public static getDownloadProductDetailsUrl = (documentRowGuid: string) => {
        return `${rootUrl}/api/lgs/document/DownloadProductDetails/${documentRowGuid}?t=${Date.now()}`;
    }

    public static getDownloadProductDetailsEdmUrl = (documentRowGuid: string) => {
        return `${rootUrl}/api/lgs/document/DownloadProductDetailsEdm/${documentRowGuid}?t=${Date.now()}`;
    }

    public static uploadAttachment = (documentRowGuid: string, folderPath: string, file: File | undefined, onSuccess: () => void) => {
        BaseAPI.uploadFile(documentRowGuid, folderPath, file, "api/lgs/document/uploadAttachment", onSuccess);
    }

    public static readAttachments = (documentRowGuid: string, onSuccess: (files: IFileDetail[]) => void) => {
        BaseAPI.readFiles(`api/lgs/document/ReadAttachments/${documentRowGuid}`, onSuccess);
    }

    public static removeAttachments = (documentRowGuid: string, filePaths: string[], onSuccess: () => void) => {
        BaseAPI.deleteFiles(documentRowGuid, filePaths, "api/lgs/document/removeAttachments", onSuccess);
    }

    public static getDownloadAttachmentUrl = (documentRowGuid: string, filePath: string) => {
        return `${rootUrl}/api/lgs/document/DownloadAttachment/${documentRowGuid}?filePath=${filePath}&t=${Date.now()}`;
    }

    public static setOrderCustomerRelationRowGuidToLocalStorage = (customerRelationRowGuid: string) => {
        localStorage.setItem(DocumentAPI.PENDING_ORDER_CUSTOMER_RELATION, (customerRelationRowGuid || ""));
    }

    public static getOrderCustomerRelationRowGuidFromLocalStorage = (): string => {
        const item = localStorage.getItem(DocumentAPI.PENDING_ORDER_CUSTOMER_RELATION);
        return item ? item : "";
    }

    public static loadActivePendingOrderCustomerState = (onSuccess?: () => void) => {

        const account = storeInstance.getState().authenticationState.account;

        let customerRelationRowGuid = "";

        if ([Profiles.DealerAccessories, Profiles.DealerFashion].includes(account.Privilege.ProfileId)) {
            customerRelationRowGuid = account.Privilege.Relation?.RowGuid;
        } else {
            customerRelationRowGuid = DocumentAPI.getOrderCustomerRelationRowGuidFromLocalStorage();
        }

        if ((customerRelationRowGuid || "") !== "") {
            RelationAPI.getCustomer(customerRelationRowGuid,
                (customerRelation) => {
                    if (customerRelation) {
                        storeInstance.dispatch(
                            setActivePendingOrderCustomer(
                                customerRelation.RowGuid,
                                customerRelation.Name,
                                customerRelation.CatalogId));

                        onSuccess && onSuccess();
                    }
                },
                () => {
                    setActivePendingOrderCustomer("", "");
                    onSuccess && onSuccess();
                });
        }
        else {
            storeInstance.dispatch(setActivePendingOrderCustomer("", "", 0));
            onSuccess && onSuccess();
        }
    }

    public static loadPendingOrdersState = (onSuccess?: () => void) => {
        const customerRelationRowGuid = storeInstance.getState().pendingOrdersState.customerRelationRowGuid;

        if ((customerRelationRowGuid || "") !== "") {
            DocumentAPI.readPendingOrders(storeInstance.getState().pendingOrdersState.customerRelationRowGuid, (data) => {
                storeInstance.dispatch(setPendingOrders(data));
                onSuccess && onSuccess();
            })
        } else {
            storeInstance.dispatch(setPendingOrders([]));
            onSuccess && onSuccess();
        }
    }

    public static addProductToOrder = (viewModel: IAddProductToOrderViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/lgs/document/AddProductToOrder", viewModel, onSuccess, onError);
    }

    public static addMultipleProductsToDocument = (viewModel: IAddMultipleProductsToDocumentViewModel, onSuccess: () => void, onError?: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/lgs/document/AddMultipleProductsToDocument", viewModel, onSuccess, onError);
    }

    /*     public static addOrUpdateDetail = (viewModel: IDocumentDetailViewModel, onSuccess: () => void) => {
            BaseAPI.post(`api/lgs/document/AddOrUpdateDetail`, viewModel, onSuccess);
        }
    
        public static getDetail = (documentRowGuid: string, documentDetailId: number, onSuccess: (data: IDocumentDetailViewModel) => void) => {
            BaseAPI.get(`api/lgs/document/GetDetail/${documentRowGuid}/${documentDetailId}`, null, onSuccess);
        } */

    public static getDocumentProductSizeRanges = (documentRowGuid: string, onSuccess: (data: IDocumentProductSizeRange[]) => void) => {
        BaseAPI.get(`api/lgs/document/GetDocumentProductSizeRanges/${documentRowGuid}`, null, onSuccess);
    }

    public static removeDetails = (viewModel: IUpdateDocumentDetailsViewModel, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/RemoveDetails`, viewModel, onSuccess);
    }

    public static updateDetailsStatus = (viewModel: IUpdateDocumentDetailsViewModel, onSuccess: () => void) => {
        BaseAPI.post(`api/lgs/document/UpdateDetailsStatus`, viewModel, onSuccess);
    }

    public static getRecalculateDocuments = (documentTypeId: number, onSuccess: (data: IRecalculateDocumentsViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/getRecalculateDocuments/${documentTypeId}`, null, onSuccess);
    }

    public static recalculateDocuments = (viewModel: IRecalculateDocumentsViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post(`api/lgs/document/RecalculateDocuments`, viewModel, onSuccess, onError);
    }

    public static getCopyDocument = (documentRowGuid: string, targetDocumentTypeId: number, targetSubDocumentTypeId: number | null, onSuccess: (viewModel: ICopyDocumentViewModel) => void) => {
        BaseAPI.get(`api/lgs/document/GetCopyDocument/${documentRowGuid}/${targetDocumentTypeId}/${targetSubDocumentTypeId || ""}`, null, onSuccess);
    }

    public static copyDocument = (viewModel: ICopyDocumentViewModel, onSuccess: (rowGuid: string) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post(`api/lgs/document/CopyDocument`, viewModel, onSuccess, onError);
    }

    public static deleteDocument = (documentRowGuid: string, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post(`api/lgs/document/DeleteDocument/${documentRowGuid}`, null, onSuccess, onError);
    }

    public static prepareDocumentFilterContextForPosting = (filterContext: IDocumentFilterContext): IDocumentFilterContext => {
        return {
            ...filterContext,
            CollectionId: { ...filterContext.CollectionId, Values: filterContext.CollectionId.Values.filter(v => v.IsChecked) },
            CatalogId: filterContext.CatalogId && { ...filterContext.CatalogId, Values: filterContext.CatalogId.Values.filter(v => v.IsChecked) },
            RelationCountryId: filterContext.RelationCountryId && { ...filterContext.RelationCountryId, Values: filterContext.RelationCountryId.Values.filter(v => v.IsChecked) },
            AgentRelationId: filterContext.AgentRelationId && { ...filterContext.AgentRelationId, Values: filterContext.AgentRelationId.Values.filter(v => v.IsChecked) },
            DocumentStatusId: { ...filterContext.DocumentStatusId, Values: filterContext.DocumentStatusId.Values.filter(v => v.IsChecked) },
            SubDocumentTypeId: { ...filterContext.SubDocumentTypeId, Values: filterContext.SubDocumentTypeId.Values.filter(v => v.IsChecked) },
            CreatedOn: { ...filterContext.CreatedOn, Values: filterContext.CreatedOn.Values.filter(v => v.IsChecked) },
            ConfirmedOn: filterContext.ConfirmedOn && { ...filterContext.ConfirmedOn, Values: filterContext.ConfirmedOn.Values.filter(v => v.IsChecked) }
        }
    }

    public static prepareDocumentBaseViewModelForPosting = (viewModel: IDocumentBaseViewModel, isValidationOnly?: boolean): IDocumentBaseViewModel => {
        return {
            ...viewModel,
            IsValidationOnly: isValidationOnly,
            Relation: null,
            ContactRelation: null,
            ToRelation: null,
        }
    }
}