import { DictionaryAPI, DocumentAPI } from 'api';
import { Dialog, RelationSelector } from 'components/common';
import { RelationTypes } from 'enums';
import { CopyDocumentViewModelFields } from 'enums/fields';
import { useForm } from 'hooks';
import { ICopyDocumentViewModel } from 'interfaces/viewmodels';
import { Checkbox, Stack, StackItem, Text } from 'office-ui-fabric-react';
import React, { useEffect } from 'react'
import { ICopyDialogProps } from './interfaces'

export const CopyDialog = (props: ICopyDialogProps) => {

    const { documentRowGuid, targetDocumentTypeId, targetSubDocumentTypeId, onSuccess, onCancel } = props;
    const { item, isSubmitting, onCheckboxChange, initialize, submit, updateItem, getErrorMessage } = useForm<ICopyDocumentViewModel>(undefined, undefined, DocumentAPI.copyDocument);
    const { RELATIONROW_GUID, IS_ADD_ALL_AVAILABLE_PRODUCT_VARIANTS } = CopyDocumentViewModelFields;

    useEffect(() => {

        const initializeForm = () => {
            DocumentAPI.getCopyDocument(
                documentRowGuid,
                targetDocumentTypeId,
                targetSubDocumentTypeId,
                (viewModel) => initialize(true, viewModel)
            );
        }

        initializeForm();

    }, [documentRowGuid])

    return (
        <Dialog
            maxWidth={500}
            isLoading={!item}
            title={DictionaryAPI.getTranslation("Copy")}
            subText={(item && DictionaryAPI.getTranslation(item.TargetDocumentTypeDescription.replace(" ", "")))}
            onAccept={() => submit((rowGuid) => onSuccess(rowGuid))}
            onCancel={onCancel}
            acceptButtonDisabled={isSubmitting}
            cancelButtonDisabled={isSubmitting}
        >
            {
                item && (
                    <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem>
                            <RelationSelector
                                searchEnabled
                                labelText={RelationTypes[item.RelationTypeId]}
                                relationTypeIds={[item.RelationTypeId]}
                                rowGuid={item.RelationRowGuid}
                                rowGuidFieldName={RELATIONROW_GUID}
                                parentContactId={item.ParentContactId}
                                onChange={updateItem}
                                errorMessage={
                                    DictionaryAPI.getTranslation(
                                        getErrorMessage(RELATIONROW_GUID, 0, true).replace("Relation", RelationTypes[item.RelationTypeId])
                                    )}
                            />
                        </StackItem>
                        <StackItem>
                            <Checkbox
                                name={IS_ADD_ALL_AVAILABLE_PRODUCT_VARIANTS}
                                label={DictionaryAPI.getTranslation("AddAllAvailableProductVariants")}
                                checked={item.IsAddAllAvailableProductVariants}
                                onChange={onCheckboxChange}
                            />
                        </StackItem>
                    </Stack>
                )
            }
        </Dialog>
    )
}
