export enum RelationViewModelFields {
    CODE = "Code",
    COMPANY = "Company",
    COMPANY_RELATION_ID = "CompanyRelationId",
    CURRENCIES = "Currencies",
    CURRENCY_ID = "CurrencyId",
    DEFAULT_SELECTED_RELATION_TYPE_ID = "DefaultSelectedRelationTypeId",
    EMAIL = "Email",
    ID = "Id",
    IS_ACTIVE = "IsActive",
    LANGUAGE_ID = "LanguageId",
    LANGUAGES = "Languages",
    MOBILE = "Mobile",
    PARENT_CONTACT_ID = "ParentContactId",
    PHONE = "Phone",
    REMARKS = "Remarks",
    ROW_GUID = "RowGuid",
    SALUTATION = "Salutation",
    SALUTATIONS = "Salutations",
    RELATION_TYPE_ID = "RelationTypeId",
    SUB_RELATION_TYPES = "SubRelationTypes",
    VAT_NUMBER = "VatNumber",
    WEBSITE = "Website"
}