import React, { useEffect, useRef, useState } from 'react';
import { mergeStyleSets, Stack, StackItem, FontSizes, FontWeights, getTheme, ImageFit, ScrollablePane } from 'office-ui-fabric-react';
import { IProductDetailProps } from './interfaces';
import { ProductAPI, DictionaryAPI } from 'api';
import { IProductDetailViewModel } from 'interfaces/viewmodels';
import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { IProductSizeRange, IProductListItem } from 'interfaces/models';
import { useNumberFormat } from 'hooks/useNumberFormat';
import { WindowModal, Image, ImageModal, ImageCarousel } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDates } from 'hooks';
import { AddProductToOrder } from './AddProductToOrder';

const ProductDetail = (props: IProductDetailProps) => {

    const { productId, catalogId, warehouseContactIds, documentRowGuid, onDismiss } = props;

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const [productDetail, setProductDetail] = useState<IProductDetailViewModel>();
    const [imageModalSrc, setImageModalSrc] = useState<string[]>();
    const documentUpdatedRef = useRef<boolean>();

    const { getImageUrl } = useFileStoreUrl();
    const { getPrice } = useNumberFormat(cultureId);
    const { dateByUtcToLocaleString } = useDates();

    const hasSizes = productDetail?.ProductSizeRanges.length > 0;

    useEffect(() => {
        getProductDetail(productId);
    }, [productId])

    const theme = getTheme();
    const classNames = mergeStyleSets({
        container: {
            width: "100%",
            padding: 15,
            color: theme.semanticColors.bodySubtext
        },
        detailsContainterItem: {
            position: "relative",
            paddingBottom: 10,
        },
        productTitle: {
            fontSize: FontSizes.large,
            fontWeight: FontWeights.semibold,
        },
        productPriceTable: {
            width: "auto",
            borderCollapse: "collcapse",
        },
        productPriceHeader: {
            fontWeight: FontWeights.semibold,
        },
        productSizeRangePrice: {
            paddingLeft: 25,
            textAlign: "right"
        },
        productPrice: {
            paddingRight: 25,
            textAlign: "center"
        },
        variantImage: {
            display: "inline-block",
            cursor: "pointer",
            margin: 1
        },
        total: {
            fontWeight: FontWeights.bold,
            paddingLeft: 10,
        },
    });

    const getProductDetail = (productId: number) => {
        ProductAPI.getProductDetail(productId, warehouseContactIds,
            (data) => {
                setProductDetail(data);
            });
    }

    const onOrderUpdated = (productDeleted?: boolean) => {
        documentUpdatedRef.current = true;
        if (productDeleted || productDetail.AssociatedProducts.length < 2) {
            _onDismiss();
        }
    }

    const _onDismiss = () => {
        onDismiss(documentUpdatedRef.current);
    }

    return (
        <>
            <WindowModal
                titleBarProps={{ title: productDetail ? productDetail.ProductName : "" }}
                isBlocking={true}
                isOpen={true}
                isLoading={!productDetail}
                disableFullScreen={true}
                onDismiss={_onDismiss}>
                {
                    productDetail && (
/*                         <div style={{ position: "relative", height: "100%" }}>
                            <ScrollablePane> */
                                <Stack tokens={{ childrenGap: 7 }} className={classNames.container} verticalFill>
                                    <StackItem grow>
                                        <div style={{ position: "relative", width: 900, height: "100%" }}>

                                                <Stack horizontal>
                                                    <StackItem disableShrink>
                                                        <ImageCarousel
                                                            images={productDetail.ImageSizeGroups.map((v, i) => {
                                                                return {
                                                                    largeSrc: getImageUrl(v.Image, dateByUtcToLocaleString(productDetail.UpdatedOn)),
                                                                    smallSrc: getImageUrl(v.SmallImage, dateByUtcToLocaleString(productDetail.UpdatedOn))

                                                                }
                                                            })}
                                                            height={255}
                                                            width={450}
                                                            containerStyle={{ width: 450 }}
                                                            onClick={() => setImageModalSrc(productDetail.ImageSizeGroups.map((v) => getImageUrl(v.LargeImage, dateByUtcToLocaleString(productDetail.UpdatedOn))))}
                                                        />
                                                        {/* <div 
                                                    style={{ cursor: "pointer" }} 
                                                    onClick={() => setImageModalSrc(productDetail.ImageSizeGroups.map((v) => v.LargeImage))}>
                                                    {
                                                        productDetail.ImageSizeGroups.map((v, i) => {
                                                            return (
                                                                <Image
                                                                    key={`productDetailImage_${i}`}
                                                                    src={getImageUrl(v.Image)}
                                                                    width={450}
                                                                    height={300}
                                                                    imageFit={ImageFit.contain}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div> */}
                                                    </StackItem>
                                                    <StackItem grow>

                                                        <div className={classNames.detailsContainterItem} style={{ borderBottom: "1px solid " + theme.semanticColors.bodyFrameDivider }}>
                                                            <div className={classNames.productTitle}>
                                                                {productDetail.ProductCode} {productDetail.ProductName}
                                                            </div>
                                                            <div style={{ fontWeight: 600 }}>
                                                                {productDetail.ProductVariantDescription}
                                                            </div>
                                                        </div>
                                                        <div className={classNames.detailsContainterItem}>
                                                            <table className={classNames.productPriceTable}>
                                                                <thead>
                                                                    <tr className={classNames.productPriceHeader}>
                                                                        {
                                                                            hasSizes && (
                                                                                <th style={{ textAlign: "left" }}>
                                                                                    {DictionaryAPI.getTranslation("Size")}
                                                                                </th>
                                                                            )
                                                                        }

                                                                        <th className={hasSizes ? classNames.productSizeRangePrice : classNames.productPrice}>
                                                                            {DictionaryAPI.getTranslation("B2b")}
                                                                        </th>
                                                                        <th className={hasSizes ? classNames.productSizeRangePrice : classNames.productPrice}>
                                                                            {DictionaryAPI.getTranslation("Srp")}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        hasSizes ? (
                                                                            productDetail.ProductSizeRanges.map((v: IProductSizeRange) => {
                                                                                return (
                                                                                    <tr key={v.MinimumId}>
                                                                                        <td>
                                                                                            {v.MinimumName}-{v.MaximumName}
                                                                                        </td>
                                                                                        <td className={classNames.productSizeRangePrice}>
                                                                                            {getPrice(v.B2bPrice)}
                                                                                        </td>
                                                                                        <td className={classNames.productSizeRangePrice}>
                                                                                            {getPrice(v.B2cPrice)}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td className={classNames.productPrice}>
                                                                                    {getPrice(productDetail.ProductB2bPrice)}
                                                                                </td>
                                                                                <td className={classNames.productPrice}>
                                                                                    {getPrice(productDetail.ProductB2cPrice)}
                                                                                </td>
                                                                            </tr>
                                                                        )

                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className={classNames.detailsContainterItem}>
                                                            {
                                                                productDetail.AssociatedProducts.map((v: IProductListItem) => {
                                                                    return <Image
                                                                        key={v.Id}
                                                                        className={productDetail.ProductId === v.Id ? ("ms-depth-8 " + classNames.variantImage) : classNames.variantImage}
                                                                        onClick={() => getProductDetail(v.Id)}
                                                                        src={getImageUrl(v.SmallImage, dateByUtcToLocaleString(v.UpdatedOn))}
                                                                        height={78}
                                                                        width={78}
                                                                        imageFit={ImageFit.contain} />
                                                                })
                                                            }
                                                        </div>
                                                        {/* <div className={classNames.detailsContainterItem} style={{ height: 252 }}>
                                                        <ScrollablePane>
                                                            {
                                                                productDetail.AssociatedProducts.map((v: IProductListItem) => {
                                                                    return <Image
                                                                        key={v.Id}
                                                                        className={productDetail.ProductId === v.Id ? ("ms-depth-8 " + classNames.variantImage) : classNames.variantImage}
                                                                        //borderStyleDefinition={productDetail.ProductId === v.Id && "2px solid " + theme.palette.neutralLight}
                                                                        onClick={() => getProductDetail(v.Id)}
                                                                        src={getImageUrl(v.SmallImage)}
                                                                        height={82}
                                                                        width={82}
                                                                        imageFit={ImageFit.contain} />
                                                                })
                                                            }
                                                        </ScrollablePane>

                                                    </div> */}

                                                    </StackItem>
                                                </Stack>

                                        </div>
                                    </StackItem>
                                    <Stack verticalAlign="end" styles={{ root: { minHeight: 250 } }}>
                                        <Stack>
                                            <AddProductToOrder
                                                productId={productDetail.ProductId}
                                                catalogId={catalogId}
                                                documentRowGuid={documentRowGuid}
                                                cultureId={cultureId}
                                                reloadAfterUpdateDisabled={productDetail.AssociatedProducts.length < 2}
                                                onUpdated={onOrderUpdated}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
/*                             </ScrollablePane>
                        </div> */

                    )
                }
                <ImageModal
                    src={imageModalSrc}
                    onDismiss={() => setImageModalSrc(undefined)} />
            </WindowModal>
        </>
    )
}

export default ProductDetail;

