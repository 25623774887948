import {
    FilterResultInfoTypes,
    IFilterResultInfoState
} from './types'
import { SET_RELATION_FILTER_RESULT_INFO } from './actions'
import { IFilterResultInfo } from 'interfaces/models'

const initalFilterResultInfo: IFilterResultInfo = { ItemCount: 0, Items: [], PageCount: 0 };
const initialState: IFilterResultInfoState = {
    relationFilterResultInfo: initalFilterResultInfo
}

export const filterResultInfoReducer = (state = initialState, action: FilterResultInfoTypes): IFilterResultInfoState => {
    let _action;
    switch (action.type) {
        case SET_RELATION_FILTER_RESULT_INFO:
            return {
                relationId: Number(action.id),
                relationFilterResultInfo: action.filterInfoResult
            }

        default:
            return state
    }
}