export enum LeftNavigationMenus {
    Dashboard = 2,
    Crm = 3,
    Customers = 4,
    Prospects = 78,
    Products = 5,
    Products_Shoes = 6,
    Products_Clothing = 7,
    Products_Accessories = 8,
    Products_Cozy = 107,
    ProductCatalog = 9,
    ProductCatalog_Shoes = 67,
    ProductCatalog_Clothing = 10,
    ProductCatalog_Accessories = 11,
    Sales = 12,
    Sales_StockResale = 106,
    Sales_StockList = 113,
    Orders = 13,
    Catalogs = 14,
    CatalogGroups = 19,
    StockAssortment = 89,
}