export enum DocumentSummaryFields {
    CURRENCY_ID = "CurrencyId",
    DISCOUNT_TOTAL = "DiscountTotal",
    ORDER_TREND_STATISTICS = "OrderTrendStatistics",
    PRODUCT_DOCUMENT_DETAILS_GROUPS = "ProductDocumentDetailsGroups",
    PRODUCT_DOCUMENT_DETAILS_GROUPS_QUANTITY = "ProductDocumentDetailsGroupsQuantity",
    QUANTITY = "Quantity",
    SHIPPING = "Shipping",
    SHIPPING_INCL_VAT = "ShippingInclVat",
    SUBTOTAL = "Subtotal",
    SUBTOTAL_EXCL_DISCOUNT = "SubtotalExclDiscount",
    TOTAL = "Total",
    VAT_TOTAL = "VatTotal"
}