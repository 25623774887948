import React from 'react';
import { Stack, StackItem, TextField, ScrollablePane, IComboBoxOption } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { RelationTypes, Profiles } from 'enums';
import { DocumentBaseViewModelFields } from 'enums/fields';
import { IRelationListItem } from 'interfaces/models';
import { ComboBox, RelationSelector, ColumnsStack } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IStockAssortmentTabBaseProps } from './interfaces';

export const InformationTab = (props: IStockAssortmentTabBaseProps) => {

    const { form, documentSummary, readOnly } = props;
    const { item, updateItem, onUnControlledTextFieldChange, onComboBoxSimpleChange } = form;

    const accountProfileId = useSelector((state: RootState) => state.authenticationState.account.Privilege.ProfileId)

    const {
        CONTACT_RELATION_ROW_GUID,
        FROM_RELATION_ROW_GUID,
        AGENT_RELATION_ROW_GUID,
        REMARKS,
        INTERNAL_REMARKS
    } = DocumentBaseViewModelFields


    return (
        <ScrollablePane>
            <Stack tokens={{ padding: 12, maxWidth: 800 }}>
                <ColumnsStack gap={12}>
                    <RelationSelector
                        readOnly
                        labelText={DictionaryAPI.getTranslation("InvoiceAddress")}
                        relationTypeIds={[RelationTypes.Customer]}
                        rowGuid={item.RelationRowGuid}
                        defaultRelation={item.Relation}
                        newNotAllowed={true}
                    />
                    <></>
                </ColumnsStack>
                {
                    <ColumnsStack gap={12}>
                        <RelationSelector
                            readOnly={readOnly}
                            labelText={DictionaryAPI.getTranslation("Contact")}
                            rowGuidFieldName={CONTACT_RELATION_ROW_GUID}
                            companyRelationId={item.CompanyRelationId}
                            parentContactId={item.Relation?.ContactId}
                            parentRelationId={item.Relation?.Id}
                            relationTypeIds={[RelationTypes.Contact]}
                            rowGuid={item.ContactRelationRowGuid}
                            defaultRelation={item.ContactRelation}
                            onChange={updateItem} />
                        <ComboBox
                            label={DictionaryAPI.getTranslation("Agent")}
                            allowFreeform
                            autoComplete="on"
                            selectedKey={item.AgentRelationRowGuid}
                            name={AGENT_RELATION_ROW_GUID}
                            disabled={!(accountProfileId === Profiles.Administrator || accountProfileId === Profiles.SalesCountryManager)}
                            options={item.Agents?.map((v: IRelationListItem, i): IComboBoxOption => {
                                return {
                                    key: v.RowGuid,
                                    text: v.Name,
                                }
                            })}
                            onSimpleChange={onComboBoxSimpleChange} />
                    </ColumnsStack>
                }
                <ColumnsStack gap={12}>
                    <TextField
                        multiline
                        defaultValue={item.InternalRemarks}
                        name={INTERNAL_REMARKS}
                        label={DictionaryAPI.getTranslation(INTERNAL_REMARKS)}
                        style={{ minHeight: 100 }}
                        onChange={onUnControlledTextFieldChange} />
                    <TextField
                        multiline
                        defaultValue={item.Remarks}
                        name={REMARKS}
                        label={DictionaryAPI.getTranslation(REMARKS)}
                        style={{ minHeight: 100 }}
                        onChange={onUnControlledTextFieldChange} />
                </ColumnsStack>
            </Stack>
        </ScrollablePane>
    );
};

