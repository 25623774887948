import React, { useEffect, useRef, useState } from 'react';
import { ProductAPI, DictionaryAPI, DocumentAPI, MenuAPI } from 'api';
import { IProductFilterContext, IFilterResultInfo, IDocumentSummary } from 'interfaces/models';
import { DocumentSummaryAccordion, ScrollableContainer, WindowModal } from 'components/common';
import { useFilterContextHandler, useSelection } from 'hooks';
import { ProductDetail } from 'components/pages';
import { ProductFilterContextFields, ProductListItemFields } from 'enums/fields';
import { ProductsListViewLayout } from 'components/layouts';
import { LeftNavigationMenus, OrderSubDocumentTypes } from 'enums';
import { getTheme, SelectionMode } from 'office-ui-fabric-react';
import { IAddProductsProps } from './interfaces';

const theme = getTheme();

export const AddProducts = (props: IAddProductsProps) => {

    const {
        //formMenu,
        productCategoryId,
        document,
        cultureId,
        checkable,
        onDismiss
    } = props;

    const onlySalesAllowed = document.SubDocumentTypeId === OrderSubDocumentTypes.Sales;
    const warehouseContactId = document.IsRestrictedToStock ? document.FromContactId : 0;

    const {
        WAREHOUSE_CONTACT_ID
    } = ProductFilterContextFields

    const LAST_PRODUCT_DETAIL_GROUPS_TO_INCLUDE = 10;

    const [productId, setProductId] = useState<number>();
    const [fetching, setFetching] = useState<boolean>();
    const [documentSummary, setDocumentSummary] = useState<IDocumentSummary>();
    const documentUpdatedRef = useRef<boolean>();

    const getFilterContext = (onSuccess: (data: IProductFilterContext) => void) => {
        ProductAPI.getFilterContext(true, productCategoryId, onlySalesAllowed, 0, 0, document.Warehouses?.map((v) => v.ContactId),
            (filterContext) => {
                if (document.CollectionId) {
                    const filterValue = filterContext.CollectionId.Values.find(v => v.Value === document.CollectionId);
                    filterValue.IsChecked = true;
                }
                onSuccess(filterContext);
            });
    }

    const filter = (filterContext: IProductFilterContext, onSuccess: (filterResultInfo: IFilterResultInfo) => void) => {
        ProductAPI.filter({ ...filterContext, AssociatedProductsGrouped: false }, onSuccess);
    }

    const filterContextHandler = useFilterContextHandler<IProductFilterContext>(getFilterContext, filter);
    const { filterContext, filterResultInfo, isFiltering, getFilterResultInfo } = filterContextHandler;

    const { setSelectedKeys, selectedKeys, selection } = useSelection(
        ProductListItemFields.ID,
        filterResultInfo?.Items,
        [],
        isFiltering,
        SelectionMode.multiple);

    const addMultipleProductsToDocument = () => {

        setFetching(true);
        DocumentAPI.addMultipleProductsToDocument({
            DocumentRowGuid: document.DocumentRowGuid,
            MaxQuantity: 0,
            ProductIds: selectedKeys.map((v) => Number(v))
        },
            () => onDismiss(true));
    }

    const selectAll = () => {
        setFetching(true);
        ProductAPI.getProductIds(filterContext,
            (data) => {
                setSelectedKeys([...data, ...selectedKeys.filter(value => !data.includes(value))])
            });
    }

    const clearSelection = () => {
        setFetching(true);
        ProductAPI.getProductIds(filterContext,
            (data) => {
                setSelectedKeys([...selectedKeys.filter(value => !data.includes(value))])
            });
    }

    const getfilterBarItemNamesToHide = (): string[] => {
        const names = [];

        if (warehouseContactId) {
            names.push(WAREHOUSE_CONTACT_ID);
        }

        return names;
    }

    const getDocumentSummary = () => {
        DocumentAPI.getDocumentSummary(
            document.DocumentRowGuid,
            LAST_PRODUCT_DETAIL_GROUPS_TO_INCLUDE,
            (data) => setDocumentSummary(data));
    }

    const onDismissProductDetail = (documentUpdated?: boolean) => {
        setProductId(undefined);

        if (documentUpdated) {
            documentUpdatedRef.current = true;
            getDocumentSummary();
        }
    }

    const onRenderInformationPanelContent = (): React.ReactNode => {
        return documentSummary && !filterContextHandler.isFiltering && (
            <ScrollableContainer
                width={270}
                padding={10}
            >
                <div
                    className="ms-depth-8"
                    style={{ padding: 10, backgroundColor: theme.semanticColors.bodyBackground, }}
                >
                    <DocumentSummaryAccordion
                        documentSummary={documentSummary}
                        cultureId={cultureId}
                        onRecentlyAddedProductClick={(productId) => setProductId(productId)}
                    />
                </div>
            </ScrollableContainer>
        )
    }

    useEffect(() => {
        getDocumentSummary();
    }, [document.DocumentRowGuid])

    useEffect(() => {
        if (fetching) {
            getFilterResultInfo(false);
            setFetching(false);
        }

    }, [selectedKeys])

    return (
        <>
            <WindowModal
                titleBarProps={{
                    title: DictionaryAPI.getTranslation("AddProducts")
                }}
                width={900}
                height={700}
                isBlocking={true}
                isOpen={true}
                onDismiss={() => onDismiss(documentUpdatedRef.current)}
            >
                <ProductsListViewLayout
                    hideTitle
                    informationPanelContentVisibleByDefault
                    menu={MenuAPI.find(LeftNavigationMenus.Products)}
                    commandBarItems={[
                        {
                            key: "Accept",
                            text: `${DictionaryAPI.getTranslation("Accept")} (${selectedKeys?.length})`,
                            iconProps: {
                                iconName: "Accept"
                            },
                            disabled: fetching || selectedKeys.length === 0,
                            onClick: () => addMultipleProductsToDocument()
                        },
                        {
                            key: "SelectAll",
                            text: DictionaryAPI.getTranslation("SelectAll"),
                            iconProps: {
                                iconName: "MultiSelect"
                            },
                            disabled: fetching,
                            onClick: selectAll
                        },
                        {
                            key: "ClearSelection",
                            text: DictionaryAPI.getTranslation("ClearSelection"),
                            iconProps: {
                                iconName: "Cancel"
                            },
                            disabled: fetching || selectedKeys.length === 0,
                            onClick: clearSelection
                        },
                    ]}
                    associatedProductsGrouped={false}
                    filterContextHandler={filterContextHandler}
                    filterBarItemNamesToHide={getfilterBarItemNamesToHide()}
                    checkable={checkable}
                    selection={selection}
                    onItemClick={(product) => setProductId(product.Id)}
                    onRenderInformationPanelContent={onRenderInformationPanelContent}
                />
            </WindowModal>

            {
                productId !== undefined ?
                    <ProductDetail
                        productId={productId}
                        documentRowGuid={document.DocumentRowGuid}
                        warehouseContactIds={document.Warehouses?.map((v) => v.ContactId)}
                        onAssociatedProductClick={(productId) => setProductId(productId)}
                        onDismiss={onDismissProductDetail} />
                    : null
            }
        </>
    );
};