import React, { useState, useEffect } from 'react';
import { IUseChangeTrackerProps } from './interfaces';

export const useChangeTracker = (): IUseChangeTrackerProps => {

    const [changes, setChanges] = useState<string[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>();

    const pushChange = (name: string, value: any, initialValue?: any) => {

        const index = changes.indexOf(name);

        if (index > -1) {
            changes.splice(index, 1);
        }

        if (initialValue !== value) {
            changes.push(name);
        }

        if (changes.length > 0 !== hasChanges) {
            setHasChanges(changes.length > 0);
        }
    }

    const pushArrayChange = (name: string, values: any[], initialValues?: any[]) => {
        const index = changes.indexOf(name);

        if (index > -1) {
            changes.splice(index, 1);
        }

        if (values.length !== initialValues?.length || values.filter(value => !initialValues?.includes(value)).length > 0) {
            changes.push(name);
        }

        if (changes.length > 0 !== hasChanges) {
            setHasChanges(changes.length > 0);
        }
    }

    const clearChanges = () => {
        setChanges([]);
        setHasChanges(false);
    }

    return {
        changes,
        hasChanges,
        pushChange,
        pushArrayChange,
        clearChanges
    };
};