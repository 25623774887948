import React from 'react';
import { ColorPickerGridCell, Stack, StackItem, IColorCellProps } from 'office-ui-fabric-react';
import { IColourFilterProps } from './interfaces';
import { SwatchCheckBox } from '../SwatchCheckBox';

export const ColourFilter = (props: IColourFilterProps): JSX.Element => {
    const { filter, name, onChange } = props;

    const _onChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean, index?: number) => {
        onChange(name, filter.Values[index], checked === true, true);
    }

    const gradientBackgrounds = {
        GOLD: `radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), 
            radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)`,
        SILVER: `radial-gradient(ellipse farthest-corner at right bottom, #fefefe 0%, #fdfdfd 8%, #9f9f9f 30%, #8a8a8a 40%, transparent 80%),
            radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #ffffff 8%, #d1d1d1 25%, #5d5d5d 62.5%, #5d5d5d 100%)`,
        BRONZE: `radial-gradient(ellipse farthest-corner at right bottom, #f4a740 0%, #f3873b 8%, #995c2e 30%, #855733 40%, transparent 80%),
            radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #fbe8b0 8%, #cb9869 25%, #5a3b22 62.5%, #5a3b22 100%)`,
        MULTICOLOR: `radial-gradient(ellipse farthest-corner at right bottom, #e97a7a, #acc979 , #65c0e0, #927fdb, #81c1d9)`,
        SPECIAL: `radial-gradient(ellipse farthest-corner at right bottom, #e97a7a, #acc979 , #65c0e0, #927fdb, #81c1d9)`
    };

    return (
        <Stack horizontal wrap tokens={{ childrenGap: 10, maxWidth: 210 }}>
            {filter.Values.map((v, i) => {
                return (
                    <StackItem key={i}>
                        <SwatchCheckBox
                            name={v.Name}
                            checked={v.IsChecked}
                            index={i}
                            background={gradientBackgrounds[v.Name] ? gradientBackgrounds[v.Name] : v.HexCode}
                            onChange={_onChange} />
                    </StackItem>
                )
            })}
        </Stack>
    );
}