export enum CatalogPageDetailListItemFields {
    CATALOG_ID = "CatalogId",
    CATALOG_PAGE_ID = "CatalogPageId",
    DISPLAY_ORDER = "DisplayOrder",
    ID = "Id",
    PRODUCT_CODE = "ProductCode",
    PRODUCT_COLLECTION_CODE = "ProductCollectionCode",
    PRODUCT_COLLECTION_ID = "ProductCollectionId",
    PRODUCT_GENDER_ID = "ProductGenderId",
    PRODUCT_ID = "ProductId",
    PRODUCT_IMAGE = "ProductImage",
    PRODUCT_NAME = "ProductName",
    PRODUCT_SMALL_IMAGE = "ProductSmallImage",
    PRODUCT_VARIANT_DESCRIPTION = "ProductVariantDescription",
    SUB_PRODUCT_CATEGORY_ID = "SubProductCategoryId",
    SUB_PRODUCT_CATEGORY_NAME = "SubProductCategoryName"
}