import React, { useEffect, useState } from 'react'
import { ListView } from 'components/common'
import { CatalogPageDetailListItemFields } from 'enums/fields';
import { useSelection } from 'hooks';
import { CatalogAPI, DictionaryAPI } from 'api';
import { ICatalogPageDetailListItem } from 'interfaces/models';
import { DefaultPalette, getTheme, IColumn, Image, ImageFit, mergeStyleSets, SelectionMode, Stack, StackItem } from 'office-ui-fabric-react';
import { IDetailsListColumn, ListViewCommandBarTypes, Dialog } from 'components/common';
import { DefaultCommandTypes } from 'enums';
import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { ICatalogProductsListViewProps } from './interfaces';
import { AddProductsDialog } from '../PagesTab/AddProductsDialog';

export const CatalogProductsListView = (props: ICatalogProductsListViewProps) => {

    const { catalogRowGuid, formMenu, catalogPageId, title } = props;

    const [fetching, setFetching] = useState<boolean>();
    const [pageDetails, setPageDetails] = useState<ICatalogPageDetailListItem[]>();
    const [addProductsToCatalogPageId, setAddProductsToCatalogPageId] = useState<number>();
    const [pageDetailIdsToRemove, setPageDetailIdsToRemove] = useState<number[]>();

    const {
        ID,
        PRODUCT_CODE,
        PRODUCT_COLLECTION_CODE,
        PRODUCT_GENDER_ID,
        PRODUCT_NAME,
        PRODUCT_SMALL_IMAGE,
        PRODUCT_VARIANT_DESCRIPTION,
        SUB_PRODUCT_CATEGORY_NAME } = CatalogPageDetailListItemFields;


    const {
        selectedKeys,
        selection,
        setSelectedKeys
    } = useSelection(ID, pageDetails, [], false, SelectionMode.multiple);

    const { getImageUrl } = useFileStoreUrl();

    const classNames = mergeStyleSets({
        tileName: {
            color: DefaultPalette.neutralSecondary,
            fontWeight: "600",
            marginLeft: 5,
            marginRight: 5,
            whiteSpace: "noWrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        }
    });

    const readPageDetails = () => {
        setFetching(true);
        CatalogAPI.readPageDetails(catalogPageId, (data) => setPageDetails(data));
    }

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: PRODUCT_SMALL_IMAGE, width: 50, name: "" },
            { fieldName: PRODUCT_CODE, width: 75, name: "Code" },
            { fieldName: PRODUCT_NAME, width: 150, name: "Name" },
            { fieldName: PRODUCT_VARIANT_DESCRIPTION, width: 100, name: "Variant" },
            { fieldName: PRODUCT_COLLECTION_CODE, width: 75, name: "Collection" },
            { fieldName: SUB_PRODUCT_CATEGORY_NAME, width: 150, name: "Category" },
            { fieldName: PRODUCT_GENDER_ID, width: 75, name: "Gender" },
        ];

        return columns;
    }

    const onRenderDetailsListItemColumn = (item?: ICatalogPageDetailListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName) {

            switch (column.fieldName) {
                case PRODUCT_SMALL_IMAGE:
                    return <Image src={getImageUrl(item.ProductSmallImage)} width={48} height={48} alt={item.ProductName + item.ProductVariantDescription} imageFit={ImageFit.contain} />;
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderTileListCell = (item?: ICatalogPageDetailListItem, index?: number | undefined): React.ReactNode | undefined => {
        if (item) {
            return (<Stack tokens={{ childrenGap: 5 }} verticalFill>
                <StackItem grow>
                    <Image
                        src={getImageUrl(item.ProductImage)}
                        width="100%"
                        height="100%"
                        imageFit={ImageFit.contain}
                    />
                </StackItem>
                <StackItem className={classNames.tileName}>
                    {item.ProductName}
                </StackItem>
                <StackItem styles={{ root: { color: DefaultPalette.neutralSecondary } }}>
                    {item.ProductVariantDescription}
                </StackItem>
            </Stack>)
        }
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {

        console.log("onDefaultCommandClick", type)


        switch (type) {
            case DefaultCommandTypes.New:
                if (catalogPageId) {
                    setAddProductsToCatalogPageId(catalogPageId);
                }
                break;
            case DefaultCommandTypes.Remove:
                setPageDetailIdsToRemove(selectedKeys);
                break;
            default:
                break;
        }
    }

    const onRemovePageDetails = () => {
        if (pageDetailIdsToRemove) {
            setFetching(true);
            CatalogAPI.removePageDetails({
                CatalogRowGuid: catalogRowGuid,
                Ids: pageDetailIdsToRemove
            },
                readPageDetails
            );
        }
    }

    const onDismissAddProductsDialog = (added?: boolean) => {
        if (added) {
            readPageDetails();
        }

        setAddProductsToCatalogPageId(undefined);
    }

    useEffect(() => {
        if (catalogPageId) {
            readPageDetails();
        }

    }, [catalogPageId])

    useEffect(() => {

        setAddProductsToCatalogPageId(undefined);
        setPageDetailIdsToRemove(undefined);
        setSelectedKeys([]);
        setFetching(false);
        
    }, [pageDetails])

    return (
        <>
            <ListView
                title={title}
                menu={formMenu}
                availableListViewCommandBarTypes={[ListViewCommandBarTypes.List, ListViewCommandBarTypes.Tiles]}
                onDefaultCommandClick={onDefaultCommandClick}
                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                items={pageDetails}
                selection={selection}
                checkable
                loading={!pageDetails}
                dataLoading={false}
                onRenderTileListCell={onRenderTileListCell}
            />
            {
                addProductsToCatalogPageId && (
                    <AddProductsDialog
                        catalogRowGuid={catalogRowGuid}
                        catalogPageId={addProductsToCatalogPageId}
                        onDismiss={onDismissAddProductsDialog} />
                )
            }
            {
                pageDetailIdsToRemove && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("RemoveSelectedProducts") + "?"}
                        onAccept={onRemovePageDetails}
                        onCancel={() => setPageDetailIdsToRemove(undefined)}
                        acceptButtonDisabled={fetching}
                        cancelButtonDisabled={fetching}
                        maxWidth={300}
                    />
                )
            }
        </>

    )
}
