export enum PendingOrderInformationViewModelFields {
    CONTACT_RELATION = "ContactRelation",
    ONLINE_PAYMENT_REQUIRED = "OnlinePaymentRequired",
    COMPANY_RELATION_ID = "CompanyRelationId",
    CONTACT_RELATION_ROW_GUID = "ContactRelationRowGuid",
    //CONFIRM_WITHOUT_EMAIL_NOTIFICATION = "ConfirmWithoutEmailNotification",
    CUSTOMERCONTACT_ID = "CustomerContactId",
    CUSTOMER_RELATION = "CustomerRelation",
    CUSTOMER_RELATION_ROW_GUID = "CustomerRelationRowGuid",
    //CUSTOMIZING_EMAIL_NOTIFICATION_ALLOWED = "CustomizingEmailNotificationAllowed",
    DELIVERY_ADDRESS_RELATION = "DeliveryAddressRelation",
    DELIVERY_ADDRESS_RELATION_ROW_GUID = "DeliveryAddressRelationRowGuid",
    DOCUMENT_ROW_GUID = "DocumentRowGuid",
}