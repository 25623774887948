import React, { useState, useEffect, useMemo } from 'react';
import { IFilterBarItemProps, Loader, LoaderModal, ShareAttachmentWindow } from 'components/common';
import { DefaultCommandTypes, LeftNavigationMenus } from 'enums';
import { DictionaryAPI, MenuAPI, ProductStockAPI } from 'api';
import { IFilter, IRangeFilter } from 'interfaces/models';
import { DayOfWeek, DefaultPalette, IColumn, IContextualMenuItem, IImageStyleProps, IImageStyles, Image, ImageFit, mergeStyleSets } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, DateRangeFilter } from 'components/common';
import { useFilterContextHandler, useUserSetting, useFileStoreUrl, useNumberFormat } from 'hooks';
import { ListView } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { imgError } from 'images';

const classNames = mergeStyleSets({
    numericColumn: {
        width: "100%",
        textAlign: "right"
    },
    sizeColumn: {
        textAlign: "center"
    },
    tileDescription: {
        color: DefaultPalette.neutralSecondary,
        fontWeight: "600",
        marginLeft: 5,
        marginRight: 5,
        whiteSpace: "noWrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    tileTotals: {
        color: DefaultPalette.neutralSecondary,
    }
});

const getProductImageStyles = (props: IImageStyleProps): Partial<IImageStyles> => {
    return {
        root: props.isError && {
            background: `url("${imgError}") no-repeat center`,
            backgroundSize: "contain"
        }
    }
}

export const ProductStock = () => {

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);
    const menu = MenuAPI.find(LeftNavigationMenus.Sales_StockList);
    const userSettingHook = useUserSetting(menu.Id);
    const { getImageUrl } = useFileStoreUrl();
    const { getNumber, getPrice, getPercent } = useNumberFormat(cultureId);
    const [loading, setLoading] = useState(false);
    const [shareOrderReportSrc, setShareOrderReportSrc] = useState<string>();

    const filterContextHandler = useFilterContextHandler<any>(
        (onSuccess) => {
            ProductStockAPI.getProductStockItemFilterContext(onSuccess);
        },
        ProductStockAPI.filterProductStockItems, userSettingHook);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterResultInfo } = filterContextHandler;

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: "Image", width: 32, name: "" },
            { fieldName: "Name", width: 150, },
            { fieldName: "VariantDescription", width: 175, name: "Variant" },
            { fieldName: "ProductSizeName", width: 70, name: "Size" },
            { fieldName: "ProductCategoryName", width: 125, name: "Category" },
            { fieldName: "SubProductCategoryName", width: 125, name: "SubCategory" },
            { fieldName: "Quantity", width: 70, name: "Quantity" },
        ];

        return columns;
    }

    const detailsListColumns = useMemo(() => getDetailsListColumns(), [filterContext?.FilterInfo?.OrderByRules]);

    const handleOnOrderByChange = (fieldName: string, isDescending: boolean) => {

        switch (fieldName) {
            case "Name":
            case "Quantity":
                onOrderByChange(fieldName, isDescending);
                break;

            default:
                return;
        }
    }

    const onRenderDetailsListItemColumn = (item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName && item[column.fieldName]) {

            switch (column.fieldName) {
                case "Image":
                    return (
                        <Image
                            src={getImageUrl(item.Image)}
                            height={32}
                            width={32}
                            imageFit={ImageFit.contain}
                            styles={getProductImageStyles}
                        />
                    )
                case "Quantity":
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (<FilterBar
                filterContextHandler={filterContextHandler}
                onRenderFilterBarItem={onRenderFilterBarItem}
            />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {

        const { filter, name } = filterBarItemProps;

        switch (name) {
            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }


    useEffect(() => {
        window.location.href = "#" + menu.Path;
    }, [filterResultInfo])

    return (
        <>
            <ListView
                menu={menu}
                title={menu.translatedName}
                detailsListColumns={detailsListColumns}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                dataLoading={isFiltering}
                onRefreshButtonClick={getFilterResultInfo}
                onOrderByChange={handleOnOrderByChange}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                shareOrderReportSrc && (
                    <ShareAttachmentWindow
                        title={DictionaryAPI.getTranslation("StockList")}
                        src={shareOrderReportSrc}
                        onDismiss={() => setShareOrderReportSrc(undefined)}
                    />
                )
            }
            {
                loading && (
                    <LoaderModal />
                )
            }
        </>
    );
};