import { DictionaryAPI } from 'api';
import { DefaultPalette, Label, SemanticColorSlots } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { IFileUploadProps } from './interfaces'

const MAX_SIZE = 1000000;

export const FileUpload = (props: IFileUploadProps) => {

    const { label, maxSize, accept, onChange } = props;
    const [errorMessage, setErrorMessage] = useState<string>();

    const _onChange = (fileList: FileList) => {

        const _maxSize = maxSize ? maxSize : MAX_SIZE;
        let hasError = false;

        for (const file of fileList) {
            if (file.size > _maxSize) {
                hasError = true;
                setErrorMessage("MaximumFileSizeExceeded");
            }
        }

        if (!hasError) {
            onChange(fileList);
        }
    }

    return (
        <div>
            <Label>{label}</Label>
            <input type="file" accept={accept} onChange={(e) => _onChange(e.target.files)} />
            <div style={{ paddingTop: 4, color: DefaultPalette.redDark }}>
                {DictionaryAPI.getTranslation(errorMessage)}
            </div>
        </div>
    )
}
