import { useCookies } from 'hooks/useCookies';
import { useRef } from 'react';
import { IUseNumberFormat } from './interfaces';

export const useNumberFormat = (locale?: string): IUseNumberFormat => {

    const getPrice = (price: number, currency?: string, minimumFractionDigits?: number): string => {
        return (price).toLocaleString(locale, {
            style: "currency", currency: currency || "EUR", minimumFractionDigits: minimumFractionDigits || 2
        });
    }

    const getNumber = (value: number, minimumFractionDigits?: number): string => {
        return value.toLocaleString(locale, {minimumFractionDigits: minimumFractionDigits || 0});
    }

    const getPercent = (value: number, minimumFractionDigits?: number): string => {
        return value?.toLocaleString(locale, { style: "percent", minimumFractionDigits: minimumFractionDigits || 0});
    }

    const parseNumber = (value: string): number => {
        const commaTemplate = 1.1;
        const comma = commaTemplate.toLocaleString(locale).substring(1, 2);
        let _value: string;

        if (comma === ",") {
            _value = value.replace(".", "");
            _value = value.replace(comma, ".");
        } else {
            _value = value.replace(",", "");
        }

        if (!isNaN(+_value)) {
            return Number(_value);
        }

        return 0;
    }

    return {
        getPrice,
        getNumber,
        getPercent,
        parseNumber,
    };
};