import { CheckboxFilter, FilterBar, IFilterBarItemProps } from 'components/common';
import { BaseListView } from 'components/common';
import React from 'react'
import { IEventLogListProps } from '.'
import { IEventLogFilterContext, IEventLogListItem, IFilter, IRangeFilter } from 'interfaces/models'
import { ActivityItem, DayOfWeek, DefaultPalette, Icon, mergeStyleSets, ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react';
import { EventLogTypes } from 'enums';
import { EventLogAPI } from 'api';
import { useDates, useFilterContextHandler } from 'hooks';
import { Loader } from 'components/common';
import { EventLogFilterContextFields } from 'enums/fields';
import { DateRangeFilter } from '../FilterBar';

const classNames = mergeStyleSets({
    item: {
        padding: "8px 12px",
        marginBottom: 4,
    },
    nameText: {
        fontWeight: 'bold',
        color: DefaultPalette.themePrimary
    },
});

export const EventLogList = (props: IEventLogListProps) => {

    const { cultureId } = props;

    const filterContextHandler = useFilterContextHandler<IEventLogFilterContext>(props.getFilterContext, EventLogAPI.filter);
    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onRangeFilterChange,
        onFilterChange,
        onPageChange } = filterContextHandler;

    const { dateByUtcToLocaleString } = useDates(cultureId);

    const getEventTypeIconName = (eventTypeId: number): string => {
        switch (eventTypeId) {
            case EventLogTypes.Added:
                return "Add";
            case EventLogTypes.Created:
                return "CircleAddition";
            case EventLogTypes.Deleted:
                return "Delete";
            case EventLogTypes.Error:
                return "Warning";
            case EventLogTypes.Information:
                return "Info";
            case EventLogTypes.Notification:
                return "Message";
            case EventLogTypes.Removed:
                return "Remove";
            case EventLogTypes.Updated:
                return "Edit";
            default:
                return "Info";
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (<FilterBar
                filterContextHandler={filterContextHandler}
                onRenderFilterBarItem={onRenderFilterBarItem}
            />)
        }
    }

    const onRenderFilterBarItem = (props: IFilterBarItemProps) => {

        switch (props.name) {
            case EventLogFilterContextFields.CREATED_ON:
                return (
                    <DateRangeFilter
                        cultureId={cultureId}
                        firstDayOfWeek={DayOfWeek.Monday}
                        filter={props.filter as IRangeFilter}
                        name={props.name}
                        onChange={onRangeFilterChange}
                    />
                )
            default:
                return (
                    <CheckboxFilter
                        filter={props.filter as IFilter}
                        name={props.name}
                        onChange={onFilterChange} />
                )
        }
    }

    const onRenderContent = (): React.ReactNode => {
        return isFiltering ? <Loader /> : (
            <div style={{ position: "relative", height: "100%" }}>

                <ScrollablePane styles={{ root: { padding: 0 } }} scrollbarVisibility={ScrollbarVisibility.auto}>
                    {filterResultInfo?.Items.map((v: IEventLogListItem) => (
                        <ActivityItem
                            key={v.Id}
                            activityDescription={[
                                <div key={String(v.Id)}>
                                    <span className={classNames.nameText}>{v.UserAccountName}</span> <span>{EventLogTypes[v.EventLogTypeId].toLowerCase()}</span>
                                </div>,
                            ]}
                            activityIcon={<Icon iconName={getEventTypeIconName(v.EventLogTypeId)} />}
                            comments={[
                                <span key={String(v.Id) + "-message"}>{v.Message}</span>,
                            ]}
                            timeStamp={dateByUtcToLocaleString(v.CreatedOn)}
                            className={classNames.item} />
                    ))}
                </ScrollablePane>
            </div>

        )
    }

    return (
        <BaseListView
            isLoading={!filterContext}
            onRenderFilterBar={onRenderFilterBar}
            onRenderContent={onRenderContent}
            pagingProps={filterContext && filterResultInfo && {
                itemCount: filterResultInfo.ItemCount,
                pageCount: filterResultInfo.PageCount,
                page: filterContext.FilterInfo.Page,
                pageSize: filterContext.FilterInfo.PageSize,
                onPageChanged: onPageChange
            }}
        />
    )
}
