import React from 'react';
import { DictionaryAPI } from 'api';
import { UnderlinedTextField } from '../UnderlinedTextField';
import { IUsernameTextFieldProps } from '.';

export const UsernameTextField = (props: IUsernameTextFieldProps) => {
    const { value, autoFocus, defaultValue, errorMessage, style, disabled, onChange } = props;
    return (
        <UnderlinedTextField
            value={value}
            key="UserName"
            name="UserName"
            defaultValue={defaultValue}
            autoFocus={autoFocus}
            placeholder={DictionaryAPI.getTranslation("Username")}
            errorMessage={errorMessage}
            style={style}
            disabled={disabled}
            onChange={onChange}
        />
    );
}