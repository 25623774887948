import React from 'react';
import { SpinButton as FabricSpinButton } from 'office-ui-fabric-react';
import { ISpinButtonProps } from './interfaces';
import { useNumberFormat } from 'hooks';

export const SpinButton = (props: ISpinButtonProps) => {

    const {
        name,
        value,
        isPercentage,
        step,
        min,
        max,
        minimumFractionDigits,
        suffix,
        locale,
        disabled,
        style,
        styles,
        inputProps,
        label,
        onValueChange,
        onFocus,
    } = props;

    const { getNumber, parseNumber } = useNumberFormat(locale);

    const _suffix = isPercentage ? "%" : suffix ? " " + suffix : "";
    const _min = min || 0;
    const _max = max || 0;
    const stringValue = (
        isPercentage ? getNumber(value * 100, (minimumFractionDigits ? minimumFractionDigits - 2 : 0))
            : getNumber(value, minimumFractionDigits)
    ) + _suffix;

    const normalizeValue = (value?: number): number => {
        if (value) {
            return Number(value.toFixed(minimumFractionDigits));
        }

        return 0;
    }

    const onDecrement = (v: string) => {
        const _value = normalizeValue(value - step);

        if (_value >= _min && onValueChange) {
            onValueChange(_value, name);
        }
    }

    const onIncrement = (v: string) => {
        const _value = normalizeValue(value + step);

        if (_value <= _max && onValueChange) {
            onValueChange(_value, name);
        }
    }

    const onValidate = (v: string, event?: React.SyntheticEvent<HTMLElement>) => {
        let numberValue = parseNumber(v.replace(_suffix, ""));

        if (isPercentage) {
            numberValue = numberValue / 100;
        }

        numberValue = normalizeValue(numberValue);

        if (numberValue < _min) {
            onValueChange(_min, name);
        } else if (numberValue > _max) {
            onValueChange(_max, name);
        } else {
            onValueChange(numberValue, name);
        }
    }

    return (
        <FabricSpinButton
            label={label}
            value={stringValue}
            inputProps={inputProps}
            style={style}
            styles={styles}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            onFocus={onFocus}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            onValidate={onValidate} 
        />
    );
};