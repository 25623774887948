import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { applicationReducer } from './application/reducers';
import { authenticationReducer } from './authentication/reducers';
import { detailLayoutCommandBarReducer, detailLayoutTitleBarReducer } from './detailLayout/reducers';
import { dictionaryReducer } from './dictionary/reducers';
import { userSettingsReducer } from './userSettings/reducers';
import { errorMessageReducer } from './errorMessage/reducers';
import { filterResultInfoReducer } from "./filterResultInfo/reducers";
import { pendingOrdersReducer } from "./pendingOrders/reducers";
import { productCatalogsReducer } from "./productCatalogs/reducers"

const rootReducer = combineReducers({
    applicationState: applicationReducer,
    authenticationState: authenticationReducer,
    detailLayoutCommandBarState: detailLayoutCommandBarReducer,
    detailLayoutTitleBarState: detailLayoutTitleBarReducer,
    dictionaryState: dictionaryReducer,
    errorMessageState: errorMessageReducer,
    filterResultInfoState: filterResultInfoReducer,
    userSettingState: userSettingsReducer,
    pendingOrdersState: pendingOrdersReducer,
    productCatalogsReducer: productCatalogsReducer
})

export type RootState = ReturnType<typeof rootReducer>

function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer)
    );

    return store;
}

export const storeInstance = configureStore();