import React from 'react';
import { Stack, StackItem, TextField } from 'office-ui-fabric-react';
import { ContactBaseViewModelFields } from 'enums/fields';
import { DictionaryAPI } from 'api';
import { IRelationViewModel, ICreateRelationViewModel } from 'interfaces/viewmodels';
import { IRelationAddressFieldsProps } from '.';

export const RelationAddressFields = <T extends ICreateRelationViewModel | IRelationViewModel>(props: IRelationAddressFieldsProps<T>) => {

    const { form, hideLabels, disabled } = props;
    const { item } = form;
    const { onTextFieldChange, getErrorMessage } = form;

    const {
        ADDRESS1,
        POSTAL_CODE,
        CITY
    } = ContactBaseViewModelFields

    return (
        <>
            <StackItem grow>
                <TextField
                    required={item.AddressPropertiesRequired && !hideLabels}
                    value={item.Address1}
                    name={ADDRESS1}
                    label={!hideLabels && DictionaryAPI.getTranslation("Address")}
                    placeholder={hideLabels && DictionaryAPI.getTranslation("Address")}
                    errorMessage={getErrorMessage(ADDRESS1)}
                    disabled={disabled}
                    onChange={onTextFieldChange} />
            </StackItem>
            <StackItem>
                <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                    <StackItem styles={{ root: { width: 125 } }}>
                        <TextField
                            required={item.AddressPropertiesRequired && !hideLabels}
                            value={item.PostalCode}
                            name={POSTAL_CODE}
                            label={!hideLabels && DictionaryAPI.getTranslation(POSTAL_CODE)}
                            placeholder={hideLabels && DictionaryAPI.getTranslation(POSTAL_CODE)}
                            errorMessage={getErrorMessage(POSTAL_CODE)}
                            disabled={disabled}
                            onChange={onTextFieldChange} />
                    </StackItem>
                    <StackItem grow>
                        <TextField
                            required={item.AddressPropertiesRequired && !hideLabels}
                            value={item.City}
                            name={CITY}
                            label={!hideLabels && DictionaryAPI.getTranslation(CITY)}
                            placeholder={hideLabels && DictionaryAPI.getTranslation(CITY)}
                            errorMessage={getErrorMessage(CITY)}
                            disabled={disabled}
                            onChange={onTextFieldChange} />
                    </StackItem>
                </Stack>
            </StackItem>
        </>
    );
};