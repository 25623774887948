export enum CatalogViewModelFields {
    ACTIVE_FROM = "ActiveFrom",
    ACTIVE_TO = "ActiveTo",
    COUNTRIES = "Countries",
    DEFAULT_SELECTED_RELATION_TYPE_ID = "DefaultSelectedRelationTypeId",
    DESCRIPTION = "Description",
    IS_ACTIVE = "IsActive",
    RELATION_TYPES = "RelationTypes",
    ROW_GUID = "RowGuid",
    SELECTED_COUNTY_IDS = "SelectedCountyIds"
}