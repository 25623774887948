import {
    defaultContentType,
    defaultDataType,
    objectToParams,
    execute
} from './request'
import { getAccessToken, authenticateByRefreshToken, clearAuthentication } from './app/authentication'
import { IErrorMessagesMap } from 'interfaces';
import { useGlobals } from 'hooks';
import { storeInstance } from 'store';
import { setErrorMessage } from 'store/errorMessage/actions';
import { IFileStoreViewModel } from 'interfaces/viewmodels';
import { IFileDetail } from 'interfaces/models';

const { rootUrl, clientId } = useGlobals();

const _onError = (xhr: XMLHttpRequest, onError?: (xmlHttpRequest: XMLHttpRequest) => void) => {
    if (onError) {
        onError(xhr);
    } else {
        const defaultErrorMessage = "An unexpected error has occurred.";
        try {
            const responseMessage = JSON.parse(xhr.response).Message;

            storeInstance.dispatch(setErrorMessage((responseMessage || "") !== "" ? responseMessage : defaultErrorMessage));
        } catch (error) {
            storeInstance.dispatch(setErrorMessage(defaultErrorMessage));
        }
    }
}

export class BaseAPI {

    public static getClientId = (): string => {
        return clientId;
    }

    private static getCultureId = (): string => {
        return storeInstance.getState().authenticationState?.account?.CultureId || "";
    }

    public static get = (url: string, data: any, onSuccess: (response: any) => void, onError?: (xmlHttpRequest: XMLHttpRequest) => void, isAnonymous?: boolean, dataType?: string) => {
        execute(`${rootUrl}/${url}` + objectToParams(data), "GET", defaultContentType, !dataType && defaultDataType, isAnonymous ? "" : getAccessToken(), null, BaseAPI.getCultureId(),
            onSuccess,
            (xhr) => _onError(xhr, onError),
            () => {
                authenticateByRefreshToken(() => {
                    execute(`${rootUrl}/${url}` + objectToParams(data), "GET", defaultContentType, !dataType && defaultDataType, isAnonymous ? "" : getAccessToken(), null, BaseAPI.getCultureId(),
                        onSuccess,
                        (xhr) => _onError(xhr, onError),
                        () => clearAuthentication())
                }, () => clearAuthentication())
            }
        );
    }

    public static post = (url: string, data: any, onSuccess: (response: any) => void, onError?: (xmlHttpRequest: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void, isFormData?: boolean) => {
        execute(`${rootUrl}/${url}`, "POST", isFormData ? "" : defaultContentType, defaultDataType, getAccessToken(), isFormData ? data : JSON.stringify(data), BaseAPI.getCultureId(),
            onSuccess,
            (xhr) => {
                if (onError) {
                    if (xhr.status === 400) {
                        try {
                            onError(xhr, JSON.parse(xhr.responseText));
                        } catch (e) {
                            _onError(xhr, onError);
                        }
                    } else {
                        _onError(xhr, onError);
                    }
                } else {
                    _onError(xhr);
                }
            },
            () => {
                authenticateByRefreshToken(() => {
                    execute(`${rootUrl}/${url}`, "POST", isFormData ? "" : defaultContentType, defaultDataType, getAccessToken(), isFormData ? data : JSON.stringify(data), BaseAPI.getCultureId(),
                        onSuccess,
                        (xhr) => {
                            if (onError) {
                                if (xhr.status === 400) {
                                    try {
                                        onError(xhr, JSON.parse(xhr.responseText));
                                    } catch (e) {
                                        _onError(xhr, onError);
                                    }
                                } else {
                                    _onError(xhr, onError);
                                }
                            } else {
                                _onError(xhr);
                            }
                        },
                        () => clearAuthentication);
                }, () => clearAuthentication())
            }
        );
    }

    public static uploadFile = (entityKey: string, folderPath: string, file: File | undefined, url: string, onSuccess: () => void) => {
        if (file) {
            const formData = new FormData();
            formData.append("entityKey", entityKey);
            formData.append("folderPath", folderPath);
            formData.append("attachment", file);

            BaseAPI.post(url, formData, () => onSuccess(), undefined, true);

        } else {
            onSuccess();
        }
    }

    public static readFiles = (url: string, onSuccess: (files: IFileDetail[]) => void) => {       
        BaseAPI.get(url, null, (data) => onSuccess(data));
    }

    public static deleteFiles = (entityKey: string, filePaths: string[], url: string, onSuccess: () => void) => {
        const viewModel: IFileStoreViewModel = {
            EntityKey: entityKey,
            FilePaths: filePaths,
            FolderPath: "",
        }
        
        BaseAPI.post(url, viewModel, () => onSuccess());
    }
}




