import React from 'react';
import {
    Stack, StackItem,
    TextField,
    IComboBoxOption,
    ScrollablePane,
} from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { ICountry, ILanguage, ICurrencyListItem } from 'interfaces/models';
import { RelationViewModelFields, ContactBaseViewModelFields } from 'enums/fields';
import { IRelationViewModel } from 'interfaces/viewmodels';
import { ComboBox, RelationAddressFields } from 'components/common';
import { IInformationTabProps } from './interfaces';

export const InformationTab = <T extends IRelationViewModel>(props: IInformationTabProps<T>) => {

    const { form, hideAddressFields, hideVatNumber } = props;
    const { item: relation, onTextFieldChange, onUnControlledTextFieldChange, onComboBoxSimpleChange, getErrorMessage } = form;

    const {
        FIRST_NAME,
        LAST_NAME,
        NAME,
        COUNTRY_ID,
    } = ContactBaseViewModelFields

    const {
        COMPANY,
        LANGUAGE_ID,
        CURRENCY_ID,
        PHONE,
        MOBILE,
        EMAIL,
        WEBSITE,
        VAT_NUMBER,
        REMARKS } = RelationViewModelFields

    return relation ? (
        <ScrollablePane>
            <div className="ms-Grid" dir="ltr" style={{ margin: 10, maxWidth: 800 }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg6">
                        <Stack>
                            {
                                relation.IsPerson ?
                                    <StackItem>
                                        <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                            <StackItem grow>
                                                <TextField
                                                    required
                                                    autoFocus
                                                    value={relation.FirstName}
                                                    name={FIRST_NAME}
                                                    label={DictionaryAPI.getTranslation(FIRST_NAME)}
                                                    errorMessage={getErrorMessage(FIRST_NAME)}
                                                    onChange={onTextFieldChange} />
                                            </StackItem>
                                            <StackItem grow>
                                                <TextField
                                                    required
                                                    value={relation.LastName}
                                                    name={LAST_NAME}
                                                    label={DictionaryAPI.getTranslation(LAST_NAME)}
                                                    errorMessage={getErrorMessage(LAST_NAME)}
                                                    onChange={onTextFieldChange} />
                                            </StackItem>
                                        </Stack>
                                    </StackItem>
                                    :
                                    <>
                                        <StackItem grow>
                                            <TextField
                                                required
                                                autoFocus
                                                value={relation.Name}
                                                name={NAME}
                                                label={DictionaryAPI.getTranslation(NAME)}
                                                errorMessage={getErrorMessage(NAME)}
                                                onChange={onTextFieldChange}
                                            />
                                        </StackItem>
                                        <StackItem grow>
                                            <TextField
                                                value={relation.Company}
                                                name={COMPANY}
                                                label={DictionaryAPI.getTranslation("FullName")}
                                                errorMessage={getErrorMessage(COMPANY)}
                                                onChange={onTextFieldChange}
                                            />
                                        </StackItem>
                                    </>
                            }
                            {
                                !hideAddressFields && (
                                    <RelationAddressFields
                                        form={form} />
                                )
                            }

                            <StackItem>
                                <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                    <StackItem grow>
                                        <ComboBox
                                            required
                                            allowFreeform
                                            autoComplete="on"
                                            selectedKey={relation.CountryId}
                                            label={DictionaryAPI.getTranslation("Country")}
                                            name={COUNTRY_ID}
                                            options={relation.Countries.map((v: ICountry, i): IComboBoxOption => {
                                                return {
                                                    key: v.Id,
                                                    text: v.Name,
                                                }
                                            })}
                                            onSimpleChange={onComboBoxSimpleChange} />
                                    </StackItem>
                                    <StackItem grow>
                                        <ComboBox
                                            allowFreeform
                                            autoComplete="on"
                                            selectedKey={relation.LanguageId}
                                            label={DictionaryAPI.getTranslation("Language")}
                                            name={LANGUAGE_ID}
                                            options={relation.Languages.map((v: ILanguage, i): IComboBoxOption => {
                                                return {
                                                    key: v.Id,
                                                    text: v.Name,
                                                }
                                            })}
                                            onSimpleChange={onComboBoxSimpleChange} />
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    selectedKey={relation.CurrencyId}
                                    label={DictionaryAPI.getTranslation("Currency")}
                                    name={CURRENCY_ID}
                                    options={relation.Currencies.map((v: ICurrencyListItem, i: number): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: `${v.Name} (${v.Symbol})`,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                        </Stack>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg6">
                        <Stack>
                            <StackItem grow>
                                <TextField
                                    value={relation.Phone}
                                    name={PHONE}
                                    label={DictionaryAPI.getTranslation(PHONE)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    value={relation.Mobile}
                                    name={MOBILE}
                                    label={DictionaryAPI.getTranslation(MOBILE)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    value={relation.Email || undefined}
                                    name={EMAIL}
                                    label={DictionaryAPI.getTranslation(EMAIL)}
                                    errorMessage={getErrorMessage(EMAIL)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    value={relation.Website}
                                    name={WEBSITE}
                                    label={DictionaryAPI.getTranslation(WEBSITE)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            {
                                !hideVatNumber && (
                                    <StackItem grow>
                                        <TextField
                                            value={relation.VatNumber}
                                            name={VAT_NUMBER}
                                            label={DictionaryAPI.getTranslation(VAT_NUMBER)}
                                            onChange={onTextFieldChange} />
                                    </StackItem>
                                )
                            }
                        </Stack>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <TextField
                            multiline
                            spellCheck={false}
                            defaultValue={relation.Remarks}
                            name={REMARKS}
                            label={DictionaryAPI.getTranslation(REMARKS)}
                            style={{ minHeight: 100 }}
                            onChange={onUnControlledTextFieldChange} />
                    </div>
                </div>
            </div>
        </ScrollablePane>
    ) : null;
}