import { IArithmetic } from "./interfaces";

export const useArithmetic = (): IArithmetic => {

    const getSum = (numbers: number[]): number => {
        return numbers.reduce((a, b) => a + b, 0);
    }

    return {
        getSum
    }
};