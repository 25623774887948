export enum CreateOrderViewModelFields {
    ORDER_SUB_DOCUMENT_TYPE_ID = "OrderSubDocumentTypeId",
    BRANDS = "Brands",
    CATALOG_ID = "CatalogId",
    CATALOGS = "Catalogs",
    CHECK_EXISTING = "CheckExisting",
    COLLECTION_ID = "CollectionId",
    COLLECTION_ID_IS_REQUIRED = "CollectionIdIsRequired",
    COLLECTIONS = "Collections",
    COMPANY_RELATION_ID = "CompanyRelationId",
    COMPANY_RELATIONS = "CompanyRelations",
    CUSTOMER_RELATION_ROW_GUID = "CustomerRelationRowGuid",
    DELIVERY_ADDRESS_RELATION_ROW_GUID = "DeliveryAddressRelationRowGuid",
    DOCUMENT_STATUS_ID = "DocumentStatusId",
    WAREHOUSE_RELATION_ID = "WarehouseRelationId",
    WAREHOUSE_RELATION_ID_IS_REQUIRED = "WarehouseRelationIdIsRequired",
    WAREHOUSES = "Warehouses"
}