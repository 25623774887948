import { BaseAPI, FilterAPI } from 'api';
import { IFilterResultInfo, ICatalogGroupFilterContext, ICatalogGroup } from 'interfaces/models';
import { ICreateCatalogGroupViewModel, ICatalogGroupViewModel } from 'interfaces/viewmodels';
import { IErrorMessagesMap } from 'interfaces';

export class CatalogGroupAPI {

    public static getFilterContext = (isActive: boolean, onSuccess: (data: ICatalogGroupFilterContext) => void) => {
        FilterAPI.getFilterContext("Sls/CatalogGroup", 
            [
                String(isActive), 
            ],
            (data) => onSuccess({ ...data, FilterInfo: { 
                ...data.FilterInfo,
                OrderByRules: [{ FieldName: "Id", IsDescending: true }],
                PageSize: 50 } }));
    }

    public static filter = (filterContext: ICatalogGroupFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Sls/CatalogGroup/Filter", {
            ...filterContext,
            CreatedOn: { ...filterContext.CreatedOn, Values: filterContext.CreatedOn.Values.filter(v => v.IsChecked) }
        }, onSuccess);
    }

    public static create = (catalogGroup: ICreateCatalogGroupViewModel, onSuccess: (Id: number) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("Api/Sls/CatalogGroup/Create", catalogGroup, onSuccess, onError);
    }

    public static get = (Id: number, onSuccess: (data: ICatalogGroupViewModel) => void) => {
        BaseAPI.get(`Api/Sls/CatalogGroup/Get/${Id}`, null, onSuccess);
    }

    public static read = (isActive: boolean, onSuccess: (data: ICatalogGroup[]) => void) => {
        BaseAPI.get(`Api/Sls/CatalogGroup/Read/${String(isActive)}`, null, onSuccess);
    }

    public static update = (catalogGroup: ICatalogGroupViewModel, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("Api/Sls/CatalogGroup/Update", catalogGroup, onSuccess, onError);
    }

    public static toggleIsActive = (Id: number, onSuccess: (response: any) => void) => {
        BaseAPI.post("Api/Sls/CatalogGroup/ToggleIsActive", Id, onSuccess);
    }

    public static delete = (Id: number, onSuccess: (response: any) => void, onError: (xhr: XMLHttpRequest) => void) => {
        BaseAPI.post("Api/Sls/CatalogGroup/Delete", Id, onSuccess, onError);
    }

}