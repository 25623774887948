import { DictionaryAPI } from 'api';
import { IButtonStyles, IContextualMenuItem, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react'
import { IUpdateOrderButtonProps } from './interfaces';

const updateOrderButtonStyles: IButtonStyles = {
    root: {
        width: "100%",
    },
    splitButtonContainer: { display: "block" },
    rootHovered: { border: 0 }
}

export const UpdateOrderButton = (props: IUpdateOrderButtonProps) => {

    const { isUpdate, disabled, onClick } = props;

    /* const getContextualMenuItems = (): IContextualMenuItem[] | undefined => {

        const items: IContextualMenuItem[] = [];

        if (onSupendClick) {
            items.push({
                key: 'suspend',
                text: DictionaryAPI.getTranslation("Suspend"),
                iconProps: { iconName: 'SurveyQuestions' },
                onClick: onSupendClick
            })
        }

        if (onRemoveClick) {
            items.push({
                key: 'remove',
                text: DictionaryAPI.getTranslation("Remove"),
                iconProps: { iconName: 'Remove' },
                onClick: onRemoveClick
            })
        }

        return items.length > 0 ? items : undefined;
    } */

    return (
        <>
            {isUpdate ? (
                <PrimaryButton
                    split
                    styles={updateOrderButtonStyles}
                    text={DictionaryAPI.getTranslation("UpdateOrder")}
                    iconProps={{ iconName: "Edit" }}
                    /* menuProps={{
                        directionalHint: 6,
                        items: getContextualMenuItems()
                    }} */
                    primaryDisabled={disabled}
                    onClick={onClick}
                />
            ) : (
                    <PrimaryButton
                    split
                        styles={updateOrderButtonStyles}
                        text={DictionaryAPI.getTranslation("AddToOrder")}
                        iconProps={{
                            iconName: "Add"
                        }}
                        disabled={disabled}
                        onClick={onClick}
                    />
                )}
        </>
    )
}
