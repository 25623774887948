import React from 'react';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { ILogoProps } from '.';

export const Logo = (props: ILogoProps) => {

    const { isLarge } = props;
    const padding: number = props.padding || 0;
    return (
        <Image
            src="logo.png"
            height={isLarge ? 72 - (padding * 2) : 48  - (padding * 2)}
            alt="logo" 
            styles={{ root: { padding: padding } }}/>
    );
}