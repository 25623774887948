import React, { useState, useEffect, useRef } from 'react';
import { IAddProductToOrderViewModel, IProductDocumentDetailsGroupViewModel, IUpdateDocumentDetailsViewModel } from 'interfaces/viewmodels';
import { useArithmetic, useChangeTracker, useGlobals, useNumberFormat } from 'hooks';
import { MessageBar, MessageBarType, Stack, StackItem } from 'office-ui-fabric-react';
import { DocumentAPI, DictionaryAPI } from 'api';
import { storeInstance, RootState } from 'store';
import { setPendingOrders } from 'store/pendingOrders/actions';
import { Dialog, UpdateDocumentDetailsStatusDialog } from 'components/common';
import { useSelector } from 'react-redux';
import { IAddProductToOrderProps, IDocumentDetailQuantity } from './interfaces';
import { DocumentDetailStatuses } from 'enums';
import { ProductDocumentDetailsGroup } from './ProductDocumentDetailsGroup';
import { UpdateOrderButton } from './UpdateOrderButton';
import { IErrorMessagesMap } from 'interfaces';
import { update } from 'lodash';

export const AddProductToOrder = (props: IAddProductToOrderProps) => {

    const { productId, documentRowGuid, cultureId, catalogId, reloadAfterUpdateDisabled, onUpdated } = props;

    const [addProductToOrder, setAddProductToOrder] = useState<IAddProductToOrderViewModel>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [statusUpdateDocumentDetailsViewModel, setStatusUpdateDocumentDetailsViewModel] = useState<IUpdateDocumentDetailsViewModel>();
    const [deleteUpdateDocumentDetailsStatusViewModel, setDeleteUpdateDocumentDetailsStatusViewModel] = useState<IUpdateDocumentDetailsViewModel>();
    const [fetching, setFetching] = useState(true);
    const { companyRelationId } = useGlobals();
    const customerRelationRowGuid = useSelector((state: RootState) => state.pendingOrdersState.customerRelationRowGuid);
    const initialProductDocumentDetailsGroupsCheckedRef = useRef<boolean[]>();
    const initialDocumentDetailsQuantityRef = useRef<number[][]>();
    const { hasChanges, clearChanges, pushChange } = useChangeTracker();

    const { getSum } = useArithmetic();
    const { getNumber, getPrice } = useNumberFormat(cultureId);

    useEffect(() => {
        getAddProductToOrder();
    }, [productId]);

    const getAddProductToOrder = () => {
        if (productId) {

            setFetching(true);

            if ((documentRowGuid || "") !== "") {
                DocumentAPI.getAddProductToOrderByDocument(productId, documentRowGuid, handleGetAddProductToOrderResponse)
            } else if ((customerRelationRowGuid || "") !== "") {
                DocumentAPI.getAddProductToOrder(productId, companyRelationId, customerRelationRowGuid, catalogId || 0, handleGetAddProductToOrderResponse)
            }
        }
    }

    const handleGetAddProductToOrderResponse = (data: IAddProductToOrderViewModel) => {
        setAddProductToOrder(data);
        setInitialProductDocumentDetailsGroupsChecked(data.ProductDocumentDetailsGroups || []);
        setInitialDocumentDetailsQuantity(data.ProductDocumentDetailsGroups || []);
        setStatusUpdateDocumentDetailsViewModel(undefined);
        setFetching(false);
        setErrorMessage("");
        clearChanges();
    }

    const handleErrorMessages = (errorMessages: IErrorMessagesMap) => {
        const key = Object.keys(errorMessages)[0];
        const message = (errorMessages[key][0]);

        if (message === "OrderInputPolicyViolation") {

            setErrorMessage(DictionaryAPI.getTranslation("OrderInputPolicyName_" +
                addProductToOrder.OrderInputPolicyId));
        }
    }

    const handleAddProductToOrder = () => {
        setFetching(true);
        if (addProductToOrder) {
            DocumentAPI.addProductToOrder(addProductToOrder,
                () => {

                    if (addProductToOrder.IsPendingOrder) {
                        DocumentAPI.readPendingOrders(customerRelationRowGuid, (data) => {
                            storeInstance.dispatch(setPendingOrders(data));
                        });
                    }

                    if (!reloadAfterUpdateDisabled) {
                        getAddProductToOrder();
                    }

                    onUpdated && onUpdated();
                },
                (xhr, errorMessages) => {
                    if (errorMessages) {
                        handleErrorMessages(errorMessages);
                    } else {
                        setErrorMessage(DictionaryAPI.getTranslation("ErrorWhileUpdating"));
                    }
                    setFetching(false);
                })
        }
    }

    const setInitialProductDocumentDetailsGroupsChecked = (productDocumentDetailsGroups: IProductDocumentDetailsGroupViewModel[]) => {
        const array = new Array(productDocumentDetailsGroups.length);

        for (let groupIndex = 0; groupIndex < productDocumentDetailsGroups.length; groupIndex++) {
            const group = productDocumentDetailsGroups[groupIndex];
            array[groupIndex] = group.Checked;
        }

        initialProductDocumentDetailsGroupsCheckedRef.current = array;
    }

    const setInitialDocumentDetailsQuantity = (productDocumentDetailsGroups: IProductDocumentDetailsGroupViewModel[]) => {
        const array = new Array(productDocumentDetailsGroups.length);

        for (let groupIndex = 0; groupIndex < productDocumentDetailsGroups.length; groupIndex++) {
            const group = productDocumentDetailsGroups[groupIndex];
            array[groupIndex] = new Array(group.DocumentDetails.length);

            for (let detailIndex = 0; detailIndex < group.DocumentDetails.length; detailIndex++) {
                const detail = group.DocumentDetails[detailIndex];
                array[groupIndex][detailIndex] = detail.Quantity;
            }
        }

        initialDocumentDetailsQuantityRef.current = array;
    }

    const handleOnDetailsQuantitiesChange = (index: number, quantities: IDocumentDetailQuantity[]) => {
        for (const detailQuantity of quantities) {
            const detail = addProductToOrder.ProductDocumentDetailsGroups[index].DocumentDetails[detailQuantity.index];

            detail.Quantity = 
                (detailQuantity.quantity > detail.AvailableQuantity && detail.AvailableQuantity !== -1) ? 
                    detail.AvailableQuantity : 
                    detailQuantity.quantity;

            pushChange(
                `ProductDocumentDetailsGroups[${index}].DocumentDetails[${detailQuantity.index}].Quantity`,
                detail.Quantity,
                initialDocumentDetailsQuantityRef.current[index][detailQuantity.index]
            );
        }

        setAddProductToOrder({ ...addProductToOrder });
    }

    const handleOnColliCheckedChange = (index: number, checked: boolean) => {

        addProductToOrder.ProductDocumentDetailsGroups[index].Checked = checked;

        pushChange(
            `ProductDocumentDetailsGroups[${index}].Checked`,
            checked,
            initialProductDocumentDetailsGroupsCheckedRef.current[index]
        );

        setAddProductToOrder({ ...addProductToOrder });
    }

    const handleOnDelete = (groupKey: string) => {

        setDeleteUpdateDocumentDetailsStatusViewModel({
            DocumentRowGuid: addProductToOrder.DocumentRowGuid,
            GroupKeys: [groupKey]
        })
    }

    const handleOnSuspend = (groupKey: string) => {
            setStatusUpdateDocumentDetailsViewModel({
                DocumentRowGuid: addProductToOrder.DocumentRowGuid,
                GroupKeys: [groupKey],
                DocumentDetailStatusId: DocumentDetailStatuses.Suspended
            })
        }

        const handleOnDismissUpdateDocumentDetailsStatusDialog = (updated?: boolean) => {
            if (updated) {
                getAddProductToOrder();
                onUpdated(false);
            } else {
                setStatusUpdateDocumentDetailsViewModel(undefined);
            }
        }

        const handleDelete = () => {
            setFetching(true);
            DocumentAPI.removeDetails(deleteUpdateDocumentDetailsStatusViewModel,
                () => {

                    if (addProductToOrder.IsPendingOrder) {
                        DocumentAPI.readPendingOrders(customerRelationRowGuid, (data) => {
                            storeInstance.dispatch(setPendingOrders(data));
                        });
                    }

                    onUpdated(true);
                }
            );
        }

        if (fetching && !addProductToOrder) return null;

        if (addProductToOrder && (!addProductToOrder.ProductDocumentDetailsGroups || addProductToOrder.ProductDocumentDetailsGroups?.length === 0)) {
            return (
                <MessageBar
                    className="ms-motion-fadeIn"
                    messageBarType={MessageBarType.info}
                    isMultiline={true}
                    dismissButtonAriaLabel={DictionaryAPI.getTranslation("Close")}>
                    {DictionaryAPI.getTranslation("ThisProductCannotBeOrdered")}
                </MessageBar>
            )
        }

        if ((customerRelationRowGuid || "") === "" && (documentRowGuid || "") === "") {
            return (
                <MessageBar
                    className="ms-motion-fadeIn"
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                    dismissButtonAriaLabel={DictionaryAPI.getTranslation("Close")}>
                    {DictionaryAPI.getTranslation("SelectCustomerToOrder")}
                </MessageBar>
            )
        }

        return (
            <>
                <Stack className="ms-motion-fadeIn" tokens={{ childrenGap: 10 }}>
                    <StackItem>
                        {
                            errorMessage && (
                                <MessageBar
                                    className="ms-motion-fadeIn"
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    dismissButtonAriaLabel={DictionaryAPI.getTranslation("Close")}
                                    onDismiss={() => setErrorMessage("")}>
                                    {errorMessage}
                                </MessageBar>
                            )
                        }
                    </StackItem>
                    {
                        (addProductToOrder.ProductDocumentDetailsGroups.map((v, i) => {
                            return (
                                <ProductDocumentDetailsGroup
                                    key={i}
                                    viewModel={v}
                                    index={i}
                                    cultureId={cultureId}
                                    currencyId={addProductToOrder.CurrencyId}
                                    documentProductSizes={addProductToOrder.DocumentProductSizes}
                                    lastOrderedProductDocumentDetails={addProductToOrder.LastOrderedProductDocumentDetails}
                                    updateOrderDisabled={fetching}
                                    onDetailsQuantitiesChange={handleOnDetailsQuantitiesChange}
                                    onColliCheckedChange={handleOnColliCheckedChange}
                                    onSuspend={handleOnSuspend}
                                    onDelete={handleOnDelete}
                                />
                            )
                        }))
                    }
                    <StackItem align="end">
                        <UpdateOrderButton
                            isUpdate={addProductToOrder.ExistsOnDocument}
                            disabled={fetching || (!hasChanges && !(!addProductToOrder.ExistsOnDocument && addProductToOrder.ZeroTotalQuantityAllowed))}
                            onClick={handleAddProductToOrder}
                        />
                    </StackItem>
                </Stack>
                {
                    deleteUpdateDocumentDetailsStatusViewModel && (
                        <Dialog
                            title={DictionaryAPI.getTranslation("Remove")}
                            subText={DictionaryAPI.getTranslation("DeleteFromOrder") + "?"}
                            acceptButtonDisabled={fetching}
                            cancelButtonDisabled={fetching}
                            onAccept={handleDelete}
                            onCancel={() => setDeleteUpdateDocumentDetailsStatusViewModel(undefined)}
                            maxWidth={300}
                        />
                    )
                }
                {
                    statusUpdateDocumentDetailsViewModel && (
                        <UpdateDocumentDetailsStatusDialog
                            viewModel={statusUpdateDocumentDetailsViewModel}
                            onDismiss={handleOnDismissUpdateDocumentDetailsStatusDialog}
                        />
                    )
                }
            </>
        )
    };