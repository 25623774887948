import React, { useEffect } from 'react'
import { IDownloadFileProps } from './interfaces'

export const DownloadFile = (props: IDownloadFileProps) => {

    const { url } = props;

    return (
        <div style={{ display: 'none' }}>
            {
                url && (
                    <iframe 
                        src={url}
                    />
                )
            }
        </div>
    )
}
