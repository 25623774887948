import React, { CSSProperties } from 'react';
import { DefaultPalette, FontSizes } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { UsernameTextField, PasswordTextField } from 'components/common';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { DictionaryAPI, AccountAPI } from 'api'
import { ISignInProps } from '.';
import { ISignInViewModel } from 'interfaces/viewmodels';
import { useForm } from 'hooks';
import { SignInViewModelFields } from 'enums/fields';
import { AuthenticationLayout } from 'components/layouts';

const underlinedTextFieldStyle: CSSProperties = {
    fontSize: FontSizes.mediumPlus
}

export const SignIn = (props: ISignInProps) => {

    const { item, isSubmitting, onTextFieldChange, submit, getErrorMessage } = useForm<ISignInViewModel>(
        {
            UserName: "",
            Password: "",
            ApplicationId: Number.parseInt(process.env.REACT_APP_CLIENT_ID as string)
        },
        undefined, AccountAPI.signIn);

    const { PASSWORD, USER_NAME } = SignInViewModelFields

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submit(() => {
            props.onSignedIn();
        });
    }

    if (!item)
        return null;

    return (
        <AuthenticationLayout title={DictionaryAPI.getTranslation("EnterYourCredentials")}>
            <form onSubmit={onSubmit}>
                <Stack tokens={{ childrenGap: 24 }}>
                    <StackItem>
                        <UsernameTextField
                            autoFocus
                            value={item.UserName}
                            errorMessage={getErrorMessage(USER_NAME)}
                            style={underlinedTextFieldStyle}
                            onChange={onTextFieldChange} />
                    </StackItem>
                    <StackItem>
                        <PasswordTextField
                            value={item.Password}
                            errorMessage={getErrorMessage(PASSWORD)}
                            style={underlinedTextFieldStyle}
                            onChange={onTextFieldChange} />
                    </StackItem>
                    <StackItem styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                        <span>{DictionaryAPI.getTranslation("ForgotOrChangeYourPassword?")} </span> <Link href="/#/create-password">{DictionaryAPI.getTranslation("CreateANewOne")}</Link>
                    </StackItem>
                    <StackItem align="end" styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                        <PrimaryButton type="submit" text={DictionaryAPI.getTranslation("SignIn")} disabled={isSubmitting || item.UserName === "" || item.Password === ""} />
                    </StackItem>
                </Stack>
            </form>
        </AuthenticationLayout>);
}