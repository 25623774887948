import { DictionaryAPI } from 'api'
import { CreateEmailNotification, Dialog } from 'components/common'
import { useForm } from 'hooks'
import { ICreateEmailNotificationViewModel } from 'interfaces/viewmodels'
import React, { useEffect } from 'react'
import { ICreateReportEmailNotificationDialogProps } from './interfaces'

export const CreateReportEmailNotificationDialog = <T extends ICreateEmailNotificationViewModel>(props: ICreateReportEmailNotificationDialogProps<T>) => {

    const { 
        documentRowGuid, 
        report, 
        reportTitle,
        isCreate, 
        getCreateReportEmailNotification, 
        createOrderReportEmailNotification,
        onDismiss 
    } = props;

    const form = useForm<T>(undefined, undefined, createOrderReportEmailNotification);
    const { item, initialize, submit, isSubmitting } = form;

    useEffect(() => {
        getCreateReportEmailNotification(documentRowGuid, report, isCreate,
            (viewModel) => initialize(true, viewModel));
    }, [documentRowGuid, report])

    return (
        <Dialog
            maxWidth={600}
            isLoading={!item}
            title={`${DictionaryAPI.getTranslation("Email")} - ${DictionaryAPI.getTranslation(reportTitle)}`}
            onAccept={() => submit(() => onDismiss(true), undefined, true)}
            onCancel={() => onDismiss(false)}
            acceptButtonDisabled={!(item?.SelectedToRecipientEmails.length > 0) || isSubmitting}
            cancelButtonDisabled={isSubmitting}
        >
            {
                item && (
                    <CreateEmailNotification
                        form={form}
                    />
                )
            }
        </Dialog>
    )
}
