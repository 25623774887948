import React, { useState, useEffect } from 'react';
import { ComboBox, IComboBoxOption, IComboBox, Stack, StackItem } from 'office-ui-fabric-react';
import { ICatalogGroupSelector } from './interfaces';
import { DictionaryAPI, MenuAPI } from 'api';
import { MoreOptionsButton } from 'components/common';
import { CreateCatalogGroup, CatalogGroup } from 'components/pages';
import { FormMenus } from 'enums';

export const CatalogGroupSelector = (props: ICatalogGroupSelector) => {

    const { catalogGroupId, catalogGroups, errorMessage, onChange } = props;

    const [Id, setId] = useState<number>();

    const formMenu = MenuAPI.find(FormMenus.CatalogGroup);

    const onComboBoxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            onChange(Number(option.key));
        }
    }

    const onMoreOptionsButtonMenuItemClick = (menuKey: string) => {
        switch (menuKey) {
            case "Add":
                setId(0);
                break;
            case "Edit":
                setId(catalogGroupId);
            default:
                break;
        }
    }

    useEffect(() => {
        setId(undefined);
    }, [catalogGroups])

    return (
        <>
            <Stack horizontal verticalFill>
                <StackItem grow>
                    <ComboBox
                        allowFreeform
                        autoComplete="on"
                        label={DictionaryAPI.getTranslation("Group")}
                        errorMessage={errorMessage}
                        selectedKey={catalogGroupId}
                        options={catalogGroups.map((v): IComboBoxOption => {
                            return {
                                key: v.Id,
                                text: v.Name,
                            }
                        })}
                        onChange={onComboBoxChange} />
                </StackItem>
                {
                    (formMenu?.Privilege.CreateAllowed || (formMenu?.Privilege.EditAllowed && catalogGroupId !== null)) && (
                        <StackItem align="end">
                            <MoreOptionsButton
                                addAllowed={formMenu?.Privilege.CreateAllowed}
                                editAllowed={formMenu?.Privilege.EditAllowed && catalogGroupId !== null}
                                onMenuItemClick={onMoreOptionsButtonMenuItemClick}
                            />
                        </StackItem>
                    )
                }
            </Stack>
            {
                Id === 0 && (
                    <CreateCatalogGroup
                        onSuccess={(Id) => onChange(Id, true)}
                        onCancel={() => setId(undefined)}
                    />
                )
            }
            {
                Id > 0 && (
                    <CatalogGroup
                        Id={catalogGroupId}
                        onDismiss={
                            (changesUpdated) => changesUpdated ? onChange(Id, changesUpdated) : setId(undefined)
                        }
                    />
                )
            }
        </>

    );
};
