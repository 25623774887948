import React, { useEffect } from 'react';

import {
    IComboBoxOption,
    Stack,
    StackItem,
    TextField,
} from 'office-ui-fabric-react';

import { Dialog, ComboBox, CatalogGroupSelector } from 'components/common'

import { ICreateCatalogProps } from '.';
import { CatalogAPI, DictionaryAPI, CatalogGroupAPI } from 'api';
import { BaseCatalogViewModelFields } from 'enums/fields';
import { useForm } from 'hooks';
import { ICreateCatalogViewModel } from 'interfaces/viewmodels';

export const CreateCatalog = (props: ICreateCatalogProps): JSX.Element => {

    const { onSuccess, onCancel } = props;
    const { NAME, PRODUCT_CATEGORY_ID, COLLECTION_ID, CATALOG_GROUP_ID } = BaseCatalogViewModelFields;

    const {
        item,
        initialize,
        submit,
        onTextFieldChange,
        onComboBoxSimpleChange,
        getErrorMessage,
        updateItem,
        hasChanges
    } = useForm<ICreateCatalogViewModel>(undefined, undefined, CatalogAPI.create);

    const onCatalogGroupSelectorChange = (Id?: number, catalogGroupsChanged?: boolean) => {

        if (catalogGroupsChanged) {
            CatalogGroupAPI.read(true,
                (data) => {
                    item.CatalogGroupId = Id;
                    item.CatalogGroups = [...data];
                })
        } else {
            updateItem(CATALOG_GROUP_ID, Id);
        }
    }

    useEffect(() => {
        CatalogAPI.getCreate((data) => initialize(true, data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Dialog
                title={DictionaryAPI.getTranslation("NewCatalog")}
                maxWidth={425}
                isLoading={!item}
                acceptButtonDisabled={!hasChanges}
                onAccept={() => submit((rowGuid) => onSuccess(rowGuid))}
                onCancel={onCancel}
            >
                {
                    item && (
                        <Stack tokens={{ childrenGap: 5 }} style={{ width: 325 }}>
                            <StackItem>
                                <TextField
                                    autoFocus
                                    required
                                    value={item.Name}
                                    name={NAME}
                                    label={DictionaryAPI.getTranslation(NAME)}
                                    errorMessage={getErrorMessage(NAME)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <StackItem grow>
                                <ComboBox
                                    allowFreeform
                                    required
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Category")}
                                    name={PRODUCT_CATEGORY_ID}
                                    errorMessage={getErrorMessage(PRODUCT_CATEGORY_ID)}
                                    selectedKey={item.ProductCategoryId}
                                    options={item.ProductCategories.map((v): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem grow>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Collection")}
                                    name={COLLECTION_ID}
                                    errorMessage={getErrorMessage(COLLECTION_ID)}
                                    selectedKey={item.CollectionId}
                                    disabled={!item.ProductCategoryId}
                                    options={item.Collections.filter(c => c.ProductCategoryId === item.ProductCategoryId).map((v): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            {
                                item.CatalogGroups && (
                                    <CatalogGroupSelector
                                        catalogGroupId={item.CatalogGroupId}
                                        catalogGroups={item.CatalogGroups}
                                        errorMessage={getErrorMessage(CATALOG_GROUP_ID)}
                                        onChange={onCatalogGroupSelectorChange}
                                    />
                                )
                            }
                        </Stack>
                    )
                }
            </Dialog>

        </>

    );
}