import { Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react'
import { IColumnsStackProps } from './interfaces';

export const ColumnsStack: React.FunctionComponent<IColumnsStackProps> = props => {

    const { children, containerStyle, gap, verticalAlign } = props;

    const columnStackItemStyles = {
        root: {
            flexBasis: 0,
        }
    }

    return (
        <Stack horizontal tokens={{ childrenGap: gap }} style={containerStyle} verticalAlign={verticalAlign}>

            {children?.map((v, i) => {
                return (
                    <StackItem key={`columnsStackItem${i}`} grow={1} styles={columnStackItemStyles}>
                        {v}
                    </StackItem>
                )
            })}
        </Stack>
    )
}
