import React, { useEffect } from 'react';
import { Dialog, ComboBox, RelationSelector } from 'components/common';
import { DictionaryAPI, DocumentAPI } from 'api';
import { useForm } from 'hooks';
import { Stack, StackItem } from 'office-ui-fabric-react';
import { RelationTypes } from 'enums';
import { IAccount } from 'interfaces/models';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ICreateStockAssortmentProps } from './interfaces';
import { ICreateStockAssortmentViewModel } from 'interfaces/viewmodels/sls/ICreateStockAssortmentViewModel';
import { CreateStockAssortmentViewModelFields } from 'enums/fields/sls/CreateStockAssortmentViewModelFields';

export const CreateStockAssortment = (props: ICreateStockAssortmentProps) => {

    const { onAccept, onCancel } = props;

    const {
        submit,
        initialize,
        item,
        updateItem,
        onComboBoxSimpleChange,
        isSubmitting,
        getErrorMessage } = useForm<ICreateStockAssortmentViewModel>(undefined, null, DocumentAPI.createStockAssortment);

    const { CUSTOMER_RELATION_ROW_GUID, COLLECTION_ID } = CreateStockAssortmentViewModelFields;

    const account = useSelector<RootState, IAccount>(s => s.authenticationState.account);

    const _onAccept = () => {
        submit((data: string) => onAccept(data), undefined, true)
    }

    useEffect(() => {
        DocumentAPI.getCreateStockAssortment(account.Privilege.CompanyRelationId, (data) => initialize(true, data));
    }, [])

    return (
        <>
            <Dialog
                title={DictionaryAPI.getTranslation(`NewStockAssortment`)}
                maxWidth={450}
                isLoading={!item}
                acceptButtonDisabled={isSubmitting}
                cancelButtonDisabled={isSubmitting}
                onAccept={_onAccept}
                onCancel={onCancel}
            >
                {
                    item && (
                        <Stack tokens={{ childrenGap: 5 }} style={{ width: 325 }}>
                            <StackItem>
                                <RelationSelector
                                    required
                                    searchEnabled
                                    rowGuidFieldName={CUSTOMER_RELATION_ROW_GUID}
                                    rowGuid={item.CustomerRelationRowGuid}
                                    companyRelationId={account.Privilege.CompanyRelationId}
                                    relationTypeIds={[RelationTypes.Customer]}
                                    labelText={DictionaryAPI.getTranslation("Customer")}
                                    errorMessage={getErrorMessage(CUSTOMER_RELATION_ROW_GUID)}
                                    onChange={(rowGuidFieldName, rowGuid) => updateItem(rowGuidFieldName, rowGuid)}
                                />
                            </StackItem>
                            {
                                item.Collections?.length > 0 && (
                                    <StackItem>
                                        <ComboBox
                                            allowFreeform
                                            label={DictionaryAPI.getTranslation("Collection")}
                                            autoComplete="on"
                                            selectedKey={item.CollectionId}
                                            name={COLLECTION_ID}
                                            options={item.Collections.map((v) => {
                                                return {
                                                    key: v.Id,
                                                    text: v.Name,
                                                }
                                            })}
                                            onSimpleChange={onComboBoxSimpleChange}
                                            errorMessage={getErrorMessage(COLLECTION_ID)} />
                                    </StackItem>
                                )
                            }
                        </Stack>
                    )
                }
            </Dialog>
        </>
    );
};
