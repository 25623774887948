import React from 'react';

import {
    DefaultPalette,
    Stack, StackItem,
} from 'office-ui-fabric-react/lib';

import {
    TitleBar,
    ListViewCommandBar, SidePanel
} from 'components/common';

import { Loader, Paging } from 'components/common';
import { IBaseListViewProps } from './interfaces';

export const BaseListView = (props: IBaseListViewProps): JSX.Element => {

    const {
        isLoading,
        titleBarProps,
        listviewCommandBarProps,
        pagingProps,
        onRenderContentBeforeCommandBar,
        onRenderFilterBar,
        onRenderContent,
        onRenderLeftPanelContent,
        onRenderRightPanelContent
    } = props;

    if (isLoading) {
        return <Loader />
    }

    return (
        <Stack horizontalAlign="stretch" verticalFill={true}>
            {
                titleBarProps ?
                    <StackItem styles={{ root: { margin: "5px 8px" } }}>
                        <TitleBar {...titleBarProps} />
                    </StackItem> : null
            }
            <Stack horizontal styles={{ root: { margin: "5px 0" } }}>
                {
                    onRenderContentBeforeCommandBar && (
                        <StackItem>
                            {onRenderContentBeforeCommandBar()}
                        </StackItem>
                    )
                }
                {
                    listviewCommandBarProps && (
                        <StackItem grow>
                            <ListViewCommandBar {...listviewCommandBarProps} />
                        </StackItem>
                    )
                }

            </Stack>
            {
                onRenderFilterBar &&
                <StackItem styles={{ root: { alignItems: "center" } }}>
                    {onRenderFilterBar()}
                </StackItem>
            }
            <StackItem verticalFill>
                <Stack horizontal verticalFill>
                    {
                        onRenderLeftPanelContent && (
                            <StackItem>
                                {onRenderLeftPanelContent()}
                            </StackItem>
                        )
                    }
                    <StackItem grow>
                        {onRenderContent()}
                    </StackItem>
                    {
                        onRenderRightPanelContent && (
                            <StackItem
                                styles={{ root: { backgroundColor: DefaultPalette.neutralLighter } }}
                            >
                                {onRenderRightPanelContent()}
                            </StackItem>
                        )
                    }
                </Stack>
            </StackItem>
            {
                pagingProps && (
                    <StackItem>
                        <Paging {...pagingProps} />
                    </StackItem>
                )
            }
        </Stack >);
}