export enum FileTypes {
    Attachment = 10,
    Image = 20,
    SmallImage = 21,
    LargeImage = 22,
    Video = 30,
    WebshopOrderDetail = 51,
    WebshopPackingSlip = 52,
    WebshopReturnRequest = 53,
    ShippingLabel = 60,
}