import { BaseAPI } from 'api';
import { IFilterResultInfo } from 'interfaces/models';
import { IOrderStatisticsViewModel } from 'interfaces/viewmodels';

export class StatisticAPI {

    public static getOrderStatistics = (subOrderDocumentTypeId: number, onSuccess: (data: IOrderStatisticsViewModel) => void) => {
        BaseAPI.get(`Api/Sls/Statistics/GetOrderStatistics/${subOrderDocumentTypeId}`, null, onSuccess);
    }

    public static getProductStockResaleItemFilterContext = (isB2c: boolean, onSuccess: (data: any) => void) => {
        
        BaseAPI.get(`Api/Sls/Statistics/GetProductStockResaleItemFilterContext/${isB2c}`, null,
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    OrderByRules: [
                        { FieldName: "OrderedQuantity", IsDescending: true },
                    ],
                    PageSize: 50
                }
            }));
    }

    public static filterProductStockResaleItems = (filterContext: any, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/Sls/Statistics/FilterProductStockResaleItems", filterContext, onSuccess);
    }

    public static createProductStockResaleQuantitiesReportAndGetUrl = (filterContext: any, onSuccess: (url: string) => void) => {
        BaseAPI.post(`Api/Sls/Statistics/CreateProductStockResaleQuantitiesReportAndGetUrl`, filterContext, 
            (data) => { onSuccess(data + `?t=${Date.now()}`)});
    }

}