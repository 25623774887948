import {
    UserSettingsTypes,
    IUserSettingMap,
    ISetUserSettingAction,
    ISetUserSettingsAction,
    IUserSettingsState
} from './types'
import { SET_USER_SETTINGS, SET_USER_SETTING } from './actions'
import { IUserSetting } from 'interfaces/models';

const initialState: IUserSettingsState = {
    userSettingMap: {},
}

export const userSettingsReducer = (state = initialState, action: UserSettingsTypes): IUserSettingsState => {
    let _action;
    switch (action.type) {
        case SET_USER_SETTINGS:
            _action = (action as ISetUserSettingsAction);

            let map: IUserSettingMap = {};
            _action.userSettings.map((item: IUserSetting) => {
                map[String(item.MenuId) + '_' + item.Name.toLowerCase()] = item
            });

            return {
                userSettingMap: map
            }

        case SET_USER_SETTING:

            _action = (action as ISetUserSettingAction);
            const _userSettingMap = state.userSettingMap;
            _userSettingMap[String(_action.userSetting.MenuId) + '_' + _action.userSetting.Name.toLowerCase()] = _action.userSetting;

            return {
                userSettingMap: _userSettingMap
            }

        default:
            return state
    }
}