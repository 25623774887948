import { FilterResultInfoTypes } from './types';
import { IFilterResultInfo } from 'interfaces/models';
export const SET_RELATION_FILTER_RESULT_INFO = "SET_RELATION_FILTER_RESULT_INFO";

export const setRelationFilterResultInfo = (filterResultInfo: IFilterResultInfo, id?: number | string): FilterResultInfoTypes => {
    return {
        type: SET_RELATION_FILTER_RESULT_INFO,
        filterInfoResult: filterResultInfo,
        id: id
    }
}