import React, { useState, useEffect } from 'react';
import { SearchBox as FabricSearchBox, TextField } from 'office-ui-fabric-react';
import { ISearchBoxProps } from '.'

export const SearchBox = (props: ISearchBoxProps): JSX.Element => {

    const { name, autoFocus, required, placeholder, underlined, onChange, readOnly } = props;
    const [value, setValue] = useState<string | undefined>(props.value);
    const [onChangeTimeOut, setOnChangeTimeOut] = useState<any>();

    const clearSearchTimeOut = () => {
        if (onChangeTimeOut !== undefined) {
            clearTimeout(onChangeTimeOut);
            setOnChangeTimeOut(undefined);
        }
    }

    const _onChange = () => {
        if (onChange) {
            if (!props.disableDelay) {
                clearSearchTimeOut();
                setOnChangeTimeOut(setTimeout(function () {
                    onChange(value, name);
                }, 750));
            } else {
                onChange(value, name);
            }
        }
    }
    
    const onTextFieldChange = (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event) {
            event.persist();
            const target = event.target as HTMLFormElement;
            setValue(target.value);
        }
    }

    const onSearchBoxChanged = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
        if (event) {
            event.persist();
            const target = event.target as HTMLInputElement;
            setValue(target.value);

        }
    }

    const onSearch = () => {
        clearSearchTimeOut();
        onChange && onChange(value, name);
    }

    const onClear = () => {
        onChange && onChange("", name);
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            onSearch();
        }
    }

    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value);
        }

    }, [props.value])

    useEffect(() => {
        if (value !== props.value) {
            _onChange();
        }
    }, [value])

    if (props.renderAsTextField) {
        return (<TextField
            required={required}
            autoComplete="off"
            autoFocus={autoFocus}
            name={name}
            value={value}
            placeholder={placeholder}
            errorMessage={props.textFieldErrorMessage}
            readOnly={readOnly}
            styles={props.textFieldStyles}
            onKeyPress={onKeyPress}
            onChange={onTextFieldChange}
        />);
    }

    return (
        <>
            <FabricSearchBox
                required={required}
                autoComplete="off"
                autoFocus={autoFocus}
                underlined={underlined}
                onClear={onClear}
                name={name}
                value={value}
                placeholder={placeholder}
                readOnly={readOnly}
                iconProps={{ iconName: props.iconName ? props.iconName : 'Search' }}
                styles={props.searchBoxStyles}
                onSearch={onSearch}
                onChange={onSearchBoxChanged}
            />
        </>
    );
}