import React from 'react';
import { mergeStyleSets, getTheme, FontSizes, DefaultButton, CommandBarButton, IButtonStyles, Icon, IIconStyles, FontWeights, IStyle } from 'office-ui-fabric-react';
import { IStepPivotItem, IStepPivotProps } from './interfaces';

export const StepPivot = (props: IStepPivotProps) => {

    const { items, activeStepIndex, onClick } = props;
    const theme = getTheme();
    const classNames = mergeStyleSets({
        ul: {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            overFlow: "hidden",
            backgroundColor: theme.palette.neutralLighterAlt,
        },
        li: {
            float: "left",
            lineHeight: "38px",
            height: "38px"
        },

    });

    const activeButtonLabelStyle: IStyle = {
        fontWeight: "600",
        color: theme.semanticColors.bodyText
    }

    const buttonStyles: IButtonStyles = {
        root: {
            padding: "10px 5px",
            fontSize: FontSizes.large,
            color: theme.semanticColors.bodySubtext
        },
    }

    const chevronIconStyles: IIconStyles = {
        root: {
            fontSize: FontSizes.medium,
            color: theme.semanticColors.disabledText
        }
    }

    return (
        <ul className={classNames.ul}>
            {items.map((v, i) => {
                return (
                    <React.Fragment key={"stepPivotItem" + String(i)}>
                        <li className={classNames.li}>
                            <CommandBarButton
                                disabled={v.disabled}
                                styles={{
                                    ...buttonStyles,
                                    label: i === activeStepIndex && activeButtonLabelStyle
                                }}
                                iconProps={v.iconProps}
                                text={v.text}
                                href={v.href}
                                onClick={() => (onClick && !v.href) && onClick(i)} />
                        </li>
                        {(i + 1) < items.length && (
                            <li className={classNames.li}>
                                <Icon iconName="ChevronRight" styles={chevronIconStyles} />
                            </li>
                        )}
                    </React.Fragment>
                )
            })}
        </ul>
    );
};