import React, { useEffect, useState } from 'react';
import { Stack, StackItem, Icon, Text, FontSizes, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { IPendingOrderConfirmationStepProps } from '.';
import { DictionaryAPI, DocumentAPI } from 'api';
import { ICreateOrderReportEmailNotificationViewModel, IPendingOrderConfirmedViewModel } from 'interfaces/viewmodels';
import { Loader } from 'components/common';
import { OrderReports, Profiles } from 'enums';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CreateReportEmailNotificationDialog } from 'components/common/CreateReportEmailNotificationDialog';

export const PendingOrderConfirmed = (props: IPendingOrderConfirmationStepProps) => {

    const { documentRowGuid } = props;
    const [viewModel, setViewModel] = useState<IPendingOrderConfirmedViewModel>();
    const [emailNotificationOrderReport, setEmailNotificationOrderReport] = useState<OrderReports>();

    const profileId = useSelector((state: RootState) => state.authenticationState.account.Privilege.ProfileId);

    useEffect(() => {
        DocumentAPI.loadPendingOrdersState(
            () => DocumentAPI.getPendingOrderConfirmed(documentRowGuid, (data) => setViewModel(data)));
    }, [documentRowGuid])

    const buttonStyle: React.CSSProperties = {
        width: "100%"
    }

    return viewModel ? (
        <>
            {
                [Profiles.DealerAccessories, Profiles.DealerFashion, Profiles.DealerShoes].includes(profileId) ? (
                    <Stack
                        verticalFill
                        horizontalAlign="center"
                        verticalAlign="center"
                        style={{ textAlign: "center" }}
                    >
                        <StackItem>
                            <h1>{DictionaryAPI.getTranslation("ThankYou")}</h1>
                            <h2>{DictionaryAPI.getTranslation("YourOrderReferenceIs", [`#${String(viewModel.DocumentReference)}`])}</h2>
                        </StackItem>
                        <StackItem>
                            <Text styles={{ root: { fontSize: FontSizes.mediumPlus } }}>
                                <span dangerouslySetInnerHTML={{ __html: DictionaryAPI.getTranslation("txtPendingOrderConfirmed") }} />
                            </Text>
                        </StackItem>
                        <StackItem>
                            <Text styles={{ root: { fontSize: FontSizes.mediumPlus } }}>
                                <span dangerouslySetInnerHTML={{ __html: DictionaryAPI.getTranslation("txtSupportInformation") }} />
                            </Text>
                        </StackItem>
                    </Stack>
                ) : (
                        <Stack
                            verticalFill
                            horizontalAlign="center"
                            verticalAlign="center"
                            style={{ textAlign: "center" }}>
                            <StackItem className="ms-motion-slideUpIn">
                                <Stack style={{ maxWidth: 600 }} tokens={{ childrenGap: 10 }}>
                                    <StackItem>
                                        <h1>{DictionaryAPI.getTranslation("OrderConfirmed")}</h1>
                                        <h2>{String(viewModel.DocumentReference)}</h2>
                                    </StackItem>
                                    <StackItem>
                                        <DefaultButton
                                            style={buttonStyle}
                                            text={DictionaryAPI.getTranslation("SendOrderInputByEmail")}
                                            onClick={() => setEmailNotificationOrderReport(OrderReports.Input)}
                                        />
                                    </StackItem>
                                    <StackItem>
                                        <DefaultButton
                                            style={buttonStyle}
                                            text={DictionaryAPI.getTranslation("SendOrderSelectionByEmail")}
                                            onClick={() => setEmailNotificationOrderReport(OrderReports.Selection)}
                                        />
                                    </StackItem>
                                </Stack>
                            </StackItem>
                        </Stack>
                    )
            }
            {
                emailNotificationOrderReport && (
                    <CreateReportEmailNotificationDialog<ICreateOrderReportEmailNotificationViewModel>
                        isCreate
                        documentRowGuid={documentRowGuid}
                        report={emailNotificationOrderReport}
                        reportTitle={OrderReports[emailNotificationOrderReport]}
                        getCreateReportEmailNotification={DocumentAPI.getCreateOrderReportEmailNotification}
                        createOrderReportEmailNotification={DocumentAPI.createOrderReportEmailNotification}
                        onDismiss={() => setEmailNotificationOrderReport(undefined)}
                    />
                )
            }
        </>


    ) : <Loader />
};