import React, { useState } from 'react';
import { Stack, StackItem, TextField, ScrollablePane, Label, IComboBoxOption, Checkbox } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { RelationTypes, OrderSubDocumentTypes, Profiles } from 'enums';
import { OrderViewModelFields, DocumentBaseViewModelFields } from 'enums/fields';
import { IRelationListItem } from 'interfaces/models';
import { DatePicker, SpinButton, ComboBox, RelationSelector, ColumnsStack } from 'components/common';
import { DocumentStatuses } from 'enums/lgs/DocumentStatuses';
import { IOrderTabBaseProps } from './interfaces';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const InformationTab = (props: IOrderTabBaseProps) => {

    const { form, documentSummary, readOnly, cultureId, } = props;
    const { item, updateItem, onTextFieldChange, onUnControlledTextFieldChange, onComboBoxSimpleChange, onCheckboxChange, onDatePickerSelectDate, onSpinButtonChange, getErrorMessage } = form;

    const [deliveryAddressNotApplicable, setDeliveryAddressNotApplicable] = useState<boolean>(false);

    const accountProfileId = useSelector((state: RootState) => state.authenticationState.account.Privilege.ProfileId)

    const {
        FROM_RELATION_ROW_GUID,
        CONTACT_RELATION_ROW_GUID,
        TO_RELATION_ROW_GUID,
        AGENT_RELATION_ROW_GUID,
        EXTERNAL_REFERENCE,
        REMARKS,
        INTERNAL_REMARKS
    } = DocumentBaseViewModelFields

    const {
        CUSTOMER_DISCOUNT,
        PRESALES_DISCOUNT,
        CONFIRMATION_SENT,
        CONFIRMATION_SENT_ON,
        SIGNED,
        SIGNED_ON,
         } = OrderViewModelFields

    return (
        <ScrollablePane>
            <Stack tokens={{ padding: 12, maxWidth: 800 }}>
                <ColumnsStack gap={12}>
                    <RelationSelector
                        readOnly={readOnly || item.SubDocumentTypeId === OrderSubDocumentTypes.Company}
                        labelText={DictionaryAPI.getTranslation("InvoiceAddress")}
                        relationTypeIds={[RelationTypes.Customer]}
                        rowGuid={item.RelationRowGuid}
                        defaultRelation={item.Relation}
                        newNotAllowed={true} />

                    <RelationSelector
                        readOnly={readOnly || deliveryAddressNotApplicable}
                        labelText={DictionaryAPI.getTranslation("DeliveryAddress")}
                        rowGuidFieldName={TO_RELATION_ROW_GUID}
                        companyRelationId={item.CompanyRelationId}
                        parentRelationId={item.Relation?.Id}
                        parentContactId={item.Relation?.ContactId}
                        relationTypeIds={[RelationTypes.DeliveryAddress]}
                        defaultRelation={item.ToRelation}
                        rowGuid={item.ToRelationRowGuid}
                        errorMessage={getErrorMessage(TO_RELATION_ROW_GUID)}
                        onChange={updateItem}
                        getRelationListItemsCallback={(items) => setDeliveryAddressNotApplicable(items?.length === 0)}
                    />
                </ColumnsStack>
                {
                    (item.SubDocumentTypeId === OrderSubDocumentTypes.Sales || item.SubDocumentTypeId === OrderSubDocumentTypes.Presales) && (
                        <>
                            <ColumnsStack gap={12}>
                                <RelationSelector
                                    readOnly={readOnly}
                                    labelText={DictionaryAPI.getTranslation("Contact")}
                                    rowGuidFieldName={CONTACT_RELATION_ROW_GUID}
                                    companyRelationId={item.CompanyRelationId}
                                    parentContactId={item.Relation?.ContactId}
                                    parentRelationId={item.Relation?.Id}
                                    relationTypeIds={[RelationTypes.Contact]}
                                    rowGuid={item.ContactRelationRowGuid}
                                    defaultRelation={item.ContactRelation}
                                    onChange={updateItem} />
                                <ComboBox
                                    label={DictionaryAPI.getTranslation("Agent")}
                                    allowFreeform
                                    autoComplete="on"
                                    selectedKey={item.AgentRelationRowGuid}
                                    name={AGENT_RELATION_ROW_GUID}
                                    disabled={!(accountProfileId === Profiles.Administrator || accountProfileId === Profiles.SalesCountryManager)}
                                    options={item.Agents?.map((v: IRelationListItem, i): IComboBoxOption => {
                                        return {
                                            key: v.RowGuid,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </ColumnsStack>
                            <ColumnsStack gap={12}>
                                <Stack tokens={{ childrenGap: 8 }}>
                                    <StackItem>
                                        <TextField
                                            value={item.ExternalReference}
                                            label={DictionaryAPI.getTranslation("ExternalReference")}
                                            name={EXTERNAL_REFERENCE}
                                            onChange={onTextFieldChange}
                                        />
                                    </StackItem>
                                </Stack>
                                <Stack style={{ paddingTop: 15 }} tokens={{ childrenGap: 8 }}>
                                    <ColumnsStack>
                                        <Checkbox
                                            disabled={item.DocumentStatusId !== DocumentStatuses.Processing}
                                            label={DictionaryAPI.getTranslation("Signed")}
                                            name={SIGNED}
                                            checked={item.Signed}
                                            onChange={onCheckboxChange}
                                        />
                                        <DatePicker
                                            cultureId={cultureId}
                                            disabled={item.DocumentStatusId !== DocumentStatuses.Processing || !item.Signed}
                                            name={SIGNED_ON}
                                            date={item.SignedOn}
                                            onSelectDate={onDatePickerSelectDate}
                                        />
                                    </ColumnsStack>
                                    <ColumnsStack>
                                        <Checkbox
                                            disabled={item.DocumentStatusId !== DocumentStatuses.Processing}
                                            label={DictionaryAPI.getTranslation("ConfirmationSent")}
                                            name={CONFIRMATION_SENT}
                                            checked={item.ConfirmationSent}
                                            onChange={onCheckboxChange}
                                        />
                                        <DatePicker
                                            cultureId={cultureId}
                                            disabled={item.DocumentStatusId !== DocumentStatuses.Processing || !item.ConfirmationSent}
                                            name={CONFIRMATION_SENT_ON}
                                            date={item.ConfirmationSentOn}
                                            onSelectDate={onDatePickerSelectDate}
                                        />
                                    </ColumnsStack>
                                </Stack>
                            </ColumnsStack>
                            {
                                accountProfileId === Profiles.Administrator && (
                                    <ColumnsStack gap={12}>
                                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                                            <StackItem grow>
                                                <Label>{DictionaryAPI.getTranslation("CustomerDiscount")}</Label>
                                                <SpinButton
                                                    isPercentage
                                                    value={item.CustomerDiscount}
                                                    name={CUSTOMER_DISCOUNT}
                                                    step={0.01}
                                                    min={0}
                                                    max={1}
                                                    minimumFractionDigits={4}
                                                    disabled={readOnly}
                                                    locale={cultureId}
                                                    onValueChange={onSpinButtonChange} />
                                            </StackItem>
                                            <StackItem grow>
                                                <Label>{DictionaryAPI.getTranslation("PresalesDiscount")}</Label>
                                                <SpinButton
                                                    isPercentage
                                                    value={item.PresalesDiscount}
                                                    name={PRESALES_DISCOUNT}
                                                    step={0.01}
                                                    min={0}
                                                    max={1}
                                                    minimumFractionDigits={4}
                                                    disabled={readOnly}
                                                    locale={cultureId}
                                                    onValueChange={onSpinButtonChange} />
                                            </StackItem>
                                        </Stack>
                                        <></>
                                    </ColumnsStack>
                                )
                            }
                        </>



                    )
                }


                <ColumnsStack gap={12}>
                    <TextField
                        multiline
                        defaultValue={item.InternalRemarks}
                        name={INTERNAL_REMARKS}
                        label={DictionaryAPI.getTranslation(INTERNAL_REMARKS)}
                        style={{ minHeight: 100 }}
                        onChange={onUnControlledTextFieldChange} />
                    <TextField
                        multiline
                        defaultValue={item.Remarks}
                        name={REMARKS}
                        label={DictionaryAPI.getTranslation(REMARKS)}
                        style={{ minHeight: 100 }}
                        onChange={onUnControlledTextFieldChange} />
                </ColumnsStack>
            </Stack>
        </ScrollablePane>

    );
};

