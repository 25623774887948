import React, { useEffect } from 'react';
import {
    Stack, StackItem,
    TextField,
    ScrollablePane,
} from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { ICatalogGroupBaseTabProps } from './interfaces';
import { CatalogGroupViewModelFields } from 'enums/fields';

export const Information = (props: ICatalogGroupBaseTabProps) => {

    const { form } = props;
    const { item: catalogGroup, onTextFieldChange, getErrorMessage } = form;

    const { CODE, NAME, } = CatalogGroupViewModelFields;

    return catalogGroup ? (
        <ScrollablePane>
            <div className="ms-Grid" dir="ltr" style={{ margin: 10, maxWidth: 800 }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-lg6">
                        <Stack>
                            <StackItem grow>
                                <TextField
                                    required
                                    autoFocus
                                    value={catalogGroup.Name}
                                    name={NAME}
                                    label={DictionaryAPI.getTranslation(NAME)}
                                    errorMessage={getErrorMessage(NAME)}
                                    onChange={onTextFieldChange}
                                />
                            </StackItem>
                            <StackItem styles={{ root: { width: "50%" } }}>
                                <TextField
                                    value={catalogGroup.Code}
                                    name={CODE}
                                    label={DictionaryAPI.getTranslation(CODE)}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                        </Stack>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg6">
                    </div>
                </div>
            </div>
        </ScrollablePane>
    ) : null;
}