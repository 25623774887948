export enum ProductListItemFields {
    B2B_PRICE = "B2bPrice",
    B2C_PRICE = "B2cPrice",
    BRAND_ID = "BrandId",
    CLOSING_ID = "ClosingId",
    CODE = "Code",
    COLLECTIONCODE = "CollectionCode",
    COLLECTION_ID = "CollectionId",
    COLOUR_ID = "ColourId",
    COLOUR_PARENT_ID = "ColourParentId",
    DESCRIPTION = "Description",
    DISPLAY_ORDER = "DisplayOrder",
    CATALOG_DETAIL_DISPLAY_ORDER = "CatalogDetailDisplayOrder",
    GENDER_ID = "GenderId",
    ID = "Id",
    IMAGE = "Image",
    IS_ACTIVE = "IsActive",
    MAXIMUM_SIZE_CODE = "MaximumSizeCode",
    MINIMUM_SIZE_CODE = "MinimumSizeCode",
    NAME = "Name",
    PARENT_ID = "ParentId",
    PRESALES_ALLOWED = "PresalesAllowed",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    SALES_ALLOWED = "SalesAllowed",
    SAMPLE_PRODUCT_SIZE_ID = "SampleProductSizeId",
    SAMPLE_PRODUCT_SIZE_NAME = "SampleProductSizeName",
    SMALL_IMAGE = "SmallImage",
    SOLD_LINES = "SoldLines",
    SOLD_QUANTITIES = "SoldQuantities",
    STYLE_ID = "StyleId",
    STYLE_NAME = "StyleName",
    SUB_PRODUCT_CATEGORY_DISPLAY_ORDER = "SubProductCategoryDisplayOrder",
    SUB_PRODUCT_CATEGORY_ID = "SubProductCategoryId",
    SUB_PRODUCT_CATEGORY_NAME = "SubProductCategoryName",
    VARIANT_DESCRIPTION = "VariantDescription",
    ASSOCIATED_PRODUCTS = "AssociatedProducts"
}