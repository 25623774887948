import React, { useEffect, useState } from 'react';
import { Dialog, ComboBox, RelationSelector } from 'components/common';
import { DictionaryAPI, DocumentAPI } from 'api';
import { ICreateOrderProps } from './interfaces';
import { useForm, useGlobals } from 'hooks';
import { Checkbox, MessageBar, MessageBarType, Stack, StackItem } from 'office-ui-fabric-react';
import { RelationTypes, OrderSubDocumentTypes, DocumentStatuses, DocumentTypes } from 'enums';
import { CreateOrderViewModelFields } from 'enums/fields'
import { ICreateOrderViewModel } from 'interfaces/viewmodels';
import { IAccount } from 'interfaces/models';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const CreateOrder = (props: ICreateOrderProps) => {

    const { subDocumentTypeId, onAccept, onCancel } = props;

    const { companyRelationId } = useGlobals();
    const [customerRelationId, setCustomerRelationId] = useState<number>(subDocumentTypeId === OrderSubDocumentTypes.Company ? companyRelationId : undefined);
    const [loading, setLoading] = useState(false);
    const [orderExists, setOrderExists] = useState(false);
    const [orderExistsConfirmed, setOrderExistsConfirmed] = useState(false);
    //const [deliveryAddressNotApplicable, setDeliveryAddressNotApplicable] = useState<boolean>(false);

    const {
        submit,
        initialize,
        item,
        updateItem,
        onComboBoxSimpleChange,
        getErrorMessage } = useForm<ICreateOrderViewModel>(undefined, null, DocumentAPI.createOrder);

    const { CUSTOMER_RELATION_ROW_GUID, DELIVERY_ADDRESS_RELATION_ROW_GUID, COLLECTION_ID, WAREHOUSE_RELATION_ID } = CreateOrderViewModelFields;


    const account = useSelector<RootState, IAccount>(s => s.authenticationState.account);

    /*     const getDeliveryAddressRelationListItemsCallback = (items?: IRelationListItem[]) => {
    
            if (items?.length === 0) {
                setDeliveryAddressNotApplicable(true);
                updateItem(DELIVERY_ADDRESS_RELATION_ROW_GUID, item.CustomerRelationRowGuid);
            } else {
                setDeliveryAddressNotApplicable(false);
            }
        } */

    const _onAccept = () => {
        submit((data: string) => onAccept(data))
    }

    useEffect(() => {
        DocumentAPI.getCreateOrder(subDocumentTypeId, account.Privilege.CompanyRelationId, (data) => initialize(true, data));
    }, [subDocumentTypeId])

    useEffect(() => {
        if (item?.CustomerRelationRowGuid && item?.CollectionId) {
            setLoading(true);
            DocumentAPI.readDocuments(item.CustomerRelationRowGuid, DocumentTypes.Order, 0, item.CollectionId, false, 0,
                (data) => {
                    setOrderExists(data.filter(d => [DocumentStatuses.Pending, DocumentStatuses.Processing].includes(d.DocumentTypeId)).length > 0);
                    setOrderExistsConfirmed(false);
                    setLoading(false);
                });
        }
    }, [item?.CustomerRelationRowGuid, item?.CollectionId])

    return (
        <>
            <Dialog
                title={DictionaryAPI.getTranslation(`New${OrderSubDocumentTypes[subDocumentTypeId]}Order`)}
                maxWidth={450}
                isLoading={!item}
                acceptButtonDisabled={loading || (orderExists && !orderExistsConfirmed)}
                cancelButtonDisabled={loading}
                onAccept={_onAccept}
                onCancel={onCancel}
            >
                {
                    item && (
                        <Stack tokens={{ childrenGap: 5 }} style={{ width: 325 }}>
                            {
                                <StackItem>
                                    <RelationSelector
                                        required
                                        searchEnabled
                                        rowGuidFieldName={CUSTOMER_RELATION_ROW_GUID}
                                        rowGuid={item.CustomerRelationRowGuid}
                                        companyRelationId={account.Privilege.CompanyRelationId}
                                        relationTypeIds={[RelationTypes.Customer, RelationTypes.Prospect]}
                                        labelText={DictionaryAPI.getTranslation("CustomerOrProspect")}
                                        errorMessage={getErrorMessage(CUSTOMER_RELATION_ROW_GUID)}
                                        onChange={(rowGuidFieldName, rowGuid) => updateItem(rowGuidFieldName, rowGuid)}
                                        getRelationCallback={(relation) => setCustomerRelationId(relation?.Id)}
                                    />
                                </StackItem>

                            }

                            <StackItem>
                                <RelationSelector
                                    required
                                    rowGuidFieldName={DELIVERY_ADDRESS_RELATION_ROW_GUID}
                                    rowGuid={item.DeliveryAddressRelationRowGuid}
                                    parentRelationId={customerRelationId}
                                    relationTypeIds={[RelationTypes.DeliveryAddress]}
                                    labelText={DictionaryAPI.getTranslation("DeliveryAddress")}
                                    disabled={!customerRelationId}
                                    errorMessage={getErrorMessage(DELIVERY_ADDRESS_RELATION_ROW_GUID)}
                                    onChange={(rowGuidFieldName, rowGuid) => updateItem(rowGuidFieldName, rowGuid)}
                                //getRelationListItemsCallback={getDeliveryAddressRelationListItemsCallback}
                                />
                            </StackItem>
                            {
                                item.Collections?.length > 0 && (
                                    <StackItem>
                                        <ComboBox
                                            allowFreeform
                                            required={item.CollectionIdIsRequired}
                                            label={DictionaryAPI.getTranslation("Collection")}
                                            autoComplete="on"
                                            selectedKey={item.CollectionId}
                                            name={COLLECTION_ID}
                                            options={item.Collections.map((v) => {
                                                return {
                                                    key: v.Id,
                                                    text: v.Name,
                                                }
                                            })}
                                            onSimpleChange={onComboBoxSimpleChange}
                                            errorMessage={getErrorMessage(COLLECTION_ID)} />
                                    </StackItem>
                                )
                            }
                            {
                                item.Warehouses?.length > 0 && (
                                    <StackItem>
                                        <ComboBox
                                            allowFreeform
                                            required={item.WarehouseRelationIdIsRequired}
                                            label={DictionaryAPI.getTranslation("Warehouse")}
                                            autoComplete="on"
                                            selectedKey={item.WarehouseRelationId}
                                            name={WAREHOUSE_RELATION_ID}
                                            options={item.Warehouses.map((v) => {
                                                return {
                                                    key: v.ContactId,
                                                    text: v.Name,
                                                }
                                            })}
                                            onSimpleChange={onComboBoxSimpleChange}
                                            errorMessage={getErrorMessage(WAREHOUSE_RELATION_ID)} />
                                    </StackItem>
                                )
                            }
                            {
                                orderExists && (
                                    <>
                                        <StackItem>
                                            <MessageBar messageBarType={MessageBarType.warning}>
                                            {DictionaryAPI.getTranslation("ExistingOrderWarningText")}
                                            </MessageBar>
                                        </StackItem>
                                        <StackItem>
                                            <Checkbox 
                                                label={DictionaryAPI.getTranslation("ConfirmExistingOrder")} 
                                                checked={orderExistsConfirmed}
                                                onChange={() => setOrderExistsConfirmed((v) => !v)}
                                            />
                                        </StackItem>
                                    </>
                                )
                            }
                        </Stack>
                    )
                }
            </Dialog>
        </>
    );
};
