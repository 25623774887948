export enum ProductSizeRangeFields {
    B2B_PRICE = "B2bPrice",
    B2C_PRICE = "B2cPrice",
    MAXIMUM_CODE = "MaximumCode",
    MAXIMUM_ID = "MaximumId",
    MAXIMUM_NAME = "MaximumName",
    MINIMUM_CODE = "MinimumCode",
    MINIMUM_ID = "MinimumId",
    MINIMUM_NAME = "MinimumName",
    PRODUCT_ID = "ProductId"
}