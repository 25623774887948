import React, { useState, useEffect, useMemo } from 'react';
import { IFilterBarItemProps, Loader, LoaderModal, ShareAttachmentWindow } from 'components/common';
import { DefaultCommandTypes, LeftNavigationMenus } from 'enums';
import { DictionaryAPI, MenuAPI, StatisticAPI } from 'api';
import { IFilter, IRangeFilter } from 'interfaces/models';
import { DayOfWeek, DefaultPalette, IColumn, IContextualMenuItem, IImageStyleProps, IImageStyles, Image, ImageFit, mergeStyleSets } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, DateRangeFilter } from 'components/common';
import { useFilterContextHandler, useUserSetting, useFileStoreUrl, useNumberFormat } from 'hooks';
import { ListView } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { imgError } from 'images';

const classNames = mergeStyleSets({
    numericColumn: {
        width: "100%",
        textAlign: "right"
    },
    sizeColumn: {
        textAlign: "center"
    },
    tileDescription: {
        color: DefaultPalette.neutralSecondary,
        fontWeight: "600",
        marginLeft: 5,
        marginRight: 5,
        whiteSpace: "noWrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    tileTotals: {
        color: DefaultPalette.neutralSecondary,
    }
});

const getProductImageStyles = (props: IImageStyleProps): Partial<IImageStyles> => {
    return {
        root: props.isError && {
            background: `url("${imgError}") no-repeat center`,
            backgroundSize: "contain"
        }
    }
}

export const ProductStockResale = () => {

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);
    const menu = MenuAPI.find(LeftNavigationMenus.Sales_StockResale);
    const userSettingHook = useUserSetting(menu.Id);
    const { getImageUrl } = useFileStoreUrl();
    const { getNumber, getPrice, getPercent } = useNumberFormat(cultureId);
    const [loading, setLoading] = useState(false);
    const [shareOrderReportSrc, setShareOrderReportSrc] = useState<string>();

    const filterContextHandler = useFilterContextHandler<any>(
        (onSuccess) => {
            StatisticAPI.getProductStockResaleItemFilterContext(true, onSuccess);
        },
        StatisticAPI.filterProductStockResaleItems, userSettingHook);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onRangeFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterResultInfo } = filterContextHandler;

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: "Image", width: 32, name: "" },
            { fieldName: "Name", width: 200, },
            { fieldName: "VariantDescription", width: 125, name: "Variant" },
            { fieldName: "ProductSizeName", width: 70, name: "Size" },
            { fieldName: "ProductCategoryName", width: 125, name: "Category" },
            { fieldName: "SubProductCategoryName", width: 125, name: "SubCategory" },
            { fieldName: "PurchasedQuantity", width: 70, name: "Purchased" },
            { fieldName: "SoldQuantity", width: 70, name: "Sold" },
            { fieldName: "OrderedQuantity", width: 70, name: "Ordered" },
            { fieldName: "ReturnedQuantity", width: 70, name: "Returned" },
            { fieldName: "StockQuantityRotation", width: 70, name: "Rotation" },
            { fieldName: "B2bPrice", width: 70, name: "B2b" },
            { fieldName: "B2cPrice", width: 70, name: "B2c" },
        ];

        return columns;
    }

    const detailsListColumns = useMemo(() => getDetailsListColumns(), [filterContext?.FilterInfo?.OrderByRules]);

    const handleOnOrderByChange = (fieldName: string, isDescending: boolean) => {

        switch (fieldName) {
            case "Name":
            case "PurchasedQuantity":
            case "SoldQuantity":
            case "OrderedQuantity":
            case "ReturnedQuantity":
                onOrderByChange(fieldName, isDescending);
                break;

            default:
                return;
        }
    }

    const onRenderDetailsListItemColumn = (item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName && item[column.fieldName]) {

            switch (column.fieldName) {
                case "Image":
                    return (
                        <Image
                            src={getImageUrl(item.Image)}
                            height={32}
                            width={32}
                            imageFit={ImageFit.contain}
                            styles={getProductImageStyles}
                        />
                    )
                case "SoldQuantity":
                case "PurchasedQuantity":
                case "OrderedQuantity":
                case "ReturnedQuantity":
                    return <div className={classNames.numericColumn}>{getNumber(item[column.fieldName])}</div>
                case "StockQuantityRotation":
                    return <div className={classNames.numericColumn}>{getPercent(item[column.fieldName])}</div>
                case "B2cPrice":
                case "B2bPrice":
                    return <div className={classNames.numericColumn}>{getPrice(item[column.fieldName])}</div>
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (<FilterBar
                filterContextHandler={filterContextHandler}
                onRenderFilterBarItem={onRenderFilterBarItem}
            />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {

        const { filter, name } = filterBarItemProps;

        switch (name) {
            case "CreatedOn":
                return (
                    <DateRangeFilter
                        cultureId={cultureId}
                        firstDayOfWeek={DayOfWeek.Monday}
                        filter={filter as IRangeFilter}
                        name={name}
                        onChange={onRangeFilterChange} />
                )

            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }

    const handlePrintContextMenuItemOnClick = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (item) {

            setLoading(true);


            switch (item.key) {
                case "Stock_Resale_Quantities":
                    StatisticAPI.createProductStockResaleQuantitiesReportAndGetUrl(filterContext,
                        (url) => {
                            setShareOrderReportSrc(url);
                            setLoading(false)
                        });
                    break;

                default:
                    break;
            }
        }
    }

    const printContextMenuItems: IContextualMenuItem[] = [
        { key: "Stock_Resale_Quantities", text: "Stock Resale Quantities", onClick: handlePrintContextMenuItemOnClick },
        /* { key: "Stock_Resale_Financial", text: "Stock Resale Financial", onClick: handlePrintContextMenuItemOnClick } */
    ];

    useEffect(() => {
        window.location.href = "#" + menu.Path;
    }, [filterResultInfo])

    return (
        <>
            <ListView
                menu={menu}
                title={menu.translatedName}
                detailsListColumns={detailsListColumns}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                printContextMenuItems={printContextMenuItems}
                loading={!filterContext}
                dataLoading={isFiltering}
                onRefreshButtonClick={getFilterResultInfo}
                onOrderByChange={handleOnOrderByChange}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                shareOrderReportSrc && (
                    <ShareAttachmentWindow
                        title={DictionaryAPI.getTranslation("StockResale")}
                        src={shareOrderReportSrc}
                        onDismiss={() => setShareOrderReportSrc(undefined)}
                    />
                )
            }
            {
                loading && (
                    <LoaderModal />
                )
            }
        </>
    );
};