import React from 'react';
import { getTheme, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';
import { IAvatarProps } from '.';

const theme: ITheme = getTheme();

export const Avatar = (props: IAvatarProps): JSX.Element => {

    return (
        <Persona
            imageInitials={props.initials}
            imageUrl={props.imageUrl}
            initialsColor={theme.palette.themePrimary}
            size={props.isLargeImage ? PersonaSize.size48 : PersonaSize.size28}
            presence={PersonaPresence.none}
            text={props.text}
            secondaryText={props.secondaryText}
            hidePersonaDetails={props.text === undefined} />);
}