import {
    ErrorMessageTypes,
    ISetErrorMessageAction,
    IErrorMessageState
} from './types'
import { SET_ERROR_MESSAGE } from './actions'

const initialState: IErrorMessageState = {
    message: "",
}

export const errorMessageReducer = (state = initialState, action: ErrorMessageTypes): IErrorMessageState => {
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return {
                message: action.message
            }
        default:
            return state
    }
}