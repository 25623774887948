import { ApplicationTypes, IApplicationState } from './types'
import { SET_APPLICATION } from './actions'

const initialState: IApplicationState = {
    application: undefined
}

export function applicationReducer(state = initialState, action: ApplicationTypes): IApplicationState {
    switch (action.type) {
        case SET_APPLICATION:
            return {
                application: action.application
            }
        default:
            return state
    }
}