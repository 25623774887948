export enum OrderRelationViewModelFields {
    ADDRESS1 = "Address1",
    ADDRESS2 = "Address2",
    CITY = "City",
    COUNTRY_ID = "CountryId",
    EMAIL = "Email",
    MOBILE = "Mobile",
    NAME = "Name",
    PHONE = "Phone",
    POSTAL_CODE = "PostalCode",
    RELATION_TYPE_ID = "RelationTypeId",
    ROW_GUID = "RowGuid"
}