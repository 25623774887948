import { IDocumentListItem } from 'interfaces/models'
import { PendingOrdersTypes } from './types'

export const SET_ACTIVE_PENDING_ORDER_CUSTOMER = "SET_ACTIVE_PENDING_ORDER_CUSTOMER";
export const SET_PENDING_ORDERS = "SET_PENDING_ORDERS";
export const SET_PENDING_ORDERS_VISIBLE = "SET_PENDING_ORDERS_VISIBLE";
export const SET_ACTIVE_PENDING_ORDER = "SET_ACTIVE_PENDING_ORDER";

export function setActivePendingOrderCustomer(
    customerRelationRowGuid: string,
    customerRelationName: string,
    catalogId?: number): PendingOrdersTypes {
    return {
        type: SET_ACTIVE_PENDING_ORDER_CUSTOMER,
        customerRelationRowGuid: customerRelationRowGuid,
        customerRelationName: customerRelationName,
        catalogId: catalogId
    }
}

export function setPendingOrders(
    orders: IDocumentListItem[],
    isVisible?: boolean): PendingOrdersTypes {
    return {
        type: SET_PENDING_ORDERS,
        orders: orders,
        totalOrders: orders.length,
        isVisible: isVisible
    }
}

export function setPendingOrdersVisible(isVisible: boolean, disabled?: boolean): PendingOrdersTypes {
    return {
        type: SET_PENDING_ORDERS_VISIBLE,
        isVisible: isVisible,
        disabled: disabled
    }
}

export function setActivePendingOrder(documentRowGuid?: string): PendingOrdersTypes {
    return {
        type: SET_ACTIVE_PENDING_ORDER,
        documentRowGuid: documentRowGuid
    }
}