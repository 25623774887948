import React, { useState, useEffect } from 'react';
import { DefaultPalette } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { DictionaryAPI, AccountAPI } from 'api'
import { IAccountVerificationProps } from '.';
import { Loader } from 'components/common';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { AuthenticationLayout } from 'components/layouts';

export const AccountVerification = (props: IAccountVerificationProps) => {

    const { rowGuid } = props;
    const [verified, setVerified] = useState<boolean>();

    useEffect(() => {
        AccountAPI.verify(rowGuid,
            () => setVerified(true),
            () => setVerified(false));
    }, [rowGuid])

    return (
        <AuthenticationLayout title={DictionaryAPI.getTranslation("AccountVerification")}>
            <Stack
                tokens={{ childrenGap: 24 }}>
                {
                    verified === undefined ? <Loader /> : (
                        <>
                            <StackItem>
                                {
                                    verified ? (
                                        <MessageBar messageBarType={MessageBarType.success} >
                                            {DictionaryAPI.getTranslation("txtOnVerifyAccountSuccess")}
                                        </MessageBar>
                                    ) : (
                                            <MessageBar messageBarType={MessageBarType.error} >
                                                {DictionaryAPI.getTranslation("txtOnVerifyAccountError")}
                                            </MessageBar>
                                        )
                                }
                            </StackItem>
                            <StackItem align="end" styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                                <PrimaryButton onClick={() => window.location.href = "/#/sign-in"} text={DictionaryAPI.getTranslation("SignIn")} />
                            </StackItem>
                        </>
                    )
                }
            </Stack>
        </AuthenticationLayout>);
}