import React, { useEffect, useState } from 'react';
import { IAccordionItemProps } from './interfaces';
import { CommandBarButton, mergeStyleSets, IButtonStyles, getTheme, FontSizes, Stack, StackItem } from 'office-ui-fabric-react';

export const AccordionItem: React.FunctionComponent<IAccordionItemProps> = props => {

    const { header, index, isExpandedByDefault, labelStyle, children, useNeutralBackground, actionButtonProps, onClick } = props;
    const [expanded, setExpanded] = useState<boolean>(isExpandedByDefault || false);

    const theme = getTheme();
    const buttonStyles: IButtonStyles = {
        root: {
            padding: "8px 4px",
            width: "100%",
            textAlign: "left",
            backgroundColor: useNeutralBackground ? theme.palette.neutralLighter : "transparent"
        },
        rootHovered: { backgroundColor: useNeutralBackground ? theme.palette.neutralLight : undefined },
        rootPressed: { backgroundColor: useNeutralBackground ? theme.palette.neutralQuaternaryAlt : undefined },
        label: labelStyle
    }



    useEffect(() => {
        onClick && onClick(expanded, index)
    }, [expanded])

    return (
        <Stack>
            <Stack horizontal>
                <StackItem grow>
                    <CommandBarButton
                        styles={buttonStyles}
                        text={header}
                        iconProps={{
                            iconName: expanded ? "ChevronDown" : "ChevronRight",
                            style: {
                                fontSize: FontSizes.small,
                                color: theme.semanticColors.bodySubtext
                            }
                        }}
                        onClick={() => setExpanded(!expanded)}
                    />
                </StackItem>
                {
                    actionButtonProps && (
                        <StackItem>
                            <CommandBarButton {...actionButtonProps}
                                styles={buttonStyles}
                            />
                        </StackItem>
                    )
                }

            </Stack>
            {
                expanded && (
                    <StackItem
                        className="ms-motion-slideDownIn"
                        styles={{ root: { margin: "10px 0" } }}
                    >
                        {children}
                    </StackItem>
                )
            }
        </Stack>

    );
};