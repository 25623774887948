import { DictionaryAPI, MenuAPI } from 'api';
import { DocumentDetailStatusSpan, ProductDocumentDetailSizes, SpinButton } from 'components/common';
import { DocumentDetailStatuses } from 'enums';
import { DocumentFormDetailsTabInteractionMenus } from 'enums/app/DocumentFormDetailsTabInteractionMenus';
import { ProductDocumentDetailViewModelFields } from 'enums/fields';
import { useArithmetic, useDates, useNumberFormat } from 'hooks';
import { Checkbox, DefaultButton, FontSizes, getTheme, Icon, IconButton, IContextualMenuItem, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { IDocumentDetailQuantity, IProductDocumentDetailsGroupProps } from './interfaces';

const MAX_AVAILABLE_QUANTITY = 999;
const { QUANTITY } = ProductDocumentDetailViewModelFields;

const getDetailsIndex = (spinButtonName: string): number => {
    const indexes = spinButtonName.replace(QUANTITY + "_", "").split("|");
    return Number(indexes[1]);
}

export const ProductDocumentDetailsGroup = (props: IProductDocumentDetailsGroupProps) => {

    const {
        viewModel,
        lastOrderedProductDocumentDetails,
        index,
        documentProductSizes,
        onDetailsQuantitiesChange,
        onColliCheckedChange,
        onSuspend,
        onDelete,
        updateOrderDisabled,
        cultureId,
        currencyId,
    } = props;

    const [fillQuantity, setFillQuantity] = useState<number>(1);
    const [isSetSizeRange, setIsSetSizeRange] = useState<boolean>(!viewModel.WarehouseRelationId);
    const [sizeRange, setSizeRange] = useState<number[]>([]);
    const { getSum } = useArithmetic();
    const { getNumber, getPrice } = useNumberFormat(cultureId);
    const { dateToLocaleDateString } = useDates(cultureId);
    const theme = getTheme();
    const suspendInteractionMenu = MenuAPI.find(DocumentFormDetailsTabInteractionMenus.Status_Suspend);

    const getContextMenu = (): IContextualMenuItem[] => {
        const items: IContextualMenuItem[] = [
            {
                key: "remove",
                iconProps: { iconName: "Delete" },
                text: DictionaryAPI.getTranslation("Remove"),
                onClick: () => onDelete(viewModel.GroupKey)
            }
        ];

        if (suspendInteractionMenu) {
            items.push({
                key: "suspend",
                iconProps: { iconName: "SurveyQuestions" },
                text: DictionaryAPI.getTranslation("Suspend"),
                disabled: viewModel.DocumentDetailStatusId === DocumentDetailStatuses.Suspended,
                onClick: () => onSuspend(viewModel.GroupKey)
            })
        }
            
        return items;
    }

    const onRenderQuantity = (quantity: number, availableQuantity: number, detailIndex: number): React.ReactNode => {
        const isInQuantityRange = (sizeRange.length === 2 && detailIndex >= sizeRange[0] && detailIndex <= sizeRange[1]);

        if (availableQuantity !== 0) {
            return (
                <SpinButton
                    value={quantity}
                    name={`${QUANTITY}_${index}|${detailIndex}`}
                    step={1}
                    max={availableQuantity !== -1 ? availableQuantity : MAX_AVAILABLE_QUANTITY}
                    onFocus={() => onSizeQuantitySpinButtonFocus(detailIndex)}
                    locale={cultureId}
                    onValueChange={onQuantitySpinButtonChange}

                    styles={{
                        root: { minWidth: 0, width: "100%", border: "none" },
                        spinButtonWrapper: { minWidth: 0, width: "100%", border: "none" },
                        input: {
                            minWidth: 0,
                            width: "100%",
                            padding: 0,
                            fontSize: FontSizes.small,
                            textAlign: "center",
                            fontWeight: isInQuantityRange ? "700" : "600",
                            cursor: isSetSizeRange && "pointer",
                            backgroundColor: isInQuantityRange && theme.palette.themeLight
                        },
                        arrowButtonsContainer: { display: "none" },
                        arrowButtonsContainerDisabled: { display: "none" }
                    }}
                />
            )
        }
    }

    const onQuantitySpinButtonChange = (value?: number | undefined, name?: string | undefined) => {
        if (name) {
            onDetailsQuantitiesChange(index, [
                { index: getDetailsIndex(name), quantity: value || 0 }
            ]);
        }
    }

    const onSizeQuantitySpinButtonFocus = (detailIndex: number) => {
        if (isSetSizeRange) {

            let range = sizeRange;

            if (range.length == 2) {
                range = [];
            }

            range.push(detailIndex);
            range.sort((a, b) => (a - b));

            applyFillSizeRange(range, fillQuantity);
        }
    }

    const applyFillSizeRange = (range: number[], quantity: number) => {

        const startIndex = range[0];
        const endIndex = range.length > 1 ? range[1] : startIndex;
        const quantities: IDocumentDetailQuantity[] = [];

        for (let index = startIndex; index < (endIndex + 1); index++) {

            const _quantity   = 

            quantities.push({ index, quantity });
        }

        setSizeRange([...range]);
        onDetailsQuantitiesChange(index, quantities);
    }

    const onSetSizeRangeClick = () => {
        setIsSetSizeRange(!isSetSizeRange);
    }

    const onRepeatClick = () => {
        if (lastOrderedProductDocumentDetails?.length) {

            const quantities: IDocumentDetailQuantity[] = [];

            for (const item of lastOrderedProductDocumentDetails) {
                const detailIndex = viewModel.DocumentDetails.findIndex(d => d.SizeCode === item.SizeCode);

                if (detailIndex !== -1) {
                    quantities.push({ quantity: item.Quantity, index: detailIndex });
                }
            }

            if (quantities.length > 0) {
                onDetailsQuantitiesChange(index, quantities);
            }
        }
    }

    const onResetClick = () => {

        const quantities: IDocumentDetailQuantity[] = [];

        for (let index = 0; index < viewModel.DocumentDetails.length; index++) {
            quantities.push({ index, quantity: 0 });
        }

        setIsSetSizeRange(false);
        setSizeRange([]);
        onDetailsQuantitiesChange(index, quantities);
    }

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack verticalAlign="center" horizontalAlign="end" horizontal grow styles={{ root: { minHeight: 32 } }} tokens={{ childrenGap: 20 }}>
                {
                    viewModel.DocumentDetailStatusId && (
                        <StackItem>
                            <DocumentDetailStatusSpan documentDetailStatusId={viewModel.DocumentDetailStatusId} />
                        </StackItem>
                    )
                }
                {
                    (viewModel.ColliId && new Date(viewModel.ExpectedOn) > new Date) && (
                        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }} styles={{ root: { color: "darkred" } }}>
                            <StackItem>
                                <Icon iconName="DeliveryTruck" styles={{ root: { fontSize: FontSizes.large } }} />
                            </StackItem>
                            <StackItem>
                                {dateToLocaleDateString(new Date(viewModel.ExpectedOn))}
                            </StackItem>
                        </Stack>
                    )
                }
                <StackItem className="ms-fontWeight-semiBold">
                    {
                        viewModel.SizeRangeId && (
                            getNumber(getSum(viewModel.DocumentDetails.map((v) => {
                                return v.Quantity
                            }))) + " pcs"
                        )
                    }
                </StackItem>
                <StackItem className="ms-fontWeight-bold">
                    {
                        getPrice(getSum(viewModel.DocumentDetails.map((v) => {
                            return v.UnitPrice * v.Quantity
                        })), currencyId)
                    }
                </StackItem>
                <StackItem>
                    {
                        (!viewModel.WarehouseRelationId && viewModel.ExistsOnDocument) ? (
                            <IconButton
                                iconProps={{ iconName: "More" }}
                                disabled={updateOrderDisabled}
                                menuProps={{
                                    items: getContextMenu()
                                }}
                            />
                        ) : (
                            viewModel.ColliId && !viewModel.ExistsOnDocument ? (
                                <IconButton
                                    iconProps={{ iconName: viewModel.Checked ? "ShoppingCartSolid" : "ShoppingCart" }}
                                    checked={viewModel.Checked}
                                    disabled={updateOrderDisabled}
                                    onClick={() => onColliCheckedChange(index, !viewModel.Checked)}
                                />
                            ) : viewModel.ExistsOnDocument && (
                                <IconButton
                                    iconProps={{ iconName: "Delete" }}
                                    disabled={updateOrderDisabled}
                                    onClick={() => onDelete(viewModel.GroupKey)}
                                />
                            )
                        )
                    }

                </StackItem>
            </Stack>
            {
                viewModel.SizeRangeId ? (
                    <>
                        <StackItem align="stretch">
                            <ProductDocumentDetailSizes
                                documentProductSizes={documentProductSizes}
                                documentDetails={viewModel.DocumentDetails}
                                currencyId={currencyId}
                                cultureId={cultureId}
                                groupIndex={index}
                                onRenderQuantity={!viewModel.ColliId && onRenderQuantity}
                            />
                        </StackItem>
                        {
                            !viewModel.ColliId && (
                                <StackItem>
                                    <Stack tokens={{ childrenGap: 15 }} style={{ paddingTop: 10 }} horizontal verticalAlign="center">
                                        <StackItem grow>
                                            <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
                                                <StackItem styles={{ root: { marginRight: 10 } }}>
                                                    <Checkbox
                                                        disabled={updateOrderDisabled}
                                                        onChange={() => onSetSizeRangeClick()}
                                                        checked={isSetSizeRange}
                                                        label={DictionaryAPI.getTranslation("SetSizeRange")}
                                                    />
                                                </StackItem>
                                                <StackItem>
                                                    <SpinButton
                                                        value={fillQuantity}
                                                        step={1}
                                                        min={0}
                                                        max={999}
                                                        disabled={updateOrderDisabled}
                                                        locale={cultureId}
                                                        onValueChange={(value) => setFillQuantity(value ? Number(value) : 1)}
                                                        style={{
                                                            width: 75
                                                        }}
                                                    />
                                                </StackItem>
                                                <StackItem>
                                                    <DefaultButton
                                                        disabled={updateOrderDisabled || sizeRange.length !== 2}
                                                        iconProps={{
                                                            iconName: "Brush"
                                                        }}
                                                        onClick={() => applyFillSizeRange(sizeRange, fillQuantity)}
                                                        text={DictionaryAPI.getTranslation("Fill")}
                                                    />
                                                </StackItem>
                                                {
                                                    lastOrderedProductDocumentDetails && (
                                                        <StackItem>
                                                            <DefaultButton
                                                                disabled={updateOrderDisabled || lastOrderedProductDocumentDetails?.length === 0}
                                                                iconProps={{
                                                                    iconName: "RepeatAll"
                                                                }}
                                                                onClick={() => onRepeatClick()}
                                                                text={DictionaryAPI.getTranslation("Repeat")}
                                                            />
                                                        </StackItem>
                                                    )
                                                }
                                                <StackItem>
                                                    <DefaultButton
                                                        disabled={updateOrderDisabled}
                                                        iconProps={{
                                                            iconName: "Refresh"
                                                        }}
                                                        onClick={() => onResetClick()}
                                                        text={DictionaryAPI.getTranslation("Reset")}
                                                    />
                                                </StackItem>
                                            </Stack>
                                        </StackItem>
                                    </Stack>
                                </StackItem>
                            )
                        }
                    </>
                ) : (
                    <StackItem align="end">
                        <SpinButton
                            inputProps={{
                                autoFocus: true
                            }}
                            name={`${QUANTITY}_${index}|${0}`}
                            label={"Quantity"}
                            value={viewModel.DocumentDetails[0].Quantity}
                            step={1}
                            min={0}
                            max={
                                viewModel.DocumentDetails[0].AvailableQuantity !== -1 ?
                                    viewModel.DocumentDetails[0].AvailableQuantity :
                                    MAX_AVAILABLE_QUANTITY
                            }
                            disabled={updateOrderDisabled}
                            style={{ width: 75 }}
                            styles={{ input: { textAlign: "center", fontWeight: "600" } }}
                            locale={cultureId}
                            onValueChange={onQuantitySpinButtonChange}
                        />
                    </StackItem>

                )
                /* : (
                    <StackItem align="end">
                        <Stack tokens={{ childrenGap: 15 }}>
                            <Stack tokens={{ childrenGap: 5 }} horizontal>
                                <StackItem>
                                    {
                                        !viewModel.ColliId ? (
                                            <SpinButton
                                                inputProps={{
                                                    autoFocus: true
                                                }}
                                                name={`${QUANTITY}_${index}|${0}`}
                                                label={"Quantity"}
                                                value={viewModel.DocumentDetails[0].Quantity}
                                                step={1}
                                                min={0}
                                                max={
                                                    viewModel.DocumentDetails[0].AvailableQuantity !== -1 ?
                                                        viewModel.DocumentDetails[0].AvailableQuantity :
                                                        MAX_AVAILABLE_QUANTITY
                                                }
                                                disabled={updateOrderDisabled}
                                                style={{ width: 75 }}
                                                styles={{ input: { textAlign: "center", fontWeight: "600" } }}
                                                locale={cultureId}
                                                onValueChange={onQuantitySpinButtonChange}
                                            />
                                        ) : (
                                            <TextField
                                                readOnly
                                                value={getNumber(viewModel.TotalQuantity)}
                                                styles={{ field: { width: 125, textAlign: "center", fontWeight: "700" } }}
                                            />
                                        )
                                    }
                                </StackItem>
                                <StackItem>
                                    <TextField
                                        readOnly
                                        value={getPrice(viewModel.TotalPrice)}
                                        styles={{ field: { width: 125, textAlign: "center", fontWeight: "700" } }}
                                    />
                                </StackItem>
                            </Stack>
                        </Stack>
                    </StackItem>
                ) */
            }
        </Stack>
    )
}
