import { BaseAPI, CatalogAPI, DictionaryAPI } from 'api';
import { CommandBar, Loader } from 'components/common';
import { ICatalogListItem } from 'interfaces/models';
import { ActionButton, ComboBox, getTheme, IComboBox, ScrollablePane, ScrollbarVisibility, Stack, StackItem } from 'office-ui-fabric-react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router';
import { ProductDetail } from '../ProductDetail';
import { IProductCatalogProps } from './interfaces'
import './styles.css'

export const ProductCatalog = (props: IProductCatalogProps) => {

    const { productCategoryId, productId } = props;

    const [catalogs, setCatalogs] = useState<ICatalogListItem[]>();
    const [catalogRowGuid, setCatalogRowGuid] = useState<string>();
    const [data, setData] = useState<string>();

    const catalogsComboBoxRef = useRef<IComboBox>();

    const theme = getTheme();

    const { goBack } = useHistory();

    useEffect(() => {
        if (catalogRowGuid) {
            BaseAPI.get(`Api/Sls/Catalog/GetProductCatalog/${catalogRowGuid}`, null,
                (html) => setData(html),
                undefined,
                false,
                "html"
            )
        }
    }, [catalogRowGuid])

    useEffect(() => {
        CatalogAPI.readActives(productCategoryId,
            (data) => {
                setCatalogs(data);
                if (data.length > 0) {
                    setCatalogRowGuid(data[0].RowGuid);
                }
            });
    }, [productCategoryId])

    return catalogs ? (
        <>
            <Stack verticalFill>
                <StackItem styles={{ root: { padding: 5, backgroundColor: theme.palette.neutralLighter, borderBottom: "1px solid " + theme.semanticColors.bodyFrameDivider } }}>
                    <CommandBar
                        useNeutralBackground
                        items={[
                            {
                                key: "ActiveCatalogs",
                                commandBarButtonAs: () => (
                                    <ComboBox
                                        allowFreeform
                                        placeholder={DictionaryAPI.getTranslation("Catalogs")}
                                        selectedKey={catalogRowGuid}
                                        onChange={(ev, option) => setCatalogRowGuid(String(option?.key))}
                                        disabled={catalogs?.length === 0}
                                        componentRef={catalogsComboBoxRef}
                                        options={catalogs.map((v) => {
                                            return {
                                                key: v.RowGuid,
                                                text: v.Name,
                                            }
                                        })}
                                    />
                                )
                            },
                        ]}
                    />
                </StackItem>
                <StackItem grow>
                    {
                        catalogRowGuid ? (

                            data ? (
                                <div style={{ position: "relative", height: "100%" }}>
                                    <ScrollablePane styles={{ root: { padding: 0 } }} scrollbarVisibility={ScrollbarVisibility.auto}>
                                        <div
                                            style={{ width: "100%" }}
                                            dangerouslySetInnerHTML={{ __html: data }}
                                        >
                                        </div>
                                    </ScrollablePane>
                                </div>
                            ) : <Loader />
                        ) : (
                                <Stack verticalAlign="center" verticalFill>
                                    <StackItem align="center">
                                        <ActionButton
                                            styles={{ root: { fontSize: 20 } }}
                                            iconProps={{
                                                styles: { root: { fontSize: 24 } },
                                                iconName: "ProductCatalog",
                                            }}
                                            text={DictionaryAPI.getTranslation("SelectACatalog")}
                                            onClick={() => catalogsComboBoxRef.current?.focus()}
                                        />
                                    </StackItem>
                                </Stack>
                            )
                    }

                </StackItem>
            </Stack>
            {
                productId && (
                    <ProductDetail
                        productId={productId}
                        onDismiss={() => goBack()} />
                )
            }
        </>
    ) : <Loader />
}
