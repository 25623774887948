import React, { useState, useDebugValue } from 'react';
import { IWindowModalProps } from './interfaces';
import { mergeStyleSets, Modal, Stack, StackItem, ICommandBarItemProps, getTheme, hiddenContentStyle } from 'office-ui-fabric-react';
import { TitleBar, CommandBar, Loader } from 'components/common';
import { DictionaryAPI } from 'api';

export const WindowModal: React.FunctionComponent<IWindowModalProps> = props => {

    const { titleBarProps, isOpen, isBlocking, width, height, isFullSize, isLoading, disableFullScreen, onDismiss } = props;

    const getDefaultSize = (): string[] => {
        return isFullSize ? ["100%", "100%"] :
            [
                width !== undefined ? String(width) + "px" : "auto",
                height !== undefined ? String(height) + "px" : "auto"
            ];
    }

    const theme = getTheme();

    const [modalSize, setModalSize] = useState<string[]>(getDefaultSize());

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            width: modalSize[0],
            height: modalSize[1]
        },
        header: {
            borderBottom: "1px solid " + theme.semanticColors.bodyDivider,
            backgroundColor: theme.semanticColors.bodyBackground,
        }
    });

    const getCommandBarFarItems = (): ICommandBarItemProps[] => {

        const items: ICommandBarItemProps[] = [];
        if (!isFullSize && !disableFullScreen) {
            items.push({
                key: "fullScreen",
                name: modalSize[0] === "100%" ? DictionaryAPI.getTranslation("ExitFullScreen") : DictionaryAPI.getTranslation("FullScreen"),
                iconOnly: true,
                iconProps: { iconName: modalSize[0] === "100%" ? "BackToWindow" : "FullScreen" },
                disabled: isLoading,
                onClick: () => modalSize[0] === "100%" ? setModalSize(getDefaultSize()) : setModalSize(["100%", "100%"])
            })
        }

        items.push({
            key: "close",
            name: DictionaryAPI.getTranslation("Close"),
            iconOnly: true,
            iconProps: { iconName: "Cancel" },
            disabled: isLoading,
            onClick: onDismiss
        })

        return items;
    };

    return (
            <Modal
                isBlocking={isBlocking}
                isOpen={isOpen}
                isDarkOverlay={false}
                onDismiss={onDismiss}
                containerClassName={contentStyles.container}>
                {
                    isLoading ? <Loader /> : (
                        <Stack horizontalAlign="stretch" verticalFill>
                            <StackItem>
                                <Stack className={contentStyles.header} horizontal>
                                    <StackItem disableShrink grow>
                                        <TitleBar {...titleBarProps} />
                                    </StackItem>
                                    <StackItem align="center">
                                        <CommandBar
                                            farItems={getCommandBarFarItems()}
                                            disabled={isLoading} />
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem grow>
                                {props.children}
                            </StackItem>
                        </Stack>
                    )
                }
            </Modal>
    );
};