import React from 'react'
import { IStatisticsCardProps } from './interfaces'
import { IStackTokens, Stack, StackItem, Icon, FontSizes, IStyle } from 'office-ui-fabric-react'

const cardStackTokens: IStackTokens = {
    padding: 8,
    maxWidth: 150,
    maxHeight: 150
}

const cardHeaderStackTokens: IStackTokens = {
    padding: 8,
}

export const StatisticsCard = (props: IStatisticsCardProps) => {

    const { value, iconName, label, color, onClick } = props;

    const cardStyle: React.CSSProperties = {
        width: 150,
        height: 150,
        borderTop: "6px solid " + color,
        cursor: "pointer",
    }

    const getFontSize = (): string => {

        if (value.length < 6) {
            return FontSizes.superLarge
        } else if (value.length === 6) {
            return FontSizes.xxLargePlus
        } else if (value.length === 7) {
            return FontSizes.xxLarge
        } else if (value.length > 7 && value.length < 10) {
            return FontSizes.xLargePlus
        } else if (value.length > 9 && value.length < 12) {
            return FontSizes.xLarge
        } else if (value.length == 12) {
            return FontSizes.large
        } else if (value.length > 12 && value.length < 15) {
            return FontSizes.mediumPlus
        }
        
        return FontSizes.medium
    }

    return (
        <Stack tokens={cardStackTokens} style={cardStyle} className="ms-depth-8" onClick={onClick}>
            <Stack tokens={cardHeaderStackTokens} horizontal grow verticalAlign="center">
                {
                    value.length < 4 && (
                        <StackItem>
                            <Icon iconName={iconName} styles={{ root: { fontSize: FontSizes.superLarge } }} />
                        </StackItem>
                    )
                }
                <StackItem styles={{ root: { fontSize: getFontSize(), textAlign: "center" } }} grow>
                    {value}
                </StackItem>
            </Stack>
            <StackItem styles={{ root: { maxWidth: 130, wordWrap: "break-word" } }}>
                {label}
            </StackItem>
        </Stack>
    )
}
