export enum ContactProductCategoryFields {
    AGENT_CONTACT_ID = "AgentContactId",
    CATALOG_ACTIVE_FROM = "CatalogActiveFrom",
    CATALOG_ACTIVE_TO = "CatalogActiveTo",
    CATALOG_ID = "CatalogId",
    CONTACT_ID = "ContactId",
    DISCOUNT = "Discount",
    GROUPED_INVOICE_DETAIL = "GroupedInvoiceDetail",
    HIDE_SRP_ON_ITEM_LABEL = "HideSrpOnItemLabel",
    NO_SHIPPING_COSTS = "NoShippingCosts",
    ORDER_INPUT_POLICY_ID = "OrderInputPolicyId",
    PAYMENT_CONDITION_ID = "PaymentConditionId",
    PAYMENT_CONDITION_NAME = "PaymentConditionName",
    PAYMENT_INSTALLMENTS = "PaymentInstallments",
    PAYMENT_TERM_IN_DAYS = "PaymentTermInDays",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    SALES_ALLOWED = "SalesAllowed",
    SHIPPING_COST_POLICY_ID = "ShippingCostPolicyId",
    SHIPPING_REMARKS = "ShippingRemarks"
}