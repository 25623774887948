export enum ProductSizeFields {
    B2B_PRICE = "B2bPrice",
    B2C_PRICE = "B2cPrice",
    BARCODE = "Barcode",
    CODE = "Code",
    HEIGHT = "Height",
    ID = "Id",
    INPUT_TIME = "InputTime",
    LENGTH = "Length",
    NAME = "Name",
    PARENT_ID = "ParentId",
    PRODUCT_ID = "ProductId",
    WEIGHT = "Weight",
    WIDTH = "Width"
}