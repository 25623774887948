import React from 'react';
import { ICatalogGroupProps } from '.';
import { FormMenus, CatalogGroupFormTabMenus } from 'enums';
import { CatalogGroupAPI, MenuAPI } from 'api';
import { ICatalogGroupViewModel } from 'interfaces/viewmodels';
import { IFormProps } from 'hooks';
import { Information } from './Information';
import { FormLayout } from 'components/layouts';

export const CatalogGroup = (props: ICatalogGroupProps) => {

    const { Id, onDismiss } = props;
    const formMenu = MenuAPI.find(FormMenus.CatalogGroup);

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<ICatalogGroupViewModel>): JSX.Element | undefined => {

        switch (tabMenuId) {
            case CatalogGroupFormTabMenus.Information:
                return (
                    <Information
                        form={form} />
                )
            default:
                break;
        }

        return <></>
    }

    return (
        <FormLayout<ICatalogGroupViewModel>
            formMenu={formMenu}
            itemDependencies={[Id]}
            getItem={(onSuccess) => CatalogGroupAPI.get(Id, (data) => onSuccess(data, data?.Name, formMenu.translatedName, data?.IsActive))}
            updateItem={CatalogGroupAPI.update}
            deleteItem={(item, onSuccess, onError) => CatalogGroupAPI.delete(item.Id, onSuccess, onError)}
            toggleIsActive={(onSuccess) => CatalogGroupAPI.toggleIsActive(Id, onSuccess)}
            getTabMenuContent={getTabMenuContent}
            onDismiss={onDismiss} />
    );
}