import React from 'react';
import { ComboBox as FabricComboBox } from 'office-ui-fabric-react'
import { IComboBoxProps } from './interfaces';

export const ComboBox = (props: IComboBoxProps) => {

    const { name, onSimpleChange } = props;

    return <FabricComboBox 
        {...props} 
        onChange={(event, option, index, value) => onSimpleChange(name, option, index, value)} />
};