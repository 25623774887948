export enum ContactProductCategoryListItemFields {
    AGENT_CONTACT_NAME = "AgentContactName",
    CATALOG_ACTIVE_FROM = "CatalogActiveFrom",
    CATALOG_ACTIVE_TO = "CatalogActiveTo",
    CATALOG_NAME = "CatalogName",
    CONTACT_ID = "ContactId",
    DISCOUNT = "Discount",
    GROUPED_INVOICE_DETAIL = "GroupedInvoiceDetail",
    HIDE_SRP_ON_ITEM_LABEL = "HideSrpOnItemLabel",
    NO_SHIPPING_COSTS = "NoShippingCosts",
    ORDER_INPUT_POLICY_NAME = "OrderInputPolicyName",
    PAYMENT_CONDITION_NAME = "PaymentConditionName",
    PAYMENT_INSTALLMENTS = "PaymentInstallments",
    PAYMENT_TERM_IN_DAYS = "PaymentTermInDays",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCT_CATEGORY_NAME = "ProductCategoryName",
    SALES_ALLOWED = "SalesAllowed",
    SHIPPING_REMARKS = "ShippingRemarks"
}