import { DictionaryAPI } from 'api';
import { DefaultButton, FontWeights, getTheme, Icon, mergeStyleSets, Stack, StackItem } from 'office-ui-fabric-react'
import React from 'react'
import { IActiveFiltersProps } from './interfaces'

const theme = getTheme();
const classNames = mergeStyleSets({
    container: {
        paddingTop: 16
    },
    header: {
        fontWeight: FontWeights.semibold,
        color: theme.palette.themePrimary,
    },
    button: {
        width: "100%",
    },
    filterButton: {
        border: 0
    },
    buttonText: {
        textAlign: "left",
        fontWeight: FontWeights.semibold,
    }
});

export const ActiveFilters = (props: IActiveFiltersProps) => {

    const { items, onUnCheckFilterValue, onClearAllFilters } = props;

    return (
        <Stack horizontalAlign="stretch" className={classNames.container} tokens={{ childrenGap: 8 }}>
            {items?.map((f, i) => {

                const checkedFilterValues = f.filter.Values.filter(v => v.IsChecked);

                return checkedFilterValues.length > 0 && (
                    <Stack tokens={{ childrenGap: 8 }} key={i}>
                        <StackItem className={classNames.header}>
                            {DictionaryAPI.getTranslation(f.filter.DisplayName)}
                        </StackItem>
                        {
                            checkedFilterValues.map((v, i) => {
                                return (
                                    <StackItem key={i}>
                                        <DefaultButton
                                            checked
                                            className={classNames.button + " " + classNames.filterButton}
                                            onClick={() => onUnCheckFilterValue(f.name, v)}>

                                            <Stack style={{ width: "100%" }} horizontal horizontalAlign="stretch">
                                                <Stack.Item
                                                    className={classNames.buttonText}
                                                    grow>
                                                    {f.filter.ValueNameTranslationRequired ? DictionaryAPI.getTranslation(v.Name) : v.Name}</Stack.Item>
                                                <Stack.Item>
                                                    <Icon iconName="Remove" />
                                                </Stack.Item>
                                            </Stack>
                                        </DefaultButton>
                                    </StackItem>
                                )
                            })
                        }
                    </Stack>
                )
            })}
            <Stack tokens={{ padding: "8px 0" }}>
                <DefaultButton
                    className={classNames.button}
                    iconProps={{
                        iconName: "ClearFilter"
                    }}
                    onClick={() => onClearAllFilters()}>
                    <span className={classNames.buttonText}>
                        {DictionaryAPI.getTranslation("ClearAll")}
                    </span>
                </DefaultButton>
            </Stack>
        </Stack>
    )
}
