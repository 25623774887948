import React, { useRef, useEffect, useState } from 'react';
import { getTheme, mergeStyleSets, FontWeights, FontSizes, Stack, StackItem, MarqueeSelection } from 'office-ui-fabric-react';
import { IProductDocumentDetailSizesProps, IProductDocumentDetailSizesState } from './interfaces';
import { IProductDocumentDetailViewModel } from 'interfaces/viewmodels';
import { useNumberFormat } from 'hooks/useNumberFormat';

export const ProductDocumentDetailSizes = (props: IProductDocumentDetailSizesProps) => {

    const MAX_AVAILABLE_QUANTITY_INDICATION = 10;
    const { documentProductSizes, documentDetails, currencyId, cultureId, groupIndex, onRenderQuantity } = props;

    const [state, setState] = useState<IProductDocumentDetailSizesState>();

    const { getNumber, getPrice } = useNumberFormat(cultureId);
    const theme = getTheme();

    const classNames = mergeStyleSets({
        table: {
            width: "100%",
            borderCollapse: "collapse",
            border: 0,
        },
        cell: {
            fontWeight: FontWeights.semibold,
            width: 34,
            height: 32,
            lineHeight: 32,
            textAlign: "center",
            padding: 0,
            borderCollapse: "collapse",
            border: 0,
            borderRight: "1px solid " + theme.semanticColors.bodyFrameDivider
        },
        cellNoRightBorder: {
            borderRight: 0,
        },
        priceHeader: {
            fontSize: FontSizes.small,
            fontWeight: FontWeights.regular,
        },
        header: {
            backgroundColor: theme.palette.neutralLighterAlt,
            fontWeight: FontWeights.bold,
        },
        availableQuantity: {
            backgroundColor: theme.palette.neutralQuaternaryAlt,
            verticalAlign: "top",
        },

    });

    const getDocumentDetail = (sizeRangeSizeIndex: number): IProductDocumentDetailViewModel => {

        if (sizeRangeSizeIndex >= state.detailSizeRangeStartIndex && sizeRangeSizeIndex <= state.detailSizeRangeEndIndex) {
            return documentDetails[sizeRangeSizeIndex - state.detailSizeRangeStartIndex];
        }

        return undefined;
    }

    const getCellClassName = (sizeRangeSizeIndex: number) => {
        return (sizeRangeSizeIndex >= state.detailSizeRangeStartIndex && sizeRangeSizeIndex < state.detailSizeRangeEndIndex) ?
            classNames.cell : classNames.cell + " " + classNames.cellNoRightBorder;
    }

    const getPriceCells = (): React.ReactNode[] => {

        const nodes: React.ReactNode[] = [];

        let unitPrice = -1;

        for (let i = 0; i < documentProductSizes.length; i++) {

            const detail = getDocumentDetail(i);

            if (detail) {
                if (unitPrice !== detail.UnitPrice) {

                    unitPrice = detail.UnitPrice;
                    const colSpan = documentDetails.filter(d => d.UnitPrice === unitPrice).length;
                    nodes.push(
                        <td key={i} colSpan={colSpan} className={`${getCellClassName(i + (colSpan - 1))} ${classNames.priceHeader}`}>
                            {
                                getPrice(unitPrice, currencyId)
                            }
                        </td>
                    );
                }
            } else {
                nodes.push(<td key={i}></td>)
            }
        }

        return nodes;
    }

    const getAvailableQuantityIndication = (availableQuantity: number): React.ReactNode => {

        const height = availableQuantity > MAX_AVAILABLE_QUANTITY_INDICATION ? 0 :
            (100 - (availableQuantity / MAX_AVAILABLE_QUANTITY_INDICATION) * 100);

        const style: React.CSSProperties = {
            backgroundColor: theme.semanticColors.bodyBackground,
            height: `${height}%`,
            width: "100%",
        }

        return <div style={style}></div>
    }

    const formatQuantity = (quantity: number): string => {
        if (!quantity) {
            return "";
        }

        return getNumber(quantity);
    }

    const getSizeRangeOffsetCells = (offsetLenght: number): React.ReactNode[] => {
        const nodes: React.ReactNode[] = [];

        for (let index = 0; index < offsetLenght; index++) {
            nodes.push(<td></td>);
        }

        return nodes;
    }

    useEffect(() => {

        setState({
            detailSizeRangeStartIndex: documentProductSizes.findIndex(r => r.ProductSizeCode === documentDetails[0].SizeCode),
            detailSizeRangeEndIndex: documentProductSizes.findIndex(r => r.ProductSizeCode === documentDetails[documentDetails.length - 1].SizeCode),
            isAvailableQuantityIndicationVisible: documentDetails.find(d => d.AvailableQuantity > 0) !== undefined && documentDetails.find(d => d.ColliId) == undefined
        });

    }, [documentProductSizes, documentDetails])

    return state ? (
        <table className={classNames.table}>
            <thead>
                <tr>
                    {getPriceCells()}
                </tr>
                <tr>
                    {documentProductSizes.map((v, i: number) => {
                        return (
                            <th key={i} className={`${getCellClassName(i)} ${classNames.header}`}>
                                {
                                    getDocumentDetail(i)?.SizeName
                                }
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {documentProductSizes.map((v, i: number) => {
                        const detail = getDocumentDetail(i);
                        return (
                            <td key={i} className={getCellClassName(i)}>
                                {detail && onRenderQuantity ? onRenderQuantity(detail.Quantity, detail.AvailableQuantity, i - state.detailSizeRangeStartIndex, groupIndex) : formatQuantity(detail?.Quantity)}
                            </td>
                        )
                    })}
                </tr>
            </tbody>
            {
                state.isAvailableQuantityIndicationVisible && (
                    <tfoot>
                        <tr>
                            {documentProductSizes.map((v, i: number) => {
                                const detail = getDocumentDetail(i);
                                return (
                                    <td key={i} className={`${getCellClassName(i)} ${detail && classNames.availableQuantity}`}>
                                        {detail && getAvailableQuantityIndication(detail.AvailableQuantity)}
                                    </td>
                                )
                            })}
                        </tr>
                    </tfoot>
                )
            }
        </table>
    ) : <></>

    /* return (
        <Stack horizontalAlign="stretch" className={contentStyles.container}>
            <StackItem>

            </StackItem>
            <Stack horizontal >
                <StackItem grow disableShrink>
                    <Stack horizontal>
                        {documentDetails.map((v: IDocumentDetailViewModel, i: number) => {
                            return (
                                <StackItem key={i}>
                                    <div className={contentStyles.header + " " + contentStyles.sizeColumn}>
                                        {v.ProductSizeName}
                                    </div>
                                    <div className={contentStyles.cell + " " + contentStyles.sizeColumn}>
                                        {onRenderQuantity ? onRenderQuantity(v.Quantity, v.AvailableQuantity, i) : formatQuantity(v.Quantity)}
                                    </div>
                                    {
                                        v.AvailableQuantity != 999 && (
                                            <div className={contentStyles.cell + " " + contentStyles.availableQuantityCell}>
                                                {getAvailableQuantityIndication(v.AvailableQuantity)}
                                            </div>
                                        )
                                    }
                                </StackItem>
                            )
                        })}
                        <StackItem grow>
                            <div className={contentStyles.header}></div>
                            <div className={contentStyles.cell}></div>
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
        </Stack> 
        );*/


};