import { UserSettingAPI } from "api";
import { useGlobals } from "hooks/useGlobals";
import { IUserSettingsMap } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { IUseUserSettingsProps } from "./interfaces";

export const useUserSetting = (menuId: number): IUseUserSettingsProps => {

    const [loaded, setLoaded] = useState(false);

    const userSettingsMapRef = useRef<IUserSettingsMap>({});

    const { clientId } = useGlobals();

    const setUserSetting = (name: string, value: string) => {
        userSettingsMapRef.current[name] = userSettingsMapRef.current[name] ? {
            ...userSettingsMapRef.current[name], Value: value
        } : {
                Id: 0,
                AccountId: 0,
                ApplicationId: Number(clientId),
                MenuId: menuId,
                Name: name,
                Value: value === "NaN" ? "" : value,
                CreatedOn: undefined,
                UpdatedOn: undefined
            }

        createOrUpdate();
    }

    const createOrUpdate = () => {
        UserSettingAPI.createOrUpdateByList(menuId, userSettingsMapRef.current);
    }

    useEffect(() => {
        UserSettingAPI.read(menuId,
            (data) => {
                userSettingsMapRef.current = data;
                setLoaded(true);
            });
    }, [menuId])

    return {
        userSettingsLoaded: loaded,
        userSettingsMap: userSettingsMapRef.current,
        setUserSetting
    }
}