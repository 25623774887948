import React from 'react';
import { CustomerFormTabMenus, FormMenus } from 'enums';
import { RelationAPI, MenuAPI } from 'api';
import { ICustomerViewModel } from 'interfaces/viewmodels';
import { IFormProps } from 'hooks';
import { AdministrationTab } from './AdministrationTab';
import { SalesTab } from './SalesTab';
import { RelationsListViewLayout, RelationFormLayout } from 'components/layouts';
import { InformationTab } from 'components/layouts/RelationFormLayout/InformationTab';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ICustomerProps } from './interfaces';
import { AttachmentsListView } from 'components/common/AttachmentsListView';

export const Customer = (props: ICustomerProps) => {

    const { rowGuid, onDismiss } = props;
    const formMenu = MenuAPI.find(FormMenus.Customer);

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const getItem = (onSuccess: (item?: ICustomerViewModel, code?: string) => void) => {
        RelationAPI.getCustomer(rowGuid,
            (data) => {
                onSuccess(data, data.Code);
            })
    }

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<ICustomerViewModel>): JSX.Element | undefined => {

        const tabMenu = MenuAPI.find(tabMenuId);

        switch (tabMenuId) {
            case CustomerFormTabMenus.Information:
                return (
                    <InformationTab
                        form={form}
                        formMenu={formMenu}
                    />
                )
            case CustomerFormTabMenus.Administration:
                return (
                    <AdministrationTab
                        cultureId={cultureId}
                        form={form}
                        formMenu={formMenu}
                    />
                )
            case CustomerFormTabMenus.Sales:
                return (
                    <SalesTab
                        cultureId={cultureId}
                        form={form}
                        formMenu={formMenu}
                    />
                )
            case CustomerFormTabMenus.Relations:
                return formMenu && (
                    <RelationsListViewLayout
                        isSubRelations
                        menu={tabMenu}
                        relationTypes={form.item?.SubRelationTypes}
                        relationTypeId={form.item?.DefaultSelectedRelationTypeId}
                        companyRelationId={form.item?.CompanyRelationId || 0}
                        parentContactId={form.item?.ContactId || 0}
                    />
                )
            case CustomerFormTabMenus.Attachments:
                return (
                    <AttachmentsListView
                        cultureId={cultureId}
                        menu={tabMenu}
                        entityKey={rowGuid}
                        getDownloadUrlCallback={RelationAPI.getDownloadAttachmentUrl}
                        readCallback={RelationAPI.readAttachments}
                        removeCallback={RelationAPI.removeAttachments}
                        uploadCallback={RelationAPI.uploadAttachment}
                    />
                )
            default:
                break;
        }
    }

    return (
        <RelationFormLayout<ICustomerViewModel>
            formMenu={formMenu}
            rowGuid={rowGuid}
            getItem={getItem}
            updateItem={RelationAPI.updateCustomer}
            getTabMenuContent={getTabMenuContent}
            onDismiss={onDismiss}
        />
    );
}