import { BaseAPI, LanguageAPI } from '..'
import { storeInstance } from 'store'
import { IDictionaryMap } from 'interfaces'
import { setDictionary } from 'store/dictionary/actions'

export class DictionaryAPI {
    public static load(onSuccess: () => void) {

        let language: string = LanguageAPI.getCookie();
        if (language === "") {
            language = navigator.language.substring(0, 2);
            //language = "en";
        }

        if (language !== storeInstance.getState().dictionaryState.language) {
            LanguageAPI.get(language,
                (data) => {

                    language = data ? data.Id : "en";
                    LanguageAPI.setCookie(language);

                    let map: IDictionaryMap = {};
                    DictionaryAPI.read(language,
                        (data) => {

                            data.map((item: any) => {
                                map[item.Caption] = item.Translation
                            });

                            storeInstance.dispatch(setDictionary(language, map));
                            onSuccess();
                        });

                });
        } else {
            onSuccess();
        }
    }

    public static read(language: string, onSuccess: (data: any) => void) {
        BaseAPI.get(`api/app/dictionary/read/${language}`, null, onSuccess, undefined, true);
    }

    public static getTranslation(caption: string, parameters?: string[]): string {
        if (caption) {
            const _caption = caption.split(" ").join("");
            let translation = storeInstance.getState().dictionaryState.dictionary[_caption];

            if (translation) {
                if (parameters) {
                    for (let index = 0; index < parameters.length; index++) {
                        const parameter = parameters[index];
                        translation = translation.replace(`{${index}}`, parameter);
                    }
                }

                return translation
            }

            return _caption !== "" ? "?" + _caption : "";
        }

        return "";
    }
}

