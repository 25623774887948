import React from 'react';
import { FontSizes, IRenderFunction } from 'office-ui-fabric-react';
import { Breadcrumb as FabricBreadcrumb } from 'office-ui-fabric-react/lib/Breadcrumb';
import { IBreadcrumbProps } from '.';

export const Breadcrumb = (props: IBreadcrumbProps): JSX.Element => {
    return (
        <FabricBreadcrumb
            style={{ margin: 0 }}
            styles={
                {
                    item: { fontSize: FontSizes.medium },
                    chevron: { fontSize: FontSizes.xSmall }
                }
            }
            items={
                props.menuNamePath.map((s: string) => {
                    return {
                        text: s, key: s
                    }
                })
            }
            maxDisplayedItems={3}
            overflowIndex={0}
            ariaLabel="Breadcrumb with maxDisplayedItems set to 2 and overflowIndex set to 1"
            overflowAriaLabel="More links"
        />)
}