import {
    PendingOrdersTypes,
    IPendingOrdersState,
    ISetActivePendingOrderCustomer,
    ISetPendingOrders,
    ISetPendingOrdersVisible,
    ISetActivePendingOrder,
} from './types'
import {
    SET_ACTIVE_PENDING_ORDER,
    SET_ACTIVE_PENDING_ORDER_CUSTOMER,
    SET_PENDING_ORDERS,
    SET_PENDING_ORDERS_VISIBLE,
} from './actions'

const initialState: IPendingOrdersState = {
    customerRelationRowGuid: "",
    customerRelationName: "",
    orders: [],
    totalOrders: 0,
}

export function pendingOrdersReducer(state = initialState, action: PendingOrdersTypes): IPendingOrdersState {
    switch (action.type) {
        case SET_ACTIVE_PENDING_ORDER_CUSTOMER:
            const activeOrderCustomerOnInputAction = action as ISetActivePendingOrderCustomer;
            return {
                ...state,
                customerRelationRowGuid: activeOrderCustomerOnInputAction.customerRelationRowGuid,
                customerRelationName: activeOrderCustomerOnInputAction.customerRelationName,
                catalogId: activeOrderCustomerOnInputAction.catalogId,
                activePendingOrderCustomerLoaded: true
            }
        case SET_PENDING_ORDERS:
            const pendingOrdersAction = action as ISetPendingOrders;
            return {
                ...state,
                orders: pendingOrdersAction.orders,
                totalOrders: pendingOrdersAction.totalOrders,
                pendingOrderLoaded: true
            }
        case SET_PENDING_ORDERS_VISIBLE:
            const pendingOrdersVisibleAction = action as ISetPendingOrdersVisible;
            return {
                ...state,
                isVisible: pendingOrdersVisibleAction.isVisible,
                disabled: pendingOrdersVisibleAction.disabled
            }
        case SET_ACTIVE_PENDING_ORDER:
            const activeOrderOnInputAction = action as ISetActivePendingOrder;
            return {
                ...state,
                documentRowGuid: activeOrderOnInputAction.documentRowGuid
            }
        default:
            return state
    }
}