import React, { useRef } from 'react';
import {
    Stack, StackItem,
    ComboBox,
    IComboBoxOption,
    IComboBox,
} from 'office-ui-fabric-react';
import { DatePicker } from 'components/common/DatePicker';

import { IDateRangeFilterProps } from '.';
import { DictionaryAPI } from 'api';
import { IRangeFilterValue } from 'interfaces/models';
import { FilterBarItemDropDown } from './FilterBarItemDropDown';

export const DateRangeFilter = (props: IDateRangeFilterProps): JSX.Element => {

    const { filter, name, cultureId, onChange } = props;

    const selectedValue: IRangeFilterValue | undefined = filter && filter.Values.find(v => v.IsChecked);
    const datePickersDisabled: boolean = selectedValue !== undefined && filter.Values[0].Name != selectedValue.Name;

    const getOptions = (): IComboBoxOption[] => {
        const options: IComboBoxOption[] = [];

        for (let v of filter.Values as IRangeFilterValue[]) {
            options.push({
                key: v.Name,
                text: DictionaryAPI.getTranslation(v.Name)
            });
        }

        return options;
    }

    const setValues = (minimum: Date, maximum: Date) => {
        onChange(name, { ...filter.Values[0], MinimumValue: minimum, MaximumValue: maximum }, true);
    }

    const _onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option && index !== undefined) {
            onChange(name, filter.Values[index], true);
        }
    }

    const onSelectDate1 = (date: Date | null | undefined) => {
        if (date !== null && date !== undefined)
            setValues(date, filter.Values[0].MaximumValue);
    }

    const onSelectDate2 = (date: Date | null | undefined) => {
        if (date !== null && date !== undefined)
            setValues(filter.Values[0].MinimumValue, date);
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <StackItem>
                <ComboBox
                    allowFreeform
                    selectedKey={selectedValue?.Name}
                    placeholder={DictionaryAPI.getTranslation("SelectOrType")}
                    autoComplete="on"
                    options={getOptions()}
                    onChange={_onChange}
                />
            </StackItem>
            <StackItem>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <StackItem>
                        <DatePicker
                            cultureId={cultureId}
                            date={selectedValue ? new Date(Date.parse(selectedValue.MinimumValue.toString())) : undefined}
                            maxDate={new Date()}
                            disabled={datePickersDisabled}
                            onSelectDate={onSelectDate1} />
                    </StackItem>
                    <StackItem>
                        <DatePicker
                            cultureId={cultureId}
                            date={selectedValue ? new Date(Date.parse(selectedValue.MaximumValue.toString())) : undefined}
                            maxDate={new Date()}
                            disabled={datePickersDisabled}
                            onSelectDate={onSelectDate2} />
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    )
}