import React, { useState, useEffect } from 'react';
import { Dialog } from 'components/common';
import { ICatalogBaseTabProps } from '../interfaces';
import { CatalogAPI, DictionaryAPI } from 'api';
import { ICatalogPage } from 'interfaces/models';
import { Stack, StackItem, Icon, getTheme } from 'office-ui-fabric-react';
import { DefaultCommandTypes } from 'enums';
import { BaseListView } from 'components/common';
import { AddPageDialog } from './AddPageDialog';
import { PageList } from './PageList';
import { CatalogProductsListView } from '../CatalogProductsListView'
import { PageDialog } from './PageDialog';


export const PagesTab = (props: ICatalogBaseTabProps) => {

    const { catalogRowGuid, formMenu } = props;

    const [fetching, setFetching] = useState<boolean>();
    const [pages, setPages] = useState<ICatalogPage[]>();
    const [catalogPageId, setCatalogPageId] = useState<number>();
    const [catalogPageIdToUpdate, setCatalogPageIdToUpdate] = useState<number>();
    const [catalogPageIdToRemove, setCatalogPageIdToRemove] = useState<number>();
    const [isAddPageVisible, setIsAddPageVisible] = useState(false);

    const theme = getTheme();

    const readPages = () => {
        setFetching(true);
        CatalogAPI.readPages(catalogRowGuid, (data) => {

            if (data.length > 0 && catalogPageId === undefined) {
                setCatalogPageId(data[0].Id);
            }

            setPages(data);
        })
    }

    const onRenderContent = () => {
        return (
            <Stack
                verticalFill
                grow
                style={{
                    borderTop: "1px solid " + theme.semanticColors.bodyFrameDivider,
                }}>
                {
                    catalogPageId ? (
                        <CatalogProductsListView
                            catalogRowGuid={catalogRowGuid}
                            formMenu={formMenu}
                            catalogPageId={catalogPageId}
                            title={pages.find(p => p.Id === catalogPageId)?.Title}
                        />
                    ) : (
                            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
                                <StackItem>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setIsAddPageVisible(true)}>
                                        <Icon iconName="Add" /> <span>{DictionaryAPI.getTranslation("AddPage")}...</span>
                                    </div>
                                </StackItem>
                            </Stack>
                        )
                }
            </Stack>
        )
    }

    const onRenderLeftPanelContent = () => {
        return (
            <PageList
                pages={pages}
                selectedCatalogPageId={catalogPageId}
                onAddClick={() => setIsAddPageVisible(true)}
                onPageSelectionChange={(Id) => setCatalogPageId(Id)} />
        )
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Edit:
                setCatalogPageIdToUpdate(catalogPageId);
                break;
            case DefaultCommandTypes.Remove:
                setCatalogPageIdToRemove(catalogPageId);
                break;
            default:
                break;
        }
    }

    const onAddPageAccept = (Id: number) => {
        setCatalogPageId(Id);
        readPages();
    }

    const onRemovePage = () => {
        if (catalogPageIdToRemove) {
            CatalogAPI.removePage(catalogRowGuid, catalogPageIdToRemove, readPages);
            setCatalogPageId(undefined);
        }
    }

    useEffect(() => {
        readPages();
    }, [catalogRowGuid])

    useEffect(() => {
        setIsAddPageVisible(false);
        setCatalogPageIdToUpdate(undefined);
        setCatalogPageIdToRemove(undefined);
        setFetching(false);
    }, [pages])

    return (
        <>
            <BaseListView
                listviewCommandBarProps={{
                    defaultCommands: [
                        { type: DefaultCommandTypes.Edit, isDisabled: !catalogPageId },
                        { type: DefaultCommandTypes.Remove, isDisabled: !catalogPageId }
                    ],
                    items: [
                        {
                            key: "Preview",
                            text: DictionaryAPI.getTranslation("Preview"),
                            iconProps: {
                                iconName: "Preview"
                            },
                            disabled: true
                        }
                    ],
                    menuPrivilege: formMenu?.Privilege,
                    onDefaultCommandClick,
                }}

                isLoading={!pages}
                onRenderLeftPanelContent={onRenderLeftPanelContent}
                onRenderContent={onRenderContent}
            />
            {
                isAddPageVisible && (
                    <AddPageDialog
                        catalogRowGuid={catalogRowGuid}
                        onAccept={onAddPageAccept}
                        onCancel={() => setIsAddPageVisible(false)}
                    />
                )
            }
            {
                catalogPageIdToUpdate && (
                    <PageDialog
                        catalogPageId={catalogPageIdToUpdate}
                        onAccept={readPages}
                        onCancel={() => setCatalogPageIdToUpdate(undefined)}
                    />
                )
            }
            {
                catalogPageIdToRemove && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("RemoveSelectedPage") + "?"}
                        onAccept={onRemovePage}
                        onCancel={() => setCatalogPageIdToRemove(undefined)}
                        acceptButtonDisabled={fetching}
                        cancelButtonDisabled={fetching}
                        maxWidth={300}
                    />
                )
            }
        </>
    );
};