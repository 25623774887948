import React from 'react';
import { RelationTypes } from 'enums';
import { IRelationsListViewLayoutBaseProps, RelationsListViewLayout } from 'components/layouts';

export const Customers = (props: IRelationsListViewLayoutBaseProps) => {

    const { menu, companyRelationId, relationTypeId, rowGuid } = props;

    return (
        <>
            <RelationsListViewLayout
                menu={menu}
                companyRelationId={companyRelationId}
                relationTypeId={relationTypeId}
                rowGuid={rowGuid}
            />
        </>
    );
};