export enum ProductDetailViewModelFields {
    ASSOCIATED_PRODUCTS = "AssociatedProducts",
    COLLECTIONCODE = "CollectionCode",
    COLLECTION_ID = "CollectionId",
    IMAGE_SIZE_GROUPS = "ImageSizeGroups",
    PRODUCT_B2B_PRICE = "ProductB2bPrice",
    PRODUCT_B2C_PRICE = "ProductB2cPrice",
    PRODUCT_CATEGORY_NAME = "ProductCategoryName",
    PRODUCT_CODE = "ProductCode",
    PRODUCT_DESCRIPTION = "ProductDescription",
    PRODUCT_ID = "ProductId",
    PRODUCT_NAME = "ProductName",
    PRODUCT_SIZE_RANGES = "ProductSizeRanges",
    PRODUCT_VARIANT_DESCRIPTION = "ProductVariantDescription",
    SUB_PRODUCT_CATEGORY_NAME = "SubProductCategoryName"
}