import { DefaultPalette, IColumn, Icon, IObjectWithKey, Link, mergeStyleSets, Selection, SelectionMode, TooltipHost } from 'office-ui-fabric-react';
import React, { useEffect, useRef, useState } from 'react'
import { ListViewCommandBarTypes } from '../ListViewCommandBar';
import { IAttachmentsListViewProps } from './interfaces';
import { getFileTypeIconProps, FileIconType } from '@uifabric/file-type-icons';
import { DefaultCommandTypes } from 'enums';
import { AddDialog } from './AddDialog';
import { ListView } from '../ListView';
import { Dialog } from '..';
import { DictionaryAPI } from 'api';
import { IFileDetail } from 'interfaces/models';
import { DownloadFile } from '../DownloadFile';
import { EntityFields, FileDetailFields } from 'enums/fields';
import { useDates } from 'hooks';

export const AttachmentsListView = (props: IAttachmentsListViewProps) => {

    const { menu, entityKey, cultureId, uploadCallback, readCallback, removeCallback, getDownloadUrlCallback } = props;

    const [fileDetails, setFileDetails] = useState<IFileDetail[]>();
    //const [parentDirectory, setParentDirectory] = useState("");
    const [selectedFilePaths, setSelectedPaths] = useState<string[]>([]);
    const [add, setAdd] = useState(false);
    const [remove, setRemove] = useState(false);
    const [downLoadUrl, setDownLoadUrl] = useState<string>();
    const [loading, setLoading] = useState(false);

    const { dateByUtcToLocaleString } = useDates(cultureId);

    const { FILE_PATH, FILE_NAME, IS_DIRECTORY, CREATED_ON, UPDATED_ON } = FileDetailFields;

    const classNames = mergeStyleSets({
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: '16px',
        },
        fileIconCell: {
            textAlign: 'center',
            selectors: {
                '&:before': {
                    content: '.',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    height: '100%',
                    width: '0px',
                    visibility: 'hidden',
                },
            },
        },
        fileIconImg: {
            verticalAlign: 'middle',
            maxHeight: '16px',
            maxWidth: '16px',
            fontSize: '16px',
            color: DefaultPalette.yellowDark
        },
        tileFileIconImgIconImage: {
            maxHeight: "100%",
            maxWidth: "100%"
        },
        tileFileName: {
            textAlign: "center",
            wordWrap: "break-word",
        }
    });

    const onSelectionChanged = () => {
        setSelectedPaths(selectionRef.current.getSelection().map((v) => v[FILE_PATH]));
    }

    const selectionRef = useRef(new Selection({
        getKey: (item: IObjectWithKey) => item[FILE_PATH],
        canSelectItem: (item) => !item[IS_DIRECTORY],
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: onSelectionChanged,
    }))

    const read = () => {
        setLoading(true);
        readCallback(entityKey, (files) => setFileDetails(files));
    }

/*     const isDirectory = (fileDetail: IFileDetail) => {
        return fileDetail.FileName.startsWith("\\");
    } */

    const getExtension = (fileDetail: IFileDetail) => {
        return fileDetail.FileName.substr(fileDetail.FileName.lastIndexOf('.') + 1);
    }

    const onRenderDetailsListItemColumn = (item?: IFileDetail, index?: number, column?: IColumn): React.ReactNode => {

        if (item && column && column.fieldName) {

            switch (column.fieldName) {

                case "Icon":
                    const extension = getExtension(item);

                    return (
                        <TooltipHost content={`${extension} file`}>
                            {
                                item.IsDirectory ? (
                                    <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 16, imageFileType: 'svg' })} />
                                ) : (
                                        <Icon {...getFileTypeIconProps({ extension: extension, size: 16, imageFileType: 'png' })} />
                                    )
                            }
                        </TooltipHost>
                    )

                case CREATED_ON:
                case UPDATED_ON:
                    return <span>{dateByUtcToLocaleString(item[column.fieldName])}</span>
            }
        }
    }

    const onRenderTileListCell = (item?: IFileDetail, index?: number): React.ReactNode => {

        const extension = getExtension(item);

        return (
            <div>
                {
                    item.IsDirectory ? (
                        <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 96, imageFileType: 'png' })} />
                    ) : (
                            <Icon {...getFileTypeIconProps({ extension: extension, size: 96, imageFileType: 'png' })} />
                        )
                }
                <div className={classNames.tileFileName}>
                    <span>{item.FileName.replace("\\", "")}</span>
                </div>
            </div>
        )
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Add:
                setAdd(true);
                break;
            case DefaultCommandTypes.Remove:
                if (selectedFilePaths.length > 0) {
                    setRemove(true);
                }
                break;
            default:
                break;
        }
    }

    const onAcceptAdd = () => {
        read();
        setAdd(false);
    }

    const onAcceptDelete = () => {
        setLoading(true);
        removeCallback(entityKey, selectedFilePaths,
            () => {
                setRemove(false);
                read();
            }
        );
    }

    useEffect(() => {
        if (fileDetails) {
            setLoading(false);
            setAdd(false);
            setRemove(false);
        }
    }, [fileDetails])

    useEffect(() => {
        readCallback(entityKey, (files) => setFileDetails(files));
    }, [entityKey])

    return (
        <>
            <ListView
                compact
                isSub
                checkable
                menu={menu}
                items={fileDetails}
                availableListViewCommandBarTypes={[ListViewCommandBarTypes.List, ListViewCommandBarTypes.Tiles]}
                detailsListColumns={[
                    {
                        className: classNames.fileIconCell,
                        iconClassName: classNames.fileIconHeaderIcon,
                        ariaLabel: 'Column operations for File type, Press to sort on File type',
                        iconName: 'Page',
                        isIconOnly: true,
                        fieldName: 'Icon',
                        width: 16,
                    },
                    {
                        fieldName: FILE_NAME,
                        name: 'Name',
                        width: 325,
                        isRowHeader: true,
                        isResizable: true,
                    },
                    {
                        fieldName: CREATED_ON,
                        name: 'Created',
                        width: 110,
                    },
                    {
                        fieldName: UPDATED_ON,
                        name: 'Updated',
                        width: 110,
                    },
                ]}
                selection={selectionRef.current}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                onRenderTileListCell={onRenderTileListCell}
                onDefaultCommandClick={onDefaultCommandClick}
                onItemClick={(item: IFileDetail) => setDownLoadUrl(getDownloadUrlCallback(entityKey, item.FilePath))}
                onRefreshButtonClick={read}
            />
            {
                add && (
                    <AddDialog
                        entityKey={entityKey}
                        uploadCallback={uploadCallback}
                        onAccept={onAcceptAdd}
                        onCancel={() => setAdd(false)}
                    />
                )
            }
            {
                remove && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("RemoveSelectedFiles") + "?"}
                        acceptButtonDisabled={loading}
                        cancelButtonDisabled={loading}
                        onAccept={onAcceptDelete}
                        onCancel={() => setRemove(false)}
                        maxWidth={300}
                    />
                )
            }
            {
                downLoadUrl && (
                    <DownloadFile url={downLoadUrl} />
                )
            }
        </>

    )
}
