import React from 'react';
import { DetailsList } from 'components/common';
import { RelationListItemFields } from 'enums/fields';
import { DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react';
import { IRelationSearchListProps } from './interfaces';

export const RelationSearchList = (props: IRelationSearchListProps) => {
    return (
        <DetailsList
            detailsListColumns={[
                {
                    fieldName: RelationListItemFields.NAME,
                    width: 185
                },
                {
                    fieldName: RelationListItemFields.COUNTRY_ID,
                    name: "Country",
                    width: 70
                }
            ]}
            compact={true}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.single}
            items={props.relations || []}
            onActiveItemChanged={props.onActiveRelationItemChanged}
        />
    );
};
