export enum OrderViewModelFields {
     CONFIRMATION_SENT = "ConfirmationSent",
     CONFIRMATION_SENT_ON = "ConfirmationSentOn",
     CUSTOMER_DISCOUNT = "CustomerDiscount",
     FROM_RELATION_ROW_GUID = "FromRelationRowGuid",
     PAYMENT_CONDITION_NAME = "PaymentConditionName",
     PAYMENT_INSTALLMENTS = "PaymentInstallments",
     PAYMENT_TERM_IN_DAYS = "PaymentTermInDays",
     PRESALES_DISCOUNT = "PresalesDiscount",
     RELATIONROW_GUID = "RelationRowGuid",
     SIGNED = "Signed",
     SIGNED_ON = "SignedOn",
     TO_RELATION_ROW_GUID = "ToRelationRowGuid",
     WAREHOUSE_RELATION_IS_REQUIRED = "WarehouseRelationIsRequired"
}