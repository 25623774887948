import React, { useState, useEffect } from 'react';
import { TitleBar, Accordion, AccordionItem, StatisticsCard, Loader } from 'components/common';
import { Stack, StackItem, mergeStyleSets, DefaultPalette, FontWeights, FontSizes, IStyle, IStackTokens, Icon } from 'office-ui-fabric-react';
import { DictionaryAPI, StatisticAPI } from 'api';
import { IDashBoardState } from './interfaces';
import { OrderSubDocumentTypes, DocumentDetailStatuses, DocumentStatuses } from 'enums';
import { useNumberFormat } from 'hooks';
import { useHistory } from 'react-router-dom';

const accordionItemLabeltyle: IStyle = {
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.mediumPlus
}

const cardContainerStackTokens: IStackTokens = {
    padding: 16,
    childrenGap: 24
}

const { getNumber } = useNumberFormat();


export const Dashboard = () => {

    const [state, setState] = useState<IDashBoardState>({ isLoading: true });

    const { push } = useHistory();

    useEffect(() => {
        StatisticAPI.getOrderStatistics(OrderSubDocumentTypes.Sales,
            (salesOrderStatistics) => {
                StatisticAPI.getOrderStatistics(OrderSubDocumentTypes.Stock,
                    (stockOrderStatistics) => {
                        setState({
                            isLoading: false,
                            salesOrderStatistics: salesOrderStatistics,
                            stockOrderStatistics: stockOrderStatistics
                        });
                    });
            });
    }, [])

    return state.isLoading ? <Loader /> : (
        <Stack horizontalAlign="stretch" tokens={{ padding: 8, childrenGap: 16 }}>
            <StackItem>
                <TitleBar iconName="ViewDashboard" title="Dashboard" />
            </StackItem>
            <StackItem grow>
                {
                    (state.salesOrderStatistics?.TotalQuantity > 0 || state.stockOrderStatistics?.TotalQuantity > 0) && (
                        <Accordion>
                            <AccordionItem labelStyle={accordionItemLabeltyle} isExpandedByDefault header={DictionaryAPI.getTranslation("SalesOrders")}>
                                <Stack horizontal tokens={cardContainerStackTokens} wrap>
                                    {
                                        state.salesOrderStatistics?.Pending && (
                                            <StatisticsCard
                                                value={getNumber(state.salesOrderStatistics.Pending)}
                                                iconName="Page"
                                                label={DictionaryAPI.getTranslation("Pending")}
                                                color={DefaultPalette.blue}
                                                onClick={() => push(`/sales/orders/_/${DocumentStatuses.Pending}`)}
                                            />
                                        )
                                    }
                                    {
                                        state.salesOrderStatistics?.Processing && (
                                            <StatisticsCard
                                                value={getNumber(state.salesOrderStatistics.Processing)}
                                                iconName="PageEdit"
                                                label={DictionaryAPI.getTranslation("Processing")}
                                                color={DefaultPalette.orangeLighter}
                                                onClick={() => push(`/sales/orders/_/${DocumentStatuses.Processing}`)}
                                            />
                                        )
                                    }
                                    {
                                        state.salesOrderStatistics?.Executed && (
                                            <StatisticsCard
                                                value={getNumber(state.salesOrderStatistics.Executed)}
                                                iconName="PageSolid"
                                                label={DictionaryAPI.getTranslation("Executed")}
                                                color={DefaultPalette.green}
                                                onClick={() => push(`/sales/orders/_/${DocumentStatuses.Executed}`)}
                                            />
                                        )
                                    }
                                    {
                                        state.salesOrderStatistics?.Cancelled && (
                                            <StatisticsCard
                                                value={getNumber(state.salesOrderStatistics.Cancelled)}
                                                iconName="PageRemove"
                                                label={DictionaryAPI.getTranslation("Cancelled")}
                                                color={DefaultPalette.red}
                                                onClick={() => push(`/sales/orders/_/${DocumentStatuses.Cancelled}`)}
                                            />
                                        )
                                    }
                                </Stack>
                            </AccordionItem>
                            <AccordionItem labelStyle={accordionItemLabeltyle} header={DictionaryAPI.getTranslation("StockOrders")}>
                                <Stack horizontal tokens={cardContainerStackTokens} wrap>
                                    {
                                        state.stockOrderStatistics?.Pending && (
                                            <StatisticsCard
                                                value={getNumber(state.stockOrderStatistics.Pending)}
                                                iconName="ShoppingCart"
                                                label={DictionaryAPI.getTranslation("Pending")}
                                                color={DefaultPalette.blue}
                                            />
                                        )
                                    }
                                    {
                                        state.stockOrderStatistics?.Processing && (
                                            <StatisticsCard
                                                value={getNumber(state.stockOrderStatistics.Processing)}
                                                iconName="PageEdit"
                                                label={DictionaryAPI.getTranslation("Processing")}
                                                color={DefaultPalette.orangeLighter}
                                            />
                                        )
                                    }
                                    {
                                        state.stockOrderStatistics?.Executed && (
                                            <StatisticsCard
                                                value={getNumber(state.stockOrderStatistics.Executed)}
                                                iconName="PageSolid"
                                                label={DictionaryAPI.getTranslation("Executed")}
                                                color={DefaultPalette.green}
                                            />
                                        )
                                    }
                                    {
                                        state.stockOrderStatistics?.Shipped && (
                                            <StatisticsCard
                                                value={getNumber(state.stockOrderStatistics.Shipped)}
                                                iconName="DeliveryTruck"
                                                label={DictionaryAPI.getTranslation("Shipped")}
                                                color={DefaultPalette.purpleLight}
                                            />
                                        )
                                    }
                                    {
                                        state.stockOrderStatistics?.Cancelled && (
                                            <StatisticsCard
                                                value={getNumber(state.stockOrderStatistics?.Cancelled)}
                                                iconName="PageRemove"
                                                label={DictionaryAPI.getTranslation("Cancelled")}
                                                color={DefaultPalette.red}
                                            />
                                        )
                                    }
                                </Stack>
                            </AccordionItem>
                        </Accordion>
                    )
                }
            </StackItem>
        </Stack>
    );
}

