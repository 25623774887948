import { getTheme, IRawStyle, mergeStyleSets } from 'office-ui-fabric-react';
import { ITileListClassNames } from './interfaces';

const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 300;

const useStyles = () => {

    const { palette, semanticColors } = getTheme();

/*     const columnsPerRow = (): number => {
        if (itemIndex === 0 && visibleRect) {
            _columnCount = Math.ceil((visibleRect.width) / MAX_ROW_HEIGHT);
            _columnWidth = Math.floor((visibleRect.width) / _columnCount);
            _rowHeight = _columnWidth;
        }
    
        return _columnCount * ROWS_PER_PAGE;
    } */

    const listTileStyle: IRawStyle = {
        textAlign: 'center',
        outline: 'none',
        position: 'relative',
        boxSizing: 'border-box',
        float: 'left',
        border: `4px solid ${palette.white}`,
        selectors: {
            '&:hover .ms-Check': { visibility: "visible" },
        }
    }

    const classNames: ITileListClassNames = mergeStyleSets({
        list: {
            position: 'relative',
            cursor: 'default'
        },
        listTile: [listTileStyle, {
            selectors: {
                '&:hover': {
                    background: semanticColors.listItemBackgroundHovered
                },
            }
        }],
        listTileSelected: [listTileStyle, {
            background: semanticColors.listItemBackgroundHovered,
            selectors: {
                '&:hover': {
                    background: semanticColors.listItemBackgroundCheckedHovered
                },
            }
        }],
        listSizer: {
            paddingBottom: '100%',
        },
        listPadder: {
            position: 'absolute',
            left: 25,
            top: 25,
            right: 25,
            bottom: 15,
            cursor: "pointer"

        },
        listTileLabel: {
            marginLeft: 5,
            marginRight: 5,
            whiteSpace: "noWrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        listTileCheckbox: {
            position: 'absolute',
            top: 7,
            right: 7,
            zIndex: 1,
            selectors: {
                '& .ms-Check': { visibility: "hidden" },
            }
        }
    });


    return {
        classNames
    }
}

export default useStyles