import { DictionaryAPI } from 'api';
import { DocumentDetailStatuses } from 'enums';
import { DefaultPalette } from 'office-ui-fabric-react';
import React from 'react'
import { IDocumentDetailStatusSpanProps } from './interfaces'

export const DocumentDetailStatusSpan = (props: IDocumentDetailStatusSpanProps) => {

    const { documentDetailStatusId } = props;

    const getStyle = (): React.CSSProperties => {
        switch (documentDetailStatusId) {
            case DocumentDetailStatuses.Processing:
                return { color: DefaultPalette.orangeLight }
            case DocumentDetailStatuses.Depot:
                return { color: DefaultPalette.purpleLight }
            case DocumentDetailStatuses.Delivered:
                return { color: DefaultPalette.green }
            case DocumentDetailStatuses.Cancelled:
            case DocumentDetailStatuses.Cancelled_Company:
            case DocumentDetailStatuses.Cancelled_Customer:
            case DocumentDetailStatuses.Cancelled_Production:
                return { color: DefaultPalette.red }
            default:
                return { color: DefaultPalette.blue }
        }
    }


    return (
        <span style={getStyle()}>
            {DictionaryAPI.getTranslation(DocumentDetailStatuses[documentDetailStatusId])}
        </span>
    )
}
