import React from 'react'
import { ISwatchCheckBoxProps } from './interfaces'
import "./styles.css"

export const SwatchCheckBox = (props: ISwatchCheckBoxProps) => {

    const { name, checked, index, background, onChange } = props;

    return (
        <label
            className="swatch-check-box-container"
        >
            <input 
                name={name} 
                type="checkbox" 
                checked={checked}
                onChange={(e) => onChange(e, !checked, index)} 
            />
            <span
                className="swatch-check-box" style={{ background }}></span>
        </label>
    )
}
