import React, { useEffect } from 'react'
import { IRelationDialogProps } from './interfaces'
import { Dialog, DatePicker, RelationSelector } from 'components/common';
import { DictionaryAPI, CatalogAPI } from 'api';
import { RelationTypes } from 'enums';
import { Stack, StackItem } from 'office-ui-fabric-react';
import { ISelectCatalogRelationViewModel } from 'interfaces/viewmodels';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IAccount } from 'interfaces/models';
import { useForm } from 'hooks';
import { SelectCatalogRelationViewModelFields } from 'enums/fields';

export const RelationDialog = (props: IRelationDialogProps) => {

    const { catalogRowGuid, relationTypeId, relationRowGuid, onAccept, onCancel } = props;
    const account = useSelector<RootState, IAccount>(s => s.authenticationState.account);
    const { CultureId } = account;

    const { RELATIONROW_GUID, CATALOG_ACTIVE_FROM, CATALOG_ACTIVE_TO } = SelectCatalogRelationViewModelFields;

    const getSelectRelation = (relationRowGuid: string) => {

        const item: ISelectCatalogRelationViewModel = {
            CatalogRowGuid: catalogRowGuid,
            CatalogActiveFrom: null,
            CatalogActiveTo: null,
            RelationRowGuid: relationRowGuid
        };

        if ((relationRowGuid || "") === "") {
            initialize(true, item);
        } else {
            CatalogAPI.getSelectRelation(relationRowGuid, catalogRowGuid,
                (data) => initialize(true, data))
        }
    }

    const onChange = (name: string, value: any, initialValue?: any) => {
        if (name === RELATIONROW_GUID) {
            getSelectRelation(value);
        }
    }

    const {
        item,
        initialize,
        updateItem,
        onDatePickerSelectDate,
        getErrorMessage,
        submit,
        isSubmitting } = useForm<ISelectCatalogRelationViewModel>(undefined, onChange, CatalogAPI.saveRelationSelection);

    const _onAccept = () => {
        submit(onAccept, undefined, true);
    }

    useEffect(() => {
        getSelectRelation(relationRowGuid);
    }, [relationRowGuid])

    return (
        <Dialog
            title={DictionaryAPI.getTranslation(`${(relationRowGuid || "") === "" ? "Add" : "Edit"}`)}
            maxWidth={450}
            isLoading={!item}
            acceptButtonDisabled={isSubmitting || (item?.RelationRowGuid || "") === ""}
            cancelButtonDisabled={isSubmitting}
            onAccept={_onAccept}
            onCancel={onCancel}
        >
            {
                item && (
                    <Stack tokens={{ childrenGap: 5 }} style={{ width: 325 }}>
                        <StackItem>
                            <RelationSelector
                                required
                                editNotAllowed
                                searchEnabled
                                readOnly={(relationRowGuid || "") !== ""}
                                rowGuidFieldName={RELATIONROW_GUID}
                                rowGuid={item.RelationRowGuid}
                                parentContactId={account.Privilege.CompanyContactId}
                                companyRelationId={account.Privilege.CompanyRelationId}
                                relationTypeIds={[relationTypeId]}
                                labelText={DictionaryAPI.getTranslation(RelationTypes[relationTypeId])}
                                errorMessage={getErrorMessage(RELATIONROW_GUID)}
                                onChange={(rowGuidFieldName, rowGuid) => updateItem(rowGuidFieldName, rowGuid)}
                            />
                        </StackItem>
                        <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                            <StackItem grow>
                                <DatePicker
                                    cultureId={CultureId}
                                    date={item.CatalogActiveFrom}
                                    name={CATALOG_ACTIVE_FROM}
                                    placeholder="ActiveFrom"
                                    minDate={item.CatalogActiveFrom}
                                    maxDate={item.CatalogActiveTo}
                                    disabled={(item.RelationRowGuid || "") === ""}
                                    onSelectDate={onDatePickerSelectDate} />
                            </StackItem>
                            <StackItem grow>
                                <DatePicker
                                    cultureId={CultureId}
                                    date={item.CatalogActiveTo}
                                    name={CATALOG_ACTIVE_TO}
                                    placeholder="ActiveTo"
                                    minDate={item.CatalogActiveFrom}
                                    maxDate={item.CatalogActiveTo}
                                    disabled={(item.RelationRowGuid || "") === ""}
                                    onSelectDate={onDatePickerSelectDate} />
                            </StackItem>
                        </Stack>
                    </Stack>
                )
            }
        </Dialog>
    )
}
