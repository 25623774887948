import { Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react'
import { CommandBar } from '../CommandBar';
import { WindowModal } from '../WindowModal';
import { IShareAttachmentWindowProps } from './interfaces';

export const ShareAttachmentWindow = (props: IShareAttachmentWindowProps) => {

    const { title, src, onEmailClick, onDismiss } = props;

    return (
        <WindowModal
            isFullSize
            isOpen
            isBlocking
            titleBarProps={{
                title: title,
            }}

            onDismiss={onDismiss}>
            <Stack verticalFill>
                <StackItem>
                    <CommandBar
                        items={[
                            {
                                key: "Email",
                                text: "E-mail",
                                iconProps: {
                                    iconName: "Mail"
                                },
                                onClick: () => onEmailClick()
                            }
                        ]}
                    />
                </StackItem>
                <StackItem grow>
                    <iframe
                        style={{ border: 0, width: "100%", height: "100%" }}
                        src={src}
                    >
                        [Your browser does <em>not</em> support <code>iframe</code>,
                        or has been configured not to display inline frames.]
                    </iframe>
                </StackItem>
            </Stack>
        </WindowModal>
    )
}
