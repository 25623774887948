import { IAccount } from 'interfaces/models'
import { AuthenticationTypes } from './types'

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ACCOUNT_IS_FETCHING = "SET_AUTHENTICATION_IS_FETCHING"

export function setAccount(account?: IAccount): AuthenticationTypes {
    return {
        type: SET_ACCOUNT,
        account: account
    }
}