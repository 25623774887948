import React, { useState, useRef } from 'react';
import { DocumentAPI, DictionaryAPI, MenuAPI } from 'api';
import { IDocumentBaseViewModel } from 'interfaces/viewmodels';
import { IDetailsTabProps, IDocumentFormLayoutProps } from './interfaces';
import { IFormProps, useDates } from 'hooks';
import { DocumentStatuses } from 'enums/lgs/DocumentStatuses';
import { DocumentFormTabMenus, DocumentFormInteractionMenus, DocumentTypes, FormMenus, OrderReports } from 'enums';
import { Stack, StackItem, getTheme, IContextualMenuItem } from 'office-ui-fabric-react';
import { Dialog, ScrollableContainer } from 'components/common';
import { DetailsTab } from './DetailsTab';
import { EventLogList } from 'components/common';
import { FormLayout } from 'components/layouts';
import { IDocumentSummary, IMenu } from 'interfaces/models';
import { CancelDocumentDialog } from './CancelDocumentDialog';
import { ProductsTab } from './ProductsTab';
import { AttachmentsListView } from 'components/common/AttachmentsListView';
import { CopyDialog } from './CopyDialog';

export const DocumentFormLayout = <T extends IDocumentBaseViewModel>(props: IDocumentFormLayoutProps<T>) => {

    const {
        documentRowGuid,
        documentTypeId,
        copyInteractionMenuId,
        printInteractionMenuId,
        exportInteractionMenuId,
        cultureId,
        addMultipleProductsAllowed,
        loading,
        itemDependencies,
        copyDialogProps,
        getItem,
        updateItem,
        deleteItem,
        onDocumentConfirmed,
        getTabMenuContent,
        onRenderInformationPanelContent,
        onCopyInteractionMenuClick,
        onPrintInteractionMenuClick,
        onExportInteractionMenuClick,
        onCopyClick,
        onDismiss
    } = props;

    const formMenu = MenuAPI.find(FormMenus.Document);

    const [documentSummary, setDocumentSummary] = useState<IDocumentSummary>();
    const [updateDocumentStatusInteractionMenuId, setUpdateDocumentStatusInteractionMenuId] = useState<number>();
    const [updatedDocumentStatusInteractionMenuId, setUpdatedDocumentStatusInteractionMenuId] = useState<number>();
    const documentUpdatedRef = useRef<boolean>();

    const { dateToLocaleDateString } = useDates(cultureId);
 

    const theme = getTheme();

    const _getItem = (onSuccess: (item?: T, windowTitle?: string, windowSubTitle?: string, isActive?: boolean) => void) => {
        getItem((item, windowTitle, windowSubTitle) => {

            setUpdateDocumentStatusInteractionMenuId(undefined);
            setDocumentSummary(item.DocumentSummary);
            onSuccess(item, windowTitle, windowSubTitle);

        });
    }

    const _deleteItem = (item: T, onSuccess: () => void, onError: (xhr: XMLHttpRequest) => void) => {
        if (deleteItem) {
            deleteItem(item, onSuccess, onError);
        } else {
            DocumentAPI.deleteDocument(item.DocumentRowGuid, onSuccess, onError);
        }
    }

    const getDocumentSummary = () => {
        DocumentAPI.getDocumentSummary(documentRowGuid, 0, (data) => {
            setDocumentSummary(data);
        });
    }

    const onDetailsUpdated = () => {
        documentUpdatedRef.current = true;
        getDocumentSummary();
    }

    const onRenderTopPanel = (form?: IFormProps<T>): JSX.Element => {

        const order = form.item;

        return (
            <Stack tokens={{ childrenGap: 15 }} horizontal>
                <StackItem>
                    <span className="ms-fontWeight-semibold">
                        {DictionaryAPI.getTranslation("Created")}
                    </span> {dateToLocaleDateString(order.CreatedOn)}
                </StackItem>
                {
                    order.ConfirmedOn && (
                        <StackItem>
                            <span className="ms-fontWeight-semibold">
                                {DictionaryAPI.getTranslation("Confirmed")}
                            </span> {dateToLocaleDateString(order.ConfirmedOn)}
                        </StackItem>
                    )
                }
                {
                    order.ExecutedOn && (
                        <StackItem>
                            <span className="ms-fontWeight-semibold">
                                {DictionaryAPI.getTranslation("Executed")}
                            </span> {dateToLocaleDateString(order.ExecutedOn)}
                        </StackItem>
                    )
                }
                <StackItem grow styles={{ root: { textAlign: "right" } }}>
                    <span className="ms-fontWeight-semibold">
                        {order.Reference}
                    </span>
                </StackItem>
            </Stack>
        )
    }

    const onRenderInformationPanel = (form?: IFormProps<T>) => {
        return (
            <ScrollableContainer
                width={250}
                padding={10}
            >
                <div
                    className="ms-depth-8"
                    style={{ padding: 10, backgroundColor: theme.semanticColors.bodyBackground }}
                >
                    {onRenderInformationPanelContent && onRenderInformationPanelContent(form, documentSummary)}
                </div>
            </ScrollableContainer>
        )
    }

    const _getTabMenuContent = (tabMenuId: number, form?: IFormProps<T>): JSX.Element | undefined => {
        const document = form.item;
        const tabMenu = MenuAPI.find(tabMenuId);

        const detailsTabProps: IDetailsTabProps = {
            readOnly: document.IsReadOnly,
            documentRowGuid: documentRowGuid,
            tabMenu: tabMenu,
            formMenu: formMenu,
            document: document,
            cultureId: cultureId,
            checkable: addMultipleProductsAllowed,
            onDetailsUpdated
        }

        switch (tabMenuId) {
            case DocumentFormTabMenus.Products:
                return (
                    <ProductsTab {...detailsTabProps} />
                )

            case DocumentFormTabMenus.Details:
                return (
                    <DetailsTab {...detailsTabProps} />
                )

            case DocumentFormTabMenus.Attachments:
                return (
                    <AttachmentsListView
                        menu={tabMenu}
                        entityKey={documentRowGuid}
                        cultureId={cultureId}
                        readCallback={DocumentAPI.readAttachments}
                        uploadCallback={DocumentAPI.uploadAttachment}
                        getDownloadUrlCallback={DocumentAPI.getDownloadAttachmentUrl}
                        removeCallback={DocumentAPI.removeAttachments}
                    />
                )

            case DocumentFormTabMenus.Log:
                return (
                    <EventLogList
                        cultureId={cultureId}
                        getFilterContext={(onSuccess) => DocumentAPI.getEventLogFilterContext(documentRowGuid, onSuccess)}
                    />
                )
            default:
                if (getTabMenuContent) {
                    return getTabMenuContent(tabMenuId, form, documentSummary);
                }
                break;
        }
    }

    const getCopyContextualMenuItems = (form?: IFormProps<T>): IContextualMenuItem[] | undefined => {

        if (copyInteractionMenuId) {

            return MenuAPI.filterByParent(copyInteractionMenuId).map((v) => {
                return {
                    key: String(v.Id),
                    text: DictionaryAPI.getTranslation(v.Name),
                    onClick: () => onCopyInteractionMenuClick(v.Id)
                }
            })
        }

        return undefined;
    }

    const getPrintContextualMenuItems = (form?: IFormProps<T>): IContextualMenuItem[] | undefined => {

        if (printInteractionMenuId) {

            return MenuAPI.filterByParent(printInteractionMenuId).filter(i => !form.item.PrintInteractionMenuIds || form.item.PrintInteractionMenuIds?.includes(i.Id)).map((v) => {
                return {
                    key: String(v.Id),
                    text: DictionaryAPI.getTranslation(v.Name),
                    onClick: () => onPrintInteractionMenuClick(v.Id)
                }
            })
        }

        return undefined;
    }

    const getExportContextualMenuItems = (): IContextualMenuItem[] | undefined => {
        if (exportInteractionMenuId) {

            return MenuAPI.filterByParent(exportInteractionMenuId).map((v) => {
                return {
                    key: String(v.Id),
                    text: DictionaryAPI.getTranslation(v.Name),
                    onClick: () => onExportInteractionMenuClick(v.Id)
                }
            })
        }

        return undefined;
    }

    const getDocumentStatusContextualMenuItems = (form?: IFormProps<T>): IContextualMenuItem[] | undefined => {
        const items: IContextualMenuItem[] = [];

        for (const action of MenuAPI.filterByParent(DocumentFormInteractionMenus.Status)) {

            if (form.item.DocumentStatusInteractionMenuIds.find(Id => Id === action.Id)) {
                items.push({
                    key: String(action.Id),
                    text: action.translatedName,
                    iconProps: { iconName: action.iconName },
                    onClick: () => {
                        form.submit(
                            () => setUpdateDocumentStatusInteractionMenuId(action.Id),
                            undefined,
                            false,
                            true
                        );
                    }
                });
            }
        }

        return items.length > 0 ? items : undefined;
    }

    const onAcceptDocumentStatusUpdate = () => {
        switch (updateDocumentStatusInteractionMenuId) {
            case DocumentFormInteractionMenus.Status_Confirm:
                DocumentAPI.confirmDocument(documentRowGuid,
                    () => {
                        onDocumentStatusUpdated();
                        onDocumentConfirmed && onDocumentConfirmed();
                    });
                break;
            case DocumentFormInteractionMenus.Status_Execute:
                DocumentAPI.executeDocument(documentRowGuid, onDocumentStatusUpdated)
                break;
            case DocumentFormInteractionMenus.Status_Edit:
                DocumentAPI.editDocument(documentRowGuid, onDocumentStatusUpdated)
                break;
            default:
                break;
        }
    }

    const onDocumentStatusUpdated = () => {
        setUpdatedDocumentStatusInteractionMenuId(updateDocumentStatusInteractionMenuId);
    }

    const getAccentColour = (form?: IFormProps<T>): string => {
        switch (form?.item?.DocumentStatusId) {
            case DocumentStatuses.Processing:
                return theme.palette.orangeLighter;
            case DocumentStatuses.Executed:
                return theme.palette.greenLight;
            case DocumentStatuses.Cancelled_Company:
            case DocumentStatuses.Cancelled_Production:
            case DocumentStatuses.Cancelled_Customer:
                return theme.palette.red;
        }
        return theme.palette.themePrimary;
    }

    const _onDismiss = (changesUpdated?: boolean) => {
        onDismiss(changesUpdated || documentUpdatedRef.current || updatedDocumentStatusInteractionMenuId !== undefined);
    }

    return (
        <>
            <FormLayout<T>
                informationPanelVisibleByDefault
                width={950}
                height={750}
                formMenu={formMenu}
                getCopyContextMenuItems={getCopyContextualMenuItems}
                getPrintContextMenuItems={getPrintContextualMenuItems}
                getExportContextMenuItems={getExportContextualMenuItems}
                deleteDisabled={documentSummary?.Quantity !== 0}
                itemDependencies={[documentRowGuid, updatedDocumentStatusInteractionMenuId, ...itemDependencies || []]}
                loading={loading}
                getAccentColour={getAccentColour}
                getCommandbarItems={(form) => [
                    {
                        key: "DocumentStatus",
                        disabled: form.hasChanges,
                        ariaLabel: "DocumentStatus",
                        text: DictionaryAPI.getTranslation(DocumentStatuses[form.item.DocumentStatusId]),
                        subMenuProps: {
                            items: getDocumentStatusContextualMenuItems(form)
                        },
                    }
                ]}
                onRenderTopPanel={onRenderTopPanel}
                onRenderInformationPanel={onRenderInformationPanel}
                onCopyClick={onCopyClick}
                getItem={_getItem}
                updateItem={updateItem}
                deleteItem={_deleteItem}
                getTabMenuContent={_getTabMenuContent}
                onDismiss={_onDismiss}
            />
            {
                copyDialogProps && (
                    <CopyDialog {...copyDialogProps} />
                )
            }
            {
                (updateDocumentStatusInteractionMenuId && updateDocumentStatusInteractionMenuId !== DocumentFormInteractionMenus.Status_Cancel) && (
                    <Dialog
                        maxWidth={300}
                        title={DictionaryAPI.getTranslation(DocumentFormInteractionMenus[updateDocumentStatusInteractionMenuId])}
                        subText={DictionaryAPI.getTranslation(DocumentFormInteractionMenus[updateDocumentStatusInteractionMenuId] + DictionaryAPI.getTranslation(DocumentTypes[documentTypeId]) + "?")}
                        onAccept={onAcceptDocumentStatusUpdate}
                        onCancel={() => setUpdateDocumentStatusInteractionMenuId(undefined)}
                    />
                )
            }
            {
                updateDocumentStatusInteractionMenuId === DocumentFormInteractionMenus.Status_Cancel && (
                    <CancelDocumentDialog
                        documentRowGuid={documentRowGuid}
                        onSuccess={onDocumentStatusUpdated}
                        onCancel={() => setUpdateDocumentStatusInteractionMenuId(undefined)}
                    />
                )
            }
            
        </>

    );
}