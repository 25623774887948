import { FontWeights, getTheme, IconButton, ImageFit, mergeStyleSets, Modal, ScrollablePane, Stack, StackItem } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'components/common';
import { IImageCarouselProps } from './interfaces'

export const ImageCarousel = (props: IImageCarouselProps) => {

    const { images, width, height, defaultSelectedIndex, containerStyle, onClick } = props;
    const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex || 0);

    const theme = getTheme();

    const slideStyle: React.CSSProperties = {
        cursor: "pointer",
            width, 
            height, 
            backgroundColor: theme.semanticColors.bodyBackground
    }

    const renderThumbs = (children: React.ReactChild[]): React.ReactChild[] => {
        return images.map((v, i) => {
            return (
                <Image
                    key={`imageCarouselThumb_${i}`}
                    src={v.smallSrc || v.largeSrc}
                    width={70}
                    height={70}
                    imageFit={ImageFit.contain}
                />
            )
        })
    }

    return (
        <div style={containerStyle}>
            <Carousel
                showStatus={false}
                showIndicators={false}
                renderThumbs={renderThumbs}
            >
                {
                    images.map((v, i) => {
                        return (
                            <div 
                                key={`imageCarouselSlide${i}`} 
                                style={slideStyle}
                                onClick={onClick ? () => onClick(i): undefined}
                            >
                                <Image
                                    src={v.largeSrc}
                                    width={"100%"}
                                    height={"100%"}
                                    imageFit={ImageFit.contain}
                                />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>

    )
}
