import React, { useState, useRef, useEffect } from 'react';
import { IFormLayoutProps } from './interfaces';

import {
    Stack, StackItem,
    Pivot,
    PivotItem,
    getTheme,
    IStyle,
    MessageBar,
    MessageBarType,
    ICommandBarItemProps,
} from 'office-ui-fabric-react';

import { DictionaryAPI, MenuAPI } from 'api';
import { DefaultCommandTypes, MenuTypes } from 'enums';
import { CommandBar, Dialog, LoaderModal, WindowModal } from 'components/common';
import { IDefaultCommand } from 'interfaces';
import { useForm } from 'hooks';

export const FormLayout = <T extends unknown>(props: IFormLayoutProps<T>) => {

    const {
        formMenu: menu,
        width,
        height,
        deleteDisabled,
        itemDependencies,
        loading,
        informationPanelVisibleByDefault,
        getAccentColour,
        getCopyContextMenuItems,
        getPrintContextMenuItems,
        getExportContextMenuItems,
        getCommandbarItems,
        getTabMenuContent,
        onRenderTopPanel,
        onRenderInformationPanel,
        onDefaultCommandClick,
        onCopyClick,
        getItem,
        updateItem,
        deleteItem,
        toggleIsActive,
        onFormChangeCallback,
        onDismiss
    } = props;

    const form = useForm<T>(undefined, onFormChangeCallback, updateItem);
    const { item, hasChanges, errorMessages: formErrorMessages, isSubmitting, initialize, submit } = form;
    const tabMenus = menu && MenuAPI.filterByType(MenuTypes.Tab, menu.Id);
    
    const [isFetching, setIsFetching] = useState<boolean>();
    const [tabMenuId, setTabMenuId] = useState<number>(tabMenus[0].Id);
    const [notifyClose, setNotifyClose] = useState<boolean>(false);
    const [notifyDelete, setNotifyDelete] = useState<boolean>(false);
    const [informationButtonChecked, setInformationButtonChecked] = useState<boolean>(informationPanelVisibleByDefault);
    const [errorMessage, setErrorMessage] = useState<string>();

    const isActiveRef = useRef<boolean>();
    const windowTitleRef = useRef<string>(menu.translatedName);
    const windowSubTitleRef = useRef<string>();
    const changesUpdatedRef = useRef<boolean>(false);

    const theme = getTheme();

    const topPanelStyles: IStyle = {
        padding: 10,
        borderBottom: "1px solid " + theme.semanticColors.bodyFrameDivider,
    }

    const informationPanelStyles: IStyle = {
        borderLeft: "1px solid " + theme.semanticColors.bodyFrameDivider,
        backgroundColor: theme.palette.neutralLighterAlt
    }

    const getDefaultCommands = (): IDefaultCommand[] => {

        const commands: IDefaultCommand[] = [];

        commands.push({ type: DefaultCommandTypes.Save, isDisabled: !hasChanges });

        if (isActiveRef.current !== undefined) {
            commands.push({ type: DefaultCommandTypes.ToggleActive, isDisabled: hasChanges });
        }

        if (getCopyContextMenuItems || onCopyClick) {
            const items = getCopyContextMenuItems && getCopyContextMenuItems(form);
            commands.push({
                type: DefaultCommandTypes.Copy,
                isDisabled: hasChanges,
                subMenuItems: items
            });
        }

        if (getPrintContextMenuItems) {
            const items = getPrintContextMenuItems(form);
            commands.push({
                type: DefaultCommandTypes.Print,
                isDisabled: hasChanges || items.length === 0,
                subMenuItems: items
            });
        }

        if (getExportContextMenuItems) {
            const items = getExportContextMenuItems(form);
            commands.push({
                type: DefaultCommandTypes.Export,
                isDisabled: hasChanges || items.length === 0,
                subMenuItems: items
            });
        }

        commands.push({ type: DefaultCommandTypes.Delete, isDisabled: deleteDisabled });

        return commands;
    }

    const getFarItems = (): ICommandBarItemProps[] => {

        const items: ICommandBarItemProps[] = [];

        if (onRenderInformationPanel) {
            items.push({
                iconOnly: true,
                key: "informationButton",
                name: DictionaryAPI.getTranslation("Information"),
                iconProps: { iconName: "Info" },
                checked: informationButtonChecked,
                onClick: () => {
                    setInformationButtonChecked((v) => !v)
                }
            });
        }

        return items;
    }

    const _onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Save:
                onSave(() => changesUpdatedRef.current = true, false);
                break;
            case DefaultCommandTypes.Copy:
                onCopyClick();
                break;
            case DefaultCommandTypes.Delete:
                setNotifyDelete(true);
                break;
            case DefaultCommandTypes.ToggleActive:
                toggleIsActive(() => _getItem(() => changesUpdatedRef.current = true))
                break;
            default:
                onDefaultCommandClick && onDefaultCommandClick(type);
                break;
        }
    }

    const _getItem = (onSuccess?: () => void) => {
        getItem((item, windowTitle, windowSubTitle, isActive) => {
            windowTitleRef.current = windowTitle;
            windowSubTitleRef.current = windowSubTitle;
            isActiveRef.current = isActive;
            initialize(true, item);
            onSuccess && onSuccess();
        });
    }

    const onSave = (onSuccess: () => void, isDismissing?: boolean) => {
        if (isDismissing) {
            submit(onSuccess, undefined, isDismissing);
        } else {
            submit(() => {
                _getItem(onSuccess)
            },
                undefined,
                isDismissing);
        }
    }

    const onDelete = () => {
        if (deleteItem) {
            setIsFetching(true);
            deleteItem(
                item,
                () => {
                    onDismiss(true);
                },
                () => {
                    setNotifyDelete(false);
                    setIsFetching(false);
                    setErrorMessage(DictionaryAPI.getTranslation("ThisItemCannotBeDeleted"));
                });
        }
    }

    const _onDismiss = () => {
        if (hasChanges) {
            setNotifyClose(true);
        } else {
            onDismiss(changesUpdatedRef.current);
        }
    }

    useEffect(() => {
        _getItem();
        setIsFetching(false);
    }, itemDependencies);

    useEffect(() => {
        if (formErrorMessages) {
            setErrorMessage(DictionaryAPI.getTranslation("PleaseCheckTheFormForErrors"));
        }
    }, [formErrorMessages])

    return (
        <WindowModal
            width={width === undefined ? 800 : width}
            height={height === undefined ? 700 : height}
            titleBarProps={{
                accentColour: getAccentColour ? getAccentColour(form) : (isActiveRef.current !== undefined ? (isActiveRef.current ? theme.palette.themePrimary : theme.palette.red) : undefined),
                title: windowTitleRef.current,
                subTitle: windowSubTitleRef.current
            }}
            isOpen={true}
            isBlocking={true}
            isLoading={item === undefined}
            onDismiss={_onDismiss}>
            <>
                {
                    item !== undefined && (
                        <Stack horizontalAlign="stretch" verticalFill>
                            <StackItem>
                                <CommandBar
                                    menuPrivilege={menu?.Privilege}
                                    defaultCommands={getDefaultCommands()}
                                    items={getCommandbarItems && getCommandbarItems(form)}
                                    farItems={getFarItems()}
                                    onDefaultCommandClick={_onDefaultCommandClick}
                                    disabled={isSubmitting || isFetching}
                                    isActive={isActiveRef.current}
                                    useNeutralBackground={true}
                                />
                            </StackItem>
                            {
                                errorMessage && (
                                    <StackItem>
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            isMultiline={true}
                                            onDismiss={() => setErrorMessage(undefined)}
                                            dismissButtonAriaLabel={DictionaryAPI.getTranslation("Close")}
                                        >
                                            {errorMessage}
                                        </MessageBar>
                                    </StackItem>
                                )
                            }
                            {
                                onRenderTopPanel && (
                                    <StackItem styles={{ root: topPanelStyles }}>
                                        {onRenderTopPanel(form)}
                                    </StackItem>
                                )
                            }
                            <Stack horizontal verticalFill>
                                <Stack grow verticalFill>
                                    {
                                        tabMenus && tabMenus.length > 0 && (
                                            <StackItem>
                                                <Pivot
                                                    styles={{
                                                        link: hasChanges && { color: theme.semanticColors.disabledText }
                                                    }}
                                                    headersOnly
                                                    selectedKey={String(tabMenuId)}
                                                    onLinkClick={!hasChanges ? (pivotItem) => setTabMenuId(pivotItem ? Number(pivotItem.props.itemKey) : 0) : undefined}>
                                                    {tabMenus.map((v, i) => {
                                                        return <PivotItem key={String(v)} headerText={v.translatedName} itemKey={String(v.Id)} />
                                                    })}
                                                </Pivot>
                                            </StackItem>
                                        )
                                    }
                                    <StackItem grow>
                                        <div style={{ position: "relative", height: "100%" }}>
                                            {getTabMenuContent && getTabMenuContent(tabMenuId, form)}
                                        </div>
                                    </StackItem>
                                </Stack>
                                {
                                    (onRenderInformationPanel && informationButtonChecked) && (
                                        <StackItem styles={{ root: informationPanelStyles }}>
                                            {onRenderInformationPanel(form)}
                                        </StackItem>
                                    )
                                }
                            </Stack>
                        </Stack>
                    )
                }
                {
                    loading && (
                        <LoaderModal />
                    )
                }
                {
                    notifyClose && (
                        <Dialog
                            maxWidth={500}
                            title={DictionaryAPI.getTranslation("Close")}
                            subText={DictionaryAPI.getTranslation("SaveChanges?")}
                            AcceptButtonText={DictionaryAPI.getTranslation("Yes")}
                            CancelButtonText={DictionaryAPI.getTranslation("No")}
                            onAccept={() => onSave(() => onDismiss(true))}
                            onCancel={() => onDismiss(changesUpdatedRef.current)}
                            acceptButtonDisabled={isSubmitting}
                            cancelButtonDisabled={isSubmitting} />
                    )
                }
                {
                    notifyDelete && (
                        <Dialog
                            maxWidth={500}
                            title={DictionaryAPI.getTranslation("Delete")}
                            subText={DictionaryAPI.getTranslation("ItemWillBeDeleted", [windowTitleRef.current || ""])}
                            onAccept={onDelete}
                            onCancel={() => setNotifyDelete(false)}
                            acceptButtonDisabled={isFetching}
                            cancelButtonDisabled={isFetching} />
                    )
                }

            </>
        </WindowModal>
    );
}