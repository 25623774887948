import { IEventLogFilterContext, IFilterResultInfo } from "interfaces/models";
import { BaseAPI } from "../BaseAPI";

export class EventLogAPI {

    public static filter = (filterContext: IEventLogFilterContext, onSuccess: (data: IFilterResultInfo) => void) => {
        BaseAPI.post("Api/App/EventLog/Filter", {
            ...filterContext,
            CatalogId: { ...filterContext.EventLogTypeId, Values: filterContext.EventLogTypeId.Values.filter(v => v.IsChecked) },
            CreatedOn: { ...filterContext.CreatedOn, Values: filterContext.CreatedOn.Values.filter(v => v.IsChecked) },
        }, onSuccess);
    }
}