import React from 'react';
import { Stack, StackItem, getTheme, IButtonStyles, DefaultButton } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IFilterValue } from 'interfaces/models';
import { IToggleButtonFilterProps } from './interfaces';
import { FilterBarItemDropDown } from './FilterBarItemDropDown';

export const ToggleButtonFilter = (props: IToggleButtonFilterProps): JSX.Element => {
    const { filter, name, onChange } = props;

    const buttonStyles: IButtonStyles = {
        root: {
            width: 80
        }
    }

    return (
        <Stack horizontal wrap tokens={{ childrenGap: 10, maxWidth: 260 }}>
            {filter.Values.map((v, i) => {
                return (
                    <StackItem key={i}>
                        <DefaultButton
                            text={filter.ValueNameTranslationRequired ? DictionaryAPI.getTranslation(v.Name) : v.Name}
                            checked={v.IsChecked}
                            styles={buttonStyles}
                            onClick={() => onChange(name, v, !v.IsChecked, true)} />
                    </StackItem>
                )
            })}
        </Stack>
    );
}