import { IRoute } from "./interfaces";

export const useRoute = (): IRoute => {

    const formatUrl = (url: string): string => {
        return encodeURI(url.toLowerCase().replace(" ", ""));
    }

    return {
        formatUrl
    }
}