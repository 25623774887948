export enum DocumentFilterContextFields {
    AGENT_RELATION_ID = "AgentRelationId",
    COLLECTION_ID = "CollectionId",
    CATALOG_ID = "CatalogId",
    COMPANY_RELATION_ID = "CompanyRelationId",
    CONFIRMED_ON = "ConfirmedOn",
    CREATED_ON = "CreatedOn",
    DOCUMENT_STATUS_ID = "DocumentStatusId",
    DOCUMENT_TYPE_ID = "DocumentTypeId",
    FROM_RELATION_ID = "FromRelationId",
    RELATION_COUNTRY_ID = "RelationCountryId",
    RELATION_ID = "RelationId",
    SUB_DOCUMENT_TYPE_ID = "SubDocumentTypeId"
}