import React, { ChangeEvent, useRef, useState } from 'react';
import { Dialog } from 'components/common';
import { AccountAPI, DictionaryAPI } from 'api';
import { MessageBar, MessageBarType, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import { IImpersonateDialogProps } from './interfaces';

export const Impersonate = (props: IImpersonateDialogProps) => {

    const { accountUserName, onDismiss } = props;
    const impersonationUserName = useRef("");
    const adminPasswordRef = useRef("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const _onAccept = () => {
        
        if (impersonationUserName.current && adminPasswordRef.current) {

            setLoading(true);

            AccountAPI.signInByImpersonation(accountUserName, adminPasswordRef.current, impersonationUserName.current,
                onDismiss,
                () => {
                    setErrorMessage(DictionaryAPI.getTranslation("ImpersonationFailed"));
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <Dialog
                title={DictionaryAPI.getTranslation("Impersonate")}
                maxWidth={450}
                acceptButtonDisabled={loading}
                cancelButtonDisabled={loading}
                onAccept={_onAccept}
                onCancel={onDismiss}
            >
                <Stack style={{ width: 300 }}>
                    {
                        errorMessage && (
                            <StackItem>
                                <MessageBar messageBarType={MessageBarType.error}>
                                    {errorMessage}
                                </MessageBar>
                            </StackItem>
                        )
                    }
                    <StackItem>
                        <TextField
                            autoFocus
                            label={DictionaryAPI.getTranslation("ImpersonationUserName")}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => impersonationUserName.current = e.target.value}
                        />
                    </StackItem>
                    <StackItem>
                        <TextField
                            label={DictionaryAPI.getTranslation("AdminPassword")}
                            type="password"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => adminPasswordRef.current = e.target.value}
                        />
                    </StackItem>
                </Stack>
            </Dialog>
        </>

    );
};