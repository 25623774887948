export enum DocumentStatuses {
    Pending = 10,
    Processing = 20,
    Executed = 30,
    Cancelled = 40,
    Cancelled_Company = 41,
    Cancelled_Production = 42,
    Cancelled_Customer = 43,
}

export enum CancelledDocumentStatuses {
    Company = 41,
    Production = 42,
    Customer = 43,
}