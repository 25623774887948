import { ICookies } from "./interfaces";

export const useCookies = (): ICookies => {

    const setCookie = (name: string, value: string, expireDate: Date) => {
        const isSecure: boolean = window.location.protocol.toLowerCase() === "https:";
        const expires = "expires=" + expireDate.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/;" + (isSecure ? "SameSite=None;Secure;" : "");
    }

    const setCookieBySeconds = (name: string, value: string, expiresInSeconds: number) => {
        const d = new Date();
        d.setTime(d.getTime() + (expiresInSeconds * 1000));
        setCookie(name, value, d);
    }

    const getCookie = (name: string): string => {
        const formattedName = name + "=";
        const ca = document.cookie.split(';');
    
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(formattedName) === 0) {
                return c.substring(formattedName.length, c.length);
            }
        }
        return "";
    }


    return {
        setCookie,
        setCookieBySeconds,
        getCookie
    }
}