import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { FontWeights, getTheme, IconButton, ImageFit, mergeStyleSets, Modal } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { Image } from '../Image/Image';
import { IImageModalProps } from './interfaces'
import { Carousel } from 'react-responsive-carousel'

export const ImageModal = (props: IImageModalProps) => {

    const { src, onDismiss } = props;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            width: "100%",
            height: "100%",
            cursor: "pointer",
        },
        carousel: {
            display: 'flex',
            height: "100%"
        }
    });

    return (
        <Modal
            titleAriaId={"imageModalTitle"}
            isOpen={src?.length > 0}
            onDismiss={onDismiss}
            isBlocking={false}
            containerClassName={classNames.container}
        >
            <div style={{ width: "100%", height: "100%" }} onClick={() => onDismiss()}>
                {
                    src?.map((v, i) => {
                        return <Image
                            key={`ImageModalImage${i}`}
                            src={v}
                            imageFit={ImageFit.contain}

                            width={"100%"}
                            height={"100%"}
                        />
                    })
                }
            </div>

            {/* <div style={{ width: "100%", height: "100%" }}>
            <Carousel
                    showThumbs={true}
                    showIndicators
                    showArrows
                    showStatus
                    className={classNames.carousel}
                >
                    {src?.map((v, i) => {
                        return (
                            <div>
                                <img src={getImageUrl(v)} />

                            </div>
                        )
                    })}

                </Carousel>
            </div> */}
                
            

            
        </Modal>

    )
}
