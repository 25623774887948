import { DictionaryAPI } from 'api';
import { useNumberFormat } from 'hooks';
import { DefaultPalette, FontWeights, IStyle, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react'
import { ISalesTrendItemProps } from './interfaces';

const trendTextStyle: IStyle = {
    fontWeight: FontWeights.semibold
}

export const SalesTrendItem = (props: ISalesTrendItemProps) => {

    const { useNumberFormatHook, salesTrend, isCurrent } = props;

    const fixedSalesTrend = salesTrend > 1 ? 1 : salesTrend;

    const { getPercent } = useNumberFormatHook;

    return (
        <Stack
            style={{
                color: fixedSalesTrend > 0 ? DefaultPalette.green : DefaultPalette.red
            }}>
            <Stack horizontal>
                <StackItem grow styles={{ root: trendTextStyle }}>
                    {DictionaryAPI.getTranslation(isCurrent ? "Current" : "Previous")}
                </StackItem>
                <StackItem styles={{ root: trendTextStyle }}>
                    <span>{getPercent(salesTrend)}</span>
                </StackItem>
            </Stack>
            <StackItem styles={{ root: { backgroundColor: DefaultPalette.neutralLighter } }}>
                <div
                    style={{
                        height: 16,
                        width: getPercent(0.5 + (fixedSalesTrend / 2)),
                        backgroundColor: fixedSalesTrend > 0 ? DefaultPalette.green : DefaultPalette.red
                    }}>
                </div>
            </StackItem>
        </Stack>
    )
}
