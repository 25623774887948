import React, { useState, useEffect } from 'react';

import {
    IComboBoxOption,
    Checkbox,
    Stack,
    StackItem,
    MessageBar,
    MessageBarType,
    DefaultPalette,
    List,
    FocusZone,
    FocusZoneDirection,
    getTheme,
    mergeStyleSets,
    getFocusStyle,
    TextField,
    Icon,
} from 'office-ui-fabric-react';

import {
    Dialog,
    ComboBox,
    RelationAddressFields,
    ScrollableContainer,
    AddressRelationListItem
} from 'components/common'

import { ICreateRelationLayoutProps } from '.';
import { DictionaryAPI, RelationAPI } from 'api';
import { RelationTypes } from 'enums';
import { ContactBaseViewModelFields } from 'enums/fields';
import { ICountry, IRelationListItem } from 'interfaces/models';
import { ICreateRelationLayoutState } from './interfaces';
import { ICreateRelationViewModel } from 'interfaces/viewmodels';
import { useForm } from 'hooks';

export const CreateRelationLayout = <T extends ICreateRelationViewModel>(props: ICreateRelationLayoutProps<T>): JSX.Element => {

    const { getContent, getCreateRelation, createRelation, onFormChangeCallback, onSuccess, onCancel } = props;
    const [state, setState] = useState<ICreateRelationLayoutState>({
        acceptButtonDisabled: true,
        notInTheListChecked: false,
        notInTheListCheckboxDisabled: true
    });

    const {
        CONTACT_ID,
        NAME,
        FIRST_NAME,
        LAST_NAME,
        COUNTRY_ID
    } = ContactBaseViewModelFields

    const theme = getTheme();
    const { palette, semanticColors } = theme;

    const classNames = mergeStyleSets({
        existingRelationListItemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                padding: 5,
                boxSizing: 'border-box',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                },
            },
        ],
    })

    const onChange = (name: string, value: any) => {
        if ([NAME.toString(), FIRST_NAME.toString(), LAST_NAME.toString(), COUNTRY_ID.toString()].includes(name)) {

            setState({
                ...state,
                existingRelations: undefined,
                isSearching: false,
                notInTheListChecked: false,
                notInTheListCheckboxDisabled: true,
                acceptButtonDisabled: !getSearchValue() || !item.CountryId,
                existingRelationsVerified: false
            });
        }

        onFormChangeCallback && onFormChangeCallback(form, name, value);
    }

    const form = useForm<T>(undefined, onChange, createRelation);
    const { item, isSubmitting, initialize, updateItem, submit, onTextFieldChange, onComboBoxSimpleChange, getErrorMessage } = form;

    const onRenderCell = (item: IRelationListItem, index: number | undefined): JSX.Element => {
        return (
            <AddressRelationListItem
                relationListItem={item}
                containerClassName={classNames.existingRelationListItemCell}
                isFocusable
            />
        )
    };

    const onNotInTheListCheckedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setState({
            ...state,
            notInTheListChecked: checked || false,
            acceptButtonDisabled: !checked
        });
    }

    const onAccept = () => {
        if (!state.existingRelationsVerified) {

            if (!state.notInTheListChecked) {
                const searchValue = getSearchValue();

                setState({
                    ...state,
                    isSearching: true,
                    acceptButtonDisabled: true
                });

                RelationAPI.search({
                    SearchText: searchValue,
                    ParentContactId: form.item.RelationTypeId !== RelationTypes.Customer ? form.item.ParentContactId : 0,
                    RelationTypeIds: form.item.RelationTypeId !== RelationTypes.Customer ? [form.item.RelationTypeId] : [RelationTypes.Customer, RelationTypes.Prospect],
                    CountryId: item.CountryId,
                    Results: 50
                }, (data) => {
                    setState({
                        ...state,
                        existingRelations: data.length > 0 ? data : undefined,
                        existingRelationsVerified: data.length === 0,
                        isSearching: false,
                        notInTheListChecked: false,
                        notInTheListCheckboxDisabled: data.length === 0,
                        acceptButtonDisabled: data.length > 0
                    });
                });
            } else {
                setState({
                    ...state,
                    existingRelations: undefined,
                    existingRelationsVerified: true
                });
            }
        } else {
            submit((rowGuid) => onSuccess(rowGuid), undefined, true);
        }
    }

    const getSearchValue = (): string => {
        return item ? item.IsPerson ? (item.FirstName || "") + " " + (item.LastName || "") : item.Name || "" : "";
    }

    const _getCreateRelation = () => {
        getCreateRelation((data) => initialize(true, data));
    }

    useEffect(() => {
        _getCreateRelation();
    }, [])

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("New" + RelationTypes[item?.RelationTypeId as number])}
            maxWidth={425}
            isLoading={!item}
            AcceptButtonText={!state.existingRelationsVerified ? DictionaryAPI.getTranslation("Next") : DictionaryAPI.getTranslation("OK")}
            acceptButtonDisabled={state.acceptButtonDisabled || state.isSearching || isSubmitting}
            cancelButtonDisabled={isSubmitting}
            onAccept={onAccept}
            onCancel={onCancel}
        >
            {
                item && (
                    <Stack styles={{ root: { minWidth: 325 } }} tokens={{ childrenGap: 10 }}>
                        {
                            item.IsPerson ?
                                <StackItem>
                                    <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                        <StackItem grow>
                                            <TextField
                                                autoFocus
                                                placeholder={DictionaryAPI.getTranslation(FIRST_NAME)}
                                                errorMessage={getErrorMessage(FIRST_NAME)}
                                                name={FIRST_NAME}
                                                value={item.FirstName}
                                                readOnly={form.item?.ContactId > 0}
                                                onChange={onTextFieldChange} />
                                        </StackItem>
                                        <StackItem grow>
                                            <TextField
                                                placeholder={DictionaryAPI.getTranslation(LAST_NAME)}
                                                errorMessage={getErrorMessage(LAST_NAME)}
                                                name={LAST_NAME}
                                                value={item.LastName}
                                                readOnly={form.item?.ContactId > 0}
                                                onChange={onTextFieldChange} />
                                        </StackItem>
                                    </Stack>
                                </StackItem> :
                                <StackItem>
                                    <TextField
                                        autoFocus
                                        errorMessage={getErrorMessage(NAME)}
                                        placeholder={DictionaryAPI.getTranslation(NAME)}
                                        name={NAME}
                                        value={item.Name}
                                        readOnly={form.item?.ContactId > 0}
                                        onChange={onTextFieldChange} />
                                </StackItem>
                        }
                        {
                            item.Countries?.length > 0 && (
                                <StackItem>
                                    <ComboBox
                                        allowFreeform
                                        autoComplete="on"
                                        placeholder={DictionaryAPI.getTranslation("Country")}
                                        errorMessage={getErrorMessage(COUNTRY_ID)}
                                        selectedKey={item.CountryId}
                                        name={COUNTRY_ID}
                                        options={item.Countries.map((v: ICountry, i): IComboBoxOption => {
                                            return {
                                                key: v.Id,
                                                text: DictionaryAPI.getTranslation("Country_" + v.Id),
                                            }
                                        })}
                                        disabled={form.item?.ContactId > 0}
                                        onSimpleChange={onComboBoxSimpleChange} />
                                </StackItem>
                            )
                        }
                        {
                            (state.existingRelations?.length > 0) && (
                                <>
                                    <StackItem>
                                        <MessageBar messageBarType={MessageBarType.warning}>
                                            {DictionaryAPI.getTranslation(RelationTypes[item?.RelationTypeId] + "MayAlreadyExist")}

                                        </MessageBar>
                                    </StackItem>
                                    <StackItem styles={{ root: { height: 180, border: "1px solid " + semanticColors.inputBorder } }}>
                                        <ScrollableContainer>
                                            <FocusZone direction={FocusZoneDirection.vertical}>
                                                <List items={state.existingRelations} onRenderCell={onRenderCell} />
                                            </FocusZone>
                                        </ScrollableContainer>
                                    </StackItem>
                                    <StackItem styles={{ root: { color: DefaultPalette.neutralPrimaryAlt } }}>
                                        <Checkbox
                                            label={DictionaryAPI.getTranslation(RelationTypes[item?.RelationTypeId] + "IsNotInTheList")}
                                            checked={state.notInTheListChecked}
                                            disabled={state.notInTheListCheckboxDisabled}
                                            onChange={onNotInTheListCheckedChange}
                                        />
                                    </StackItem>
                                </>
                            )
                        }
                        {
                            state.existingRelationsVerified && (
                                <>
                                    {
                                        item.AddressPropertiesRequired && (
                                            <RelationAddressFields
                                                hideLabels
                                                form={form}
                                            />
                                        )
                                    }
                                    {getContent && getContent(form)}
                                </>
                            )
                        }
                    </Stack>
                )
            }
        </Dialog>
    );
}