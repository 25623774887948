import React, { useRef, useState } from 'react';
import { DocumentAPI, DictionaryAPI } from 'api';
import { OrderReports, DocumentFormTabMenus, DocumentTypes } from 'enums';
import { IFormProps } from 'hooks';
import { InformationTab } from './InformationTab';
import { DocumentStatuses } from 'enums/lgs/DocumentStatuses';
import { DocumentFormLayout } from 'components/layouts';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DocumentSummaryAccordion, DownloadFile, LoaderModal, ShareAttachmentWindow } from 'components/common';
import { IDocumentSummary } from 'interfaces/models';
import { ICopyDialogProps, IDocumentFormLayoutBaseProps } from 'components/layouts/DocumentFormLayout/interfaces';
import { IStockAssortmentViewModel } from 'interfaces/viewmodels/sls/IStockAssortmentViewModel';
import { StockAssortimentFormInteractionMenus } from 'enums/app/StockAssortimentFormInteractionMenus';
import { StockAssortmentReports } from 'enums/lgs/StockAssortmentReports';
import { useHistory } from 'react-router';
import { ICreateStockAssortmentReportEmailNotificationViewModel } from 'interfaces/viewmodels/lgs/ICreateStockAssortmentReportEmailNotificationViewModel';
import { CreateReportEmailNotificationDialog } from 'components/common/CreateReportEmailNotificationDialog';

export const StockAssortment = (props: IDocumentFormLayoutBaseProps) => {
    const { documentRowGuid, onDismiss } = props;

    const [copyDialogProps, setCopyDialogProps] = useState<ICopyDialogProps>();
    const [reportSrc, setShareOrderReportSrc] = useState<string>();
    const [exportDownloadUrl, setExportDownloadUrl] = useState<string>();
    const [emailReport, setEmailReport] = useState<StockAssortmentReports>()
    const [emailReportSent, setEmailReportSent] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const reportRef = useRef<StockAssortmentReports>();
    const { push } = useHistory();
    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const getStockAssortment = (onSuccess: (item?: IStockAssortmentViewModel, windowTitle?: string, windowSubTitle?: string) => void) => {
        DocumentAPI.getStockAssortment(documentRowGuid,
            (stockAssortment) => {
                onSuccess(
                    stockAssortment,
                    `${DictionaryAPI.getTranslation(DocumentTypes[DocumentTypes.StockAssortment])} #${stockAssortment?.Number || 0} \u2022 ${stockAssortment?.Relation.Name}`);
            })
    }

    const deleteStockAssortment = (item: IStockAssortmentViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest) => void) => {
        DocumentAPI.deleteDocument(item.DocumentRowGuid, onSuccess, onError);
    }

    const onCopyClick = () => {
        setCopyDialogProps({
            documentRowGuid,
            targetDocumentTypeId: DocumentTypes.StockAssortment,
            targetSubDocumentTypeId: null,
            onSuccess: (rowGuid)  => onCopyDialogSucces(`/sales/stockassortments/${rowGuid}`),
            onCancel: () => setCopyDialogProps(undefined)
        })
    }

    const onCopyDialogSucces = (redirectPath: string) => {
        setCopyDialogProps(undefined);
        push(redirectPath);
    }

    const onPrintInteractionMenuClick = (menuId: number) => {

        switch (menuId) {
            case StockAssortimentFormInteractionMenus.Print_Summary:
                reportRef.current = StockAssortmentReports.Summary;
                break;
            case StockAssortimentFormInteractionMenus.Print_Selection:
                reportRef.current = StockAssortmentReports.Selection;
                break;
            default:
                break;
        }

        if (reportRef.current !== undefined) {

            setLoading(true);
            DocumentAPI.createStockAssortmentReportAndGetUrl(documentRowGuid, reportRef.current,
                (url) => {
                    setShareOrderReportSrc(url);
                    setLoading(false)
                });
        }

    }

    const onExportInteractionMenuClick = (menuId: number) => {

        switch (menuId) {
            case StockAssortimentFormInteractionMenus.Export_ProductDetailsCsv:
                setExportDownloadUrl(DocumentAPI.getDownloadProductDetailsUrl(documentRowGuid));
                break;
            default:
                break;
        }
    }

    const onRenderInformationPanelContent = (form?: IFormProps<IStockAssortmentViewModel>, documentSummary?: IDocumentSummary): JSX.Element => {
        return (
            <DocumentSummaryAccordion
                cultureId={cultureId}
                documentSummary={documentSummary}
            />
        )
    }

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<IStockAssortmentViewModel>, documentSummary?: IDocumentSummary): JSX.Element | undefined => {
        const stockAssortment = form.item;
        const readOnly = [DocumentStatuses.Pending, DocumentStatuses.Processing].includes(stockAssortment?.DocumentStatusId) === false;

        switch (tabMenuId) {
            case DocumentFormTabMenus.Information:
                return (
                    <InformationTab
                        readOnly={readOnly}
                        form={form}
                        documentSummary={documentSummary}
                        cultureId={cultureId}
                    />
                )
            default:
                break;
        }
    }

    const onDismissCreateReportEmailNotificationDialog = (sent?: boolean) => {
        setEmailReport(undefined);
        setEmailReportSent(sent);
    }

    return (
        <>
            <DocumentFormLayout<IStockAssortmentViewModel>
                addMultipleProductsAllowed
                documentRowGuid={documentRowGuid}
                documentTypeId={DocumentTypes.StockAssortment}
                cultureId={cultureId}
                loading={loading}
                copyDialogProps={copyDialogProps}
                printInteractionMenuId={StockAssortimentFormInteractionMenus.Print}
                exportInteractionMenuId={StockAssortimentFormInteractionMenus.Export}
                onCopyClick={onCopyClick}
                onPrintInteractionMenuClick={onPrintInteractionMenuClick}
                onExportInteractionMenuClick={onExportInteractionMenuClick}
                onRenderInformationPanelContent={onRenderInformationPanelContent}
                getTabMenuContent={getTabMenuContent}
                getItem={getStockAssortment}
                deleteItem={deleteStockAssortment}
                updateItem={DocumentAPI.updateStockAssortment}
                onDismiss={onDismiss}
            />
            {
                reportSrc && (
                    <ShareAttachmentWindow
                        title={DictionaryAPI.getTranslation(StockAssortmentReports[reportRef.current])}
                        src={reportSrc}
                        onEmailClick={() => setEmailReport(reportRef.current)}
                        onDismiss={() => setShareOrderReportSrc(undefined)}
                    />
                )
            }
            {
                emailReport && (
                    <CreateReportEmailNotificationDialog<ICreateStockAssortmentReportEmailNotificationViewModel>
                        documentRowGuid={documentRowGuid}
                        report={emailReport}
                        reportTitle={StockAssortmentReports[emailReport]}
                        isCreate={false}
                        getCreateReportEmailNotification={DocumentAPI.getCreateStockAssortmentReportEmailNotification}
                        createOrderReportEmailNotification={DocumentAPI.createStockAssortmentReportEmailNotification}
                        onDismiss={onDismissCreateReportEmailNotificationDialog}
                    />
                )
            }
            <DownloadFile
                url={exportDownloadUrl}
            />
        </>

    );
};