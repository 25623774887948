import React from 'react';
import { IRelationProps } from '.';
import { AddressFormTabMenus, ContactFormTabMenus } from 'enums';
import { RelationAPI, MenuAPI } from 'api';
import { IRelationViewModel } from 'interfaces/viewmodels';
import { IFormProps } from 'hooks';
import { Log } from './Log';
import { RelationFormLayout, RelationsListViewLayout } from 'components/layouts';
import { InformationTab } from 'components/layouts/RelationFormLayout/InformationTab';

export const Relation = (props: IRelationProps) => {

    const { rowGuid, formMenu, onDismiss } = props;

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<IRelationViewModel>): JSX.Element | undefined => {
        switch (tabMenuId) {
            case AddressFormTabMenus.Information:
            case ContactFormTabMenus.Information:
                return (
                    <InformationTab
                        hideVatNumber
                        form={form}
                        formMenu={formMenu} />
                )
            case AddressFormTabMenus.Relations:
                return formMenu && (
                    <RelationsListViewLayout
                        isSubRelations
                        menu={MenuAPI.find(tabMenuId)}
                        relationTypes={form.item?.SubRelationTypes}
                        relationTypeId={form.item?.DefaultSelectedRelationTypeId}
                        companyRelationId={form.item?.CompanyRelationId || 0}
                        parentContactId={form.item?.ContactId || 0}
                    />
                )
            case AddressFormTabMenus.Log:
                return (
                    <Log />)
            default:
                break;
        }
    }

    return (
        <RelationFormLayout<IRelationViewModel>
            formMenu={formMenu}
            rowGuid={rowGuid}
            getItem={(onSuccess) => RelationAPI.get(rowGuid, onSuccess)}
            updateItem={RelationAPI.update}
            getTabMenuContent={getTabMenuContent}
            onDismiss={onDismiss}
        />
    );
}