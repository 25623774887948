import React, { useState } from 'react';
import { Image as FabricImage, ImageLoadState, IImageStyleProps, IImageStyles, IStyle } from 'office-ui-fabric-react';
import { imgError } from 'images';
import { IImageProps } from './interfaces';

export const Image = (props: IImageProps) => {

    const { styles, borderStyleDefinition } = props;

    const getProductImageStyles = (props: IImageStyleProps): Partial<IImageStyles> => {
        return {
            ...styles,
            root: props.isError ? {
                background: `url("${imgError}") no-repeat center`,
                backgroundSize: "contain",
                border: borderStyleDefinition
            } : {
                border: borderStyleDefinition
            },
        }
    }

    return (
        <FabricImage {...props}
            styles={getProductImageStyles}
        />
    );
};