export enum ProductFilterContextFields {
    BRAND_ID = "BrandId",
    B2B_PRICE = "B2bPrice",
    B2C_PRICE = "B2cPrice",
    CLOSING_ID = "ClosingId",
    COLLECTION_ID = "CollectionId",
    COLOUR_PARENT_ID = "ColourParentId",
    GENDER_ID = "GenderId",
    GROUP_ASSOCIATED_PRODUCTS = "GroupAssociatedProducts",
    HAS_STOCK_ONLY = "HasStockOnly",
    PARENT_ID = "ParentId",
    POINT_OF_SALES_RELATION_ID = "PointOfSalesRelationId",
    PRESALES_ALLOWED = "PresalesAllowed",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCT_CATALOG_ID = "ProductCatalogId",
    PRODUCT_SIZE_CODE = "ProductSizeCode",
    SALES_ALLOWED = "SalesAllowed",
    SAMPLE_PRODUCT_SIZE_NAME = "SampleProductSizeName",
    SORT_OPTIONS = "SortOptions",
    STOCK_QUANTITY = "StockQuantity",
    STYLE_ID = "StyleId",
    SUB_PRODUCT_CATEGORY_ID = "SubProductCategoryId",
    WAREHOUSE_CONTACT_ID = "WarehouseContactId",
    FILTER_INFO = "FilterInfo"
}