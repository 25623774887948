import { DictionaryAPI, DocumentAPI, MenuAPI } from 'api';
import { CheckboxFilter, ComboBox, Dialog, FilterBar, IDetailsListColumn, ListViewCommandBarTypes, UpdateDocumentDetailsStatusDialog } from 'components/common';
import { ListView } from 'components/common';
import { DefaultCommandTypes, DocumentDetailStatuses } from 'enums';
import { ProductDocumentDetailsGroupFields } from 'enums/fields';
import { useDates, useFileStoreUrl, useFilterContextHandler, useNumberFormat, useSelection } from 'hooks';
import { IDocumentProductSizeRange, IFilter, IProductCategoryListItem, IProductDocumentDetailFilterContext, IProductDocumentDetailsGroup } from 'interfaces/models';
import { ContextualMenuItemType, DefaultPalette, IColumn, IComboBoxOption, Icon, IContextualMenuItem, IImageStyleProps, IImageStyles, Image, ImageFit, mergeStyleSets, SelectableOptionMenuItemType, SelectionMode, Stack, StackItem } from 'office-ui-fabric-react';
import React, { useEffect, useRef, useState } from 'react'
import { imgError } from 'images';
import { DocumentFormDetailsTabInteractionMenus } from 'enums/app/DocumentFormDetailsTabInteractionMenus';
import { IUpdateDocumentDetailsViewModel } from 'interfaces/viewmodels';
import { ProductDetail } from 'components/pages';
import { AddProducts } from './AddProducts';
import { IDetailsTabProps } from '../interfaces';

export const ProductsTab = (props: IDetailsTabProps) => {

    const { documentRowGuid, document, tabMenu, readOnly, cultureId, checkable, onDetailsUpdated } = props;

    const [sizeRanges, setSizeRanges] = useState<IDocumentProductSizeRange[]>();
    const [sizeRangeId, setSizeRangeId] = useState<number>();
    const [productId, setProductId] = useState<number>();
    const [groupKeysToUpdate, setGroupKeysToUpdate] = useState<string[]>();
    const [addProductsCategoryId, setAddProductsCategoryId] = useState<number>();
    const [updateDetailsStatusViewModel, setUpdateDetailsStatusViewModel] = useState<IUpdateDocumentDetailsViewModel>();
    const [loading, setLoading] = useState<boolean>();
    const [productIsReadOnly, setProductIsReadOnly] = useState<boolean>();
    const statusInteractionMenusRef = useRef(MenuAPI.filterByParent(DocumentFormDetailsTabInteractionMenus.Status));
    const sizes = sizeRanges?.find(s => s.SizeRangeId === sizeRangeId)?.Sizes;

    const {
        IMAGE,
        DISPLAY_NAME,
        DOCUMENTDETAIL_STATUS_ID,
        COLLI_PACKAGE_NUMBER,
        REMARKS,
        TOTAL_QUANTITY,
        TOTAL_PRICE,
        CREATED_ON,
        HAS_PRODUCTION_ORDER
    } = ProductDocumentDetailsGroupFields;


    const { getImageUrl } = useFileStoreUrl();
    const { dateByUtcToLocaleString } = useDates();
    const { getNumber, getPrice } = useNumberFormat(cultureId);

    const getProductSizeRanges = (onSuccess?: () => void) => {
        DocumentAPI.getDocumentProductSizeRanges(documentRowGuid,
            (data) => {
                setSizeRanges(data);
                onSuccess && onSuccess();
            });
    }

    const filterContextHandler = useFilterContextHandler<IProductDocumentDetailFilterContext>(
        (onSuccess) => {
            DocumentAPI.getProductDetailFilterContext(
                documentRowGuid,
                sizeRangeId,
                true,
                onSuccess
            );
        },
        (filterContext, onSuccess) => DocumentAPI.filterProductDetailsGroups(filterContext, onSuccess),
        undefined,
        true
    );

    const listViewSelection = useSelection<string>(
        ProductDocumentDetailsGroupFields.GROUP_KEY,
        filterContextHandler.filterResultInfo?.Items,
        [],
        false,
        SelectionMode.multiple
    );

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        getFilterContext,
        getFilterResultInfo,
        onFilterChange,
        onPageChange,
        onOrderByChange } = filterContextHandler;

    const classNames = mergeStyleSets({
        numericColumn: {
            width: "100%",
            textAlign: "right"
        },
        sizeColumn: {
            textAlign: "center"
        },
        tileDescription: {
            color: DefaultPalette.neutralSecondary,
            fontWeight: "600",
            marginLeft: 5,
            marginRight: 5,
            whiteSpace: "noWrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        tileTotals: {
            color: DefaultPalette.neutralSecondary,
        }
    });

    const SIZE_CODE_ = "SizeCode_";

    const renderSizeRangeComboBox = (): React.ReactNode => {

        const headerOptions: IComboBoxOption[] = [
            { key: 'SizeRangeHeader', text: DictionaryAPI.getTranslation("SizeRange"), itemType: SelectableOptionMenuItemType.Header },
        ];

        return (
            <ComboBox
                allowFreeform
                style={{ marginLeft: 10 }}
                selectedKey={sizeRangeId}
                placeholder={DictionaryAPI.getTranslation("SizeRange")}
                onSimpleChange={(name, option) => setSizeRangeId(option ? Number(option?.key) : 0)}
                disabled={sizeRanges.length === 0}
                options={headerOptions.concat(sizeRanges.map((v) => {
                    return {
                        key: v.SizeRangeId,
                        text: v.SizeRangeName ? v.SizeRangeName : `(${DictionaryAPI.getTranslation("None")})`,
                        itemType: SelectableOptionMenuItemType.Normal
                    }
                }))}
            />
        )
    }

    const getDetailsListColumns = (): IDetailsListColumn[] => {

        const columns: IDetailsListColumn[] = [
            { fieldName: IMAGE, width: 32, name: "" },
            { fieldName: DISPLAY_NAME, width: 225, name: "Name", isRowHeader: true },
            { fieldName: DOCUMENTDETAIL_STATUS_ID, width: 100, name: "Status" },
            { fieldName: TOTAL_QUANTITY, width: 40, name: "Abv_Quantity" },
        ];

        if (sizes) {
            for (const size of sizes) {
                columns.push(
                    { fieldName: `${SIZE_CODE_}${size.ProductSizeCode}`, width: 30, name: size.ProductSizeName, translateHeaderDisabled: true, textAlign: "center" },
                )
            }
        }

        columns.push(
            { fieldName: TOTAL_PRICE, width: 70, name: "Total", textAlign: "center" },
            { fieldName: COLLI_PACKAGE_NUMBER, width: 70, name: "Colli" },
        )

        columns.push(
            { fieldName: REMARKS, width: 300 },
        )

        columns.push(
            { fieldName: CREATED_ON, width: 100, name: "Created" },
        )

        return columns;
    }

    const onRenderDetailsListItemColumn = (item?: IProductDocumentDetailsGroup, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {

        if (item && column && column.fieldName) {
            //console.log("onRenderDetailsListItemColumn", item);
            switch (column.fieldName) {
                case IMAGE:
                    return (
                        <Image
                            src={getImageUrl(item.Image)}
                            height={32}
                            width={32}
                            imageFit={ImageFit.contain}
                            styles={getProductImageStyles}
                        />
                    )
                case TOTAL_QUANTITY:
                    return <div className={classNames.numericColumn}>{getNumber(item[column.fieldName])}</div>
                case TOTAL_PRICE:
                    return <div className={classNames.numericColumn}>{getPrice(item[column.fieldName])}</div>
                case DOCUMENTDETAIL_STATUS_ID:
                    const statusName = DictionaryAPI.getTranslation(DocumentDetailStatuses[item.DocumentDetailStatusId]);
                    switch (item.DocumentDetailStatusId) {
                        case DocumentDetailStatuses.Processing:
                            return <span style={{ color: DefaultPalette.orangeLight }}>{statusName}</span>
                        case DocumentDetailStatuses.Depot:
                            return <span style={{ color: DefaultPalette.purpleLight }}>{statusName}</span>
                        case DocumentDetailStatuses.Delivered:
                            return <span style={{ color: DefaultPalette.green }}>{statusName}</span>
                        case DocumentDetailStatuses.Cancelled:
                        case DocumentDetailStatuses.Cancelled_Company:
                        case DocumentDetailStatuses.Cancelled_Customer:
                        case DocumentDetailStatuses.Cancelled_Production:
                            return <span style={{ color: DefaultPalette.red }}>{statusName}</span>
                        default:
                            return <span style={{ color: DefaultPalette.blue }}>{statusName}</span>
                    }
                case CREATED_ON:
                    return <span>{dateByUtcToLocaleString(item[column.fieldName])}</span>
                default:
                    break;
            }

            if (column.fieldName.substr(0, SIZE_CODE_.length) === SIZE_CODE_) {
                const sizeCode = Number(column.fieldName.replace(SIZE_CODE_, ""));
                const quantity: number | undefined = item.Details.find(d => d.SizeCode === sizeCode)?.Quantity;
                if (quantity && quantity > 0) {
                    return <div className={classNames.sizeColumn}>{getNumber(quantity)}</div>
                } else {
                    return <span></span>
                }
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onColumnClick = (column: IColumn) => {
        if (onOrderByChange && column.fieldName.substr(0, SIZE_CODE_.length) !== SIZE_CODE_) {
            onOrderByChange(column.fieldName || "", (!column.isSortedDescending && column.isSorted));
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (
                <FilterBar
                    filterContextHandler={filterContextHandler}
                    onRenderFilterBarItem={(props) => (
                        <CheckboxFilter
                            filter={props.filter as IFilter}
                            name={props.name}
                            onChange={onFilterChange} />
                    )}
                />)
        }
    }

    const onRenderTileListCell = (item?: IProductDocumentDetailsGroup, index?: number | undefined): React.ReactNode | undefined => {
        if (item) {
            /* 
                        const minimumProductSizeName = item.Details[0].ProductSizeName;
                        const maximumProductSizeName = item.Details[item.Details.length - 1].ProductSizeName; */

            return (
                <Stack verticalFill>
                    <StackItem grow>
                        <Image
                            src={getImageUrl(item.Image)}
                            width="100%"
                            height="100%"
                            imageFit={ImageFit.contain}
                            styles={getProductImageStyles}
                        />
                    </StackItem>
                    <StackItem className={classNames.tileDescription}>
                        {item.Name} {item.VariantDescription}
                    </StackItem>
                    <StackItem className={classNames.tileTotals}>
                        {getPrice(item.TotalPrice)} ({getNumber(item.TotalQuantity)})
                        {
                            /* (minimumProductSizeName && maximumProductSizeName) &&
                            ` [${minimumProductSizeName}-${maximumProductSizeName}] ` */
                        }
                    </StackItem>
                </Stack>
            )
        }
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Add:
                if (document.ProductCategories.length === 1) {
                    setAddProductsCategoryId(document.ProductCategories[0].Id);
                }
                break;

            case DefaultCommandTypes.Remove:
                if (listViewSelection.selectedKeys.length > 0) {
                    setGroupKeysToUpdate(listViewSelection.selectedKeys);
                }
                break;
            default:
                break;
        }
    }

    const onAddClick = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (item) {
            const productCategoryListItem = item.data as IProductCategoryListItem;
            setAddProductsCategoryId(productCategoryListItem.Id);
        }
    }

    const onRemove = () => {
        if (documentRowGuid) {
            setLoading(true);
            DocumentAPI.removeDetails({
                DocumentRowGuid: documentRowGuid,
                GroupKeys: groupKeysToUpdate
            }, () => {
                listViewSelection.setSelectedKeys([]);
                _onDetailsUpdated();
            });
        }
    }

    const onCancelRemove = () => {
        setGroupKeysToUpdate(undefined);
    }

    const onDismissAddOrUpdateDetails = (documentUpdated?: boolean) => {
        if (documentUpdated) {
            _onDetailsUpdated();
        } else {
            setProductId(undefined);
            setAddProductsCategoryId(undefined);
        }
    }

    const onStatusInteractionMenuClick = (menuId: number) => {

        let documentDetailStatusId: number = 0;

        switch (menuId) {
            case DocumentFormDetailsTabInteractionMenus.Status_Suspend:
                documentDetailStatusId = DocumentDetailStatuses.Suspended;
                break;
            case DocumentFormDetailsTabInteractionMenus.Status_CancelByCompany:
                documentDetailStatusId = DocumentDetailStatuses.Cancelled_Company;
                break;
            case DocumentFormDetailsTabInteractionMenus.Status_CancelByProduction:
                documentDetailStatusId = DocumentDetailStatuses.Cancelled_Production;
                break;
            case DocumentFormDetailsTabInteractionMenus.Status_CancelByCustomer:
                documentDetailStatusId = DocumentDetailStatuses.Cancelled_Customer;
                break;
            case DocumentFormDetailsTabInteractionMenus.Reset:
                documentDetailStatusId = DocumentDetailStatuses.Processing;
                break;
            default:
                break;
        }

        if (documentDetailStatusId !== 0) {
            setUpdateDetailsStatusViewModel({
                DocumentRowGuid: documentRowGuid,
                DocumentDetailStatusId: documentDetailStatusId,
                GroupKeys: listViewSelection.selectedKeys
            });
        }
    }

    const onDismissUpdateDetailsStatusDialog = (updated?: boolean) => {
        if (updated) {
            _onDetailsUpdated();
        }

        setUpdateDetailsStatusViewModel(undefined);
    }

    const _onDetailsUpdated = () => {

        getProductSizeRanges(() => {
            if (sizeRangeId !== undefined) {
                getFilterContext();
            }
        });

        onDetailsUpdated();
    }

    useEffect(() => {
        if (documentRowGuid) {

            getProductSizeRanges();
        }
    }, [documentRowGuid])

    useEffect(() => {
        if (sizeRanges) {
            if (sizeRangeId === undefined) {
                setSizeRangeId(sizeRanges.length > 0 ? sizeRanges[0].SizeRangeId : 0)
            }
        }
    }, [sizeRanges])

    useEffect(() => {
        if (sizeRangeId !== undefined) {
            getFilterContext();
        }
    }, [sizeRangeId])

    useEffect(() => {
        onCancelRemove();
        setProductId(undefined);
        setAddProductsCategoryId(undefined);
        setLoading(false);

    }, [filterContext])

    useEffect(() => {

        if (!readOnly) {
            setProductIsReadOnly(false);

            for (const key of listViewSelection.selectedKeys) {

                const productGroup: IProductDocumentDetailsGroup = filterContextHandler.filterResultInfo?.Items.find(
                    i => i[ProductDocumentDetailsGroupFields.GROUP_KEY] === key
                );

                if (productGroup[ProductDocumentDetailsGroupFields.HAS_PRODUCTION_ORDER] === true) {


                    setProductIsReadOnly(true);
                }
            }
        }

    }, [listViewSelection.selectedKeys, readOnly])

    const getProductImageStyles = (props: IImageStyleProps): Partial<IImageStyles> => {
        return {
            root: props.isError && {
                background: `url("${imgError}") no-repeat center`,
                backgroundSize: "contain"
            }
        }
    }

    return (
        <>
            <ListView
                compact
                isSub
                checkable
                readOnly={readOnly || productIsReadOnly}
                menu={tabMenu}
                title=""
                onRenderContentBeforeCommandBar={renderSizeRangeComboBox}
                newContextMenuItems={document.ProductCategories.length > 1 &&
                    document.ProductCategories.map((v) => {
                        return {
                            key: String(v.Id),
                            text: DictionaryAPI.getTranslation(v.Name),
                            data: v,
                            onClick: onAddClick
                        }
                    })
                }
                availableListViewCommandBarTypes={[ListViewCommandBarTypes.List, ListViewCommandBarTypes.Tiles]}
                overflowCommandBarItems={!readOnly ? [
                    {
                        key: "Status",
                        text: DictionaryAPI.getTranslation("Status"),
                        disabled: listViewSelection.selectedKeys.length === 0,
                        subMenuProps: {
                            items: statusInteractionMenusRef.current.map((v) => {
                                return {
                                    key: String(v.Id),
                                    text: DictionaryAPI.getTranslation(v.Name),
                                    data: v,
                                    onClick: () => onStatusInteractionMenuClick(v.Id)
                                }
                            })
                        }
                    }
                ] : undefined}

                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                onDefaultCommandClick={onDefaultCommandClick}
                onRefreshButtonClick={getFilterResultInfo}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                onItemClick={(item) => setProductId(item.ProductId)}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                dataLoading={isFiltering}
                onOrderByChange={onOrderByChange}
                onRenderTileListCell={onRenderTileListCell}
                selection={listViewSelection.selection}
                getInitialFocusedIndex={listViewSelection.getFirstSelectedItemIndex}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                productId &&
                <ProductDetail
                    productId={productId}
                    documentRowGuid={documentRowGuid}
                    onDismiss={onDismissAddOrUpdateDetails}
                />
            }
            {
                groupKeysToUpdate && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("RemoveSelectedDetails") + "?"}
                        acceptButtonDisabled={loading}
                        cancelButtonDisabled={loading}
                        onAccept={onRemove}
                        onCancel={onCancelRemove}
                        maxWidth={300}
                    />
                )
            }
            {
                updateDetailsStatusViewModel && (
                    <UpdateDocumentDetailsStatusDialog
                        viewModel={updateDetailsStatusViewModel}
                        onDismiss={onDismissUpdateDetailsStatusDialog}
                    />
                )
            }
            {
                addProductsCategoryId && (
                    <AddProducts
                        productCategoryId={addProductsCategoryId}
                        document={document}
                        cultureId={cultureId}
                        checkable={checkable}
                        onDismiss={onDismissAddOrUpdateDetails} />
                )

            }
        </>
    )
}
