import React from 'react';
import { getTheme, mergeStyleSets, ITheme, FontWeights, FontSizes } from 'office-ui-fabric-react/lib/Styling';
import { CommandButton, CommandBarButton, IButtonStyles } from 'office-ui-fabric-react/lib/Button';
import { GroupedList, IGroupedList, IGroup, IGroupHeaderProps } from 'office-ui-fabric-react/lib/GroupedList';
import { Nav, INavLink, INavStyleProps, INavStyles } from 'office-ui-fabric-react/lib/Nav';
import { IMenu } from 'interfaces/models'
import { ISideMenuProps } from '.';
import { OverflowSet, IOverflowSetItemProps, SemanticColorSlots, DirectionalHint, IContextualMenuProps, IContextualMenuItem, ContextualMenuItemBase, ContextualMenuItemType } from 'office-ui-fabric-react';
import { useLocation, useHistory  } from 'react-router-dom';
import { DictionaryAPI, MenuAPI } from 'api';
import { NavigationMenus } from 'enums/app/NavigationMenus';
import { MenuTypes } from 'enums';

export const SideMenu = (props: ISideMenuProps): JSX.Element => {
    const { items } = props;
    const location = useLocation();
    const history = useHistory();
    const theme = getTheme();
    const defaultBackgroundColor = theme.palette.neutralLighterAlt;

    const buttonStyles: IButtonStyles = {
        root: { backgroundColor: defaultBackgroundColor },
    }

    const onMenuClick = (menu: IMenu) => {
        history.push(menu.Path);
    }

    const getSubContextualMenuItems = (menu: IMenu): IContextualMenuItem[] | undefined => {

        const children = items.filter(m => m.ParentId === menu.Id && m.TypeId === MenuTypes.Navigation);

        if (children.length > 0) {
            return children.map((v) => {
                if (v.Separated) {
                    return {
                        key: "divider_" + String(v.Id),
                        itemType: ContextualMenuItemType.Divider
                    }
                }

                const subContextualMenuItems = getSubContextualMenuItems(v);

                return {
                    key: String(v.Id),
                    name: v.translatedName,
                    subMenuProps: subContextualMenuItems && {
                        items: subContextualMenuItems
                    },
                    onClick: !subContextualMenuItems ? () => onMenuClick(v) : undefined,
                    ariaLabel: 'Use left and right arrow keys to navigate',
                }
            })
        }

        return undefined;
    }

    const _onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {

        const menu: IMenu = item.menu as IMenu;

        const menuProps: IContextualMenuProps = {
            directionalHint: DirectionalHint.rightTopEdge,
            items: getSubContextualMenuItems(menu),
        };

        return (
            <CommandBarButton
                role="menuitem"
                title={item.name}
                checked={location.pathname.startsWith(menu.Path)}
                aria-label={item.name}
                style={{ padding: 12 }}
                styles={buttonStyles}
                iconProps={{ iconName: item.icon, style: { fontSize: FontSizes.xLarge } }}
                menuIconProps={{ style: { display: "none" } }}
                menuProps={menuProps.items?.length > 0 && menuProps}
                onClick={menuProps.items?.length > 0 ? undefined : () => onMenuClick(menu)}
            />
        );
    };

    const _onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        return (
            <CommandBarButton
                role="menuitem"
                style={{ padding: 12 }}
                styles={buttonStyles}
                menuIconProps={{ iconName: 'More', style: { fontSize: FontSizes.large } }}
                menuProps={{ items: overflowItems! }}
            />
        );
    };

    return (
        <OverflowSet
            aria-label="Vertical Example"
            role="menubar"
            vertical
            styles={{ root: { height: "100%", borderRight: "1px solid " + theme.semanticColors.bodyFrameDivider, backgroundColor: defaultBackgroundColor } }}
            items={MenuAPI.filterByParent(NavigationMenus.LeftNavigation).map((m: IMenu, i) => {
                return {
                    key: String(m.Id),
                    icon: m.iconName,
                    name: m.translatedName,
                    menu: m,
                    ariaLabel: 'Use left and right arrow keys to navigate',
                }
            })}
            /* overflowItems={[
                {
                    key: 'item4',
                    icon: 'Mail',
                    name: 'Overflow Link 1',
                },
                {
                    key: 'item5',
                    icon: 'Calendar',
                    name: 'Overflow Link 2',
                }
            ]} */
            onRenderOverflowButton={_onRenderOverflowButton}
            onRenderItem={_onRenderItem}
        />
    )

}