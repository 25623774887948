import React, { useRef, useState } from 'react';
import { DocumentAPI, DictionaryAPI } from 'api';
import { OrderReports, DocumentFormTabMenus, DocumentTypes, OrderSubDocumentTypes } from 'enums';
import { ICreateOrderReportEmailNotificationViewModel, IOrderViewModel } from 'interfaces/viewmodels';
import { IFormProps } from 'hooks';
import { InformationTab } from './InformationTab';
import { DocumentStatuses } from 'enums/lgs/DocumentStatuses';
import { DocumentFormLayout } from 'components/layouts';
import { useSelector } from 'react-redux';
import { RootState, storeInstance } from 'store';
import { setPendingOrders } from 'store/pendingOrders/actions';
import { PaymentTab } from './PaymentTab';
import { Dialog, DocumentSummaryAccordion, DownloadFile, ShareAttachmentWindow } from 'components/common';
import { IDocumentSummary } from 'interfaces/models';
import { ICopyDialogProps, IDocumentFormLayoutBaseProps } from 'components/layouts/DocumentFormLayout/interfaces';
import { OrderFormInteractionMenus } from 'enums/app/OrderFormInteractionMenus';
import { CopyDialog } from 'components/layouts/DocumentFormLayout/CopyDialog';
import { useHistory } from 'react-router';
import { CreateReportEmailNotificationDialog } from 'components/common/CreateReportEmailNotificationDialog';
import { setErrorMessage } from 'store/errorMessage/actions';

export const Order = (props: IDocumentFormLayoutBaseProps) => {
    const { documentRowGuid, onDismiss } = props;

    const [copyDialogProps, setCopyDialogProps] = useState<ICopyDialogProps>();
    const [dialogErrorMessage, setDialogErrorMessage] = useState("");
    const [shareOrderReportSrc, setShareOrderReportSrc] = useState<string>();
    const [exportDownloadUrl, setExportDownloadUrl] = useState<string>();
    const [emailReport, setEmailOrderReport] = useState<OrderReports>()
    const [emailReportSent, setEmailOrderReportSent] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);
    const pendingOrdersState = useSelector((state: RootState) => state.pendingOrdersState);
    const orderReportRef = useRef<OrderReports>();
    const { push } = useHistory();

    const reloadPendingOrders = () => {
        if (pendingOrdersState.customerRelationRowGuid !== "") {
            DocumentAPI.readPendingOrders(pendingOrdersState.customerRelationRowGuid, (data) => {
                storeInstance.dispatch(setPendingOrders(data));
            });
        }
    }

    const getOrder = (onSuccess: (item?: IOrderViewModel, windowTitle?: string, windowSubTitle?: string) => void) => {
        DocumentAPI.getOrder(documentRowGuid,
            (order) => {
                onSuccess(
                    order,
                    `${DictionaryAPI.getTranslation(OrderSubDocumentTypes[order.SubDocumentTypeId] + "Order")} #${order?.Number || 0} ${order.CollectionId > 0 ? "\u2022 " + order.CollectionCode : ""} \u2022 ${order?.Relation.Name}`);
            })
    }

    const deleteOrder = (item: IOrderViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest) => void) => {
        DocumentAPI.deleteDocument(item.DocumentRowGuid, onSuccess, onError);

        if (pendingOrdersState.customerRelationRowGuid === item.RelationRowGuid && item.DocumentStatusId === DocumentStatuses.Pending) {
            reloadPendingOrders();
        }
    }

    const onCopyDialogSucces = (redirectPath: string) => {
        setCopyDialogProps(undefined);
        push(redirectPath);
    }

    const onRenderInformationPanelContent = (form?: IFormProps<IOrderViewModel>, documentSummary?: IDocumentSummary): JSX.Element => {
        return (
            <DocumentSummaryAccordion
                cultureId={cultureId}
                documentSummary={documentSummary}
            />
        )
    }

    const onCopyInteractionMenuClick = (menuId: number) => {
        const baseCopyDialogProps: ICopyDialogProps = {
            documentRowGuid,
            targetDocumentTypeId: 0,
            targetSubDocumentTypeId: null,
            onCancel: () => setCopyDialogProps(undefined)
        }

        switch (menuId) {
            case OrderFormInteractionMenus.Copy_SalesOrder:
                setCopyDialogProps(
                    {
                        ...baseCopyDialogProps,
                        targetDocumentTypeId: DocumentTypes.Order,
                        targetSubDocumentTypeId: OrderSubDocumentTypes.Sales,
                        onSuccess: (rowGuid) => onCopyDialogSucces(`/sales/orders/${rowGuid}`),
                    })
                break;
            case OrderFormInteractionMenus.Copy_StockAssortment:
                setCopyDialogProps(
                    {
                        ...baseCopyDialogProps,
                        targetDocumentTypeId: DocumentTypes.StockAssortment,
                        onSuccess: (rowGuid) => onCopyDialogSucces(`/sales/stockassortments/${rowGuid}`),
                    })
                break;
            default:
                break;
        }
    }

    const onPrintInteractionMenuClick = (menuId: number) => {

        switch (menuId) {
            case OrderFormInteractionMenus.Print_Confirmation:
                orderReportRef.current = OrderReports.Confirmation;
                break;
            case OrderFormInteractionMenus.Print_Input:
                orderReportRef.current = OrderReports.Input;
                break;
            case OrderFormInteractionMenus.Print_Selection:
                orderReportRef.current = OrderReports.Selection;
                break;
            case OrderFormInteractionMenus.Print_Summary:
                orderReportRef.current = OrderReports.Summary;
                break;
            default:
                break;
        }

        if (orderReportRef.current !== undefined) {

            setLoading(true);
            DocumentAPI.createOrderReportAndGetUrl(documentRowGuid, orderReportRef.current,
                (url) => {
                    setShareOrderReportSrc(url);
                    setLoading(false)
                });
        }

    }

    const onExportInteractionMenuClick = (menuId: number) => {

        switch (menuId) {
            case OrderFormInteractionMenus.Export_ProductDetailsCsv:
                setExportDownloadUrl(DocumentAPI.getDownloadProductDetailsUrl(documentRowGuid));
                break;
            case OrderFormInteractionMenus.Export_ProductDetailsEdmCsv:
                setExportDownloadUrl(DocumentAPI.getDownloadProductDetailsEdmUrl(documentRowGuid));
                break;
            case OrderFormInteractionMenus.Export_SendProductDetailsEdmByFtp:
                setLoading(true);
                DocumentAPI.sendProductDetailsEdmByFtp(documentRowGuid, true,
                    (errorMessage) => {
                        //console.log("sendProductDetailsEdmByFtp errorMessage", errorMessage)
                        setLoading(false);
                        setDialogErrorMessage(errorMessage);
                    });
                break;
            default:
                break;
        }
    }

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<IOrderViewModel>, documentSummary?: IDocumentSummary): JSX.Element | undefined => {
        const order = form.item;
        const readOnly = [DocumentStatuses.Pending, DocumentStatuses.Processing].includes(order?.DocumentStatusId) === false;

        switch (tabMenuId) {
            case DocumentFormTabMenus.Information:
                return (
                    <InformationTab
                        readOnly={readOnly}
                        form={form}
                        documentSummary={documentSummary}
                        cultureId={cultureId}
                    />
                )
            case DocumentFormTabMenus.Payment:
                return (
                    <PaymentTab
                        form={form}
                        readOnly={readOnly}
                        cultureId={cultureId}
                    />
                )
            default:
                break;
        }
    }

    const onDismissCreateOrderReportEmailNotificationDialog = (sent?: boolean) => {
        setEmailOrderReport(undefined);
        setEmailOrderReportSent(sent);
    }

    return (
        <>
            <DocumentFormLayout<IOrderViewModel>
                documentRowGuid={documentRowGuid}
                itemDependencies={[emailReportSent]}
                documentTypeId={DocumentTypes.Order}
                cultureId={cultureId}
                loading={loading}
                copyInteractionMenuId={OrderFormInteractionMenus.Copy}
                printInteractionMenuId={OrderFormInteractionMenus.Print}
                exportInteractionMenuId={OrderFormInteractionMenus.Export}
                copyDialogProps={copyDialogProps}
                onRenderInformationPanelContent={onRenderInformationPanelContent}
                getTabMenuContent={getTabMenuContent}
                getItem={getOrder}
                deleteItem={deleteOrder}
                updateItem={DocumentAPI.updateOrder}
                onDocumentConfirmed={reloadPendingOrders}
                onCopyInteractionMenuClick={onCopyInteractionMenuClick}
                onPrintInteractionMenuClick={onPrintInteractionMenuClick}
                onExportInteractionMenuClick={onExportInteractionMenuClick}
                onDismiss={onDismiss}
            />

            {
                shareOrderReportSrc && (
                    <ShareAttachmentWindow
                        title={DictionaryAPI.getTranslation(OrderReports[orderReportRef.current])}
                        src={shareOrderReportSrc}
                        onEmailClick={() => setEmailOrderReport(orderReportRef.current)}
                        onDismiss={() => setShareOrderReportSrc(undefined)}
                    />
                )
            }
            {
                emailReport && (
                    <CreateReportEmailNotificationDialog<ICreateOrderReportEmailNotificationViewModel>
                        documentRowGuid={documentRowGuid}
                        report={emailReport}
                        reportTitle={OrderReports[emailReport]}
                        isCreate={false}
                        getCreateReportEmailNotification={DocumentAPI.getCreateOrderReportEmailNotification}
                        createOrderReportEmailNotification={DocumentAPI.createOrderReportEmailNotification}
                        onDismiss={onDismissCreateOrderReportEmailNotificationDialog}
                    />
                )
            }
            {
                dialogErrorMessage && (
                    <Dialog 
                        title='Error' 
                        maxWidth={300} 
                        onAccept={() => setDialogErrorMessage("")} 
                        onCancel={() => setDialogErrorMessage("")} 
                        isHidden={false}
                    >
                        {dialogErrorMessage}
                    </Dialog>
                )
            }
            <DownloadFile
                url={exportDownloadUrl}
            />
        </>

    );
};