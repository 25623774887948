import { CatalogAPI, DictionaryAPI } from 'api';
import { Dialog, FileUpload } from 'components/common';
import { AddCatalogPageViewModelFields } from 'enums/fields';
import { useForm } from 'hooks';
import { IAddCatalogPageViewModel } from 'interfaces/viewmodels';
import { Checkbox, Label, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React, { useRef, useState } from 'react'
import { IAddPageDialogProps } from './interfaces';

export const AddPageDialog = (props: IAddPageDialogProps) => {

    const { catalogRowGuid, onAccept, onCancel } = props;
    const [isLeaveBlank, setIsLeaveBlank] = useState(false);

    const fileListRef = useRef<FileList>();

    const {
        item,
        isSubmitting,
        onTextFieldChange,
        hasChanges,
        submit } = useForm<IAddCatalogPageViewModel>({
            CatalogRowGuid: catalogRowGuid,
            Image: "",
            Title: ""
        },
            undefined,
            (data, onSuccess) => {
                const file: File = fileListRef.current ? fileListRef.current[0] : undefined;

                if (file) {
                    const fileSize = file.size / 1024 / 1024; // in MB

                    if (fileSize < 10) {
                        CatalogAPI.addPage(data, file, onSuccess);
                    }
                } else {
                    CatalogAPI.addPage(data, null, onSuccess);
                }
            });

    const { TITLE } = AddCatalogPageViewModelFields;

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("AddPage")}
            maxWidth={450}
            acceptButtonDisabled={(!hasChanges && !isLeaveBlank) || isSubmitting}
            cancelButtonDisabled={isSubmitting}
            onAccept={() => submit((Id) => onAccept(Id), undefined, true)}
            onCancel={onCancel}
        >
            <Stack>
                <StackItem>
                    <TextField
                        autoFocus
                        label={DictionaryAPI.getTranslation(TITLE)}
                        name={TITLE}
                        value={item.Title}
                        disabled={isLeaveBlank || isSubmitting}
                        onChange={onTextFieldChange} />
                </StackItem>
                <StackItem styles={{ root: { paddingTop: 6 } }}>
                    <Checkbox
                        label={DictionaryAPI.getTranslation("LeaveBlank")}
                        checked={isLeaveBlank}
                        disabled={isSubmitting}
                        onChange={() => setIsLeaveBlank((v) => !v)} />
                </StackItem>
                <StackItem>
                    <FileUpload
                        label={DictionaryAPI.getTranslation("Image")}
                        accept="jpg"
                        onChange={(list) => fileListRef.current = list} />
                </StackItem>
            </Stack>
            {/*             <form onSubmit={(e) => e.preventDefault()}>
                
            </form> */}
        </Dialog>
    )
}
