import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import { storeInstance } from "./store";
import App from './App';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { Customizer, mergeStyles } from 'office-ui-fabric-react';
import 'draft-js/dist/Draft.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.css'
import * as serviceWorker from './serviceWorker';

import { initializeIcons } from '@uifabric/icons';

// Register icons and pull the fonts from the default SharePoint CDN:
initializeIcons();
initializeFileTypeIcons();

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

//const store = configureStore();

ReactDOM.render(
    <Customizer {...FluentCustomizations}>
        <HashRouter>
            <Provider store={storeInstance}>
                <App />
            </Provider>
        </HashRouter>
  </Customizer>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
