export enum DocumentListItemFields {
    AGENT_CODE = "AgentCode",
    AGENT_RELATION_ID = "AgentRelationId",
    BRAND_CODE = "BrandCode",
    BRAND_ID = "BrandId",
    COLLECTIONCODE = "CollectionCode",
    COLLECTION_ID = "CollectionId",
    CATALOGCODE = "CatalogCode",
    CATALOG_ID = "CatalogId",
    COMPANY_RELATION_ID = "CompanyRelationId",
    CREATED_ON = "CreatedOn",
    DISCOUNT_TOTAL = "DiscountTotal",
    DOCUMENT_STATUS_ID = "DocumentStatusId",
    DOCUMENT_SUMMARY = "DocumentSummary",
    DOCUMENT_TYPE_ID = "DocumentTypeId",
    FROM_RELATION_ID = "FromRelationId",
    FROM_RELATION_NAME = "FromRelationName",
    HAS_PRODUCTION_ORDER = "HasProductionOrder",
    ID = "Id",
    INPUT_ACCOUNT_ID = "InputAccountId",
    INTERNAL_REMARKS = "InternalRemarks",
    NUMBER = "Number",
    QUANTITY = "Quantity",
    REFERENCE = "Reference",
    RELATION_COUNTRY_ID = "RelationCountryId",
    RELATION_ID = "RelationId",
    RELATION_NAME = "RelationName",
    ROW_GUID = "RowGuid",
    SALES_TREND = "SalesTrend",
    SUB_DOCUMENT_TYPE_ID = "SubDocumentTypeId",
    SUB_DOCUMENT_TYPE_NAME = "SubDocumentTypeName",
    TO_RELATION_NAME = "ToRelationName",
    SUB_TOTAL = "Subtotal",
    SUB_TOTAL_EXCL_DISCOUNT = "SubtotalExclDiscount",
    TOTAL = "Total"
}