import { IUseFileStoreUrlProps } from "./interfaces";
import { ApplicationAPI } from "api/app/ApplicationAPI";

export const useFileStoreUrl = (): IUseFileStoreUrlProps => {

    const applicationParameters = ApplicationAPI.getApplicationParameters();

    const getImageUrl = (image: string | undefined, parameter?: string): string => {
        if (image && applicationParameters) {
            return applicationParameters.ImageServer + image + (parameter ? "?" + parameter : "");
        }

        return "";
    }

    return {
        getImageUrl
    }
}