import React, { useRef, useEffect, useState } from 'react';
import { IPdfViewerProps } from './interfaces';
import pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Stack, StackItem, ScrollablePane, CommandBar } from 'office-ui-fabric-react';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PdfViewer = (props: IPdfViewerProps) => {
    const { src } = props;
    const [isLoading, setIsLoading] = useState<boolean>();
    const [pdf, setPdf] = useState<pdfjs.PDFDocumentProxy | null>();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [currentScale, setCurrentScale] = useState<number>(1.25);
    const canvasRef = useRef(null);

    const fetchPdf = async () => {
        const loadingTask = pdfjs.getDocument(src);
        setPdf(await loadingTask.promise);
    };

    const renderPage = async (pageNumber: number, scale: number) => {
        if (pdf) {
            const page = await pdf.getPage(pageNumber);
            const viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            const canvas = canvasRef.current;

            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);

            await renderTask.promise;
        }
    }

    const onFirstPageClick = () => {
        setCurrentPageNumber(1);
    }

    const onPreviousPageClick = () => {
        if (currentPageNumber > 1) {
            setCurrentPageNumber(currentPageNumber - 1);
        }
    }

    const onNextPageClick = () => {
        if (currentPageNumber < pdf.numPages) {
            setCurrentPageNumber(currentPageNumber + 1);
        }
    }

    const onLastPageClick = () => {
        setCurrentPageNumber(pdf.numPages);
    }

    useEffect(() => {
        fetchPdf();
    }, [src]);

    useEffect(() => {
        renderPage(currentPageNumber, currentScale);
    }, [pdf, currentPageNumber, currentScale])

    return (
        <Stack verticalFill>
            <StackItem>
                <CommandBar
                    items={[
                        {
                            key: "FirstPage",
                            text: "FirstPage",
                            iconOnly: true,
                            iconProps: { iconName: "Previous" },
                            disabled: !pdf || currentPageNumber === 1,
                            onClick: () => onFirstPageClick()
                        },
                        {
                            key: "PreviousPage",
                            text: "PreviousPage",
                            iconOnly: true,
                            iconProps: { iconName: "Play", styles: { root: { transform: "rotate(180deg)" } } },
                            disabled: !pdf || currentPageNumber === 1,
                            onClick: () => onPreviousPageClick()
                        },
                        {
                            key: "NextPage",
                            text: "NextPage",
                            iconOnly: true,
                            iconProps: { iconName: "Play" },
                            disabled: !pdf || currentPageNumber === pdf.numPages,
                            onClick: () => onNextPageClick()
                        },
                        {
                            key: "LastPage",
                            text: "LastPage",
                            iconOnly: true,
                            iconProps: { iconName: "Next" },
                            disabled: !pdf || currentPageNumber === pdf.numPages,
                            onClick: () => onLastPageClick()
                        },
                        {
                            key: "Zoom",
                            text: "Zoom",
                            subMenuProps: {
                                items: [
                                    {
                                        key: "100",
                                        text: "100%",
                                        canCheck: true,
                                        checked: currentScale === 1,
                                        onClick: () => setCurrentScale(1)
                                    },
                                    {
                                        key: "125",
                                        text: "125%",
                                        canCheck: true,
                                        checked: currentScale === 1.25,
                                        onClick: () => setCurrentScale(1.25)
                                    },
                                    {
                                        key: "150",
                                        text: "150%",
                                        canCheck: true,
                                        checked: currentScale === 1.5,
                                        onClick: () => setCurrentScale(1.5)
                                    },
                                    {
                                        key: "200",
                                        text: "200%",
                                        canCheck: true,
                                        checked: currentScale === 2,
                                        onClick: () => setCurrentScale(2)
                                    },
                                ]
                            }
                        },
                        {
                            key: "Mail",
                            text: "Mail",
                            iconOnly: true,
                            iconProps: { iconName: "Mail" }
                        },
                        {
                            key: "Print",
                            text: "Download and print",
                            iconOnly: true,
                            iconProps: { iconName: "Print" }
                        },
                    ]} />
            </StackItem>
            <StackItem grow>
                <div style={{ position: "relative", height: "100%" }}>
                    <ScrollablePane styles={{ root: { padding: 0 } }}>
                        <canvas
                            ref={canvasRef}
                        />
                    </ScrollablePane>

                </div>
            </StackItem>
        </Stack>

    );
};