import { useNumberFormat } from 'hooks';
import { FontWeights, Stack, StackItem } from 'office-ui-fabric-react'
import React from 'react'
import { ISalesTrendTotalsItemProps } from './interfaces'

export const SalesTrendTotalsItem = (props: ISalesTrendTotalsItemProps) => {

    const { useNumberFormatHook, collectionCode, salesTotal, salesQuantity } = props;
    const { getPrice, getNumber } = useNumberFormatHook;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <StackItem styles={{ root: { fontWeight: FontWeights.bold } }}>
                {collectionCode}
            </StackItem>
            <Stack horizontal styles={{ root: { fontWeight: FontWeights.semibold } }}>
                <StackItem grow>
                    {getNumber(salesQuantity)}
                </StackItem>
                <StackItem >
                    {getPrice(salesTotal)}
                </StackItem>
            </Stack>
        </Stack>
    )
}
