import React from 'react';
import { IUseDatesProps } from './interfaces';

export const useDates = (locale?: string): IUseDatesProps => {

    const getDateByUtc = (utcDate: any): Date | undefined => {
        if (utcDate) {
            const utcDateString = String(utcDate);
            return new Date(!utcDateString.includes("Z") ? utcDateString + "Z" : utcDateString);
        }
    }

    const dateByUtcToLocaleDateString = (utcDate: any): string | undefined => {
        return getDateByUtc(utcDate).toLocaleDateString(locale);
    }

    const dateToLocaleDateString = (date?: Date): string | undefined => {
        return date && date.toLocaleDateString(locale);
    }

    const dateByUtcToLocaleString = (utcDate: any): string | undefined => {
        return getDateByUtc(utcDate).toLocaleString(locale);
    }

    const dateToLocaleString = (date?: Date): string | undefined => {
        return date && date.toLocaleString(locale);
    }

    return {
        getDateByUtc,
        dateByUtcToLocaleString,
        dateToLocaleString,
        dateByUtcToLocaleDateString,
        dateToLocaleDateString
    };
};