export enum AccountFields {
    ACTIVATED = "Activated",
    ACTIVATED_ON = "ActivatedOn",
    CHANGE_PASSWORD_ON_NEXT_LOGIN = "ChangePasswordOnNextLogin",
    CODE = "Code",
    CONTACT_ID = "ContactId",
    CULTURE_ID = "CultureId",
    CREATED_ON = "CreatedOn",
    ID = "Id",
    IS_ACTIVE = "IsActive",
    NAME = "Name",
    PASSWORD = "Password",
    ROW_GUID = "RowGuid",
    ROW_GUID_CREATED_ON = "RowGuidCreatedOn",
    SALT = "Salt",
    TOKEN = "Token",
    USERNAME = "Username",
    PRIVILEGE = "Privilege"
}