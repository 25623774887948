import { ISetDetailLayoutCommandBarAction, ISetDetailLayoutTitleBarAction } from './types';
import { DefaultCommandTypes } from 'enums';
export const SET_DETAILS_LAYOUT_COMMAND_BAR_STATE = "SET_DETAILS_LAYOUT_COMMAND_BAR_STATE";
export const SET_DETAILS_LAYOUT_TITLE_BAR_STATE = "SET_DETAILS_LAYOUT_TITLE_BAR_STATE";

export const setDetailLayoutCommandBarState = (id: number, menuId: number, defaultCommandType: DefaultCommandTypes, enabled: boolean): ISetDetailLayoutCommandBarAction => {
    return {
        type: SET_DETAILS_LAYOUT_COMMAND_BAR_STATE, 
        id, menuId, defaultCommandType, enabled
    }
}

export const setDetailLayoutTitleBarState = (id: number, menuId: number, title: string): ISetDetailLayoutTitleBarAction => {
    return {
        type: SET_DETAILS_LAYOUT_TITLE_BAR_STATE, 
        id, menuId, title
    }
}