import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Link } from 'office-ui-fabric-react/lib/Link';

import { DictionaryAPI, AccountAPI, MenuAPI, DocumentAPI, UserSettingAPI } from 'api';
import { IMatchParameters } from 'interfaces';
import { IMenu } from 'interfaces/models';
import { connect } from 'react-redux';
import { IAuthenticationState } from 'store/authentication/types';
import { IErrorMessageState } from 'store/errorMessage/types'
import { setErrorMessage } from 'store/errorMessage/actions';
import { Logo } from 'components/common';
import { LeftNavigationMenus, MenuTypes, ParentProductCategories, RelationTypes } from 'enums';

import { AppLayout } from 'components/layouts'
import {
    Dashboard,
    SignIn,
    CreatePassword,
    Customers,
    Products,
    Orders,
    AccountVerification,
    Catalogs,
    ProductCatalog,
    StockAssortments
} from 'components/pages';

import { storeInstance } from 'store';
import { useGlobals } from 'hooks';
import { ApplicationAPI } from 'api/app/ApplicationAPI';
import { IApplicationState } from 'store/application/types';
import { IDictionaryState } from 'store/dictionary/types';
import { NavigationMenus } from 'enums/app/NavigationMenus';
import ProductStockResale from 'components/pages/ProductStockResale';
import ProductStock from 'components/pages/ProductStock';

interface IAppProps {
    dictionaryState: IDictionaryState,
    authenticationState: IAuthenticationState,
    applicationState: IApplicationState,
    errorMessageState: IErrorMessageState
}

/* interface IAppState {
    isLoading: boolean,
    navigationMenus: IMenu[],
    routeMenuItems: IMenu[],
} */

const App = (props: IAppProps): JSX.Element => {

    const { authenticationState, applicationState, errorMessageState } = props;
    const [loading, setLoading] = useState(true);

    /* const [state, setState] = useState<IAppState>({
        isLoading: true,
        navigationMenus: [],
        routeMenuItems: []
    }); */

    //const [selectedMenu, setSelectedMenu] = useState<IMenu>();

    const { clientId, companyContactId, companyRelationId } = useGlobals();

    /* const onMenuClick = (menu: IMenu) => {

        setSelectedMenu(menu);

        if (menu.Children.filter(m => m.TypeId === MenuTypes.Navigation).length === 0) {
            window.location.href = "#" + menu.url;
        }
    } */

    const getErrorMessageContent = (errorMessage: string): JSX.Element => {
        return (
            <Stack
                horizontalAlign="stretch"
                verticalAlign="center"
                verticalFill={true}>
                <StackItem align="center">
                    <Logo isLarge={true} padding={5} />
                </StackItem>
                <StackItem align="center">
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {errorMessage + " "}
                        <Link onClick={() => {
                            window.location.reload();
                        }}>
                            Try again
                        </Link>
                    </MessageBar>
                </StackItem>
            </Stack>);
    }

    const getRouteContent = (menu: IMenu, id?: string, type?: string): JSX.Element | undefined => {

        UserSettingAPI.saveCurrentUrlToLocalStorage();

        switch (menu.Id) {
            case LeftNavigationMenus.Dashboard:
                return <Dashboard />
            case LeftNavigationMenus.Customers:
                return <Customers menu={menu} companyRelationId={companyRelationId} parentContactId={companyContactId} relationTypeId={RelationTypes.Customer} rowGuid={id} />
            case LeftNavigationMenus.Prospects:
                return <Customers menu={menu} companyRelationId={companyRelationId} parentContactId={companyContactId} relationTypeId={RelationTypes.Prospect} rowGuid={id} />
            case LeftNavigationMenus.Products_Shoes:
                return <Products menu={menu} parentCategoryId={ParentProductCategories.Shoes} />
            case LeftNavigationMenus.Products_Clothing:
                return <Products menu={menu} parentCategoryId={ParentProductCategories.Clothing} />
            case LeftNavigationMenus.Products_Accessories:
                return <Products menu={menu} parentCategoryId={ParentProductCategories.Accessories} />
            case LeftNavigationMenus.Products_Cozy:
                return <Products menu={menu} parentCategoryId={ParentProductCategories.Cozy} />
            case LeftNavigationMenus.ProductCatalog_Accessories:
                return <ProductCatalog productCategoryId={ParentProductCategories.Accessories} productId={Number(id)} />
            case LeftNavigationMenus.ProductCatalog_Clothing:
                return <ProductCatalog productCategoryId={ParentProductCategories.Clothing} productId={Number(id)} />
            case LeftNavigationMenus.ProductCatalog_Shoes:
                return <ProductCatalog productCategoryId={ParentProductCategories.Shoes} productId={Number(id)} />
            case LeftNavigationMenus.Catalogs:
                return <Catalogs menu={menu} rowGuid={id} />
            case LeftNavigationMenus.Orders:
                return <Orders menu={menu} rowGuid={id?.replace("_", "")} documentStatusId={type && Number(type)} />
            case LeftNavigationMenus.StockAssortment:
                return <StockAssortments menu={menu} rowGuid={id?.replace("_", "")} documentStatusId={type && Number(type)} />
            case LeftNavigationMenus.Sales_StockResale:
                return <ProductStockResale />
            case LeftNavigationMenus.Sales_StockList:
                return <ProductStock />
            default:
                return undefined;
        }
    }

    /*     const setLoadingComplete = (): void => {
            setState({
                ...state,
                isLoading: false
            });
        } */

    const init = (): void => {
        DictionaryAPI.load(() => {
            AccountAPI.authenticateByRefreshToken(() => {
                ApplicationAPI.load(Number(clientId), () => {
                    DocumentAPI.loadActivePendingOrderCustomerState(() => {
                        MenuAPI.load(() => {
                            setLoading(false);

                            /* setState({
                                isLoading: false,
                                navigationMenus: MenuAPI.find(NavigationMenus.LeftNavigation)?.Children,
                                routeMenuItems: MenuAPI.filterForRoutes()
                            }); */
                        });
                    })
                });
            },
                () => setLoading(false)
            );
        });
    }

    const onSignedIn = (): void => {
        setLoading(true);
    }

    useEffect(() => {
        if (loading) {
            init();
        }
    }, [loading]);

    useEffect(() => {
        if (authenticationState.account) {
            setLoading(true);
        }
    }, [authenticationState.account])

    if (errorMessageState.message !== "") {
        return getErrorMessageContent(errorMessageState.message);
    }

    if (!loading && authenticationState.account && applicationState.application) {

        var routeMenus = MenuAPI.getRouteMenus();

        console.log("routeMenus", routeMenus)

        return (
            <>
                <AppLayout menuItems={MenuAPI.filterByType(MenuTypes.Navigation)} >
                    <Switch>
                        {
                            MenuAPI.getRouteMenus().map((v, i) => {
                                const menu = MenuAPI.find(v.Id)!;
                                let param: string = "/:id?";

                                switch (v.Id) {
                                    case LeftNavigationMenus.Orders:
                                        param = "/:id?/:type?"
                                        break;

                                    default:
                                        break;
                                }

                                return (
                                    <Route
                                        key={v.Id}
                                        path={v.Path + param}
                                        render={
                                            (props: RouteComponentProps<IMatchParameters>) => getRouteContent(menu, props.match.params.id, props.match.params.type)
                                        }
                                    />
                                )
                            })
                        }
                        <Route path="/" render={() => <Redirect to={{ pathname: '/dashboard' }} />} />
                    </Switch>
                </AppLayout>
            </>
        );
    } else if (!loading && authenticationState.account === undefined) {
        return (
            <>
                <Switch>
                    <Route path="/sign-in" render={() => <SignIn onSignedIn={onSignedIn} />} />
                    <Route path="/create-password" component={CreatePassword} />
                    <Route path="/account-verification/:id" render={
                        (props: RouteComponentProps<IMatchParameters>) =>
                            <AccountVerification rowGuid={props.match.params.id} />
                    }
                    />
                    <Route path="/" render={() => <Redirect to={{ pathname: '/sign-in' }} />} />
                </Switch>
            </>
        );
    }
    return (
        <Stack
            horizontalAlign="stretch"
            verticalAlign="center"
            verticalFill={true}>
            <StackItem align="center">
                <Logo isLarge={true} padding={5} />
                <ProgressIndicator />
            </StackItem>
        </Stack>
    );

}

const mapStateToProps = (state: IAppProps) => ({
    dictionaryState: state.dictionaryState,
    authenticationState: state.authenticationState,
    applicationState: state.applicationState,
    errorMessageState: state.errorMessageState
});

export default connect(mapStateToProps)(App);

