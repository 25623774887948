import { BaseAPI } from 'api';
import { IContactSearchListItem } from 'interfaces/models';
import { IAddContactProductCategoryViewModel, IContactProductCategoryViewModel, IDeleteContactProductCategoryViewModel } from 'interfaces/viewmodels';

export class ContactAPI {

    public static searchByName = (name: string, onSuccess: (data: IContactSearchListItem[]) => void) => {
        BaseAPI.post("api/crm/contact/searchByName", name, onSuccess);
    }

/*     public static readProductCategories = (contactId: number, onSuccess: (data: IContactProductCategoryListItem[]) => void) => {
        BaseAPI.get(`api/crm/contact/ReadProductCategories/${contactId}`, null, onSuccess);
    } */

/*     public static getContactProductCategoryListViewModel = (contactId: number, onSuccess: (data: IContactProductCategoryListViewModel) => void) => {
        BaseAPI.get(`api/crm/contact/getContactProductCategoryListViewModel/${contactId}`, null, onSuccess);
    } */

    public static addContactProductCategory = (viewModel: IAddContactProductCategoryViewModel, onSuccess: () => void) => {
        BaseAPI.post("api/crm/contact/AddContactProductCategory", viewModel, onSuccess);
    }

    public static getContactProductCategory = (contactId: number, productCategoryId: number, onSuccess: (data: IContactProductCategoryViewModel) => void) => {
        BaseAPI.get(`api/crm/contact/GetContactProductCategory/${contactId}/${productCategoryId}`, null, onSuccess);
    }

/*     public static updateProductCategory = (viewModel: IUpdateContactProductCategoryViewModel, onSuccess: () => void, onError: (xhr: XMLHttpRequest, errorMessages?: IErrorMessagesMap) => void) => {
        BaseAPI.post("api/crm/contact/UpdateProductCategory", viewModel, onSuccess, onError);
    } */

    public static deleteProductCategory = (viewModel: IDeleteContactProductCategoryViewModel, onSuccess: () => void) => {
        BaseAPI.post("api/crm/contact/DeleteProductCategory", viewModel, onSuccess);
    }
}