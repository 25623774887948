export enum RelationTypes {
    Any = 0,
    Company = 1,
    Customer = 2,
    Prospect = 3,
    Agent = 5,
    Branch = 7,
    Contact = 8,
    Warehouse = 9,
    DeliveryAddress = 10,
    PointOfSales = 91,
    PurchaseAssociation = 1020
}