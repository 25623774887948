export enum Profiles {
    Administrator = 1,
    Sales = 5,
    Customer = 6,
    SalesCountryManager = 7,
    SalesAccessories = 8,
    SalesClothing = 9,
    SalesShoes = 53,
    DealerAccessories = 61,
    DealerFashion = 62,
    DealerShoes = 63,
    Design = 70
}