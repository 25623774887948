export enum ProductDocumentDetailListItemFields {
    BARCODE = "Barcode",
    CODE = "Code",
    COMPOSITION = "Composition",
    CREATED_ON = "CreatedOn",
    DISCOUNT1 = "Discount1",
    DISCOUNT2 = "Discount2",
    DISCOUNT_TOTAL = "DiscountTotal",
    DOCUMENTDETAIL_STATUS_ID = "DocumentDetailStatusId",
    DOCUMENT_ID = "DocumentId",
    GENDER_ID = "GenderId",
    HAS_PRODUCTION_ORDER = "HasProductionOrder",
    ID = "Id",
    IMAGE = "Image",
    NAME = "Name",
    ORIGIN_PRODUCT_ID = "OriginProductId",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCT_CATEGORY_NAME = "ProductCategoryName",
    PRODUCT_ID = "ProductId",
    QUANTITY = "Quantity",
    SIZE_CODE = "SizeCode",
    SIZE_ID = "SizeId",
    SIZE_NAME = "SizeName",
    SIZE_RANGE_ID = "SizeRangeId",
    SRP = "Srp",
    SUB_PRODUCT_CATEGORY_ID = "SubProductCategoryId",
    SUB_PRODUCT_CATEGORY_NAME = "SubProductCategoryName",
    TOTAL_PRICE = "TotalPrice",
    TOTAL_PRICE_EXCL_DISCOUNT = "TotalPriceExclDiscount",
    UNIT_PRICE = "UnitPrice",
    UOM_CODE = "UomCode",
    VARIANT_DESCRIPTION = "VariantDescription",
    VAT_TOTAL = "VatTotal"
}