export enum SizeFields {
    AGE_GROUP_ID = "AgeGroupId",
    CODE = "Code",
    CREATED_ON = "CreatedOn",
    GENDER_ID = "GenderId",
    HEIGHT = "Height",
    IS_ACTIVE = "IsActive",
    LENGTH = "Length",
    NAME = "Name",
    ORGIN_ID = "OrginId",
    SIZE_TYPE_ID = "SizeTypeId",
    WEIGHT = "Weight",
    WIDTH = "Width"
}