import React, { useState, useEffect, useMemo } from 'react';
import { IFilterBarItemProps } from 'components/common';
import { ICatalogsProps } from './interfaces';
import { DefaultCommandTypes } from 'enums';
import { CatalogAPI } from 'api';
import { ICatalogFilterContext, ICatalogListItem, IFilter, IRangeFilter } from 'interfaces/models';
import { DayOfWeek, IColumn } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, DateRangeFilter } from 'components/common';
import { useFilterContextHandler, useDates, useSelection, useUserSetting } from 'hooks';
import { Catalog, CreateCatalog } from 'components/pages';
import { CatalogListItemFields } from 'enums/fields';
import { IDefaultCommand } from 'interfaces';
import { ListView } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const Catalogs = (props: ICatalogsProps) => {

    const { menu, rowGuid } = props;

    const {
        ACTIVE_FROM,
        ACTIVE_TO,
        PRODUCT_CATEGORY_NAME,
        COLLECTION_CODE,
        CREATED_ON,
        NAME,
        ROW_GUID
    } = CatalogListItemFields;

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const userSettingHook = useUserSetting(menu.Id);

    const [isCreateCatalog, setIsCreateCatalog] = useState<boolean>();
    const { dateByUtcToLocaleDateString } = useDates(cultureId);

    const filterContextHandler = useFilterContextHandler<ICatalogFilterContext>(
        (onSuccess, isActive) => {
            CatalogAPI.getFilterContext(isActive, onSuccess);
        },
        CatalogAPI.filter, userSettingHook);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onRangeFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterResultInfo } = filterContextHandler;

    const { selection, getFirstSelectedItemIndex } = useSelection<string>(
        ROW_GUID,
        filterContextHandler.filterResultInfo?.Items,
        [],
        filterContextHandler.isFiltering);

    const getDefaultCommands = (): IDefaultCommand[] => {
        return [
            { type: DefaultCommandTypes.New },
        ]
    }

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: NAME, width: 200, isRowHeader: true, linkProps: { baseUrl: "#" + menu.Path, urlParameterFieldName: ROW_GUID } },
            { fieldName: PRODUCT_CATEGORY_NAME, width: 125, name: "Category" },
            { fieldName: COLLECTION_CODE, width: 80, name: "Collection" },
            { fieldName: ACTIVE_FROM, width: 100 },
            { fieldName: ACTIVE_TO, width: 100 },
            { fieldName: CREATED_ON, width: 80, name: "Created" },
        ];

        return columns;
    }

    const detailsListColumns = useMemo(() => getDetailsListColumns(), [menu]);

    const onRenderDetailsListItemColumn = (item?: ICatalogListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName && item[column.fieldName]) {

            switch (column.fieldName) {
                case ACTIVE_FROM:
                case ACTIVE_TO:
                case CREATED_ON:
                    return <span>{dateByUtcToLocaleDateString(item[column.fieldName])}</span>

                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (<FilterBar
                filterContextHandler={filterContextHandler}
                onRenderFilterBarItem={onRenderFilterBarItem}
            />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {

        const { filter, name } = filterBarItemProps;

        switch (name) {
            case CREATED_ON:

                return (
                    <DateRangeFilter
                        cultureId={cultureId}
                        firstDayOfWeek={DayOfWeek.Monday}
                        filter={filter as IRangeFilter}
                        name={name}
                        onChange={onRangeFilterChange} />
                )

            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.New:
                setIsCreateCatalog(true);
                break;
            case DefaultCommandTypes.Print:
                break;
            default:
                break;
        }
    }

    const onCreateCatalogAccept = (rowGuid: string) => {
        setIsCreateCatalog(false);
        getFilterResultInfo(true);
        window.location.href = "#" + menu.Path + "/" + rowGuid;
    }

    const onDismiss = (changesUpdated?: boolean) => {
        if (changesUpdated) {
            getFilterResultInfo(false);
        } else {
            window.location.href = "#" + menu.Path;
        }
    }

    useEffect(() => {
        window.location.href = "#" + menu.Path;
    }, [filterResultInfo])

    return (
        <>
            <ListView
                menu={menu}
                title={menu.translatedName}
                detailsListColumns={detailsListColumns}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                selection={selection}
                getInitialFocusedIndex={getFirstSelectedItemIndex}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                dataLoading={isFiltering}
                onRefreshButtonClick={getFilterResultInfo}
                onOrderByChange={onOrderByChange}
                onDefaultCommandClick={onDefaultCommandClick}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                isCreateCatalog &&
                <CreateCatalog
                    onSuccess={onCreateCatalogAccept}
                    onCancel={() => setIsCreateCatalog(false)} />
            }
            {
                rowGuid &&
                <Catalog
                    rowGuid={rowGuid}
                    onDismiss={onDismiss} />
            }
        </>
    );
};