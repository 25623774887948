import { BaseAPI } from '..'
import { useCookies } from 'hooks';
import { ILanguage } from 'interfaces/models';

const { getCookie, setCookie } = useCookies();
const COOKIE_NAME: string = "adminui.language";

export class LanguageAPI {
    public static get(id: string, onSuccess: (data: ILanguage) => void) {
        BaseAPI.get(`api/utl/language/get/${id}`, null, onSuccess, undefined, true);
    }

    public static getCookie(): string {
        const language = getCookie(COOKIE_NAME);
        return language || "";
    }

    public static setCookie(language: string) {
        let d = new Date();
        d.setFullYear(d.getFullYear() + 1);
        setCookie(COOKIE_NAME, language, d);
    }
}
