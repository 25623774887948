export enum CollectionFields {
    BRAND_ID = "BrandId",
    CODE = "Code",
    CREATED_ON = "CreatedOn",
    DELIVERY_REMARKS = "DeliveryRemarks",
    ID = "Id",
    IS_ACTIVE = "IsActive",
    NAME = "Name",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCTION_ALLOWED = "ProductionAllowed",
    SALES_ALLOWED = "SalesAllowed",
    SEASON_ID = "SeasonId"
}