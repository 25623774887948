import { DictionaryAPI, DocumentAPI } from 'api'
import { Dialog } from 'components/common'
import { CancelledDocumentStatuses } from 'enums';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { ICancelDocumentDialogProps } from './interfaces';

export const CancelDocumentDialog = (props: ICancelDocumentDialogProps) => {

    const { documentRowGuid, onSuccess, onCancel } = props;

    const [cancelledDocumentStatus, setCancelledDocumentStatus] = useState<CancelledDocumentStatuses>();
    const [fetching, setFetching] = useState(false);

    const getOption = (cancelledDocumentStatuses: CancelledDocumentStatuses): IChoiceGroupOption => {
        return {
            key: String(cancelledDocumentStatuses), 
            text: DictionaryAPI.getTranslation(CancelledDocumentStatuses[cancelledDocumentStatuses]),
            disabled: fetching
        }
    }

    const onAccept = () => {
        setFetching(true);
        DocumentAPI.cancelDocument({
            DocumentRowGuid: documentRowGuid,
            CancelledDocumentStatus: cancelledDocumentStatus
        }, onSuccess)
    }

    return (
        <Dialog
            maxWidth={600}
            title={DictionaryAPI.getTranslation("Cancel")}
            onAccept={onAccept}
            onCancel={onCancel}
            acceptButtonDisabled={!cancelledDocumentStatus || fetching}
            cancelButtonDisabled={fetching}
        >
           <ChoiceGroup 
                options={[
                    getOption(CancelledDocumentStatuses.Company),
                    getOption(CancelledDocumentStatuses.Production),
                    getOption(CancelledDocumentStatuses.Customer),
                ]} 
                onChange={(ev, option) => setCancelledDocumentStatus(Number(option.key))} 
                label={DictionaryAPI.getTranslation("CancelledBy")}
                required={true} />
        </Dialog>
    )
}
