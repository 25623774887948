export enum ProductDocumentDetailsGroupViewModelFields {
    DOCUMENTDETAILS = "DocumentDetails",
    DOCUMENTDETAIL_STATUS_ID = "DocumentDetailStatusId",
    IMAGE = "Image",
    NAME = "Name",
    PRODUCT_ID = "ProductId",
    SIZE_RANGE_ID = "SizeRangeId",
    TOTAL_PRICE = "TotalPrice",
    TOTAL_PRICE_INCL_VAT = "TotalPriceInclVat",
    TOTAL_QUANTITY = "TotalQuantity",
    VARIANT_DESCRIPTION = "VariantDescription"
}