import React, { useState, useEffect } from 'react';
import {
    getTheme,
    ScrollablePane, ScrollbarVisibility,
    Sticky,
    ShimmeredDetailsList as FabricDetailsList,
    IColumn,
    ConstrainMode,
    CheckboxVisibility,
    IDetailsHeaderProps,
    StickyPositionType,
    IRenderFunction,
    Stack,
    StackItem,
    DetailsListLayoutMode,
    Link,
    LinkBase,
    SelectionMode,
} from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api'

import { IDetailsListColumn, IDetailsListProps } from '.';
//import { IDetailsListColumn } from './interfaces'; 
import { Loader } from 'components/common';

export const DetailsList = (props: IDetailsListProps): JSX.Element => {

    const {
        detailsListColumns,
        items,
        compact,
        orderByRule,
        dataLoading,
        selectionMode,
        selection,
        noBorder,
        readOnly,
        onRowDidMountIndexThreshold,
        onRowDidMount,
        onRenderItemColumn,
        onColumnClick,
        onItemLinkClick,
        getInitialFocusedIndex } = props;


    const [columns, setColumns] = useState<IColumn[]>();
    const [initialFocusedIndex, setInitialFocusedIndex] = useState<number>();

    const theme = getTheme();
    const ITEM_COLUMN_LINK = "item-column-link-";

    const getColumns = (): IColumn[] => {
        return detailsListColumns.map((v, i): IColumn => {
            return {
                key: v.fieldName,
                fieldName: v.fieldName,
                name: v.name === "" ? "" : v.translateHeaderDisabled ? (v.name ? v.name : v.fieldName) : DictionaryAPI.getTranslation(v.name ? v.name : v.fieldName),
                className: v.className,
                iconClassName: v.iconClassName,
                ariaLabel: v.ariaLabel,
                iconName: v.iconName,
                isIconOnly: v.isIconOnly,
                minWidth: v.width,
                isRowHeader: v.isRowHeader,
                isResizable: v.isResizable,
                isSorted: orderByRule?.FieldName === v.fieldName,
                isSortedDescending: orderByRule?.FieldName === v.fieldName && orderByRule?.IsDescending,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: v,
                styles: {
                    root: { textAlign: v.textAlign },
                    cellName: { padding: 0 }
                }
            }
        });
    }

/*     const _onRowHeaderLinkClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLElement | HTMLButtonElement | LinkBase, MouseEvent>) => {
        if (onItemLinkClick) {
            const ev = event as React.MouseEvent<HTMLElement>;
            ev.preventDefault();
            const index = Number(ev.currentTarget.id.replace(ITEM_COLUMN_LINK, ""));
            onItemLinkClick(items[index]);
        }
    } */

    const _onRenderItemColumn = (item?: any, index?: number, column?: IColumn): React.ReactNode => {

        if (index !== undefined && column !== undefined) {
            const detailsListColumn: IDetailsListColumn = column.data;
            const { fieldName, translate, linkProps } = detailsListColumn;

            if (detailsListColumn.isRowHeader && !readOnly) {

                let url: string | undefined;

                if (linkProps) {

                    url = linkProps.baseUrl;
                    if (linkProps.urlParameterFieldName) {
                        url = url + "/" + item[linkProps.urlParameterFieldName];
                    }
                }

                return (
                    <Link
                        href={url}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={onItemLinkClick ? () => onItemLinkClick(item) : undefined}
                    >
                        {item[fieldName]}
                    </Link>
                )
            } else if (!onRenderItemColumn) {
                return (
                    <span>{translate ? DictionaryAPI.getTranslation(item[fieldName]) : item[fieldName]}</span>
                )
            } else {
                return onRenderItemColumn(item, index, column);
            }
        }

        return undefined;
    }

    const _onRowDidMount = (item?: any, index?: number) => {
        if (onRowDidMount && (index > onRowDidMountIndexThreshold || onRowDidMountIndexThreshold === 0)) {
            onRowDidMount(item, index);
        }
    }

    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const handleColumnReorder = (draggedIndex: number, targetIndex: number): void => {
        //const draggedItems = this.state.columns[dragDropDetails.draggedIndex];
        //const newColumns: IColumn[] = [...this.state.columns];

        //// insert before the dropped item
        //newColumns.splice(dragDropDetails.draggedIndex, 1);
        //newColumns.splice(dragDropDetails.targetIndex, 0, draggedItems);
        //this.setState({ columns: newColumns });
    }

    const onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (column) {
            if (props.disableServerSideSort) {

                const newColumns: IColumn[] = columns.slice();
                const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
                newColumns.forEach((newCol: IColumn) => {
                    if (newCol === currColumn) {
                        currColumn.isSortedDescending = !currColumn.isSortedDescending;
                        currColumn.isSorted = true;
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = false;
                    }
                });

                setColumns([...newColumns]);

            } else if (onColumnClick) {
                onColumnClick(column);
            }
        }
    };

    const onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {
                    props && defaultRender ?
                        defaultRender({
                            ...props, styles: {
                                root: {
                                    padding: 0
                                }
                            }
                        })
                        : null
                }
            </Sticky>
        );
    }

    const onActiveItemChanged = (item?: any, index?: number | undefined, ev?: React.FocusEvent<HTMLElement> | undefined) => {
        setInitialFocusedIndex(undefined);

        if (props.onActiveItemChanged) {
            props.onActiveItemChanged(item, index, ev);
        }
    }

        useEffect(() => {
            if (detailsListColumns) {
                setColumns(getColumns());
            }
    
        }, [detailsListColumns])

    useEffect(() => {
        if (getInitialFocusedIndex) {
            setInitialFocusedIndex(getInitialFocusedIndex());
        }
    }, [items])

    return (
        <Stack horizontalAlign="stretch" verticalFill>
            <StackItem verticalFill>
                <div style={{ position: "relative", height: "100%", border: noBorder ? "" : "1px solid " + theme.semanticColors.inputBorder }}>
                    {
                        columns ? (
                            <ScrollablePane styles={{ root: { padding: 0 } }} scrollbarVisibility={props.layoutMode === DetailsListLayoutMode.justified ? ScrollbarVisibility.always : ScrollbarVisibility.auto}>
                                <FabricDetailsList
                                    items={items}
                                    columns={columns}
                                    compact={compact}
                                    onRowDidMount={_onRowDidMount}
                                    enableShimmer={dataLoading}
                                    constrainMode={ConstrainMode.unconstrained}
                                    selection={selection}
                                    selectionMode={selectionMode}
                                    selectionPreservedOnEmptyClick={true}
                                    layoutMode={props.layoutMode}
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    initialFocusedIndex={initialFocusedIndex}
                                    /* columnReorderOptions={!props.disableReorderColumns ? {
                                        frozenColumnCountFromStart: 0,
                                        frozenColumnCountFromEnd: 0,
                                        handleColumnReorder: handleColumnReorder
                                    } : undefined} */
                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                    onRenderItemColumn={_onRenderItemColumn}
                                    onColumnHeaderClick={onColumnHeaderClick}
                                    onActiveItemChanged={onActiveItemChanged}
                                    styles={{
                                        root: {
                                            padding: 0
                                        }

                                    }}
                                />
                            </ScrollablePane>
                        ) : <Loader />
                    }
                </div>
            </StackItem>
        </Stack>
    );

}

