import React, { useEffect } from 'react';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { SideMenu } from 'components/common'
import { IAppLayoutProps } from '.';
import { Header } from './Header';
import { useSelector } from 'react-redux';
import { RootState, storeInstance } from 'store';
import { setActivePendingOrder, setPendingOrdersVisible } from 'store/pendingOrders/actions';
import { DocumentAPI } from 'api';
import { PendingOrder, PendingOrders } from 'components/pages';

import { getTheme } from 'office-ui-fabric-react';

const theme = getTheme();

export const AppLayout: React.FunctionComponent<IAppLayoutProps> = props => {
    const { menuItems } = props;

    const { account } = useSelector((state: RootState) => state.authenticationState);
    const pendingOrdersState = useSelector((state: RootState) => state.pendingOrdersState);

    const onOpenOrderButtonClick = (documentRowGuid: string) => {
        storeInstance.dispatch(setActivePendingOrder(documentRowGuid));
    }

    useEffect(() => {
        DocumentAPI.loadPendingOrdersState();
    }, [pendingOrdersState.customerRelationRowGuid])

    return (
        <Stack horizontalAlign="stretch" verticalFill>
            <StackItem disableShrink>
                <Header
                    isLogoCollapsed={false}
                    account={account}
                    pendingOrdersState={pendingOrdersState}
                    breadcrumbProps={{ menuNamePath: [] }}
                    onPendingOrdersPanelButtonClick={() => storeInstance.dispatch(setPendingOrdersVisible(!pendingOrdersState.isVisible))}
                />
            </StackItem>
            <StackItem grow>
                <Stack horizontalAlign="stretch" horizontal verticalFill>
                    <StackItem>
                        <SideMenu items={menuItems} />
                    </StackItem>
                    <StackItem styles={{ root: { overflow: "hidden" } }} grow>
                        {props.children}
                    </StackItem>
                    {
                        pendingOrdersState.orders?.length > 0 && pendingOrdersState.isVisible && (
                            <StackItem styles={{ root: { borderLeft: "1px solid " + theme.semanticColors.bodyFrameDivider } }}>
                                <PendingOrders
                                    orders={pendingOrdersState.orders}
                                    cultureId={account.CultureId}
                                    customerRelationRowGuid={pendingOrdersState.customerRelationRowGuid}
                                    onOpenOrderButtonClick={onOpenOrderButtonClick}
                                />
                            </StackItem>
                        )
                    }
                    {
                        pendingOrdersState.documentRowGuid && (
                            <PendingOrder
                                documentRowGuid={pendingOrdersState.documentRowGuid}
                                customerRelationRowGuid={pendingOrdersState.customerRelationRowGuid}
                                onDismiss={() => storeInstance.dispatch(setActivePendingOrder(undefined))}
                            />
                        )
                    }
                </Stack>
            </StackItem>

        </Stack>
    );
}
