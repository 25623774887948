import React from 'react'
import { Label, Stack, StackItem, TextField } from 'office-ui-fabric-react'
import { DictionaryAPI } from 'api'
import { IOrderTabBaseProps } from '.'
import { OrderViewModelFields } from 'enums/fields'
import { ColumnsStack, SpinButton } from 'components/common'

export const PaymentTab = (props: IOrderTabBaseProps) => {

    const { form, readOnly, cultureId } = props;
    const { item, onSpinButtonChange } = form;

    const { PAYMENT_INSTALLMENTS, PAYMENT_TERM_IN_DAYS } = OrderViewModelFields

    const columnStackItemStyles = {
        root: {
            flexBasis: 0
        }
    }

    return (
        <ColumnsStack containerStyle={{ padding: 10, maxWidth: 800 }}>
            <Stack>
                <TextField
                    readOnly
                    label={DictionaryAPI.getTranslation("PaymentCondition")}
                    value={item.PaymentConditionName}
                />
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <StackItem grow shrink>
                        <Label>{DictionaryAPI.getTranslation("PaymentInstallments")}</Label>
                        <SpinButton
                            value={item.PaymentInstallments}
                            name={PAYMENT_INSTALLMENTS}
                            step={1}
                            min={1}
                            max={3}
                            disabled={readOnly}
                            locale={cultureId}
                            onValueChange={onSpinButtonChange} 
                        />
                    </StackItem>
                    <StackItem grow shrink>
                        <Label>{DictionaryAPI.getTranslation("PaymentTerm")}</Label>
                        <SpinButton
                            value={item.PaymentTermInDays}
                            name={PAYMENT_TERM_IN_DAYS}
                            step={30}
                            min={30}
                            max={90}
                            suffix={DictionaryAPI.getTranslation("Days").toLowerCase()}
                            disabled={readOnly}
                            locale={cultureId}
                            onValueChange={onSpinButtonChange} 
                        />
                    </StackItem>
                </Stack>
            </Stack>

            <></>
        </ColumnsStack>
    )

    return (
        <Stack horizontal tokens={{ padding: 10, maxWidth: 800 }}>
            <Stack grow={1} styles={columnStackItemStyles}>


            </Stack>
            <Stack grow={1} styles={columnStackItemStyles}>
            </Stack>
        </Stack>
    )
}
