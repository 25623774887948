import React, { useState } from 'react';
import { DownloadFile, IFilterBarItemProps, LoaderModal, ToggleButtonFilter } from 'components/common';
import { ListViewCommandBarTypes } from 'components/common/ListViewCommandBar/enums';
import { IProductsListViewLayoutProps } from './interfaces';
import { ProductListItemFields, ProductFilterContextFields } from 'enums/fields';
import { IColourFilter, IFilter, IProductListItem } from 'interfaces/models';
import { Stack, StackItem, ImageFit, mergeStyleSets, DefaultPalette, FontSizes, IColumn, Link, ICommandBarItemProps, IContextualMenuItem, Icon, SemanticColorSlots } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, Image, ColourFilter } from 'components/common';
import { useDates, useNumberFormat } from 'hooks';
import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { ListView } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DictionaryAPI, MenuAPI, ProductAPI } from 'api';
import { OrdersInteractionMenus } from 'enums';
import { ProductsInteractionMenus } from 'enums/app/ProductsInteractionMenus';


export const ProductsListViewLayout = (props: IProductsListViewLayoutProps) => {

    const {
        menu,
        commandBarItems,
        printInteractionMenuId,
        associatedProductsGrouped,
        hideTitle,
        filterContextHandler,
        disableTileView,
        filterBarItemNamesToHide,
        detailsListColumnFieldNamesToHide,
        selection,
        checkable,
        informationPanelContentVisibleByDefault,
        onItemClick,
        onRenderInformationPanelContent,
    } = props;

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterResultInfo } = filterContextHandler;

    const { B2B_PRICE, B2C_PRICE, CODE, SUB_PRODUCT_CATEGORY_NAME, COLLECTIONCODE, SMALL_IMAGE, SOLD_QUANTITIES, SOLD_LINES, NAME, VARIANT_DESCRIPTION } = ProductListItemFields;
    const { COLLECTION_ID, PRODUCT_SIZE_CODE, SAMPLE_PRODUCT_SIZE_NAME, COLOUR_PARENT_ID } = ProductFilterContextFields;

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const { getPrice } = useNumberFormat(cultureId);
    const { getImageUrl } = useFileStoreUrl();
    const { dateByUtcToLocaleString } = useDates();
    const [downloadProductsJsonUrl, setDownloadProductsJsonUrl] = useState<string>(undefined);
    const [loading, setLoading] = useState(false);

    const classNames = mergeStyleSets({
        tileName: {
            color: DefaultPalette.neutralSecondary,
            fontWeight: "600",
            marginLeft: 5,
            marginRight: 5,
            whiteSpace: "noWrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        expectedIcon: {
            display: "inline-block",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            fontSize: FontSizes.xLarge,
            color: DefaultPalette.neutralSecondaryAlt
        }
    })

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (<FilterBar
                filterContextHandler={filterContextHandler}
                filterBarItemNamesToHide={filterBarItemNamesToHide}
                onRenderFilterBarItem={onRenderFilterBarItem}
            />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps): React.ReactNode => {

        const { filter, name } = filterBarItemProps;

        switch (name) {
            case COLOUR_PARENT_ID:
                return (
                    <ColourFilter
                        filter={filter as IColourFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
            case COLLECTION_ID:
            case SAMPLE_PRODUCT_SIZE_NAME:
            case PRODUCT_SIZE_CODE:
                return (
                    <ToggleButtonFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: SMALL_IMAGE, width: 50, name: "" },
            { fieldName: NAME, width: 175 },
            { fieldName: CODE, width: 60 },
            { fieldName: SAMPLE_PRODUCT_SIZE_NAME, width: 70, name: "SampleSize" },
            { fieldName: COLLECTIONCODE, width: 90, name: "Collection" },
            { fieldName: SUB_PRODUCT_CATEGORY_NAME, width: 125, name: "Category" },
            { fieldName: B2B_PRICE, width: 70, name: "B2b" },
            { fieldName: B2C_PRICE, width: 70, name: "B2c" },
            /*             { fieldName: SOLD_QUANTITIES, width: 60, name: "Abv_" + SOLD_QUANTITIES },
                        { fieldName: SOLD_LINES, width: 60, name: "Abv_" + SOLD_LINES }, */
        ];

        if (associatedProductsGrouped) {
            columns.push({ fieldName: "VARIANTS", width: 300, name: "Variants" });
        }

        return columns.filter(c => !detailsListColumnFieldNamesToHide?.includes(c.fieldName));
    }

    const onRenderVariantImages = (item: IProductListItem, imageSize: number, maxCount: number): JSX.Element => {
        return <Stack style={{ height: imageSize }} horizontal verticalAlign="center">
            {
                item.AssociatedProducts ?
                    item.AssociatedProducts.slice(0, item.AssociatedProducts.length > maxCount ? maxCount : item.AssociatedProducts.length).map((v: IProductListItem, i: number) => {
                        return (
                            <StackItem key={v.Id}>
                                <Image
                                    src={getImageUrl(v.SmallImage)}
                                    height={imageSize}
                                    width={imageSize}
                                    imageFit={ImageFit.contain} />
                            </StackItem>
                        )
                    }) : null
            }
            {
                item.AssociatedProducts && item.AssociatedProducts.length > maxCount ?
                    <StackItem styles={{ root: { paddingLeft: 5, fontSize: FontSizes.small, color: DefaultPalette.neutralSecondaryAlt } }}>
                        {`+ ${item.AssociatedProducts.length - maxCount}`}
                    </StackItem> : null
            }
        </Stack>
    }

    const onRenderDetailsListItemColumn = (item?: IProductListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName) {

            switch (column.fieldName) {
                case NAME:
                    return (
                        <Link onClick={() => onItemClick && onItemClick(item)}>
                            {associatedProductsGrouped ? item[NAME] : item[NAME] + " " + item[VARIANT_DESCRIPTION]}
                        </Link>
                    )
                case SMALL_IMAGE:
                    return <Image src={getImageUrl(item.SmallImage, dateByUtcToLocaleString(item.UpdatedOn))} width={48} height={48} alt={item.VariantDescription} imageFit={ImageFit.contain} />;
                case B2B_PRICE:
                case B2C_PRICE:
                    return <span>{getPrice(item[column.fieldName])}</span>
                case "VARIANTS":
                    return onRenderVariantImages(item, 32, 8);
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderTileListCell = (item?: IProductListItem, index?: number | undefined): React.ReactNode | undefined => {
        if (item) {
            return (<Stack tokens={{ childrenGap: 5 }} verticalFill>
                <StackItem grow>
                    {
                        new Date(item.ExpectedOn) > new Date() && (
                            <Icon className={classNames.expectedIcon} iconName="DeliveryTruck" />
                        )
                    }

                    <Image
                        src={getImageUrl(item.Image, dateByUtcToLocaleString(item.UpdatedOn))}
                        width="100%"
                        height="100%"
                        imageFit={ImageFit.contain}
                    />
                </StackItem>
                <StackItem className={classNames.tileName}>
                    {item[NAME] + " " + item[VARIANT_DESCRIPTION]}
                </StackItem>
                <StackItem styles={{ root: { color: DefaultPalette.neutralSecondary } }}>
                    {getPrice(item.B2bPrice)}
                </StackItem>
            </Stack>)
        }
    }

    const getPrintContextualMenuItems = (): IContextualMenuItem[] | undefined => {

        if (printInteractionMenuId) {

            return MenuAPI.filterByParent(printInteractionMenuId).map((v) => {
                return {
                    key: String(v.Id),
                    text: DictionaryAPI.getTranslation(v.Name),
                    onClick: () => ProductAPI.printProductInventory(filterContext, (path) => console.log("path", path))
                }
            })
        }

        return undefined;
    }

    const getExportContextualMenuItems = (): IContextualMenuItem[] => {

        return MenuAPI.filterByParent(menu.Id).map((v) => {
            return {
                key: String(v.Id),
                text: DictionaryAPI.getTranslation(v.Name),
                onClick: () => onExportInteractionMenuClick(v.Id)
            }
        })

    }

    const onExportInteractionMenuClick = (menuId: number) => {

        setLoading(true);

        switch (menuId) {

            case ProductsInteractionMenus.Export_Accessories:
            case ProductsInteractionMenus.Export_Clothing:
            case ProductsInteractionMenus.Export_Cozy:
            case ProductsInteractionMenus.Export_Shoes:
                ProductAPI.createProductsJson(filterContext,
                    (url) => {
                        setDownloadProductsJsonUrl(url);
                        setLoading(false);
                    })
                break;

            default:
                break;
        }
    }

    return (
        <>
            <ListView
                menu={menu}
                title={!hideTitle && menu.translatedName}
                commandBarItems={commandBarItems}
                printContextMenuItems={getPrintContextualMenuItems()}
                exportContextMenuItems={getExportContextualMenuItems()}
                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                onRefreshButtonClick={() => getFilterResultInfo()}
                availableListViewCommandBarTypes={!disableTileView ? [ListViewCommandBarTypes.Tiles, ListViewCommandBarTypes.List] : undefined}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                checkable={checkable}
                compact={true}
                items={filterResultInfo?.Items}
                selection={selection}
                informationPanelContentVisibleByDefault={informationPanelContentVisibleByDefault}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                onOrderByChange={onOrderByChange}
                loading={!filterContext}
                dataLoading={isFiltering}
                onItemClick={onItemClick}
                onRenderInformationPanelContent={onRenderInformationPanelContent}
                onRenderTileListCell={onRenderTileListCell}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                loading && (
                    <LoaderModal />
                )
            }
            <DownloadFile
                url={downloadProductsJsonUrl}
            />
        </>
    );
};