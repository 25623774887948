import React from 'react';
import { ComboBox, IComboBoxOption, IComboBox, Checkbox, Stack, StackItem, getTheme } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IFilterValue } from 'interfaces/models';
import { IFilterProps } from './interfaces';

export const CheckboxFilter = (props: IFilterProps): JSX.Element => {
    const { filter, name, onChange } = props;

    const selectedKeys: string[] = filter ?
        filter.Values.filter(fv => fv.IsChecked).map((v: IFilterValue, i: number): string => String(v.Value))
        : [];

    const options: IComboBoxOption[] =
        filter.Values.map((v, i): IComboBoxOption => {
            return {
                key: String(v.Value),
                text: filter.ValueNameTranslationRequired ? DictionaryAPI.getTranslation(v.Name) : v.Name
            }
        });

    const onComboBoxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option && index !== undefined) {
            onChange(name, filter.Values[index], option.selected, true);
        }
    }

    return (
        filter.Values.length > 10 ? (
            <ComboBox
                allowFreeform
                selectedKey={selectedKeys}
                placeholder={DictionaryAPI.getTranslation("SelectOrType")}
                multiSelect={true}
                autoComplete="on"
                options={options}
                onChange={onComboBoxChange}
            />
        ) : (
                <Stack tokens={{ childrenGap: 10 }}>
                    {filter.Values.map((v, i) => {
                        return (
                            <StackItem key={i}>
                                <Checkbox
                                    label={filter.ValueNameTranslationRequired ? DictionaryAPI.getTranslation(v.Name) : v.Name}
                                    checked={v.IsChecked}
                                    onChange={(ev, checked) => onChange(name, filter.Values[i], checked, true)} />
                            </StackItem>
                        )
                    })}
                </Stack>
            )
    );
}