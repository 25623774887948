import React, { useEffect, useRef } from 'react';
import {
    Stack, StackItem,
    TextField,
    IComboBoxOption,
    ScrollablePane,
    Label,
    Checkbox,
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IOrderInputPolicyListItem, IPaymentCondition, IRelationListItem, ITerritory } from 'interfaces/models';
import { CustomerViewModelFields } from 'enums/fields';
import { SpinButton, ComboBox, ColumnsStack, DatePicker } from 'components/common';
import { ICustomerViewModel } from 'interfaces/viewmodels';
import { ProductCategoryList } from './ProductCategoryList';
import { ISalesTabProps } from './interfaces';
import { useDates, useNumberFormat } from 'hooks';

export const SalesTab = (props: ISalesTabProps) => {

    const { form, cultureId } = props;
    const {
        item: relation,
        updateItem,
        onTextFieldChange,
        onSpinButtonChange,
        onCheckboxChange,
        onComboBoxSimpleChange,
        getErrorMessage
    } = form;

    const {
        DISCOUNT1,
        ORDER_INPUT_POLICY_ID,
        PURCHASE_ASSOCIATION_CONTACT_ID,
        PURCHASE_ASSOCIATION_DESCRIPTION,
        SHIPPING_REMARKS,
        PAYMENT_CONDITION_ID,
        PAYMENT_CONDITION_NAME,
        PAYMENT_INSTALLMENTS,
        PAYMENT_TERM_IN_DAYS,
        NO_SHIPPING_COSTS,
        AGENT_RELATION_ID,
        TERRITORY_ID
    } = CustomerViewModelFields;

    const onPaymentConditionComboBoxSimpleChange = (name?: string, option?: IComboBoxOption, index?: number, value?: string) => {

        console.log("option, value", option, value);

        updateItem(PAYMENT_CONDITION_NAME, (option?.text || value) || "");
        onComboBoxSimpleChange(name, option, index, value);
    }

    return relation ? (
        <ScrollablePane>
            <Stack gap={10} tokens={{ padding: 10, maxWidth: 800 }}>
                <ColumnsStack gap={10}>
                    <Stack>
                        <StackItem>
                            <ComboBox
                                allowFreeform
                                autoComplete="on"
                                label={DictionaryAPI.getTranslation("Territory")}
                                errorMessage={getErrorMessage(TERRITORY_ID)}
                                selectedKey={relation.TerritoryId}
                                name={TERRITORY_ID}
                                options={relation.Territories.map((v: ITerritory, i): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                        <StackItem>
                            <ComboBox
                                label={DictionaryAPI.getTranslation("Agent")}
                                allowFreeform
                                autoComplete="on"
                                selectedKey={relation.AgentRelationId}
                                name={AGENT_RELATION_ID}
                                options={relation.Agents.map((v: IRelationListItem, i): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                        <StackItem>
                            <ComboBox
                                allowFreeform
                                autoComplete="on"
                                selectedKey={relation.PurchaseAssociationContactId}
                                label={DictionaryAPI.getTranslation("PurchaseAssocation")}
                                name={PURCHASE_ASSOCIATION_CONTACT_ID}
                                disabled={relation.PurchaseAssociations?.length === 0}
                                options={relation.PurchaseAssociations.map((v: IOrderInputPolicyListItem, i: number): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                        <StackItem grow>
                            <TextField
                                value={relation.PurchaseAssociationDescription || undefined}
                                name={PURCHASE_ASSOCIATION_DESCRIPTION}
                                label={DictionaryAPI.getTranslation(PURCHASE_ASSOCIATION_DESCRIPTION)}
                                disabled={!relation.PurchaseAssociationContactId}
                                onChange={onTextFieldChange} />
                        </StackItem>
                        {/* <StackItem>
                            <ComboBox
                                label={DictionaryAPI.getTranslation("Catalog")}
                                allowFreeform
                                autoComplete="on"
                                selectedKey={relation.CatalogId}
                                name={CATALOG_ID}
                                options={relation.Catalogs.map((v: ICatalog, i): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                        <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                            <StackItem grow>
                                <DatePicker
                                    date={relation.CatalogActiveFrom}
                                    name={CATALOG_ACTIVE_FROM}
                                    placeholder="ActiveFrom"
                                    minDate={getDateByUtc(selectedCatalogRef.current?.ActiveFrom)}
                                    maxDate={getDateByUtc(selectedCatalogRef.current?.ActiveTo)}
                                    disabled={!relation.CatalogId}
                                    onSelectDate={onDatePickerSelectDate} />
                            </StackItem>
                            <StackItem grow>
                                <DatePicker
                                    date={relation.CatalogActiveTo}
                                    name={CATALOG_ACTIVE_TO}
                                    placeholder="ActiveTo"
                                    minDate={getDateByUtc(selectedCatalogRef.current?.ActiveFrom)}
                                    maxDate={getDateByUtc(selectedCatalogRef.current?.ActiveTo)}
                                    disabled={!relation.CatalogId}
                                    onSelectDate={onDatePickerSelectDate} />
                            </StackItem>
                        </Stack> */}
                    </Stack>
                    <Stack horizontalAlign="stretch">
                        <StackItem>
                            <ComboBox
                                allowFreeform
                                autoComplete="on"
                                selectedKey={relation.OrderInputPolicyId}
                                label={DictionaryAPI.getTranslation("OrderInputPolicy")}
                                name={ORDER_INPUT_POLICY_ID}
                                options={relation.OrderInputPolicies.map((v: IOrderInputPolicyListItem, i: number): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: DictionaryAPI.getTranslation(`OrderInputPolicyName_${v.Id}`),
                                    }
                                })}
                                onSimpleChange={onComboBoxSimpleChange} />
                        </StackItem>
                        <StackItem>
                            <ComboBox
                                allowFreeform
                                autoComplete="on"
                                text={relation.PaymentConditionName}
                                selectedKey={relation.PaymentConditionId}
                                label={DictionaryAPI.getTranslation("PaymentCondition")}
                                name={PAYMENT_CONDITION_ID}
                                options={relation.PaymentConditions.map((v: IPaymentCondition, i: number): IComboBoxOption => {
                                    return {
                                        key: v.Id,
                                        text: v.Name,
                                    }
                                })}
                                onSimpleChange={onPaymentConditionComboBoxSimpleChange} />
                        </StackItem>
                        <ColumnsStack gap={10}>
                            <StackItem>
                                <Label>{DictionaryAPI.getTranslation("PaymentInstallments")}</Label>
                                <SpinButton
                                    value={relation.PaymentInstallments}
                                    name={PAYMENT_INSTALLMENTS}
                                    step={1}
                                    min={1}
                                    max={3}
                                    locale={cultureId}
                                    onValueChange={onSpinButtonChange} />
                            </StackItem>
                            <StackItem>
                                <Label>{DictionaryAPI.getTranslation("PaymentTerm")}</Label>
                                <SpinButton
                                    value={relation.PaymentTermInDays}
                                    name={PAYMENT_TERM_IN_DAYS}
                                    step={30}
                                    min={30}
                                    max={90}
                                    suffix={DictionaryAPI.getTranslation("Days").toLowerCase()}
                                    locale={cultureId}
                                    onValueChange={onSpinButtonChange} />
                            </StackItem>
                        </ColumnsStack>
                        <ColumnsStack gap={10} verticalAlign="end">
                            <StackItem>
                                <Label>{DictionaryAPI.getTranslation("Discount")}</Label>
                                <SpinButton
                                    isPercentage
                                    value={relation.Discount1}
                                    name={DISCOUNT1}
                                    step={0.01}
                                    min={0}
                                    max={1}
                                    minimumFractionDigits={4}
                                    locale={cultureId}
                                    onValueChange={onSpinButtonChange} />
                            </StackItem>
                            <StackItem>
                                <Checkbox
                                    checked={relation.NoShippingCosts}
                                    name={NO_SHIPPING_COSTS}
                                    label={DictionaryAPI.getTranslation(NO_SHIPPING_COSTS)}
                                    onChange={onCheckboxChange} />
                            </StackItem>
                        </ColumnsStack>
                        <StackItem>
                            <TextField
                                multiline
                                spellCheck={false}
                                value={relation.ShippingRemarks}
                                name={SHIPPING_REMARKS}
                                label={DictionaryAPI.getTranslation(SHIPPING_REMARKS)}
                                style={{ height: 100 }}
                                onChange={onTextFieldChange} />
                        </StackItem>
                    </Stack>
                </ColumnsStack>
            </Stack>

            {/*             <div className="ms-Grid" dir="ltr" style={{ margin: 10, maxWidth: 800 }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg6">
                        <Stack horizontalAlign="stretch" verticalFill>
                            <StackItem>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    label={DictionaryAPI.getTranslation("Territory")}
                                    errorMessage={getErrorMessage(TERRITORY_ID)}
                                    selectedKey={relation.TerritoryId}
                                    name={TERRITORY_ID}
                                    options={relation.Territories.map((v: ITerritory, i): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem>
                                <ComboBox
                                    label={DictionaryAPI.getTranslation("Agent")}
                                    allowFreeform
                                    autoComplete="on"
                                    selectedKey={relation.AgentRelationId}
                                    name={AGENT_RELATION_ID}
                                    options={relation.Agents.map((v: IRelationListItem, i): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>

                            <StackItem>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    selectedKey={relation.PurchaseAssociationContactId}
                                    label={DictionaryAPI.getTranslation("PurchaseAssocation")}
                                    name={PURCHASE_ASSOCIATION_CONTACT_ID}
                                    disabled={relation.PurchaseAssociations?.length === 0}
                                    options={relation.PurchaseAssociations.map((v: IOrderInputPolicyListItem, i: number): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem grow>
                                <TextField
                                    value={relation.PurchaseAssociationDescription || undefined}
                                    name={PURCHASE_ASSOCIATION_DESCRIPTION}
                                    label={DictionaryAPI.getTranslation(PURCHASE_ASSOCIATION_DESCRIPTION)}
                                    disabled={!relation.PurchaseAssociationContactId}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <StackItem>
                                <TextField
                                    multiline
                                    spellCheck={false}
                                    value={relation.ShippingRemarks}
                                    name={SHIPPING_REMARKS}
                                    label={DictionaryAPI.getTranslation(SHIPPING_REMARKS)}
                                    style={{ height: 100 }}
                                    onChange={onTextFieldChange} />
                            </StackItem>
                            <Stack tokens={{ childrenGap: 5 }}>
                                <StackItem>
                                    <ComboBox
                                        label={DictionaryAPI.getTranslation("Catalog")}
                                        allowFreeform
                                        autoComplete="on"
                                        selectedKey={relation.CatalogId}
                                        name={CATALOG_ID}
                                        options={relation.Catalogs.map((v: ICatalog, i): IComboBoxOption => {
                                            return {
                                                key: v.Id,
                                                text: v.Name,
                                            }
                                        })}
                                        onSimpleChange={onComboBoxSimpleChange} />
                                </StackItem>
                                <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                    <StackItem grow>
                                        <DatePicker
                                            date={relation.CatalogActiveFrom}
                                            name={CATALOG_ACTIVE_FROM}
                                            placeholder="ActiveFrom"
                                            minDate={getDateByUtc(selectedCatalogRef.current?.ActiveFrom)}
                                            maxDate={getDateByUtc(selectedCatalogRef.current?.ActiveTo)}
                                            disabled={!relation.CatalogId}
                                            onSelectDate={onDatePickerSelectDate} />
                                    </StackItem>
                                    <StackItem grow>
                                        <DatePicker
                                            date={relation.CatalogActiveTo}
                                            name={CATALOG_ACTIVE_TO}
                                            placeholder="ActiveTo"
                                            minDate={getDateByUtc(selectedCatalogRef.current?.ActiveFrom)}
                                            maxDate={getDateByUtc(selectedCatalogRef.current?.ActiveTo)}
                                            disabled={!relation.CatalogId}
                                            onSelectDate={onDatePickerSelectDate} />
                                    </StackItem>
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>
                    <div className="ms-Grid-col ms-lg6">
                        <Stack horizontalAlign="stretch">

                            <StackItem>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    selectedKey={relation.OrderInputPolicyId}
                                    label={DictionaryAPI.getTranslation("OrderInputPolicy")}
                                    name={ORDER_INPUT_POLICY_ID}
                                    options={relation.OrderInputPolicies.map((v: IOrderInputPolicyListItem, i: number): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: DictionaryAPI.getTranslation(`OrderInputPolicyName_${v.Id}`),
                                        }
                                    })}
                                    onSimpleChange={onComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem>
                                <ComboBox
                                    allowFreeform
                                    autoComplete="on"
                                    text={relation.PaymentConditionName}
                                    selectedKey={relation.PaymentConditionId}
                                    label={DictionaryAPI.getTranslation("PaymentCondition")}
                                    name={PAYMENT_CONDITION_ID}
                                    options={relation.PaymentConditions.map((v: IPaymentCondition, i: number): IComboBoxOption => {
                                        return {
                                            key: v.Id,
                                            text: v.Name,
                                        }
                                    })}
                                    onSimpleChange={onPaymentConditionComboBoxSimpleChange} />
                            </StackItem>
                            <StackItem>
                                <Stack horizontal tokens={{ childrenGap: 5 }}>
                                    <StackItem grow shrink>
                                        <Label>{DictionaryAPI.getTranslation("PaymentInstallments")}</Label>
                                        <SpinButton
                                            value={String(relation.PaymentInstallments)}
                                            name={PAYMENT_INSTALLMENTS}
                                            step={1}
                                            min={1}
                                            max={3}
                                            onValueChange={onSpinButtonChange} />
                                    </StackItem>
                                    <StackItem grow shrink>
                                        <Label>{DictionaryAPI.getTranslation("PaymentTerm")}</Label>
                                        <SpinButton
                                            value={String(relation.PaymentTermInDays)}
                                            name={PAYMENT_TERM_IN_DAYS}
                                            step={30}
                                            min={30}
                                            max={90}
                                            suffix={DictionaryAPI.getTranslation("Days").toLowerCase()}
                                            onValueChange={onSpinButtonChange} />
                                    </StackItem>
                                </Stack>
                            </StackItem>

                            <Stack tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal>
                                <StackItem grow shrink>
                                    <Label>{DictionaryAPI.getTranslation("BasicDiscount")}</Label>
                                    <SpinButton
                                        isPercentage
                                        value={String(relation.Discount1)}
                                        name={DISCOUNT1}
                                        step={0.01}
                                        min={0}
                                        max={1}
                                        onValueChange={onSpinButtonChange} />
                                </StackItem>
                                <StackItem grow shrink>
                                    <Label>{DictionaryAPI.getTranslation("ExtraDiscount")}</Label>
                                    <SpinButton
                                        isPercentage
                                        value={String(relation.Discount2)}
                                        name={DISCOUNT2}
                                        step={0.01}
                                        min={0}
                                        max={1}
                                        onValueChange={onSpinButtonChange} />
                                </StackItem>

                            </Stack>
                            <Stack horizontal style={{ marginTop: 10 }}>
                                <StackItem grow>
                                    <Checkbox
                                        checked={relation.NoShippingCosts}
                                        name={NO_SHIPPING_COSTS}
                                        label={DictionaryAPI.getTranslation(NO_SHIPPING_COSTS)}
                                        onChange={onCheckboxChange} />
                                </StackItem>
                            </Stack>
                        </Stack>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    
                </div>
            </div> */}
        </ScrollablePane>
    ) : null;
}