import React, { useState, useEffect, useMemo } from 'react';
import { IFilterBarItemProps } from 'components/common';
import { IRelationsListViewLayoutProps } from './interfaces';
import { DefaultCommandTypes, RelationTypes, FormMenus } from 'enums';
import { RelationListItemFields } from 'enums/fields';
import { RelationAPI, DictionaryAPI, MenuAPI } from 'api';
import { IFilter, IRangeFilter, IRelationFilterContext, IRelationListItem } from 'interfaces/models';
import { DayOfWeek, ICommandBarItemProps, ComboBox, IColumn } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter, DateRangeFilter } from 'components/common';
import { useDates, useFilterContextHandler, useSelection, useUserSetting } from 'hooks';
import { IDefaultCommand } from 'interfaces';
import { IRelationViewModel } from 'interfaces/viewmodels';
import { Customer, CreateCustomer, CreateRelation, Relation, CreateContactPerson } from 'components/pages';
import { ListView } from 'components/common';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useHistory } from 'react-router';

export const RelationsListViewLayout = (props: IRelationsListViewLayoutProps) => {

    const {
        menu,
        companyRelationId,
        parentContactId,
        relationTypes,
        detailsListColumnFieldNamesToHide,
        isSubRelations } = props;

    const account = useSelector((state: RootState) => state.authenticationState.account);
    const { CultureId } = account;

    const [rowGuid, setRowGuid] = useState<string>(props.rowGuid);
    const [relationTypeId, setRelationTypeId] = useState<number>(props.relationTypeId);
    const [relationTypeIdToCreate, setRelationTypeIdToCreate] = useState<number>();

    const userSettingHook = useUserSetting(menu.Id);
    const { userSettingsLoaded } = userSettingHook;
    const { dateByUtcToLocaleDateString } = useDates(CultureId);
    const { push } = useHistory();

    const filterContextHandler = useFilterContextHandler<IRelationFilterContext>(
        (onSuccess, isActive) => {
            RelationAPI.getFilterContext(isActive, companyRelationId, parentContactId, relationTypeId || 0, onSuccess);
        },
        RelationAPI.filter,
        userSettingHook,
        true);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onRangeFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterContext,
        getFilterResultInfo } = filterContextHandler;

    const {
        ADDRESS1,
        CITY,
        CODE,
        COUNTRY_ID,
        LANGUAGE_ID,
        EMAIL,
        NAME,
        POSTAL_CODE,
        PHONE,
        MOBILE,
        PRESENTATION_IMAGE,
        CREATED_ON,
        ROW_GUID } = RelationListItemFields;

    const { selection, getFirstSelectedItemIndex } = useSelection<string>(
        ROW_GUID,
        filterContextHandler.filterResultInfo?.Items,
        [],
        filterContextHandler.isFiltering);

    const commandBarButtonAsRelationTypeComboBox: React.FunctionComponent<ICommandBarItemProps> = props => {

        return (
            <ComboBox
                style={{ marginLeft: 10 }}
                selectedKey={relationTypeId}
                onChange={(ev, option) => setRelationTypeId(Number(option?.key))}
                options={relationTypes.map((v) => {
                    return {
                        key: v.Id,
                        text: DictionaryAPI.getTranslation(v.Name),
                    }
                })}
            />
        );
    };

    const detailsListColumns: IDetailsListColumn[] = useMemo<IDetailsListColumn[]>(() => {
        return [
            { fieldName: NAME, width: 200, isRowHeader: true, linkProps: !isSubRelations && { baseUrl: "#" + menu.Path, urlParameterFieldName: ROW_GUID } },
            { fieldName: CODE, width: 70 },
            { fieldName: ADDRESS1, width: 150, name: "Address" },
            { fieldName: CITY, width: 125 },
            { fieldName: POSTAL_CODE, width: 80 },
            { fieldName: COUNTRY_ID, width: 60, name: "Country" },
            { fieldName: LANGUAGE_ID, width: 60, name: "Language" },
            { fieldName: PHONE, width: 125 },
            { fieldName: MOBILE, width: 125 },
            { fieldName: EMAIL, width: 200 },
            { fieldName: CREATED_ON, width: 70, name: "Created" },
        ].filter(c => !detailsListColumnFieldNamesToHide?.includes(c.fieldName));
    }, [filterContext?.FilterInfo?.OrderByRules])

    const onRenderDetailsListItemColumn = (item?: IRelationListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName) {
            switch (column.fieldName) {
                case CREATED_ON:
                    return <span>{dateByUtcToLocaleDateString(item[column.fieldName])}</span>
                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (
                <FilterBar
                    filterContextHandler={filterContextHandler}
                    onRenderFilterBarItem={onRenderFilterBarItem}
                />)
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {
        const { filter, name } = filterBarItemProps;

        switch (name) {
            case CREATED_ON:
                return (
                    <DateRangeFilter
                        cultureId={CultureId}
                        firstDayOfWeek={DayOfWeek.Monday}
                        filter={filter as IRangeFilter}
                        name={name}
                        onChange={onRangeFilterChange} />
                )

            default:
                return (
                    <CheckboxFilter
                        filter={filter as IFilter}
                        name={name}
                        onChange={onFilterChange} />
                )
        }
    }

    const getDefaultCommands = (): IDefaultCommand[] => {
        return [
            { type: DefaultCommandTypes.New, isDisabled: !relationTypeId },
        ]
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.New:
                setRelationTypeIdToCreate(relationTypeId);
                break;
            case DefaultCommandTypes.Print:
                break;
            default:
                break;
        }
    }

    const getCreateRelationComponent = (relationTypeId: number, onSuccess: (rowGuid?: string) => void, onCancel: () => void): JSX.Element => {
        switch (relationTypeId) {
            case RelationTypes.Customer:
            case RelationTypes.Prospect:
                return (
                    <CreateCustomer
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        isProspect={relationTypeId === RelationTypes.Prospect}
                        onSuccess={onSuccess}
                        onCancel={onCancel} />
                )
            case RelationTypes.Contact:
                return (
                    <CreateContactPerson
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        onSuccess={onSuccess}
                        onCancel={onCancel} />
                )
            default:
                return (
                    <CreateRelation
                        isPerson={relationTypeId === RelationTypes.Contact}
                        relationTypeId={relationTypeId}
                        companyRelationId={companyRelationId}
                        parentContactId={parentContactId}
                        onSuccess={onSuccess}
                        onCancel={onCancel} />
                )
        }
    }

    const getRelationComponent = (rowGuid: string, relationTypeId: number, onDismiss: (changesUpdated?: boolean) => void): JSX.Element => {

        switch (relationTypeId) {
            case RelationTypes.Customer:
            case RelationTypes.Prospect:
                return (
                    <Customer rowGuid={rowGuid} onDismiss={onDismiss} />
                )
            case RelationTypes.DeliveryAddress:
            case RelationTypes.Branch:
            case RelationTypes.PointOfSales:
                return (
                    <Relation formMenu={MenuAPI.find(FormMenus.Address)} rowGuid={rowGuid} onDismiss={onDismiss} />
                )
            case RelationTypes.Contact:
                return (
                    <Relation formMenu={MenuAPI.find(FormMenus.Contact)} rowGuid={rowGuid} onDismiss={onDismiss} />
                )
        }
    }

    const onCreateRelationSuccess = (rowGuid: string) => {
        if (rowGuid) {
            getFilterResultInfo();

            if (!isSubRelations) {
                push(`${menu.Path}/${rowGuid}`);
            }
        }
    }

    const onRelationDismiss = (changesUpdated?: boolean) => {
        if (changesUpdated) {
            getFilterResultInfo(false);
        } else {
            dismissRelation();
        }
    }

    const dismissRelation = () => {
        if (!isSubRelations) {
            window.location.href = "#" + menu.Path;
        } else {
            setRowGuid(undefined);
        }
    }

    useEffect(() => {
        setRowGuid(props.rowGuid);
    }, [props.rowGuid])

    useEffect(() => {
        if (userSettingsLoaded) {
            getFilterContext();
        }
    }, [relationTypeId, userSettingsLoaded])

    useEffect(() => {
        setRelationTypeIdToCreate(undefined);
        dismissRelation();

    }, [filterResultInfo?.Items])

    return (
        <>
            <ListView
                menu={menu}
                title={!isSubRelations ? menu.translatedName : undefined}
                isInsertCommandBarItemsItemsBeforeDefaultCommands
                commandBarItems={isSubRelations && [
                    {
                        key: "RelationTypes",
                        commandBarButtonAs: commandBarButtonAsRelationTypeComboBox
                    },
                ]}
                detailsListColumns={detailsListColumns}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                onDefaultCommandClick={onDefaultCommandClick}
                onRefreshButtonClick={getFilterResultInfo}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                onItemClick={isSubRelations ? (item: IRelationViewModel) => setRowGuid(item.RowGuid) : undefined}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                dataLoading={isFiltering}
                onOrderByChange={onOrderByChange}
                tileListCellProps={{
                    imageItemFieldName: PRESENTATION_IMAGE,
                    labelItemFieldName: NAME
                }}
                selection={selection}
                getInitialFocusedIndex={getFirstSelectedItemIndex}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                relationTypeIdToCreate &&
                    getCreateRelationComponent(
                        relationTypeIdToCreate,
                        onCreateRelationSuccess,
                    () => setRelationTypeIdToCreate(undefined))
            }
            {
                (rowGuid && rowGuid !== "") &&
                getRelationComponent(rowGuid, relationTypeId, onRelationDismiss)
            }
        </>
    );
};