import { IApplication } from 'interfaces/models'
import { ApplicationTypes } from './types'

export const SET_APPLICATION = "SET_APPLICATION";

export function setApplication(application?: IApplication): ApplicationTypes {
    return {
        type: SET_APPLICATION,
        application: application
    }
}