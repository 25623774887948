import React from 'react';
import { IconButton, DirectionalHint, IContextualMenuProps, IContextualMenuItem, CommandBarButton, DefaultButton, DefaultPalette } from 'office-ui-fabric-react';
import { IMoreOptionsButtonProps } from './interfaces';
import { DictionaryAPI } from 'api';

export const MoreOptionsButton = (props: IMoreOptionsButtonProps) => {

    const { addAllowed, editAllowed, menuItems, onMenuItemClick } = props;

    const getOptionMenuItems = (): IContextualMenuItem[] => {
        const items: IContextualMenuItem[] = [];

        if (addAllowed) {
            const ADD = "Add";
            items.push({
                key: ADD,
                text: DictionaryAPI.getTranslation(ADD),
                iconProps: { iconName: "Add" },
                onClick: () => onMenuItemClick(ADD)
            })
        }

        if (editAllowed) {
            const EDIT = "Edit"
            items.push({
                key: EDIT,
                text: DictionaryAPI.getTranslation(EDIT),
                iconProps: { iconName: "Edit" },
                onClick: () => onMenuItemClick(EDIT)
            })
        }

        return menuItems ? [...items, ...menuItems] : items;
    }
    const optionMenuItems = getOptionMenuItems();

    if (optionMenuItems.length > 0) {

        const menuProps: IContextualMenuProps = optionMenuItems.length > 1 && {
            directionalHintFixed: true,
            directionalHint: DirectionalHint.bottomRightEdge,
            items: optionMenuItems
        }

        return (
            <DefaultButton
                styles={{ 
                    root: { 
                        maxWidth: "100%", 
                        minWidth: 0, 
                        padding: "0 4px", 
                        borderLeft: 0,
                        color: DefaultPalette.neutralSecondaryAlt
                    } 
                }}
                role="menuItem"
                key={menuProps ? "More" : optionMenuItems[0].key}
                title={!menuProps ? optionMenuItems[0].text : undefined}
                onClick={() => onMenuItemClick(optionMenuItems[0].key)}
                menuIconProps={{
                    style: {fontSize: 16},
                    iconName: menuProps ? "More" : optionMenuItems[0].iconProps?.iconName
                }}
                menuProps={menuProps}
            />
        );
    }
};