import React from 'react';
import { FontWeights, FontSizes, getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { ITitleBarProps } from '.';

export const TitleBar = (props: ITitleBarProps): JSX.Element => {
    const { title, subTitle, iconName, accentColour } = props
    const theme = getTheme();
    return (
        <Stack horizontalAlign="stretch" horizontal>
            {
                accentColour !== undefined && (
                    <StackItem>
                        <div style={{ borderLeft: "8px solid " + accentColour, height: "100%" }}></div>
                    </StackItem>
                )
            }
            <StackItem align="center">
                <Icon iconName={iconName} styles={{ root: { fontSize: FontSizes.xLarge } }} />
            </StackItem>
            <StackItem align="center">
                <Label styles={{
                    root: {
                        paddingLeft: 8,
                        fontSize: FontSizes.large,
                        fontWeight: FontWeights.semibold
                    }
                }}>
                    {title}
                </Label>
            </StackItem>
            <StackItem align="center">
                <Label styles={{
                    root: {
                        paddingLeft: 8,
                        color: theme.semanticColors.bodySubtext,
                        fontSize: FontSizes.mediumPlus,
                        fontWeight: FontWeights.regular
                    }
                }}>
                    {subTitle}
                </Label>
            </StackItem>
        </Stack>);
}