
export enum DocumentDetailFilterContextFields {
    COMPANY_RELATION_ID = "CompanyRelationId",
    DOCUMENTDETAIL_TYPE_ID = "DocumentDetailTypeId",
    DOCUMENT_ID = "DocumentId",
    DOCUMENT_STATUS_ID = "DocumentStatusId",
    DOCUMENT_TYPE_ID = "DocumentTypeId",
    FROM_RELATION_ID = "FromRelationId",
    IS_B2B = "IsB2b",
    PRODUCT_ID = "ProductId",
    PRODUCT_SIZE_ID = "ProductSizeId",
    QUANTITY = "Quantity",
    RELATION_ID = "RelationId",
    SUB_DOCUMENT_TYPE_ID = "SubDocumentTypeId"
}