import { DictionaryAPI, DocumentAPI, MenuAPI } from 'api'
import { ComboBox, Dialog } from 'components/common'
import { DocumentTypes, OrdersInteractionMenus } from 'enums'
import { RecalculateDocumentsViewModelFields } from 'enums/fields'
import { useForm } from 'hooks'
import { IRecalculateDocumentsViewModel } from 'interfaces/viewmodels'
import { MessageBar, MessageBarType, ProgressIndicator, Stack, StackItem } from 'office-ui-fabric-react'
import React, { useEffect, useState } from 'react'
import { IRefreshDocumentsDialogProps } from './interfaces'

export const RecalculateDocumentsDialog = (props: IRefreshDocumentsDialogProps) => {

    const { documentTypeId, recalculateDocumentsInteractionMenuId, onDismiss } = props;
    const [errorMessage, setErrorMessage] = useState("");

    const {
        item,
        initialize,
        onComboBoxSimpleChange,
        submit,
        isSubmitting,
        getErrorMessage } = useForm<IRecalculateDocumentsViewModel>(undefined, undefined, DocumentAPI.recalculateDocuments);

    const onAccept = () => {
        submit(() => onDismiss(true), () => {
            setErrorMessage(DictionaryAPI.getTranslation("ErrorWhileRecalculating"))
        },
        true);
    }

    useEffect(() => {
        DocumentAPI.getRecalculateDocuments(documentTypeId, (data) => initialize(true, data));
    }, [documentTypeId])

    return (
        <Dialog
            title={MenuAPI.find(recalculateDocumentsInteractionMenuId).translatedName}
            subText={DictionaryAPI.getTranslation("RecalculateDialogSubText")}
            maxWidth={350}
            AcceptButtonText={DictionaryAPI.getTranslation("Recalculate")}
            acceptButtonDisabled={isSubmitting}
            cancelButtonDisabled={isSubmitting}
            isLoading={!item}
            onAccept={onAccept}
            onCancel={!isSubmitting ? onDismiss : undefined}
        >
            <Stack>
                {
                    errorMessage && (
                        <StackItem>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={() => setErrorMessage("")}
                                dismissButtonAriaLabel="Close"
                            >
                                {errorMessage}
                            </MessageBar>
                        </StackItem>
                    )
                }
                <StackItem>
                    <ComboBox
                        allowFreeform
                        required
                        label={DictionaryAPI.getTranslation("Collection")}
                        autoComplete="on"
                        selectedKey={item?.CollectionId}
                        name={RecalculateDocumentsViewModelFields.COLLECTION_ID}
                        disabled={isSubmitting}
                        options={item?.Collections.map((v) => {
                            return {
                                key: v.Id,
                                text: v.Name,
                            }
                        })}
                        onSimpleChange={onComboBoxSimpleChange}
                        errorMessage={getErrorMessage(RecalculateDocumentsViewModelFields.COLLECTION_ID)} />
                </StackItem>
                {
                    isSubmitting && (
                        <StackItem>
                            <ProgressIndicator />
                        </StackItem>
                    )
                }
            </Stack>
        </Dialog>
    )
}
