export enum DocumentDetailListItemFields {
    CREATED_ON = "CreatedOn",
    DESCRIPTION = "Description",
    DISCOUNT1 = "Discount1",
    DISCOUNT2 = "Discount2",
    DISCOUNT_TOTAL = "DiscountTotal",
    DOCUMENTDETAIL_STATUS_ID = "DocumentDetailStatusId",
    DOCUMENTDETAIL_STATUS_NAME = "DocumentDetailStatusName",
    DOCUMENTDETAIL_TYPE_ID = "DocumentDetailTypeId",
    DOCUMENTDETAIL_TYPE_NAME = "DocumentDetailTypeName",
    DOCUMENT_ID = "DocumentId",
    COLLI_PACKAGE_NUMBER = "ColliPackageNumber",
    GROUP_KEY = "GroupKey",
    ID = "Id",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCT_ID = "ProductId",
    PRODUCT_IMAGE = "ProductImage",
    PRODUCT_SIZE_CODE = "ProductSizeCode",
    PRODUCT_SIZE_ID = "ProductSizeId",
    PRODUCT_SIZE_NAME = "ProductSizeName",
    PRODUCT_SIZE_RANGE_ID = "ProductSizeRangeId",
    QUANTITY = "Quantity",
    REMARKS = "Remarks",
    ROW_GUID = "RowGuid",
    SRP = "Srp",
    TOTAL_PRICE = "TotalPrice",
    TOTAL_PRICE_EXCL_DISCOUNT = "TotalPriceExclDiscount",
    TOTAL_PRICE_INCL_VAT = "TotalPriceInclVat",
    UNIT_PRICE = "UnitPrice",
    UNIT_PRICE_INCL_VAT = "UnitPriceInclVat",
    UOM_CODE = "UomCode",
    VAT = "Vat",
    VAT_TOTAL = "VatTotal"
}