import React from 'react';

import { ICreateContactPersonViewModel } from 'interfaces/viewmodels';
import { RelationAPI, DictionaryAPI } from 'api';
import { ICreateContactPersonProps } from '.';
import { IFormProps } from 'hooks';
import { StackItem, TextField } from 'office-ui-fabric-react';
import { CreateContactPersonViewModelFields } from 'enums/fields';
import { CreateRelationLayout } from 'components/layouts';

export const CreateContactPerson = (props: ICreateContactPersonProps): JSX.Element => {

    const { companyRelationId, parentContactId, onSuccess, onCancel } = props;

    const { EMAIL, PHONE } = CreateContactPersonViewModelFields;

    const getContent = (form: IFormProps<ICreateContactPersonViewModel>): JSX.Element => {

        const { item, onTextFieldChange } = form;

        return (
            <>
                <StackItem grow>
                    <TextField
                        value={item.Email}
                        name={EMAIL}
                        placeholder={DictionaryAPI.getTranslation(EMAIL)}
                        onChange={onTextFieldChange} />
                </StackItem>
                <StackItem grow>
                    <TextField
                        value={item.Phone}
                        name={PHONE}
                        placeholder={DictionaryAPI.getTranslation(PHONE)}
                        onChange={onTextFieldChange} />
                </StackItem>
            </>
        )
    }

    return (
        <CreateRelationLayout<ICreateContactPersonViewModel>
            getCreateRelation={(onSuccess) => RelationAPI.getCreateContactPerson(companyRelationId, parentContactId, onSuccess)}
            createRelation={RelationAPI.createContactPerson}
            getContent={getContent}
            onSuccess={onSuccess}
            onCancel={onCancel}
        />
    );
}