import React, { useState, useEffect } from 'react';
import { ProductAPI, DictionaryAPI, CatalogAPI, MenuAPI } from 'api';
import { IProductFilterContext } from 'interfaces/models';
import { WindowModal, Dialog } from 'components/common';
import { useFilterContextHandler, useChangeTracker, useSelection } from 'hooks';
import { SelectionMode } from 'office-ui-fabric-react';
import { ProductListItemFields } from 'enums/fields';
import { CatalogFormPageTabFormMenus, ParentProductCategories } from 'enums';
import { ProductsListViewLayout } from 'components/layouts';
import { IAddProductsDialogProps } from './interfaces';
import { IAddProductsToCatalogViewModel } from 'interfaces/viewmodels';

export const AddProductsDialog = (props: IAddProductsDialogProps) => {

    const { catalogRowGuid, catalogPageId, onDismiss } = props;

    const [addProductsToCatalogViewModel, setAddProductsToCatalogViewModel] = useState<IAddProductsToCatalogViewModel>();
    
    const { ProductCategoryId, CollectionId, } = addProductsToCatalogViewModel || {};

    const [fetching, setFetching] = useState<boolean>();
    const [notifyClose, setNotifyClose] = useState<boolean>(false);

    const filterContextHandler = useFilterContextHandler<IProductFilterContext>(
        (onSuccess) => {
            ProductAPI.getFilterContext(true, ProductCategoryId, false, 0, 0, [], 
                (filterContext) => {
                    if (CollectionId) {
                        const filterValue = filterContext.CollectionId.Values.find(v => v.Value === CollectionId);
                        filterValue.IsChecked = true;
                    }
                    
                    onSuccess(filterContext);
                })
        },
        (filterContext, onSuccess) => {
            ProductAPI.filter({
                ...filterContext,
                AssociatedProductsGrouped: false,
                /* Id: {
                    DisplayName: "",
                    DisplayOrder: 0,
                    TypeName: "",
                    IsNot: true,
                    ValueNameTranslationRequired: false,
                    Values: addProductsToCatalogViewModel.AddedProductIds.map((v) => {
                        return {
                            IsChecked: true,
                            Name: "",
                            Value: v
                        }
                    })
                }, */
            },
                onSuccess
            )
        },
        undefined,
        true
    );

    const {
        isFiltering,
        filterContext,
        filterResultInfo,
        getFilterContext,
        getFilterResultInfo,
    } = filterContextHandler;

    const { setSelectedKeys, selectedKeys, selection } = useSelection(
        ProductListItemFields.ID,
        filterResultInfo?.Items,
        [],
        isFiltering,
        SelectionMode.multiple);

    const addProductsToCatalog = () => {

        setFetching(true);
        CatalogAPI.addProductsToCatalog({
            ...addProductsToCatalogViewModel,
            AddedProductIds: [],
            ProductIds: selectedKeys.map((v) => Number(v))
        }, 
        () => onDismiss(true));
    }

    const selectAll = () => {
        setFetching(true);
        ProductAPI.getProductIds(filterContext,
            (data) => {
                setSelectedKeys([...data, ...selectedKeys.filter(value => !data.includes(value))])
            });
    }

    const clearSelection = () => {
        setFetching(true);
        ProductAPI.getProductIds(filterContext,
            (data) => {
                setSelectedKeys([...selectedKeys.filter(value => !data.includes(value))])
            });
    }

    useEffect(() => {
        const getAddProductsToCatalogViewModel = () => {
            CatalogAPI.getAddProductsToCatalog(catalogRowGuid, catalogPageId,
                (data) => setAddProductsToCatalogViewModel(data));
        }

        getAddProductsToCatalogViewModel();

    }, [catalogRowGuid, catalogPageId])

    useEffect(() => {
        if (addProductsToCatalogViewModel) {
            getFilterContext();
        }

    }, [addProductsToCatalogViewModel])

    useEffect(() => {
        if (fetching) {
            getFilterResultInfo(false);
            setFetching(false);
        }

    }, [selectedKeys])

    return (

        <WindowModal
            titleBarProps={{
                title: DictionaryAPI.getTranslation("AddProducts"),
                subTitle: DictionaryAPI.getTranslation(ParentProductCategories[ProductCategoryId]),
            }}
            width={900}
            height={700}
            isBlocking={true}
            isOpen={true}
            isLoading={!addProductsToCatalogViewModel}
            onDismiss={() => selectedKeys.length > 0 ? setNotifyClose(true) : onDismiss()}
        >
            <ProductsListViewLayout
                checkable
                hideTitle
                menu={MenuAPI.find(CatalogFormPageTabFormMenus.AddProducts)}
                commandBarItems={[
                    {
                        key: "Accept",
                        text: `${DictionaryAPI.getTranslation("Accept")} (${selectedKeys?.length})`,
                        iconProps: {
                            iconName: "Accept"
                        },
                        disabled: fetching || selectedKeys.length === 0,
                        onClick: () => addProductsToCatalog()
                    },
                    {
                        key: "SelectAll",
                        text: DictionaryAPI.getTranslation("SelectAll"),
                        iconProps: {
                            iconName: "MultiSelect"
                        },
                        disabled: fetching,
                        onClick: selectAll
                    },
                    {
                        key: "ClearSelection",
                        text: DictionaryAPI.getTranslation("ClearSelection"),
                        iconProps: {
                            iconName: "Cancel"
                        },
                        disabled: fetching,
                        onClick: clearSelection
                    },
                ]}
                associatedProductsGrouped={false}
                filterContextHandler={filterContextHandler}
                selection={selection}
                disableToggleActive={true}
            />
            {
                notifyClose &&
                    <Dialog
                        maxWidth={500}
                        title={DictionaryAPI.getTranslation("Close")}
                        subText={DictionaryAPI.getTranslation("Accept") + "?"}
                        AcceptButtonText={DictionaryAPI.getTranslation("Yes")}
                        CancelButtonText={DictionaryAPI.getTranslation("No")}
                        onAccept={() => addProductsToCatalog()}
                        onCancel={props.onDismiss} />
            }

        </WindowModal>
    );
};