import React, { useState, useEffect } from 'react';
import { IAutoCompleteComboBoxProps } from './interfaces';
import { ComboBox, IComboBoxOption, IComboBox, Autofill } from 'office-ui-fabric-react';

export const AutoCompleteComboBox = (props: IAutoCompleteComboBoxProps) => {

    const { label, defaultText, placeholder, options, required, disabled, errorMessage, styles, onInputValueChange, onRenderOption, onChange } = props;
    const [text, setText] = useState<string>();
    const [onChangeTimeOut, setOnChangeTimeOut] = useState<any>();

    const comboBoxRef = React.useRef<IComboBox>(null);

    const clearSearchTimeOut = () => {
        if (onChangeTimeOut !== undefined) {
            clearTimeout(onChangeTimeOut);
            setOnChangeTimeOut(undefined);
        }
    }

    const _onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        setText(option?.text);
        onChange && onChange(event, option, index, value);
    }

    const _onInputValueChange = (newValue?: string, composing?: boolean) => {

        setText(newValue);

        if (onInputValueChange) {
            clearSearchTimeOut();
            setOnChangeTimeOut(setTimeout(function () {
                onInputValueChange(newValue, composing);
            }, 750));
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | Autofill>) => {
        if (event.key === "Enter") {
            clearSearchTimeOut();
            onInputValueChange(text);
        }
    }

    useEffect(() => {
        setText(defaultText);
    }, [defaultText])

    useEffect(() => {
        if (options && options.length > 0) {
            comboBoxRef.current?.focus(true);
        }
    }, [options])

    return (
        <ComboBox
            allowFreeform
            required={required}
            styles={styles}
            componentRef={comboBoxRef}
            label={label}
            placeholder={placeholder}
            text={text}
            options={options}
            autofill={{
                autoFocus: true,
                onInputValueChange: _onInputValueChange,
                onKeyDown: onKeyDown
                
            }}
            disabled={disabled}
            errorMessage={errorMessage}
            onChange={_onChange}
            onKeyDown={(e) => {
                if ((e.charCode || e.keyCode) === 13) {
                    e.preventDefault();
                }
            }}
            onRenderOption={onRenderOption}
        />
    );
};