export enum RelationFilterContextFields {
    AGENT_RELATION_ID = "AgentRelationId",
    COUNTRY_ID = "CountryId",
    CREATED_ON = "CreatedOn",
    EMAIL = "Email",
    LANGUAGE_ID = "LanguageId",
    PARENT_CONTACT_ID = "ParentContactId",
    COMPANY_RELATION_ID = "CompanyRelationId",
    IS_PRIMARY = "IsPrimary",
    RELATION_TYPE_ID = "RelationTypeId",
    FILTER_INFO = "FilterInfo"
}