import { BaseAPI, FilterAPI } from 'api';
import { IProductFilterContext, IFilterResultInfo, IOrderByRule } from 'interfaces/models';
import { ProductListItemFields } from 'enums/fields';
import { IProductDetailViewModel } from 'interfaces/viewmodels/sls/IProductDetailViewModel';

export class ProductAPI {
    public static getFilterContext = (
        isActive: boolean,
        parentCategoryId: number,
        onlySalesAllowed: boolean,
        catalogId: number | undefined,
        brandId: number | undefined,
        warehouseContactIds: number[] | undefined,
        onSuccess: (filterContext: IProductFilterContext) => void) => {

        let warehouseContactIdsQueryString = "";

        if (warehouseContactIds?.length > 0) {
            warehouseContactIdsQueryString = `?warehouseContactIds=${encodeURIComponent(JSON.stringify(warehouseContactIds))}`;
        }

        FilterAPI.getFilterContext("prd/product",
            [
                String(isActive),
                String(parentCategoryId),
                "0",
                "_",
                String(onlySalesAllowed),
                String(catalogId || 0),
                String(brandId || 0) + warehouseContactIdsQueryString,
            ],
            (data) => onSuccess({
                ...data, FilterInfo: {
                    ...data.FilterInfo,
                    PageSize: 500,
                    OrderByRules: [
                        { FieldName: ProductListItemFields.COLLECTION_ID, IsDescending: true },
                        { FieldName: ProductListItemFields.SAMPLE_PRODUCT_SIZE_ID, IsDescending: false },
                        { FieldName: ProductListItemFields.SUB_PRODUCT_CATEGORY_DISPLAY_ORDER, IsDescending: false },
                        { FieldName: ProductListItemFields.NAME, IsDescending: false },
                    ]
                }
            }));
    }

    public static filter = (filterContext: IProductFilterContext, onSuccess: (filterResultInfo: IFilterResultInfo) => void) => {
        BaseAPI.post("api/prd/product/filter", ProductAPI.prepareFilterContextForPosting(filterContext), onSuccess);
    }

    public static printProductInventory = (filterContext: IProductFilterContext, onSuccess: (path: string) => void) => {
        BaseAPI.post("api/prd/product/printProductInventory", ProductAPI.prepareFilterContextForPosting(filterContext), onSuccess);
    }

    public static createProductsJson = (filterContext: IProductFilterContext, onSuccess: (url: string) => void) => {
        BaseAPI.post("api/prd/product/createProductsJson", ProductAPI.prepareFilterContextForPosting(filterContext), onSuccess);
    }

    public static getProductDetail = (productId: number, warehouseContactIds: number[] | undefined, onSuccess: (data: IProductDetailViewModel) => void) => {

        let url = `api/prd/product/GetProductDetail/${productId}`;

        if (warehouseContactIds?.length > 0) {
            var warehouseContactIdsQueryString = encodeURIComponent(JSON.stringify(warehouseContactIds));
            url = url + `?warehouseContactIds=${warehouseContactIdsQueryString}`;
        }

        BaseAPI.get(url, null, onSuccess);
    }

    public static getProductIds = (filterContext: IProductFilterContext, onSuccess: (data: number[]) => void) => {
        BaseAPI.post("api/prd/product/GetProductIds", ProductAPI.prepareFilterContextForPosting(filterContext), onSuccess);
    }

    private static prepareFilterContextForPosting = (filterContext: IProductFilterContext): IProductFilterContext => {
        return {
            ...filterContext,
            SalesAllowed_WarehouseContactId: undefined,
            CollectionId: { ...filterContext.CollectionId, Values: filterContext.CollectionId.Values.filter(v => v.IsChecked) },
            GenderId: { ...filterContext.GenderId, Values: filterContext.GenderId.Values.filter(v => v.IsChecked) },
            SampleProductSizeName: filterContext.ClosingId && { ...filterContext.SampleProductSizeName, Values: filterContext.SampleProductSizeName.Values.filter(v => v.IsChecked) },
            SubProductCategoryId: { ...filterContext.SubProductCategoryId, Values: filterContext.SubProductCategoryId.Values.filter(v => v.IsChecked) },
            StyleId: filterContext.StyleId && { ...filterContext.StyleId, Values: filterContext.StyleId.Values.filter(v => v.IsChecked) },
            ClosingId: filterContext.ClosingId && { ...filterContext.ClosingId, Values: filterContext.ClosingId.Values.filter(v => v.IsChecked) },
            ColourParentId: { ...filterContext.ColourParentId, Values: filterContext.ColourParentId.Values.filter(v => v.IsChecked) },
            ProductSizeCode: filterContext.ProductSizeCode && { ...filterContext.ProductSizeCode, Values: filterContext.ProductSizeCode.Values.filter(v => v.IsChecked) },
            WarehouseContactId: filterContext.WarehouseContactId && { ...filterContext.WarehouseContactId, Values: filterContext.WarehouseContactId.Values.filter(v => v.IsChecked) },
        }
    }

}