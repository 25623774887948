import { DictionaryAPI, DocumentAPI, MenuAPI } from 'api';
import { CheckboxFilter, ComboBox, Dialog, FilterBar, IDetailsListColumn, ListViewCommandBarTypes, UpdateDocumentDetailsStatusDialog } from 'components/common';
import { ListView } from 'components/common';
import { DefaultCommandTypes, DocumentDetailStatuses } from 'enums';
import { DocumentDetailListItemFields, ProductDocumentDetailsGroupFields } from 'enums/fields';
import { useDates, useFileStoreUrl, useFilterContextHandler, useNumberFormat, useSelection } from 'hooks';
import { IDocumentDetailFilterContext, IDocumentDetailListItem, IDocumentProductSizeRange, IFilter, IProductCategoryListItem, IProductDocumentDetailFilterContext, IProductDocumentDetailsGroup } from 'interfaces/models';
import { DefaultPalette, IColumn, IComboBoxOption, IContextualMenuItem, IImageStyleProps, IImageStyles, Image, ImageFit, mergeStyleSets, SelectableOptionMenuItemType, SelectionMode, Stack, StackItem } from 'office-ui-fabric-react';
import React, { useEffect, useRef, useState } from 'react'
import { imgError } from 'images';
import { DocumentFormDetailsTabInteractionMenus } from 'enums/app/DocumentFormDetailsTabInteractionMenus';
import { IUpdateDocumentDetailsViewModel } from 'interfaces/viewmodels';
import { ProductDetail } from 'components/pages';
import { IDetailsTabProps } from '../interfaces';
import { DetailDialog } from './DetailDialog';

export const DetailsTab = (props: IDetailsTabProps) => {

    const { documentRowGuid, document, tabMenu, readOnly, cultureId, onDetailsUpdated } = props;

    const [detailId, setDetailId] = useState<number>();
    const [detailIdsToUpdate, setDetailIdsToUpdate] = useState<number[]>();
    const [loading, setLoading] = useState<boolean>();

    const {
        PRODUCT_IMAGE,
        DESCRIPTION,
        COLLI_PACKAGE_NUMBER,
        UNIT_PRICE,
        QUANTITY,
        TOTAL_PRICE_EXCL_DISCOUNT,
        DISCOUNT1,
        DISCOUNT2,
        DISCOUNT_TOTAL,
        TOTAL_PRICE,
        DOCUMENTDETAIL_STATUS_ID,
        REMARKS,
        CREATED_ON
    } = DocumentDetailListItemFields;

    const { getImageUrl } = useFileStoreUrl();
    const { dateByUtcToLocaleString } = useDates();
    const { getNumber, getPrice, getPercent } = useNumberFormat(cultureId);

    const filterContextHandler = useFilterContextHandler<IDocumentDetailFilterContext>(
        (onSuccess) => {
            DocumentAPI.getDetailFilterContext(
                documentRowGuid,
                true,
                onSuccess
            );
        },
        (filterContext, onSuccess) => DocumentAPI.filterDetails(filterContext, onSuccess),
        undefined);

    const listViewSelection = useSelection<number>(DocumentDetailListItemFields.ID, filterContextHandler.filterResultInfo?.Items, [], false, SelectionMode.multiple);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        getFilterContext,
        getFilterResultInfo,
        onFilterChange,
        onPageChange,
        onOrderByChange } = filterContextHandler;

    const classNames = mergeStyleSets({
        numericColumn: {
            width: "100%",
            textAlign: "right"
        },
        sizeColumn: {
            textAlign: "center"
        },
        tileDescription: {
            color: DefaultPalette.neutralSecondary,
            fontWeight: "600",
            marginLeft: 5,
            marginRight: 5,
            whiteSpace: "noWrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        },
        tileTotals: {
            color: DefaultPalette.neutralSecondary,
        }
    });

    const getDetailsListColumns = (): IDetailsListColumn[] => {

        const columns: IDetailsListColumn[] = [
            { fieldName: PRODUCT_IMAGE, width: 32, name: "" },
            { fieldName: DESCRIPTION, width: 275, isRowHeader: true },
            { fieldName: COLLI_PACKAGE_NUMBER, width: 70, name: "Colli" },
            { fieldName: UNIT_PRICE, width: 60, name: "Price" },
            { fieldName: QUANTITY, width: 40, name: "Abv_Quantity" },
            { fieldName: TOTAL_PRICE_EXCL_DISCOUNT, width: 60, name: "Subtotal" },
            { fieldName: DISCOUNT1, width: 40, name: "-% [1]", translateHeaderDisabled: true },
            { fieldName: DISCOUNT2, width: 40, name: "-% [2]", translateHeaderDisabled: true },
            { fieldName: DISCOUNT_TOTAL, width: 60, name: "Discount" },
            { fieldName: TOTAL_PRICE, width: 60, name: "Total" },
            { fieldName: DOCUMENTDETAIL_STATUS_ID, width: 100, name: "Status" },
            { fieldName: REMARKS, width: 300 },
            { fieldName: CREATED_ON, width: 100, name: "Created" },
        ];

        return columns;
    }

    const onRenderDetailsListItemColumn = (item?: IDocumentDetailListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName) {
            switch (column.fieldName) {
                case PRODUCT_IMAGE:
                    return (
                        <Image
                            src={getImageUrl(item.ProductImage)}
                            height={32}
                            width={32}
                            imageFit={ImageFit.contain}
                            styles={getProductImageStyles}
                        />
                    )
                case UNIT_PRICE:
                case TOTAL_PRICE_EXCL_DISCOUNT:
                case TOTAL_PRICE:
                case DISCOUNT_TOTAL:
                    return <div className={classNames.numericColumn}>{getPrice(item[column.fieldName])}</div>
                case QUANTITY:
                    return <div className={classNames.numericColumn}>{getNumber(item[column.fieldName])}</div>
                case DISCOUNT1:
                case DISCOUNT2:
                    return <div className={classNames.numericColumn}>{getPercent(item[column.fieldName], 2)}</div>
                case DOCUMENTDETAIL_STATUS_ID:
                    const statusName = DictionaryAPI.getTranslation(DocumentDetailStatuses[item.DocumentDetailStatusId]);
                    switch (item.DocumentDetailStatusId) {
                        case DocumentDetailStatuses.Processing:
                            return <span style={{ color: DefaultPalette.orangeLight }}>{statusName}</span>
                        case DocumentDetailStatuses.Depot:
                            return <span style={{ color: DefaultPalette.purpleLight }}>{statusName}</span>
                        case DocumentDetailStatuses.Delivered:
                            return <span style={{ color: DefaultPalette.green }}>{statusName}</span>
                        case DocumentDetailStatuses.Cancelled:
                        case DocumentDetailStatuses.Cancelled_Company:
                        case DocumentDetailStatuses.Cancelled_Customer:
                        case DocumentDetailStatuses.Cancelled_Production:
                            return <span style={{ color: DefaultPalette.red }}>{statusName}</span>
                        default:
                            return <span style={{ color: DefaultPalette.blue }}>{statusName}</span>
                    }
                case CREATED_ON:
                    return <span>{dateByUtcToLocaleString(item[column.fieldName])}</span>
                default:
                    return <span>{item[column.fieldName]}</span>
            }
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (
                <FilterBar
                    filterContextHandler={filterContextHandler}
                    onRenderFilterBarItem={(props) => (
                        <CheckboxFilter
                            filter={props.filter as IFilter}
                            name={props.name}
                            onChange={onFilterChange} />
                    )}
                />)
        }
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Add:
                setDetailId(0);
                break;

            case DefaultCommandTypes.Remove:
                if (listViewSelection.selectedKeys.length > 0) {
                    setDetailIdsToUpdate(listViewSelection.selectedKeys);
                }
                break;
            default:
                break;
        }
    }

    const onRemove = () => {
        if (documentRowGuid) {
            setLoading(true);
            DocumentAPI.removeDetails({
                DocumentRowGuid: documentRowGuid,
                Ids: detailIdsToUpdate,
                GroupKeys: []
            }, () => {
                listViewSelection.setSelectedKeys([]);
                onDetailsUpdated();
            });
        }
    }

    const onCancelRemove = () => {
        setDetailIdsToUpdate(undefined);
    }

    const onDismissAddOrUpdateDetails = (documentUpdated?: boolean) => {
        if (documentUpdated) {
            onDetailsUpdated();
        } else {
            setDetailId(undefined);
        }
    }

    useEffect(() => {
        onCancelRemove();
        setDetailId(undefined);
        setLoading(false);

    }, [filterContext])


    const getProductImageStyles = (props: IImageStyleProps): Partial<IImageStyles> => {
        return {
            root: props.isError && {
                background: `url("${imgError}") no-repeat center`,
                backgroundSize: "contain"
            }
        }
    }

    return (
        <>
            <ListView
                readOnly
                compact
                isSub
                menu={tabMenu}
                title=""
                availableListViewCommandBarTypes={[ListViewCommandBarTypes.List]}
                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderDetailsListItemColumn}
                onDefaultCommandClick={onDefaultCommandClick}
                onRefreshButtonClick={getFilterResultInfo}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                items={filterResultInfo?.Items}
                onItemClick={(item) => setDetailId(item.Id)}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                loading={!filterContext}
                dataLoading={isFiltering}
                onOrderByChange={onOrderByChange}
                selection={listViewSelection.selection}
                getInitialFocusedIndex={listViewSelection.getFirstSelectedItemIndex}
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                detailIdsToUpdate && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("RemoveSelectedDetails") + "?"}
                        acceptButtonDisabled={loading}
                        cancelButtonDisabled={loading}
                        onAccept={onRemove}
                        onCancel={onCancelRemove}
                        maxWidth={300}
                    />
                )
            }
            {
                /*  detailId !== undefined && (
                     <DetailDialog
                         documentRowGuid={documentRowGuid}
                         documentDetailId={detailId}
                         onSuccess={onDismissAddOrUpdateDetails}
                         onCancel={() => setDetailId(undefined)}
                     />
                 ) */
            }
        </>
    )
}
