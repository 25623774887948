import { Check, Image, ImageFit, Label, ScrollablePane, ScrollbarVisibility, SelectionMode, SelectionZone, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react'
import { ITileListItemProps, ITileListProps } from './interfaces'
import useStyles from './useStyles';

const ITEM_LINK = 'tilelist-item-link'

const TileListView = (props: ITileListProps) => {
    const { items, readOnly, onItemClick } = props;

    const _onItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!readOnly) {
            const ev = event as React.MouseEvent<HTMLElement>;
            ev.preventDefault();
            const index = Number(ev.currentTarget.id.replace(ITEM_LINK, ""));
            const item = items[index];

            onItemClick && onItemClick(item);
        }
    }

    return (
        <div>
            {items.map((item, index) => (
                <TileListItem {...props}
                    key={index}
                    item={item}
                    index={index}
                    onItemClick={_onItemClick}
                />
            ))}
        </div>
    )
}

const TileList = (props: ITileListProps) => {

    const { selection } = props;

    return (
        <div style={{ position: "relative", height: "100%" }}>
            <ScrollablePane styles={{ root: { padding: 0 } }} scrollbarVisibility={ScrollbarVisibility.auto}>
                {
                    selection ? (
                        <SelectionZone selection={selection}>
                            <TileListView {...props} />
                        </SelectionZone>
                    ) : <TileListView {...props} />
                }

            </ScrollablePane>
        </div>
    )
}

const TileListItem = (props: ITileListItemProps) => {

    const { index, item, selection, onSelectionClick, readOnly, onItemClick, onRenderCell, cellProps } = props;
    const { classNames } = useStyles();

    let isSelected = false;

    if (selection && index !== undefined) {
        isSelected = selection.isIndexSelected(index);
    }

    return (
        <div
            className={isSelected ? classNames.listTileSelected : classNames.listTile}
            data-is-focusable={true}
            data-selection-index={index}
            style={{
                width: 300,
            }}
        >
            {selection && selection.canSelectItem(item) && selection.mode !== SelectionMode.none && (
                <div onClick={onSelectionClick} className={classNames.listTileCheckbox} data-is-focusable data-selection-toggle>
                    <Check styles={isSelected && { root: { visibility: "visible !important" } }} checked={isSelected} />
                </div>
            )}

            <div onClick={onSelectionClick} className={classNames.listSizer} data-selection-toggle>
                <div id={`${ITEM_LINK}${index}`} onClick={readOnly ? undefined : onItemClick} className={classNames.listPadder} data-selection-disabled>
                    {onRenderCell ? onRenderCell(item, index) :
                        cellProps ? (
                            <Stack verticalFill>
                                <StackItem grow>
                                    <Image
                                        src={item[cellProps.imageItemFieldName]}
                                        width="100%"
                                        height="100%"
                                        imageFit={ImageFit.contain}
                                    />
                                </StackItem>
                                <StackItem>
                                    <Label className={classNames.listTileLabel}>
                                        {item[cellProps.labelItemFieldName]}
                                    </Label>
                                </StackItem>
                            </Stack>
                        ) : undefined}
                </div>
            </div>
        </div>
    );
}



export default TileList

