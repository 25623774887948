import React, { CSSProperties, useState, useEffect } from 'react';
import { IRenderFunction, Icon, FontSizes, Panel } from 'office-ui-fabric-react';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { Avatar, Logo } from 'components/common'
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { IContextualMenuListProps } from 'office-ui-fabric-react/lib/ContextualMenu';
import { AccountAPI, DictionaryAPI, MenuAPI } from 'api'
import { DocumentAPI } from 'api/lgs/DocumentAPI';
import { SelectPendingOrderCustomer } from './SelectPendingOrderCustomer';
import { IHeaderProps } from '.';
import { TopNavigationInteractionMenus } from 'enums';
import { Impersonate } from './Impersonate';

const theme = getTheme();

const containerStyle: CSSProperties = {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.semanticColors.bodyDivider
}

export const Header = (props: IHeaderProps): JSX.Element => {

    const { account, pendingOrdersState, onPendingOrdersPanelButtonClick } = props;
    const { customerRelationName, customerRelationRowGuid, totalOrders } = pendingOrdersState;

    const [isSelectCustomer, setIsSelectCustomer] = useState<boolean>();
    const [pendingOrdersDisabled, setPendingOrdersDisabled] = useState<boolean>();
    const [impersonateDialogVisible, setImpersonateDialogVisible] = useState(false);

    const accountMenus = MenuAPI.filterByParent(TopNavigationInteractionMenus.Account);

    const onMenuClick = (menuId: number) => {
        switch (menuId) {
            case TopNavigationInteractionMenus.Account_Impersonate:
                setImpersonateDialogVisible(true);
                break;
            case TopNavigationInteractionMenus.Account_SignOut:
                AccountAPI.signOut();
                break;
            default:
                break;
        }
    }

    const renderAccountMenu = (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
            <div style={{ minWidth: 250 }}>
                <div style={{ padding: '10px', borderBottom: '1px solid ' + theme.semanticColors.bodyDivider }}>
                    <Avatar
                        initials={account.Code}
                        text={account.Name}
                        secondaryText={account.Username}
                        isLargeImage={true}
                    />
                    <div style={{
                        marginTop: 14,
                        color: theme.semanticColors.disabledText,
                        textAlign: "right"
                    }}
                    >
                        {`v${process.env.REACT_APP_VERSION}`}<br />
                    </div>
                </div>

                {defaultRender ? defaultRender(menuListProps) : null}
            </div>
        );
    };

    const onSelectCustomerAccept = (customerRelationRowGuid: string) => {
        setPendingOrdersDisabled(true);

        DocumentAPI.setOrderCustomerRelationRowGuidToLocalStorage(customerRelationRowGuid);
        DocumentAPI.loadActivePendingOrderCustomerState(() => {
            setPendingOrdersDisabled(false);
            setIsSelectCustomer(false);
        });
    }

    return (
        <>
            <Stack style={containerStyle} tokens={{ childrenGap: 5 }} horizontalAlign="stretch" horizontal verticalFill>
                <StackItem align="center" grow>
                    <Stack tokens={{ childrenGap: 15 }} verticalAlign="center" horizontal>
                        <StackItem>
                            <Logo padding={5} />
                        </StackItem>
                        <StackItem>
                        </StackItem>
                        {/* <StackItem>
                            <ComboBox 
                                placeholder={DictionaryAPI.getTranslation("SelectBrand")}
                                selectedKey={"SB"}
                                options={
                                    [{ key: "SB", text: "STONES and BONES" }]
                                }
                            />
                        </StackItem> */}
                    </Stack>
                </StackItem>
                {
                    MenuAPI.find(TopNavigationInteractionMenus.SelectCustomer) && (
                        <StackItem>
                            <CommandBarButton
                                text={
                                    customerRelationRowGuid === "" ?
                                        `${DictionaryAPI.getTranslation("SelectCustomer")}...` :
                                        customerRelationName
                                }
                                iconProps={{ iconName: "FollowUser" }}
                                style={{ height: "100%" }}
                                disabled={pendingOrdersDisabled}
                                menuProps={customerRelationRowGuid !== "" && {
                                    directionalHint: 2,
                                    items: [
                                        {
                                            key: 'selectCustomer',
                                            text: `${DictionaryAPI.getTranslation("SelectCustomer")}...`,
                                            iconProps: { iconName: "FollowUser" },
                                            onClick: () => setIsSelectCustomer(true),
                                        },
                                        {
                                            key: 'clearCustomer',
                                            text: DictionaryAPI.getTranslation("Clear"),
                                            iconProps: { iconName: "Clear" },
                                            onClick: () => onSelectCustomerAccept(undefined)
                                        }
                                    ]
                                }}
                                onClick={customerRelationRowGuid === "" && (() => setIsSelectCustomer(true))} />
                        </StackItem>
                    )
                }
                {
                    customerRelationRowGuid !== "" && (
                        <StackItem align="stretch">
                            <CommandBarButton
                                styles={{ root: { height: "100%" } }}
                                onClick={onPendingOrdersPanelButtonClick}
                                checked={pendingOrdersState.isVisible}
                                disabled={totalOrders === 0 || pendingOrdersDisabled || pendingOrdersState.disabled}>
                                <Icon style={
                                    {

                                        fontSize: FontSizes.large,
                                        color: (totalOrders === 0 || pendingOrdersDisabled || pendingOrdersState.disabled) ? theme.semanticColors.disabledText : theme.semanticColors.menuIcon
                                    }}
                                    iconName="OpenEnrollment" />
                                <span style={{
                                    width: 24,
                                    height: 24,
                                    lineHeight: "24px",
                                    marginLeft: 8,
                                    backgroundColor: theme.semanticColors.successBackground,
                                    fontSize: FontSizes.xSmall,
                                    fontWeight: "bold",
                                    borderRadius: "100%",
                                    textAlign: "center"
                                }}>
                                    {String(totalOrders)}
                                </span>
                            </CommandBarButton>
                        </StackItem>
                    )
                }

                <StackItem>
                    <CommandBarButton
                        styles={{ root: { height: "100%" } }}
                        menuIconProps={{ style: { display: "none" } }}
                        menuProps={{
                            directionalHint: 2,
                            onRenderMenuList: renderAccountMenu,
                            items: accountMenus.map((v, i) => {
                                return {
                                    key: String(v.Id),
                                    text: v.translatedName,
                                    onClick: () => onMenuClick(v.Id),
                                }
                            })
                        }}>
                        <Avatar initials={account.Code} />
                    </CommandBarButton>
                </StackItem>
            </Stack>
            {
                isSelectCustomer && (
                    <SelectPendingOrderCustomer
                        onAccept={onSelectCustomerAccept}
                        onCancel={() => setIsSelectCustomer(false)} />
                )
            }
            {
                impersonateDialogVisible && (
                    <Impersonate 
                        accountUserName={account.Username}
                        onDismiss={() => setImpersonateDialogVisible(false)}
                    />
                )
            }
            {
                /* documentRowGuid && (
                    <Order
                        documentRowGuid={documentRowGuid}
                        onDismiss={() => setOrderRowGuid(undefined)}
                    />
                ) */
            }
        </>

    );
}
