import { DictionaryAPI, BaseAPI } from 'api'
import { IMenuIconMap } from 'interfaces'
import { IMenu } from 'interfaces/models'
import { LeftNavigationMenus, DocumentFormInteractionMenus, MenuTypes, OrdersInteractionMenus } from 'enums';
import { useRoute, useGlobals } from 'hooks';
import { forEach } from 'lodash';

const { formatUrl } = useRoute();
const { clientId } = useGlobals();

const getIconsById = (): IMenuIconMap => {
    let map: IMenuIconMap = {};
    map[LeftNavigationMenus.Dashboard] = "ViewDashboard";
    map[LeftNavigationMenus.Crm] = "Group";
    map[LeftNavigationMenus.Customers] = "Group";
    map[LeftNavigationMenus.Products] = "Tag";
    map[LeftNavigationMenus.Products_Shoes] = "Tag";
    map[LeftNavigationMenus.Products_Clothing] = "Tag";
    map[LeftNavigationMenus.Products_Accessories] = "Tag";
    map[LeftNavigationMenus.ProductCatalog] = "ReadingMode";
    map[LeftNavigationMenus.Sales] = "Financial";
    map[LeftNavigationMenus.Orders] = "Financial";
    map[LeftNavigationMenus.Catalogs] = "ReadingMode";
    map[OrdersInteractionMenus.RecalculateOrders] = "Sync";
    map[DocumentFormInteractionMenus.Status_Edit] = "PageEdit";
    map[DocumentFormInteractionMenus.Status_Confirm] = "WaitlistConfirm";
    map[DocumentFormInteractionMenus.Status_Execute] = "Accept";
    map[DocumentFormInteractionMenus.Status_Cancel] = "Cancel";

    return map;
}

const iconsById: IMenuIconMap = getIconsById();


const getWithExtensionFields = (menu: IMenu): IMenu => {
    return {
        ...menu,
        Path: menu.Path?.toLocaleLowerCase(),
        iconName: iconsById[menu.Id],
        translatedName: DictionaryAPI.getTranslation(menu.Name),
    }
}

export class MenuAPI {

    public static load = (onSuccess: () => void): void => {
        BaseAPI.get(`api/app/menu/read/${clientId}`, null,
            (response: IMenu[]) => {
                
                MenuAPI._menus = [];
                MenuAPI._menuMap = new Map();
                MenuAPI._routeMenus = [];

                for (const m of response) {
                    const menu = getWithExtensionFields(m);
                    MenuAPI._menus.push(menu);
                    MenuAPI._menuMap.set(m.Id, menu);
                }

                MenuAPI.setRouteMenus();
                onSuccess();
            });
    }

    public static find = (id: number): IMenu | undefined => {
        return MenuAPI._menuMap.get(id);
    }

    public static filterByType = (menuType: MenuTypes, parentId?: number): IMenu[] => {
        return MenuAPI._menus.filter(m => m.TypeId === menuType && (m.ParentId === parentId || !parentId));
    }

    public static filterByParent = (parentId: number): IMenu[] => {
        return MenuAPI._menus.filter(m => m.ParentId === parentId);
    }

    public static getRouteMenus = (): IMenu[] => {
        return MenuAPI._routeMenus;
    } 

    private static setRouteMenus = () => {
        MenuAPI._routeMenus = [];
        for (const m of MenuAPI.filterByType(MenuTypes.Navigation)) {
            if (MenuAPI.filterByType(MenuTypes.Navigation, m.Id).length === 0) {
                MenuAPI._routeMenus.push(m);
            }
        }
    }

    public static getIconById = (id: number): string => {
        return iconsById[id];
    }

    private static _menus: IMenu[] = [];
    private static _routeMenus: IMenu[] = [];
    private static _menuMap: Map<number, IMenu> = new Map();
}
