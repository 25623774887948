import React from 'react';

import { ICreateRelationViewModel } from 'interfaces/viewmodels';
import { RelationAPI } from 'api';
import { ICreateRelationProps } from '.';
import { CreateRelationLayout } from 'components/layouts';

export const CreateRelation = (props: ICreateRelationProps): JSX.Element => {

    const { isPerson, relationTypeId, companyRelationId, parentContactId, onSuccess, onCancel } = props;

    return (
        <CreateRelationLayout<ICreateRelationViewModel>
            getCreateRelation={(onSuccess) => RelationAPI.getCreate(isPerson, relationTypeId, companyRelationId, parentContactId, onSuccess)}
            createRelation={RelationAPI.create}
            onSuccess={onSuccess}
            onCancel={onCancel}
        />
    );
}