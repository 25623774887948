import React, { CSSProperties } from 'react';
import { Stack, StackItem, CommandBarButton, getTheme, IButtonStyles, Text } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IPagingProps } from './interfaces';

export const Paging = (props: IPagingProps) => {

    const { page, pageSize, pageCount, itemCount, onPageChanged } = props;
    const currentPageItemCount: number = (page || 0) * (pageSize || 0);

    const theme = getTheme();
    const pagingTextStyle: CSSProperties = {
        color: theme.palette.neutralSecondary,
    }
    const pagingButtonStyles: IButtonStyles = {
        root: {
            padding: "5px 0",
            backgroundColor: theme.palette.neutralLighterAlt,
        },
        rootDisabled: {
            backgroundColor: theme.palette.neutralLighterAlt,
        },
        icon: {
            fontSize: "10px",
            color: theme.palette.neutralSecondary,
        }
    }

    return (
        <Stack style={{ backgroundColor: theme.palette.neutralLighterAlt, borderTop: "1px solid " + theme.palette.neutralLight }} horizontalAlign="stretch" horizontal>
            <StackItem align="start" grow>
                <Stack horizontalAlign="stretch" horizontal>
                    <StackItem>
                        <CommandBarButton
                            title={DictionaryAPI.getTranslation("FirstPage")}
                            disabled={page < 2}
                            styles={pagingButtonStyles}
                            iconProps={{ iconName: "ChevronLeftEnd6" }}
                            onClick={() => onPageChanged!(1)}
                        />
                    </StackItem>
                    <StackItem>
                        <CommandBarButton
                            title={DictionaryAPI.getTranslation("PreviousPage")}
                            disabled={page < 2}
                            styles={pagingButtonStyles}
                            iconProps={{ iconName: "ChevronLeftSmall" }}
                            onClick={() => onPageChanged!(page - 1)}
                        />
                    </StackItem>
                    <StackItem styles={{ root: { padding: "0 5px" } }} align="center">
                        <Text style={pagingTextStyle} variant="small">{DictionaryAPI.getTranslation("Page")} {page} {DictionaryAPI.getTranslation("Of").toLowerCase()} {pageCount}</Text>
                    </StackItem>
                    <StackItem>
                        <CommandBarButton
                            title={DictionaryAPI.getTranslation("NextPage")}
                            disabled={pageCount === 0 || page === pageCount}
                            styles={pagingButtonStyles}
                            iconProps={{ iconName: "ChevronRightSmall" }}
                            onClick={() => onPageChanged!(page + 1)}
                        />
                    </StackItem>
                    <StackItem>
                        <CommandBarButton
                            title={DictionaryAPI.getTranslation("LastPage")}
                            disabled={pageCount === 0 || page === pageCount}
                            styles={pagingButtonStyles}
                            iconProps={{ iconName: "ChevronRightEnd6" }}
                            onClick={() => onPageChanged!(pageCount)}
                        />
                    </StackItem>
                </Stack>
            </StackItem>
            <StackItem styles={{ root: { paddingRight: 10 } }} align="center">
                <Text style={pagingTextStyle} variant="small">
                    {pageSize > itemCount ? itemCount :
                        `${currentPageItemCount - (pageSize - 1)}-${currentPageItemCount > itemCount ? itemCount : currentPageItemCount}`} {DictionaryAPI.getTranslation("Of").toLowerCase()} {itemCount}
                </Text>
            </StackItem>
        </Stack>
    );
};