import React from 'react';
import { ICatalogProps, ICatalogBaseTabProps } from '.';
import { FormMenus, CatalogFormTabMenus, ParentProductCategories } from 'enums';
import { CatalogAPI, DictionaryAPI, MenuAPI } from 'api';
import { ICatalogViewModel } from 'interfaces/viewmodels';
import { IFormProps } from 'hooks';
import { InformationTab } from './InformationTab';
import { PagesTab } from './PagesTab';
import { RelationsTab } from './RelationsTab';
import { EventLogList } from 'components/common';
import { FormLayout } from 'components/layouts';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const Catalog = (props: ICatalogProps) => {

    const { rowGuid, onDismiss } = props;
    const formMenu = MenuAPI.find(FormMenus.Catalog);

    const cultureId = useSelector((state: RootState) => state.authenticationState.account.CultureId);

    const getItem = (onSuccess: (item?: ICatalogViewModel, windowTitle?: string, windowSubTitle?: string, isActive?: boolean) => void) => {
        CatalogAPI.get(rowGuid,
            (data) => onSuccess(
                data,
                data?.Name,
                `${formMenu.translatedName} (${DictionaryAPI.getTranslation(ParentProductCategories[data.ProductCategoryId])})`,
                data?.IsActive
            ))
    }

    const getTabMenuContent = (tabMenuId: number, form?: IFormProps<ICatalogViewModel>): JSX.Element | undefined => {

        const catalog = form?.item;

        const baseTabProps: ICatalogBaseTabProps = {
            form: form,
            formMenu: formMenu,
            catalogRowGuid: rowGuid,
            catalogName: catalog?.Name,
            cultureId: cultureId
        }

        switch (tabMenuId) {
            case CatalogFormTabMenus.Information:
                return (
                    <InformationTab {...baseTabProps} />
                )
            case CatalogFormTabMenus.Pages:
                return (
                    <PagesTab {...baseTabProps} />
                )
            case CatalogFormTabMenus.Relations:
                return (
                    <RelationsTab {...baseTabProps} />
                )
            case CatalogFormTabMenus.Log:
                return (
                    <EventLogList
                        cultureId={cultureId}
                        getFilterContext={(onSuccess) => CatalogAPI.getEventLogFilterContext(baseTabProps.catalogRowGuid, onSuccess)} />
                )
            default:
                break;
        }

        return <></>
    }

    return (
        <FormLayout<ICatalogViewModel>
            formMenu={formMenu}
            itemDependencies={[rowGuid]}
            getItem={getItem}
            updateItem={CatalogAPI.update}
            deleteItem={(item, onSuccess, onError) => CatalogAPI.delete(item.RowGuid, onSuccess, onError)}
            toggleIsActive={(onSuccess) => CatalogAPI.toggleIsActive(rowGuid, onSuccess)}
            getTabMenuContent={getTabMenuContent}
            onDismiss={onDismiss} />
    );
}