import { DictionaryTypes } from './types'
import { IDictionaryMap } from 'interfaces';
export const SET_DICTIONARY = "SET_DICTIONARY";

export function setDictionary(language: string, dictionary: IDictionaryMap): DictionaryTypes {
    return {
        type: SET_DICTIONARY,
        language: language,
        dictionary: dictionary
    }
}