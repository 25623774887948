import {
    IProductCatalogsState,
    ISetProductCatalogs,
    ProductCatalogsTypes,
} from './types'
import {
    SET_PRODUCT_CATALOGS,
} from './actions'

const initialState: IProductCatalogsState = {
    productCatalogs: [],
}

export function productCatalogsReducer(state = initialState, action: ProductCatalogsTypes): IProductCatalogsState {
    switch (action.type) {
        case SET_PRODUCT_CATALOGS:
            const setProductCatalogsAction = action as ISetProductCatalogs;
            return {
                ...state,
                productCatalogs: setProductCatalogsAction.productCatalogs
            }
        default:
            return state
    }
}