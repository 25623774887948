import React, { useEffect, useState } from 'react'
import { DocumentTypes, OrdersInteractionMenus, OrderSubDocumentTypes } from 'enums';
import { CreateOrder, Order } from 'components/pages';
import { DocumentsListViewLayout } from 'components/layouts';
import { storeInstance } from 'store';
import { setPendingOrdersVisible } from 'store/pendingOrders/actions';
import { IDocumentsListViewLayoutBaseProps } from 'components/layouts/DocumentsListViewLayout/interfaces';
import { CreateStockAssortment } from '../CreateStockAssortment';
import { StockAssortment } from '../StockAssortment/StockAssortment';

export const StockAssortments = (props: IDocumentsListViewLayoutBaseProps) => {

    useEffect(() => {
        storeInstance.dispatch(setPendingOrdersVisible(false, true));
        return () => {
            storeInstance.dispatch(setPendingOrdersVisible(false, false));
        }
    }, [props.menu])

    return (
        <DocumentsListViewLayout {...props}
            documentTypeId={DocumentTypes.StockAssortment}
            relationNameHeader="Customer"
            fromRelationNameHeader="Warehouse"
            detailsListColumnFieldNamesToHide={[
                "ToRelationName",
                "Trend",
            ]}
            getCreateDocumentComponent={(onAccept, onCancel, menuId) => {

                return (
                    <CreateStockAssortment
                        onAccept={onAccept}
                        onCancel={onCancel}
                    />
                )
            }}
            getDocumentComponent={(rowGuid, onDismiss) => {
                return (
                    <StockAssortment
                        documentRowGuid={rowGuid}
                        onDismiss={onDismiss}
                    />
                )
            }}
        />


    )
}
