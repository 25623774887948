import { DictionaryAPI } from 'api';
import { CreateEmailNotificationViewModelFields } from 'enums/fields';
import { ActionButton, Checkbox, CommandBarButton, IComboBoxOption, Label, mergeStyleSets, Stack, StackItem, TextField } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { IBaseCreateEmailNotificationProps } from './interfaces';
import { Editor, EditorState } from 'draft-js';
import { NotificationRecipientTypes } from 'enums';
import { ComboBox } from '..';
import { IEmailNotificationRecipientViewModel } from 'interfaces/viewmodels';

export const CreateEmailNotification: React.FC<IBaseCreateEmailNotificationProps> = props => {

    const { form, disabled } = props;
    const { item, isSubmitting, updateItem, onUnControlledTextFieldChange, onTextFieldChange, onMultiSelectComboBoxSimpleChange, getErrorMessage } = form;

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const { SUBJECT, MESSAGE, SELECTED_TO_RECIPIENT_EMAILS, SELECTED_CC_RECIPIENT_EMAILS } = CreateEmailNotificationViewModelFields;

    const onEditorChange = (editorState: EditorState) => {
        setEditorState(editorState);
    }

    const getRecipientComboBoxItems = (recipients: IEmailNotificationRecipientViewModel[]): IComboBoxOption[] => {
        return recipients.map((v, i): IComboBoxOption => {
            return {
                key: v.Email,
                text: `${v.Name} (${v.Email})`
            }
        });
    }

    return (
        <Stack horizontalAlign="stretch" style={{ minWidth: 500 }}>

            <StackItem>
                <ComboBox
                    allowFreeform
                    autoComplete="on"
                    label={DictionaryAPI.getTranslation("To")}
                    multiSelect={true}
                    selectedKey={item.SelectedToRecipientEmails}
                    name={SELECTED_TO_RECIPIENT_EMAILS}
                    options={getRecipientComboBoxItems(item.ToRecipients)}
                    disabled={disabled || isSubmitting}
                    onSimpleChange={onMultiSelectComboBoxSimpleChange} />
            </StackItem>
            <StackItem>
                <ComboBox
                    allowFreeform
                    autoComplete="on"
                    label="CC"
                    multiSelect={true}
                    selectedKey={item.SelectedCcRecipientEmails}
                    name={SELECTED_CC_RECIPIENT_EMAILS}
                    options={getRecipientComboBoxItems(item.CcRecipients)}
                    disabled={disabled || isSubmitting}
                    onSimpleChange={onMultiSelectComboBoxSimpleChange} />
            </StackItem>
            <StackItem>
                <TextField
                    label={DictionaryAPI.getTranslation(SUBJECT)}
                    name={SUBJECT}
                    value={item.Subject}
                    disabled={disabled || isSubmitting}
                    onChange={onTextFieldChange}
                    errorMessage={getErrorMessage(SUBJECT)} />
            </StackItem>
            {
                item.FileAttachmentUrls?.length > 0 && (
                    <Stack horizontal>
                        {
                            item.FileAttachmentUrls.map((v, i) => {
                                return (
                                    <StackItem key={v}>
                                        <ActionButton
                                            href={v}
                                            target="_blank"
                                            text={v.substring(v.lastIndexOf("/") + 1)}
                                            disabled={disabled || isSubmitting}
                                            iconProps={{
                                                iconName: "Attach"
                                            }} />
                                    </StackItem>
                                )
                            })
                        }
                    </Stack>
                )
            }
            <StackItem>
                <TextField
                    multiline
                    style={{ minHeight: 200, width: "100%" }}
                    label={DictionaryAPI.getTranslation(MESSAGE)}
                    name={MESSAGE}
                    defaultValue={item.Message}
                    disabled={disabled || isSubmitting}
                    onChange={onUnControlledTextFieldChange}
                    errorMessage={getErrorMessage(MESSAGE)} />
            </StackItem>

        </Stack>
    )
}
