import React from 'react';
import { Text, ITheme, getTheme } from 'office-ui-fabric-react'
import { IErrorMessageProps } from './interfaces';

export const ErrorMessage = (props: IErrorMessageProps) => {

    const theme = getTheme();

    return (
        <div style={{ paddingTop: 5, color: theme.semanticColors.errorText }}>
            <Text key={props.key} variant="small" block>
                {props.message || ""}
            </Text>
        </div>
    );
}    