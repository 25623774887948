import { useNumberFormat } from 'hooks';
import { DefaultPalette, FontWeights, Stack, StackItem } from 'office-ui-fabric-react'
import React from 'react'
import { IOrderTrendStatisticsProps, ISalesTrendItemProps } from './interfaces'
import { SalesTrendItem } from './salesTrendItem';
import { SalesTrendTotalsItem } from './salesTrendTotalsItem';

export const OrderTrendStatistics = (props: IOrderTrendStatisticsProps) => {

    const { orderTrendStatistics, cultureId } = props;

    const numberFormatHook = useNumberFormat(cultureId);

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <StackItem>
                <SalesTrendItem
                    isCurrent
                    useNumberFormatHook={numberFormatHook}
                    salesTrend={orderTrendStatistics.CurrentSalesTrend}
                />
            </StackItem>
            {
                orderTrendStatistics.PreviousSalesTrend > 0 && (
                    <StackItem>
                        <SalesTrendItem
                            useNumberFormatHook={numberFormatHook}
                            salesTrend={orderTrendStatistics.PreviousSalesTrend}
                        />
                    </StackItem>
                )
            }
            <StackItem>
                <SalesTrendTotalsItem
                    useNumberFormatHook={numberFormatHook}
                    collectionCode={orderTrendStatistics.CurrentCollectionCode}
                    salesTotal={orderTrendStatistics.CurrentSalesTotal}
                    salesQuantity={orderTrendStatistics.CurrentSalesQuantity}
                />
            </StackItem>
            <StackItem>
                <SalesTrendTotalsItem
                    useNumberFormatHook={numberFormatHook}
                    collectionCode={orderTrendStatistics.PreviousCollectionCode}
                    salesTotal={orderTrendStatistics.PreviousSalesTotal}
                    salesQuantity={orderTrendStatistics.PreviousSalesQuantity}
                />
            </StackItem>
        </Stack>
    )
}
