import React from 'react';
import { DefaultPalette, FontSizes, FontWeights } from 'office-ui-fabric-react/lib/Styling';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { IAuthenticationLayoutProps } from './interfaces';
import { Logo } from 'components/common';
import { Depths } from '@uifabric/fluent-theme';

export const AuthenticationLayout: React.FunctionComponent<IAuthenticationLayoutProps> = props => {

    const { title } = props;

    return (
        <Stack
            horizontalAlign="stretch"
            verticalAlign="center"
            verticalFill={true}
            styles={{ root: { backgroundColor: DefaultPalette.neutralLighter } }}>
            <StackItem align="center">
                <Stack
                    style={{
                        boxShadow: Depths.depth8,
                        backgroundColor: DefaultPalette.white,
                        width: 440,
                        padding: 44
                    }}
                    tokens={{ childrenGap: 24 }}>
                    <StackItem align="center">
                        <Logo isLarge={true} />
                    </StackItem>
                    <StackItem styles={{
                        root: {
                            color: DefaultPalette.neutralPrimaryAlt,
                            fontSize: FontSizes.xLarge,
                            fontWeight: FontWeights.semibold
                        }
                    }}>
                        <div>{title}</div>
                    </StackItem>
                    <StackItem>
                        {props.children}
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
