import { DictionaryTypes, IDictionaryState } from './types' 
import { SET_DICTIONARY } from './actions'

const initialState: IDictionaryState = {
    language: "",
    dictionary: {},
}

export function dictionaryReducer(state = initialState, action: DictionaryTypes): IDictionaryState {
    switch (action.type) {
        case SET_DICTIONARY:
            return {
                language: action.language,
                dictionary: action.dictionary,
                isLoaded: true
            }
        default:
            return state
    }
}