import React, { useState, useEffect } from 'react';
import { Loader, Dialog, ProductDocumentDetailSizes, DocumentTotalsTable } from 'components/common';
import { DictionaryAPI } from 'api';
import { IPendingOrderStepBaseProps } from './interfaces';
import { Stack, StackItem, Image, ImageFit, mergeStyleSets, getTheme, FontWeights, PrimaryButton, CommandBarButton, FontSizes, Icon } from 'office-ui-fabric-react';
import { IPendingOrderDetailsViewModel, IProductDocumentDetailsGroupViewModel, IUpdateDocumentDetailsViewModel } from 'interfaces/viewmodels';
import { DocumentAPI } from 'api/lgs/DocumentAPI';
import { useFileStoreUrl } from 'hooks/useFileStoreUrl';
import { useDates, useNumberFormat } from 'hooks';
import { ProductDetail } from 'components/pages';
import { IDocumentProductSize } from 'interfaces/models';

export const PendingOrderDetails = (props: IPendingOrderStepBaseProps) => {

    const { documentRowGuid, documentSummary, cultureId, onNextStepRequest, onOrderUpdate } = props;
    const [pendingOrderDetails, setPendingOrderDetails] = useState<IPendingOrderDetailsViewModel>();
    const [productId, setProductId] = useState<number>();
    const [deleteUpdateDocumentDetailsStatusViewModel, setDeleteUpdateDocumentDetailsStatusViewModel] = useState<IUpdateDocumentDetailsViewModel>();
    const [fetching, setFetching] = useState(false);

    let sizeRangeId: number = 0;
    let documentProductSizes: IDocumentProductSize[] = [];

    const { getNumber, getPrice } = useNumberFormat(cultureId);
    const { dateToLocaleDateString } = useDates(cultureId);
    const { getImageUrl } = useFileStoreUrl();
    const theme = getTheme();

    const classNames = mergeStyleSets({
        container: {
            color: theme.semanticColors.bodySubtext,
        },
        detailContainer: {
            padding: "5px 0",
            borderBottom: "1px solid " + theme.semanticColors.bodyFrameDivider,
        },
        description: {
            fontWeight: FontWeights.semibold,
            paddingLeft: 7,

        },
        footer: {
            padding: 15,
            borderTop: "1px solid " + theme.semanticColors.bodyFrameDivider,
            backgroundColor: theme.palette.neutralLighterAlt,
        },
    });

    const getPendingOrderDetails = () => {
        DocumentAPI.getPendingOrderDetails(documentRowGuid,
            (data) => {
                setProductId(undefined);
                setDeleteUpdateDocumentDetailsStatusViewModel(undefined);
                setPendingOrderDetails(data);
                setFetching(false);
            });
    }

    const onProductOrderDetailUpdated = () => {
        getPendingOrderDetails();
        onOrderUpdate && onOrderUpdate();
    }

    const handleDelete = () => {

        setFetching(true);
        if (deleteUpdateDocumentDetailsStatusViewModel) {
            DocumentAPI.removeDetails(deleteUpdateDocumentDetailsStatusViewModel, onProductOrderDetailUpdated);
            DocumentAPI.loadPendingOrdersState();
        }
    }

    useEffect(() => {
        if (documentRowGuid) {
            getPendingOrderDetails();
        }
    }, [documentRowGuid])

    return pendingOrderDetails ? (
        <>
            <Stack className={classNames.container} tokens={{ childrenGap: 7, padding: 10 }} verticalFill>
                <StackItem grow>

                    {pendingOrderDetails.ProductDocumentDetailsGroupViewModels.map((v: IProductDocumentDetailsGroupViewModel, i) => {

                        if (sizeRangeId !== v.SizeRangeId) {
                            sizeRangeId = v.SizeRangeId;
                            documentProductSizes = pendingOrderDetails.DocumentProductSizeRanges.find(s => s.SizeRangeId === v.SizeRangeId)?.Sizes || [];
                        }

                        return (
                            <Stack
                                key={v.GroupKey}
                                tokens={{ childrenGap: 5 }}
                                className={classNames.detailContainer}
                            >
                                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center">
                                    <StackItem>
                                        <Image
                                            key={v.GroupKey}
                                            src={getImageUrl(v.Image)}
                                            height={64}
                                            width={64}
                                            shouldFadeIn={false}
                                            imageFit={ImageFit.contain} />
                                    </StackItem>
                                    <StackItem grow>
                                        <span className={classNames.description}>{v.Name} {v.VariantDescription}</span>
                                    </StackItem>
                                    <Stack horizontalAlign="end" horizontal grow tokens={{ childrenGap: 20 }}>
                                        {
                                            /* v.ColliId && (
                                                <StackItem>
                                                    {v.ColliPackageNumber}
                                                </StackItem>
                                            ) */
                                        }
                                        {
                                            (v.ColliId && new Date(v.ExpectedOn) > new Date) && (
                                                <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }} styles={{ root: { color: "darkred" } }}>
                                                    <StackItem>
                                                        <Icon iconName="DeliveryTruck" styles={{ root: { fontSize: FontSizes.large } }} />
                                                    </StackItem>
                                                    <StackItem>
                                                        {dateToLocaleDateString(new Date(v.ExpectedOn))}
                                                    </StackItem>
                                                </Stack>
                                            )
                                        }
                                        <StackItem className="ms-fontWeight-semibold">
                                            {getNumber(v.TotalQuantity)} pcs
                                        </StackItem>
                                        <StackItem className="ms-fontWeight-semibold">
                                            {getPrice(v.TotalPrice, documentSummary.CurrencyId)}
                                        </StackItem>
                                    </Stack>
                                    <StackItem>
                                        <CommandBarButton
                                            title={DictionaryAPI.getTranslation("Edit")}
                                            style={{ padding: 5 }}
                                            iconProps={{ iconName: "Edit" }}
                                            onClick={() => setProductId(v.ProductId)} />
                                        <CommandBarButton
                                            title={DictionaryAPI.getTranslation("Delete")}
                                            style={{ padding: 5 }}
                                            iconProps={{ iconName: "Delete" }}
                                            onClick={() => setDeleteUpdateDocumentDetailsStatusViewModel({
                                                DocumentRowGuid: documentRowGuid,
                                                GroupKeys: [v.GroupKey]
                                            })} />
                                    </StackItem>
                                </Stack>
                                <StackItem>
                                    <ProductDocumentDetailSizes
                                        key={v.ProductId}
                                        currencyId={documentSummary.CurrencyId}
                                        cultureId={cultureId}
                                        groupIndex={i}
                                        documentProductSizes={documentProductSizes}
                                        documentDetails={v.DocumentDetails} />
                                </StackItem>
                            </Stack>
                        )
                    })}
                    <Stack
                        horizontal
                        styles={{
                            root: {
                                marginTop: 20,
                                marginBottom: 10,

                            }
                        }}>
                        <StackItem grow>
                            <Stack>
                                <StackItem
                                    styles={{
                                        root: {
                                            padding: 20,
                                            backgroundColor: theme.palette.neutralLighterAlt
                                        }
                                    }}
                                    align="end">
                                    <DocumentTotalsTable
                                        cultureId={cultureId}
                                        documentSummary={documentSummary} />
                                </StackItem>
                                <StackItem align="end">
                                    <PrimaryButton
                                        style={{ width: "100%", marginTop: "15px" }}
                                        text={DictionaryAPI.getTranslation("PlaceOrder")}
                                        onClick={() => onNextStepRequest && onNextStepRequest()}
                                    />
                                </StackItem>
                            </Stack>
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
            {
                productId && (
                    <ProductDetail
                        productId={productId}
                        documentRowGuid={documentRowGuid}
                        onDismiss={(orderIsUpdated) => orderIsUpdated ? onProductOrderDetailUpdated() : setProductId(undefined)} />
                )
            }
            {
                deleteUpdateDocumentDetailsStatusViewModel && (
                    <Dialog
                        title={DictionaryAPI.getTranslation("Remove")}
                        subText={DictionaryAPI.getTranslation("DeleteFromOrder") + "?"}
                        acceptButtonDisabled={fetching}
                        cancelButtonDisabled={fetching}
                        onAccept={handleDelete}
                        onCancel={() => setDeleteUpdateDocumentDetailsStatusViewModel(undefined)}
                        maxWidth={300}
                    />
                )

            }
        </>
    ) : <Loader />
};