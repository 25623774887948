import React, { useState, useRef } from 'react';
import { getTheme, Label } from 'office-ui-fabric-react';
import { IDrawableCanvasProps } from './interfaces';

export const DrawableCanvas = (props: IDrawableCanvasProps) => {
    const { ref, labeltext, drawColor, drawWidth, containerStyle } = props;
    //const [lastCoordinates, setLastCoordinates] = useState<number[]>([0, 0]);
    const canvasRef = useRef<HTMLCanvasElement>();

    let mousePressed = false;
    let lastX: number, lastY: number;
    const theme = getTheme();

    const draw = (x: number, y: number, isDown: boolean) => {
        const offsetX = x - canvasRef.current.offsetLeft;
        const offsetY = y - canvasRef.current.offsetTop;


        if (isDown) {
            const ctx = canvasRef.current.getContext("2d");
            ctx.beginPath();
            ctx.strokeStyle = drawColor ? drawColor : "black";
            ctx.lineWidth = drawWidth ? drawWidth : 2;
            ctx.lineJoin = "round";
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(offsetX, offsetY);
            ctx.closePath();
            ctx.stroke();
        }

        lastX = offsetX; lastY = offsetY;
    }

    function onMouseDown(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        mousePressed = true;
        draw(event.pageX, event.pageY, false);
    };

    function onMouseMove(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        draw(event.pageX, event.pageY, mousePressed);
    };

    const onMouseUpAndMouseLeave = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        mousePressed = false;
    };

    return (
        <div style={containerStyle}>
            {
                labeltext && (
                    <Label>{labeltext}</Label>
                )
            }
            <canvas
                ref={canvasRef}
                style={{
                    height: 150,
                    width: 200,
                    border: "1px solid " + theme.semanticColors.inputBorder,
                    //boxSizing: "border-box"
                }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUpAndMouseLeave}
                onMouseLeave={onMouseUpAndMouseLeave}
            >


            </canvas>
        </div>
    );
};