import React, { useState, useRef } from 'react';
import {
    getTheme, ITheme, IStyle,
    Stack, StackItem,
    ISearchBoxStyleProps,
    ISearchBoxStyles,
    CommandBar,
    ICommandBar,
    ICommandBarItemProps, IButtonStyles, Panel
} from 'office-ui-fabric-react/lib';

import { SearchBox } from '../SearchBox';
import { IFilterBarItemProps, IFilterBarProps } from '.';
import { DictionaryAPI } from 'api';
import { ActiveFilters } from './ActiveFilters';
import { FilterContextFields } from 'enums/fields/core/FilterContextFields';
import { FilterBarItemDropDown } from './FilterBarItemDropDown';
import { IBaseFilter, IFilterContext } from 'interfaces/models';

const theme: ITheme = getTheme();
const filterBarStyle: IStyle = {
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.neutralLighterAlt,
}
const filterButtonStyles: IButtonStyles = {
    root: {
        color: theme.palette.neutralSecondary,
        backgroundColor: theme.palette.neutralLighterAlt,
        lineHeight: 32,
        height: 32,
    },
    rootDisabled: {
        backgroundColor: theme.palette.neutralLighterAlt,
    },
    icon: { color: theme.palette.neutralSecondary },
    iconHovered: { color: theme.palette.neutralSecondary },
    iconPressed: { color: theme.palette.black },
}

const getSearchBoxStyles = (props: ISearchBoxStyleProps): Partial<ISearchBoxStyles> => {
    return {
        root: {
            backgroundColor: theme.palette.neutralLighterAlt,
            border: "none",
            width: 250,
        },
        icon: { color: theme.palette.neutralSecondary }
    }
}

const { FILTER_INFO } = FilterContextFields;

export const FilterBar = <T extends IFilterContext>(props: IFilterBarProps<T>) => {

    const {
        filterContextHandler,
        filterBarItemNamesToHide,
        searchPlaceHolder,
        onRenderFilterBarItem } = props;

    const { filterContext, onActiveToggle, onClearAllFilters, onClearFilter, onUnCheckFilterValue, onSearchChange } = filterContextHandler;
    const { IsActive, Search } = filterContext.FilterInfo;

    const [activeFiltersVisible, setActiveFiltersVisible] = useState(false);
    const commandBarRef = useRef<ICommandBar>();

    const getItems = (): IFilterBarItemProps[] => {

        const items: IFilterBarItemProps[] = [];

        for (var filterPropName in filterContext) {
            if (Object.prototype.hasOwnProperty.call(filterContext, filterPropName)) {

                if (filterPropName !== FILTER_INFO) {
                    const filterProp = filterContext[filterPropName];

                    if (filterProp && !filterBarItemNamesToHide?.includes(filterPropName)) {
                        const filter: IBaseFilter = filterProp as any;

                        if (filter.DisplayName) {
                            items.push({
                                filter: filterProp as any,
                                name: filterPropName
                            });
                        }
                    }
                }
            }
        }

        return items.sort(function(a, b) {
            return a.filter.DisplayOrder - b.filter.DisplayOrder;
        });
    }

    const onSearchValueChanged = (value?: string, name?: string) => {
        onSearchChange && onSearchChange(value || "");
    }

    const onActiveToggleButtonClick = () => {
        onActiveToggle && onActiveToggle(!IsActive);
    }

    const getCommandBarFarItems = (): ICommandBarItemProps[] => {
        const farItems: ICommandBarItemProps[] = [
            {
                key: "SearchBox",
                onRender: () => (
                    <SearchBox
                        name="Search"
                        placeholder={searchPlaceHolder || DictionaryAPI.getTranslation("TypeToSearch")}
                        value={Search}
                        searchBoxStyles={getSearchBoxStyles}
                        onChange={onSearchValueChanged}
                    />
                )
            },
            {
                key: "activeFilters",
                iconOnly: true,
                role: "menuItem",
                title: DictionaryAPI.getTranslation("ActiveFilters"),
                checked: activeFiltersVisible === true,
                iconProps: { iconName: "Filter" },
                buttonStyles: filterButtonStyles,
                onClick: () => setActiveFiltersVisible(true)
            }
        ]

        if (IsActive !== null) {
            farItems.splice(1, 0,
                {
                    key: "ActiveToggle",
                    text: IsActive ? DictionaryAPI.getTranslation("Active") : DictionaryAPI.getTranslation("NonActive"),
                    title: DictionaryAPI.getTranslation("Toggle"),
                    iconProps: {
                        iconName: IsActive ? "CheckMark" : "Cancel"
                    },
                    buttonStyles: {
                        ...filterButtonStyles,
                        icon: { color: IsActive ? theme.palette.green : theme.palette.red }
                    },
                    onClick: onActiveToggleButtonClick
                });
        }

        return farItems;
    }

    return (
        <>
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack>
                    <StackItem grow>
                        <div style={{ maxWidth: "100%" }}>
                            <CommandBar
                                componentRef={commandBarRef}
                                items={getItems().filter(f => f.filter.Values.length > 0).map((v, i) => {
                                    const isChecked = v.filter.Values.find(v => v.IsChecked) !== undefined;
                                    return {
                                        key: String(i),
                                        text: DictionaryAPI.getTranslation(v.filter.DisplayName),
                                        //disabled: v.filter.Values.length === 0,
                                        checked: isChecked,
                                        buttonStyles: isChecked ? {
                                            ...filterButtonStyles,
                                            label: {
                                                fontWeight: 600,
                                            },
                                        } : filterButtonStyles,
                                        subMenuProps: {
                                            onRenderMenuList: () => (
                                                <FilterBarItemDropDown onClearClick={() => onClearFilter(v.name)}>
                                                    {onRenderFilterBarItem(v)}
                                                </FilterBarItemDropDown>
                                            ),
                                            items: [{
                                                key: String(i),
                                            }]
                                        }
                                    }
                                })}
                                farItems={getCommandBarFarItems()}
                                overflowButtonProps={{ styles: { root: filterBarStyle } }}
                                styles={{ root: filterBarStyle }} />
                        </div>
                    </StackItem>
                </Stack>
            </Stack>
            {
                activeFiltersVisible && (
                    <Panel
                        headerText={DictionaryAPI.getTranslation("ActiveFilters")}
                        isOpen={true}
                        isBlocking={true}
                        isLightDismiss={true}
                        onDismiss={() => setActiveFiltersVisible(false)}
                        closeButtonAriaLabel={DictionaryAPI.getTranslation("Close")}
                    >
                        <ActiveFilters
                            items={getItems()}
                            onUnCheckFilterValue={onUnCheckFilterValue}
                            onClearAllFilters={onClearAllFilters} />
                    </Panel>
                )
            }
        </>
    );
}