import React from 'react';
import { UnderlinedTextField } from '../UnderlinedTextField';
import { DictionaryAPI } from 'api';
import { IPasswordTextFieldProps } from '.';

export const PasswordTextField = (props: IPasswordTextFieldProps) => {
    const { value, isRepeat, errorMessage, style, disabled, onChange } = props;
    const placeholder: string = isRepeat ? DictionaryAPI.getTranslation("RepeatPassword") : DictionaryAPI.getTranslation("Password");
    return (
        <UnderlinedTextField
            value={value}
            key="Password"
            name={props.isRepeat ? "RepeatPassword" : "Password"}
            type="password"
            placeholder={placeholder}
            errorMessage={errorMessage}
            style={style}
            disabled={disabled}
            onChange={onChange}
        />
    );
}