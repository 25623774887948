import { DictionaryAPI, DocumentAPI } from 'api'
import { Dialog } from 'components/common'
import { DocumentDetailStatuses } from 'enums'
import { DocumentFormDetailsTabInteractionMenus } from 'enums/app/DocumentFormDetailsTabInteractionMenus'
import { UpdateDocumentDetailsViewModelFields } from 'enums/fields'
import { useForm } from 'hooks'
import { IUpdateDocumentDetailsViewModel } from 'interfaces/viewmodels'
import { TextField } from 'office-ui-fabric-react'
import React from 'react'
import { IUpdateDetailsStatusDialogProps } from './interfaces'

export const UpdateDocumentDetailsStatusDialog = (props: IUpdateDetailsStatusDialogProps) => {

    const { viewModel, onDismiss } = props;
    const { item, isSubmitting, submit, onUnControlledTextFieldChange } = useForm<IUpdateDocumentDetailsViewModel>(viewModel, undefined, DocumentAPI.updateDetailsStatus);
    const { REMARKS } = UpdateDocumentDetailsViewModelFields;

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("UpdateStatus")}
            subText={DictionaryAPI.getTranslation(DocumentDetailStatuses[viewModel.DocumentDetailStatusId])}
            acceptButtonDisabled={isSubmitting}
            cancelButtonDisabled={isSubmitting}
            onAccept={() => submit(() => onDismiss(true), undefined, true)}
            onCancel={() => onDismiss()}
            maxWidth={400}
        >
            <TextField
                autoFocus
                multiline
                defaultValue={item.Remarks}
                name={REMARKS}
                label={DictionaryAPI.getTranslation(REMARKS)}
                style={{ minHeight: 100, minWidth: 300 }}
                onChange={onUnControlledTextFieldChange} />
        </Dialog>
    )
}
