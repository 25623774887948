import { ICatalogListItem } from 'interfaces/models'
import { ProductCatalogsTypes } from './types'

export const SET_PRODUCT_CATALOGS = "SET_PRODUCT_CATALOGS";

export function setProductCatalogs(productCatalogs: ICatalogListItem[]): ProductCatalogsTypes {
    return {
        type: SET_PRODUCT_CATALOGS,
        productCatalogs: productCatalogs
    }
}