export enum OrderFormInteractionMenus {
    Print = 48,
    Print_Selection = 49,
    Print_Input = 50,
    Print_Confirmation = 51,
    Print_Summary = 84,
    Export = 76,
    Export_ProductDetailsCsv = 77,
    Export_ProductDetailsEdmCsv = 104,
    Export_SendProductDetailsEdmByFtp = 105,
    Copy = 101,
    Copy_SalesOrder = 102,
    Copy_StockAssortment = 103,
}