import React from 'react';
import { mergeStyleSets, PrimaryButton, DefaultButton, FontSizes, IButtonProps } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { IButtonBarProps } from './interfaces';
import { DefaultCommandTypes } from 'enums';

export const ButtonBar = (props: IButtonBarProps) => {

    const { items, isSmall, onClick } = props;
    const classNames = mergeStyleSets({
        container: {
            marginTop: 10
        },
        button: isSmall && {
            fontSize: FontSizes.small,
            height: 28
        }
    })

    return (
        <div className={classNames.container}>
            {items.map((v) => {
                const text = DictionaryAPI.getTranslation(DefaultCommandTypes[v.type]);
                let iconName = "";
                switch (v.type) {
                    case DefaultCommandTypes.Save:
                        iconName = "Save"
                        break;

                    default:
                        break;
                }

                const buttonProps: IButtonProps = {
                    text: text,
                    disabled: v.isDisabled,
                    iconProps: { iconName: iconName },
                    className: classNames.button,
                    onClick: () => onClick(v.type)
                }

                return v.isPrimary ? 
                    <PrimaryButton key={v.type} {...buttonProps} /> :
                    <DefaultButton key={v.type} {...buttonProps} />
            })}

        </div>
    );
};