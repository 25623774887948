import React from 'react';

import {
    Dialog as FabricDialog, DialogType, DialogFooter,
    PrimaryButton, DefaultButton, Modal, mergeStyleSets
} from 'office-ui-fabric-react';

import { IDialogProps } from '.'
import { DictionaryAPI } from 'api';
import { Loader } from 'components/common';

export const Dialog: React.FunctionComponent<IDialogProps> = props => {

    const { title, subText, maxWidth, AcceptButtonText, CancelButtonText, isHidden, isFooterHidden, isLoading, onAccept, onCancel } = props;

    const contentStyles = mergeStyleSets({
        modalLoaderContainer: {
            display: 'flex',
        },
    });

    return isLoading ? (
        <Modal
            isBlocking={true}
            isOpen={true}
            isDarkOverlay={false}
            containerClassName={contentStyles.modalLoaderContainer}>
            <Loader />
        </Modal>) : (

            <FabricDialog
                hidden={isHidden === true}
                maxWidth={maxWidth}
                onDismiss={onCancel}
                dialogContentProps={{
                    type: DialogType.close,
                    title: title,
                    subText: subText,
                    showCloseButton: true
                }}
                modalProps={{
                    isBlocking: false
                }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    {
                        props.children
                    }
                    {
                        !isFooterHidden && (
                            <DialogFooter>
                                <PrimaryButton
                                    text={AcceptButtonText ? AcceptButtonText : DictionaryAPI.getTranslation("OK")}
                                    disabled={props.acceptButtonDisabled}
                                    type="submit"
                                    onClick={onAccept} />
                                <DefaultButton
                                    text={CancelButtonText ? CancelButtonText : DictionaryAPI.getTranslation("Cancel")}
                                    disabled={props.cancelButtonDisabled}
                                    type="reset"
                                    onClick={onCancel} />
                            </DialogFooter>
                        )
                    }
                </form>
            </FabricDialog>

        );
}