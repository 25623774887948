export enum CatalogListItemFields {
    ACTIVE_FROM = "ActiveFrom",
    ACTIVE_TO = "ActiveTo",
    CATALOG_GROUP_ID = "CatalogGroupId",
    CATALOG_GROUP_NAME = "CatalogGroupName",
    CREATED_ON = "CreatedOn",
    ID = "Id",
    INPUT_ACCOUNT_ID = "InputAccountId",
    IS_ACTIVE = "IsActive",
    NAME = "Name",
    PRODUCT_CATEGORY_ID = "ProductCategoryId",
    PRODUCT_CATEGORY_NAME = "ProductCategoryName",
    COLLECTION_ID = "CollectionId",
    COLLECTION_CODE = "CollectionCode",
    ROW_GUID = "RowGuid"
}