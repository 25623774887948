import React, { useState, useEffect } from 'react';
import { DateRangeFilter, IFilterBarItemProps } from 'components/common';
import { ICatalogBaseTabProps } from './interfaces';
import { CatalogRelationListItemFields } from 'enums/fields';
import { CatalogAPI, DictionaryAPI, RelationAPI } from 'api';
import { ICatalogRelationFilterContext, ICatalogRelationListItem, IFilter, IRangeFilter } from 'interfaces/models';
import { IColumn, ComboBox, DayOfWeek } from 'office-ui-fabric-react';
import { IDetailsListColumn, FilterBar, CheckboxFilter } from 'components/common';
import { useFilterContextHandler, useDates, useSelection } from 'hooks';
import { DefaultCommandTypes } from 'enums';
import { RelationDialog } from './RelationDialog';
import { ListView } from 'components/common';

export const RelationsTab = (props: ICatalogBaseTabProps) => {

    const { catalogRowGuid, form, formMenu, cultureId, } = props;
    const [relationTypeId, setRelationTypeId] = useState<number>(form.item.DefaultSelectedRelationTypeId);
    const [relationRowGuid, setRelationRowGuid] = useState<string>();

    const {
        NAME,
        RELATION_ROW_GUID,
        COUNTRY_ID,
        CATALOG_ACTIVE_FROM,
        CATALOG_ACTIVE_TO,
    } = CatalogRelationListItemFields;

    const filterContextHandler = useFilterContextHandler<ICatalogRelationFilterContext>(
        (onSuccess) => {
            CatalogAPI.getRelationFilterContext(catalogRowGuid, relationTypeId || 0, onSuccess);
        },
        CatalogAPI.filterRelations,
        undefined,
        true);

    const {
        filterContext,
        filterResultInfo,
        isFiltering,
        onFilterChange,
        onRangeFilterChange,
        onPageChange,
        onOrderByChange,
        getFilterContext,
        getFilterResultInfo } = filterContextHandler;

    const { selection, selectedKeys } = useSelection<string>(RELATION_ROW_GUID, filterResultInfo?.Items, [], false);

    const { dateByUtcToLocaleDateString } = useDates(cultureId);

    const renderRelationTypeComboBox = (): React.ReactNode => {

        return (
            <ComboBox
                style={{ marginLeft: 10 }}
                selectedKey={relationTypeId}
                placeholder={DictionaryAPI.getTranslation("SelectOrType")}
                onChange={(ev, option) => setRelationTypeId(Number(option?.key))}
                options={form.item?.RelationTypes.map((v) => {
                    return {
                        key: v.Id,
                        text: DictionaryAPI.getTranslation(v.Name),
                    }
                })}
            />
        );
    };

    const getDetailsListColumns = (): IDetailsListColumn[] => {
        const columns: IDetailsListColumn[] = [
            { fieldName: NAME, width: 200, isRowHeader: true },
            { fieldName: COUNTRY_ID, name: "Country", width: 70 },
            { fieldName: CATALOG_ACTIVE_FROM, name: "ActiveFrom", width: 100 },
            { fieldName: CATALOG_ACTIVE_TO, name: "ActiveTo", width: 100 },
        ];

        return columns;
    }

    const onRenderRelationsListItemColumn = (item?: ICatalogRelationListItem, index?: number | undefined, column?: IColumn | undefined): React.ReactNode | undefined => {
        if (item && column && column.fieldName && item[column.fieldName]) {

            switch (column.fieldName) {
                case CATALOG_ACTIVE_FROM:
                case CATALOG_ACTIVE_TO:
                    return <span>{dateByUtcToLocaleDateString(item[column.fieldName])}</span>

                default:
                    break;
            }

            return <span>{item[column.fieldName]}</span>
        }
    }

    const onRenderFilterBar = (): JSX.Element | undefined => {

        if (filterContext) {
            return (
                <FilterBar
                    filterContextHandler={filterContextHandler}
                    onRenderFilterBarItem={onRenderFilterBarItem}
                />
            )
        }
    }

    const onRenderFilterBarItem = (filterBarItemProps: IFilterBarItemProps) => {

        const { filter, name } = filterBarItemProps;

        switch (name) {
            case COUNTRY_ID:
                return <CheckboxFilter
                    filter={filter as IFilter}
                    name={name}
                    onChange={onFilterChange} />

            default:
                return <DateRangeFilter
                    cultureId={cultureId}
                    firstDayOfWeek={DayOfWeek.Monday}
                    filter={filter as IRangeFilter}
                    name={name}
                    onChange={onRangeFilterChange} />
        }
    }

    const onDefaultCommandClick = (type: DefaultCommandTypes) => {
        switch (type) {
            case DefaultCommandTypes.Add:
                setRelationRowGuid("");
                break;
            case DefaultCommandTypes.Delete:
                if (selectedKeys.length > 0) {
                    RelationAPI.clearCatalog(selectedKeys[0], () => getFilterContext());
                }

                break;
            default:
                break;
        }
    }

    const onItemClick = (item: ICatalogRelationListItem) => {
        setRelationRowGuid(item.RelationRowGuid);
    }

    useEffect(() => {
        if (relationTypeId !== undefined) {
            getFilterContext();
        }
    }, [relationTypeId])

    useEffect(() => {
        setRelationRowGuid(undefined);
    }, [filterContext])

    return (
        <>
            <ListView
                compact
                menu={formMenu}
                onRenderContentBeforeCommandBar={renderRelationTypeComboBox}
                onDefaultCommandClick={onDefaultCommandClick}
                detailsListColumns={getDetailsListColumns()}
                onRenderDetailsListItemColumn={onRenderRelationsListItemColumn}
                onRefreshButtonClick={getFilterResultInfo}
                items={filterResultInfo?.Items}
                pagingProps={filterContext && filterResultInfo && {
                    itemCount: filterResultInfo.ItemCount,
                    pageCount: filterResultInfo.PageCount,
                    page: filterContext.FilterInfo.Page,
                    pageSize: filterContext.FilterInfo.PageSize,
                    onPageChanged: onPageChange
                }}
                onItemClick={onItemClick}
                orderByRules={filterContext?.FilterInfo?.OrderByRules}
                onOrderByChange={onOrderByChange}
                selection={selection}
                loading={!filterContext}
                dataLoading={isFiltering}
                
                onRenderFilterBar={onRenderFilterBar}
            />
            {
                relationRowGuid !== undefined && (
                    <RelationDialog
                        catalogRowGuid={catalogRowGuid}
                        relationTypeId={relationTypeId}
                        relationRowGuid={relationRowGuid}
                        onAccept={getFilterContext}
                        onCancel={() => setRelationRowGuid(undefined)} />
                )
            }
        </>
    );
};