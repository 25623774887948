import { CatalogAPI, DictionaryAPI } from 'api';
import { Dialog, FileUpload } from 'components/common';
import { CatalogPageViewModelFields } from 'enums/fields';
import { useForm } from 'hooks';
import { ICatalogPageViewModel } from 'interfaces/viewmodels';
import { Label, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React, { useEffect, useRef } from 'react'
import { IPageDialogProps } from './interfaces';

export const PageDialog = (props: IPageDialogProps) => {

    const { catalogPageId, onAccept, onCancel } = props;

    const fileListRef = useRef<FileList>();

    const {
        item,
        isSubmitting,
        updateItem,
        onTextFieldChange,
        hasChanges,
        initialize,
        submit } = useForm<ICatalogPageViewModel>(
            undefined,
            undefined,
            (data, onSuccess) => {
                CatalogAPI.updatePage(data, fileListRef.current ? fileListRef.current[0] : undefined, onSuccess);
            });

    const { TITLE, IMAGE } = CatalogPageViewModelFields;

    const onFileUploadChange = (fileList: FileList) => {
        updateItem(IMAGE, fileList[0].name);
        fileListRef.current = fileList;
    }

    useEffect(() => {
        CatalogAPI.getPage(catalogPageId, (data) => initialize(true, data))
    }, [catalogPageId])

    return (
        <Dialog
            title={DictionaryAPI.getTranslation("Page")}
            maxWidth={450}
            isLoading={!item}
            acceptButtonDisabled={(!hasChanges) || isSubmitting}
            cancelButtonDisabled={isSubmitting}
            onAccept={() => submit(() => onAccept(), undefined, true)}
            onCancel={onCancel}
        >
            {item && (
                <form>
                    <Stack>
                        <StackItem>
                            <TextField
                                autoFocus
                                label={DictionaryAPI.getTranslation(TITLE)}
                                name={TITLE}
                                value={item.Title}
                                disabled={isSubmitting}
                                onChange={onTextFieldChange} />
                        </StackItem>
                        <StackItem>
                            <FileUpload
                                label={DictionaryAPI.getTranslation("Image")}
                                accept="jpg"
                                onChange={onFileUploadChange} />
                        </StackItem>
                    </Stack>
                </form>
            )}

        </Dialog>
    )
}
