import React from 'react';
import { IDocumentTotalsProps } from './interfaces';
import { getTheme, mergeStyleSets, FontWeights, FontSizes } from 'office-ui-fabric-react';
import { DictionaryAPI } from 'api';
import { useNumberFormat } from 'hooks';

export const DocumentTotalsTable = (props: IDocumentTotalsProps) => {

    const { documentSummary, cultureId } = props;

    const { getPrice, getNumber } = useNumberFormat(cultureId);
    const theme = getTheme();

    const classNames = mergeStyleSets({
        container: {
            color: theme.semanticColors.bodySubtext,

        },
        contentContainer: {
        },
        totalsTable: {
            borderCollapse: "collapse",
            width: "100%",
            minWidth: 175
        },
        totalsTableHeaderCell: {
            fontWeight: FontWeights.semibold,
            padding: "5px 5px 5px 0",
            textAlign: "left"
        },
        totalsTableValueCell: {
            textAlign: "right",
        },
        totalsTableLastCell: {
            borderTop: "1px solid " + theme.semanticColors.bodyFrameDivider,
            fontSize: FontSizes.mediumPlus,
            fontWeight: FontWeights.bold,
        },
    });

    return (
        <table className={classNames.totalsTable}>
            <tbody>
                <tr>
                    <th className={classNames.totalsTableHeaderCell}>{DictionaryAPI.getTranslation("Quantity")}</th>
                    <td className={classNames.totalsTableValueCell}>{getNumber(documentSummary.Quantity)}</td>
                </tr>
                {
                    documentSummary.DiscountTotal > 0 && (
                        <>
                            <tr>
                                <th className={classNames.totalsTableHeaderCell}>{DictionaryAPI.getTranslation("SubtotalExclDiscount")}</th>
                                <td className={classNames.totalsTableValueCell}>{getPrice(documentSummary.SubtotalExclDiscount)}</td>
                            </tr>
                            <tr>
                                <th className={classNames.totalsTableHeaderCell}>{DictionaryAPI.getTranslation("DiscountTotal")}</th>
                                <td className={classNames.totalsTableValueCell}>{getPrice(documentSummary.DiscountTotal)}</td>
                            </tr>
                        </>
                    )
                }
                <tr>
                    <th className={classNames.totalsTableHeaderCell}>{DictionaryAPI.getTranslation("Subtotal")}</th>
                    <td className={classNames.totalsTableValueCell}>{getPrice(documentSummary.Subtotal)}</td>
                </tr>
{/*                 <tr>
                    <th className={classNames.totalsTableHeaderCell}>{DictionaryAPI.getTranslation("Shipping")}</th>
                    <td className={classNames.totalsTableValueCell}>{getPrice(documentTotalsViewModel.Shipping, CurrencyId)}</td>
                </tr> */}
                <tr>
                    <th className={classNames.totalsTableHeaderCell + " " + classNames.totalsTableLastCell}>{DictionaryAPI.getTranslation("Total")}</th>
                    <td className={classNames.totalsTableValueCell + " " + classNames.totalsTableLastCell}>{getPrice(documentSummary.Total)}</td>
                </tr>
            </tbody>
        </table>
    );
};