import { IUseStringProps } from "./interfaces";

export const useString = (): IUseStringProps => {

    const isEmpty = (string: string | undefined | null): boolean => {
        return string === null || string === undefined || string === "";
    }

    return {
        isEmpty
    }
};