import React from 'react'
import { IScrollableContainterProps } from './interfaces'

export const ScrollableContainer: React.FC<IScrollableContainterProps> = props => {

    const { width, height, padding, children } = props;

    return (
        <div
            style={{
                position: "relative",
                height: height || "100%",
                width: width || "100%",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    inset: 0,
                    padding: padding,
                    overflow: "auto"
                }}
            >
                {children}
            </div>
        </div>
    )
}
